import { Grid, Tooltip } from "@mui/material";
import chroma from "chroma-js";
import React, { useEffect } from "react";
import { useState } from "react";

export function ComplementaryColors({
  setPalletInput,
  hexInput,
  firstColor,
  setFirstColor,
  secondColor,
  setSecondColor,
}) {
  // initial states

  //
  useEffect(() => {
    const complementColor1 = chroma(hexInput).brighten().hex();
    const complementColor2 = chroma(hexInput).darken().hex();
    setFirstColor(complementColor1);
    setSecondColor(complementColor2);
  }, [hexInput]);

  // handle set pallet input
  const handleSetPalletInput = (hexCode) => {
    setPalletInput(hexCode);
  };
  // handle copy text
  const handleCopyHashText = (content) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(content);
    } else {
      console.log("Clipboard API not supported");
    }
  };
  return (
    <>
      <Grid container spacing={1} columns={12}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <span className="text-lg text-slate-100">Complementary Colors</span>
        </Grid>
        <Grid
          className="flex justify-start items-center gap-5"
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          <Tooltip title={firstColor} placement="top">
            <div
              onClick={() => {
                handleSetPalletInput(firstColor);
                handleCopyHashText(firstColor);
                document
                  .getElementsByClassName("container")[0]
                  .scrollTo({ top: 0, behavior: "smooth" });
              }}
              className="cursor-pointer w-12 h-12 rounded-full"
              style={{
                backgroundColor: firstColor,
                border: "0.8px solid gray",
              }}
            ></div>
          </Tooltip>{" "}
          <Tooltip title={secondColor} placement="top">
            <div
              onClick={() => {
                handleSetPalletInput(secondColor);
                handleCopyHashText(secondColor);
                document
                  .getElementsByClassName("container")[0]
                  .scrollTo({ top: 0, behavior: "smooth" });
              }}
              className="cursor-pointer w-12 h-12 rounded-full"
              style={{
                backgroundColor: secondColor,
                border: "0.8px solid gray",
              }}
            ></div>
          </Tooltip>
        </Grid>
      </Grid>
    </>
  );
}
