import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Collapse,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect, useState, useCallback, useContext } from "react";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import VerifiedIcon from "@mui/icons-material/Verified";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ProgressInfo } from "../../loading-components/ProgressPingWorld";
import LanguageIcon from "@mui/icons-material/Language";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import AddAlertIcon from "@mui/icons-material/AddAlert";
import { AddAlertToDomain, handleAutoLogOut } from "../../includes/functions";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SettingsSystemDaydreamIcon from "@mui/icons-material/SettingsSystemDaydream";
import CloudIcon from "@mui/icons-material/Cloud";
import DownloadingIcon from "@mui/icons-material/Downloading";
import { AppContext } from "../../context/AppContext";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { lng } from "../../lang/languagesObjects";
import { useLocation, useNavigate } from "react-router-dom";
import { db } from "../../db";
import { useApi } from "../../customAxios";
export function InfoWorldItem({
  infoInput,
  ipBrowser,
  setIpBrowser,
  darkMode,
  doRequest,
  flagURL,
  setDoRequest,
  setInfoPartDisplay,
  infoPartDisplay,
}) {
  // for multilanguage
  const { t } = useTranslation("prints");
  i18next.addResourceBundle("fa", "prints", lng.pingworld.info.fa);
  i18next.addResourceBundle("en", "prints", lng.pingworld.info.en);
  i18next.addResourceBundle("ar", "prints", lng.pingworld.info.ar);
  // initial states
  const api = useApi();
  const [isOnline, setIsOnline] = useState(true);
  const navigate = useNavigate();
  const { lang } = useContext(AppContext);
  const [expanded, setExpanded] = useState(false);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [helperText, setHelperText] = useState(<ProgressInfo />);
  const location = useLocation();

  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    // console.log("nav", navigator, navigator.connection.type);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch("https://api.ipify.org", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        setIpBrowser(result);
      })
      .catch((error) => console.log("error", error));
  }, []);

  useEffect(() => {
    if (ipBrowser && !doRequest && !infoInput) {
      setIsLoading(true);
      db.info
        .where("domain")
        .equals(ipBrowser)
        .first((result) => {
          if (result) {
            console.log("Cache hit!", result);
            setData(result.data);
            setIsLoading(false);
          } else {
            console.log("Cache miss!");
            api
              .post("/tools/ip/details", {
                url: ipBrowser,
              })
              .then((response) => {
                console.log("info res", response);
                if (response.data.status === false) {
                  throw Error("Server response was not ok.");
                }
                db.info.add({
                  domain: ipBrowser,
                  data: response.data.body,
                  expire: Date.now(),
                });
                setData(response.data.body);
                setIsLoading(false);
              })
              .catch((err) => {
                console.log("info err", err);
                setHelperText(err.message);
                setIsLoading(true);
                if (err.response?.status === 511) {
                  handleAutoLogOut(navigate);
                }
              });
          }
        });
    }
  }, [ipBrowser, doRequest]);

  useEffect(() => {
    if (doRequest) {
      setData(null);
      setIsLoading(true);
      setInfoPartDisplay(false);
      setHelperText(<ProgressInfo />);
      db.info
        .where("domain")
        .equals(infoInput)
        .first((result) => {
          if (result) {
            console.log("info req result: ", result);
            setData(result.data);
            setIsLoading(false);
          } else {
            console.log("info req Cache miss!");
            api
              .post("/tools/ip/details", {
                url: infoInput,
              })
              .then((response) => {
                console.log("info res", response);
                if (response.data.status === false) {
                  throw Error("Server response was not ok.");
                }
                db.info.add({
                  domain: infoInput,
                  data: response.data.body,
                  expire: Date.now(),
                });
                setIsLoading(false);
                setData(response.data.body);
              })
              .catch((err) => {
                console.log("info err", err);
                setHelperText(err.message);
                setIsLoading(true);
                if (err.response?.status === 511) {
                  handleAutoLogOut(navigate);
                }
              });
          }
        });
    }
  }, [doRequest, infoPartDisplay]);

  function Map() {
    return (
      <>
        <MapContainer
          style={{ width: "100%", height: "180px", zIndex: 0 }}
          className="leaflet-container"
          center={data && [data.lat, data.lon]}
          zoom={13}
          animate={true}
          zoomControl={false}
          scrollWheelZoom={false}
          dragging={false}
          doubleClickZoom={false}
          keyboard={false}
        >
          <TileLayer
            // accessToken="2003f983-6a16-4b13-af97-135f9aec96c8"
            url={`https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png?api_key=2003f983-6a16-4b13-af97-135f9aec96c8`}
          />
          <Marker position={data && [data.lat, data.lon]}> </Marker>
        </MapContainer>
      </>
    );
  }
  useEffect(() => {
    function handleOnline() {
      setIsOnline(true);
    }
    function handleOffline() {
      setIsOnline(false);
    }
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);
  return (
    <>
      <Grid container spacing={1} columns={12}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          {" "}
          <Card
            className={`${
              localStorage.getItem("theme") === "dark-mode"
                ? "card-bg-gradient-dark"
                : "card-bg-gradient-light"
            } flex flex-row items-center justify-around gap-2 w-full pb-2 pt-2 pl-3 pr-3`}
          >
            <Grid container spacing={1} columns={12}>
              <Grid
                className="flex flex-row items-center justify-start gap-2"
                item
                xs={12}
                sm={6}
                md={6}
                lg={3}
              >
                <LocationOnIcon fontSize="small" color="info" />
                <p className="text-slate-200 ">IP: </p>
                <p className="text-slate-200 ">{ipBrowser}</p>
              </Grid>
              <Grid
                className="flex flex-row items-center justify-start gap-2"
                item
                xs={12}
                sm={6}
                md={6}
                lg={3}
              >
                <SettingsSystemDaydreamIcon fontSize="small" color="info" />
                <p className="text-slate-200 whitespace-nowrap overflow-hidden text-ellipsis">
                  {t("connection", { lng: localStorage.getItem("lang") })}
                </p>
                <p className="text-slate-200 whitespace-nowrap overflow-hidden text-ellipsis">
                  {isOnline ? "Online" : "Offline"}
                </p>
              </Grid>
              <Grid
                className="flex flex-row items-center justify-start gap-2"
                item
                xs={12}
                sm={6}
                md={6}
                lg={3}
              >
                <DownloadingIcon fontSize="small" color="info" />
                <p className="text-slate-200 whitespace-nowrap overflow-hidden text-ellipsis">
                  {t("download", { lng: localStorage.getItem("lang") })}
                </p>
                <p className="text-slate-200 whitespace-nowrap overflow-hidden text-ellipsis">
                  {navigator.connection.downlink} Mbps
                </p>
              </Grid>
              <Grid
                className="flex flex-row items-center justify-start gap-2"
                item
                xs={12}
                sm={6}
                md={6}
                lg={3}
              >
                <CloudIcon fontSize="small" color="info" />
                <p className="text-slate-200 whitespace-nowrap overflow-hidden text-ellipsis">
                  {t("system", { lng: localStorage.getItem("lang") })}
                </p>
                <p className="text-slate-200 whitespace-nowrap overflow-hidden text-ellipsis">
                  {navigator.userAgentData
                    ? navigator.userAgentData.platform
                    : "Unknown"}
                </p>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          {" "}
          {data ? (
            <Card
              className={`tabsMargin p-3 ${
                localStorage.getItem("theme") === "dark-mode"
                  ? "card-bg-gradient-dark"
                  : "card-bg-gradient-light"
              }`}
              sx={{
                width: "100%",
              }}
            >
              <Grid columns={12} container spacing={1}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <CardMedia
                    height="190"
                    component={Map}
                    width="100%"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  {" "}
                  <CardContent
                    sx={{
                      padding: "0px 7px",
                    }}
                  >
                    <div className="flex flex-col items-start justify-start ">
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "start",
                          gap: "3px",
                          marginBottom: "3px",
                          color: darkMode ? "white" : null,
                        }}
                      >
                        IP address:
                        <p
                          className={darkMode ? "dark:text-slate-300" : null}
                          style={{ fontWeight: "bold" }}
                        >
                          {data && data.query}
                        </p>
                        <AddAlertToDomain url={data && data.query} />
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "start",
                          gap: "3px",
                          marginBottom: "3px",
                          color: darkMode ? "white" : null,
                        }}
                      >
                        {t("hostName", { lng: localStorage.getItem("lang") })}
                        <p className={darkMode ? "dark:text-slate-300" : null}>
                          {data && data.reverse}
                        </p>
                      </Typography>{" "}
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "start",
                          justifyContent: "start",
                          gap: "3px",
                          marginBottom: "3px",
                          color: darkMode ? "white" : null,
                        }}
                      >
                        ISP:
                        <p className={darkMode ? "dark:text-slate-300" : null}>
                          {data && data.isp}
                        </p>
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "start",
                          gap: "2px",
                          marginBottom: "3px",
                        }}
                      >
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{ color: darkMode ? "white" : null }}
                        >
                          {t("country", { lng: localStorage.getItem("lang") })}
                        </Typography>

                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "start",
                            gap: "3px",
                          }}
                        >
                          <img src={data && flagURL + data.countryLogo} />
                          <p
                            style={{ fontWeight: "bold" }}
                            className={darkMode ? "dark:text-slate-300" : null}
                          >
                            {data && data.country}
                          </p>{" "}
                          <p
                            className={darkMode ? "dark:text-slate-300" : null}
                          >
                            {" "}
                            ({data && data.countryCode})
                          </p>
                        </Typography>
                      </div>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "start",
                          gap: "3px",
                          marginBottom: "3px",
                          color: darkMode ? "white" : null,
                        }}
                      >
                        {t("region", { lng: localStorage.getItem("lang") })}
                        <p className={darkMode ? "dark:text-slate-300" : null}>
                          {data && data.regionName}
                        </p>
                      </Typography>{" "}
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "start",
                          gap: "3px",
                          marginBottom: "3px",
                          color: darkMode ? "white" : null,
                        }}
                      >
                        {t("city", { lng: localStorage.getItem("lang") })}
                        <p className={darkMode ? "dark:text-slate-300" : null}>
                          {" "}
                          {data && data.city}
                        </p>
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "start",
                          gap: "3px",
                          marginBottom: "3px",
                          color: darkMode ? "white" : null,
                        }}
                      >
                        {t("timeZone", { lng: localStorage.getItem("lang") })}
                        <p className={darkMode ? "dark:text-slate-300" : null}>
                          {" "}
                          {data && data.timezone}
                        </p>
                      </Typography>{" "}
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "start",
                          gap: "3px",
                          marginBottom: "3px",
                          color: "white",
                        }}
                      >
                        {t("postalCode", { lng: localStorage.getItem("lang") })}
                        <p className={darkMode ? "dark:text-slate-300" : null}>
                          {data && data.zip}
                        </p>
                      </Typography>
                    </div>
                  </CardContent>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <CardActions
                  disableSpacing
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingLeft: "0px",
                  }}
                >
                  <IconButton aria-label="Share">
                    <ShareIcon color="disabled" />
                  </IconButton>
                  <Tooltip title="Basically verified">
                    <VerifiedIcon color="disabled" />
                  </Tooltip>
                  <Typography
                    className="text-slate-200 pl-3 overflow-hidden text-ellipsis whitespace-nowrap"
                    onClick={handleExpandClick}
                  >
                    {data && data.isp}
                  </Typography>
                  <Tooltip
                    title="Ownership and management of this section will be available to you"
                    className="pl-3"
                  >
                    <Link
                      sx={{ color: "azure", fontSize: "12px", flexShrink: 0 }}
                    >
                      <Chip
                        color="primary"
                        label="I own it!"
                        size="small"
                        icon={
                          <FingerprintIcon
                            sx={{ color: "azure" }}
                            fontSize="small"
                          />
                        }
                      />
                    </Link>
                  </Tooltip>
                  <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                  >
                    <ExpandMoreIcon color="info" />
                  </ExpandMore>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  <CardContent>
                    <Typography className="text-slate-200 text-justify">
                      {data && data.company && data.company.description}
                    </Typography>
                    <Grid container className="mt-3" gap={3}>
                      <Grid item>
                        <Link href={data.company.site}>
                          <LanguageIcon color="primary"></LanguageIcon>{" "}
                          {data.company.site}
                        </Link>
                      </Grid>
                      <Grid item>
                        <Link href={data.company.email}>
                          <AlternateEmailIcon color="primary"></AlternateEmailIcon>{" "}
                          {data.company.email}
                        </Link>
                      </Grid>
                      {data.company.phone &&
                        data.company.phone.split(",").map((phone, index) => (
                          <Grid item key={index}>
                            <Link href={`tel:${phone.trim()}`}>
                              <LocalPhoneIcon color="primary"></LocalPhoneIcon>
                              {phone.trim()}
                            </Link>
                          </Grid>
                        ))}
                    </Grid>
                  </CardContent>
                </Collapse>
              </Grid>
            </Card>
          ) : (
            isLoading && (
              <p className="w-full flex items-center justify-center ">
                {helperText}
              </p>
            )
          )}
        </Grid>
      </Grid>
    </>
  );
}
