import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import {
  StyledButton,
  StyledTextField,
} from "../../../../../components/styled-components";
import ClearIcon from "@mui/icons-material/Clear";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { lng } from "../../../../../lang/languagesObjects";
import AddIcon from "@mui/icons-material/Add";
import { IconsItem } from "./components/IconsItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const Icons = memo(({ jsonKeys }) => {
  // for multi language
  const { t } = useTranslation("prints");
  i18next.addResourceBundle("fa", "prints", lng.manifest.fa);
  i18next.addResourceBundle("en", "prints", lng.manifest.en);
  i18next.addResourceBundle("ar", "prints", lng.manifest.ar);
  //initial states
  const [test, setTest] = useState(true);
  const [iconIdCounter, setIconIdCounter] = useState(0);
  const [iconKeys, setIconKeys] = useState([
    { id: iconIdCounter, data: { url: "", sizes: "", type: "" } },
  ]);
  // handle test state
  useEffect(() => {
    setTest(!test);
  }, []);

  // handle add icon
  const handleAddIcons = () => {
    setIconIdCounter(iconIdCounter + 1);
    const newIcons = [
      ...iconKeys,
      {
        id: iconIdCounter,
        data: { url: "", sizes: "", type: "" },
      },
    ];
    jsonKeys.current.icons = newIcons;
    setIconKeys(newIcons);
  };

  // handle remove icon
  const handleRemoveIcon = (id) => {
    const newListIcons = iconKeys.filter((icon) => icon.id !== id);
    jsonKeys.current.icons = newListIcons;
    setIconKeys(newListIcons);
  };
  return (
    <>
      <Accordion
        className="w-full text-slate-100 rounded-md"
        sx={{
          background: "rgb(29,103,158)",
          background:
            "linear-gradient(90deg, rgba(13, 166, 194, 0.23573179271708689) 0%, rgba(38, 36, 80, 0.804359243697479) 100%)",
        }}
      >
        <AccordionSummary
          className="w-full h-16"
          expandIcon={
            <ExpandMoreIcon fontSize="medium" className="text-slate-100" />
          }
        >
          <div className="w-full flex justify-between items-center mr-1">
            <p className="text-xl ml-2">Icons</p>
            <Tooltip
              title={
                <p
                  style={{
                    textAlign:
                      localStorage.getItem("dir") === "ltr" ? "left" : "right",
                  }}
                >
                  {t("iconHelperText", {
                    lng: localStorage.getItem("lang"),
                  })}
                </p>
              }
            >
              <HelpOutlineIcon
                className="pt-1"
                sx={{
                  cursor: "pointer",
                  paddingLeft: "4px",
                  paddingBottom: "2px",
                }}
                fontSize="medium"
                htmlColor="#aaaaaa"
              />
            </Tooltip>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3} columns={12}>
            {iconKeys.map((item, index) => {
              return (
                <>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <IconsItem
                      id={item.id}
                      handleRemoveIcon={handleRemoveIcon}
                      iconKeys={iconKeys}
                      setIconKeys={setIconKeys}
                      jsonKeys={jsonKeys}
                      index={index}
                      handleAddIcons={handleAddIcons}
                    />
                  </Grid>
                </>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
});
