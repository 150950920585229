export const lng = {
  home: {
    ar: {
      service: "خدمة",
      network: "شبكة",
      site: "موقع",
      domainHost: "المجال و الاستضافة",
    },
    en: {
      service: "Services",
      network: "Network",
      site: "Site",
      domainHost: "Domain & Host",
    },
    fa: {
      service: "خدمات",
      network: "شبکه",
      site: "سایت",
      domainHost: "دامنه و هاست",
    },
  },
  pingworld: {
    pingWorld: {
      ar: { enterUrl: "...ادخل الرابط" },
      en: { enterUrl: "Enter an url..." },
      fa: { enterUrl: "...آدرسی وارد کنید" },
    },
    info: {
      ar: {
        connection: "الاتصال:",
        download: "تحميل:",
        system: "النظام:",
        hostName: "اسم المضيف:",
        country: "دولة:",
        city: "مدينة:",
        region: "منطقة:",
        timeZone: "المنطقة الزمنية:",
        postalCode: "الرمز البريدي:",
      },
      en: {
        connection: "Connection:",
        download: "Download:",
        system: "System:",
        hostName: "Host Name:",
        country: "Country:",
        city: "City:",
        region: "Region:",
        timeZone: "Time Zone:",
        postalCode: "Postal Code:",
      },
      fa: {
        connection: "اتصال:",
        download: "دریافت:",
        system: "سیستم:",
        hostName: "نام هاست:",
        country: "کشور:",
        city: "شهر:",
        region: "منطقه:",
        timeZone: "منطقه زمانی:",
        postalCode: "کد پستی:",
      },
    },
    ping: {
      ar: { enterUrlPing: "لم يتم إدخال أي عنوان للتحليل" },
      en: { enterUrlPing: "No address has been entered for analysis" },
      fa: { enterUrlPing: "هیچ آدرسی برای آنالیز وارد نشده" },
    },
    dns: {
      ar: {
        notAvailable: "لا يوجد متصل",
        enterUrlDNS: "لم يتم إدخال أي عنوان للتحليل",
      },
      en: {
        notAvailable: "Not available",
        enterUrlDNS: "No address has been entered for analysis",
      },
      fa: {
        notAvailable: "در دسترس نیست",
        enterUrlDNS: "هیچ آدرسی برای آنالیز وارد نشده",
      },
    },
    tcp: {
      ar: {
        timeout: "توقف الاتصال",
        enterUrlTcp: "لم يتم إدخال أي عنوان للتحليل",
      },
      en: {
        timeout: "Connection timeout",
        enterUrlTcp: "No address has been entered for analysis",
      },
      fa: {
        timeout: "مهلت اتصال به پایان رسید",
        enterUrlTcp: "هیچ آدرسی برای آنالیز وارد نشده",
      },
    },
    udp: {
      ar: {
        enterUrlUdp: "لم يتم إدخال أي عنوان للتحليل",
        correctResult: "فتح أو فلتر النتيجة",
        notAvailable: "غير متاح",
      },
      en: {
        enterUrlUdp: "No address has been entered for analysis",
        correctResult: "Open or filtered",
        notAvailable: "not available",
      },
      fa: {
        enterUrlUdp: "هیچ آدرسی برای آنالیز وارد نشده",
        correctResult: "باز یا فیلتر شده",
        notAvailable: "در دسترس نیست",
      },
    },
    http: {
      ar: { enterUrlHttp: "ادخل الرابط...", notAvailableHttp: "غير متاح" },
      en: {
        enterUrlHttp: "Enter an url...",
        notAvailableHttp: "not available",
      },
      fa: {
        enterUrlHttp: "آدرسی وارد کنید...",
        notAvailableHttp: "در دسترس نیست",
      },
    },
  },
  viewSource: {
    ar: {
      enterUrl: "...ادخل الرابط",
      search: "بحث",
      noAddress: "لا يوجد عنوان مدخل",
      errorText: "خطا في الاتصال بالخادم",
    },
    en: {
      enterUrl: "Enter an url...",
      search: "Search",
      noAddress: "No address entered",
      errorText: "Error while requesting server",
    },
    fa: {
      enterUrl: "...آدرسی وارد کنید",
      search: "جستجو",
      noAddress: "آدرسی وارد نشده",
      errorText: "خطا در برقراری ارتباط با سرور",
    },
  },
  alertCenter: {
    ar: {
      addAlert: "إضافة تحذير",
      type: "نوع التحذير",
      period: "الفترة",
      expiration: "تاريخ الانتهاء",
      domain: "المجال",
      gateway: "البوابة",
      status: "الحالة",
      month: "شهر",
      year: "سنة",
      alertMessage:
        "إذا كان الموقع معطلاً بعد عشر عمليات فحص بينج، فسيتم تعطيل خدمة التحذير لموقعك، ثم قم بتنشيطها يدويًا.",
      optionsAutoComplete: "لا توجد خيارات متاحة",
      editAlertIconHover: "تعديل التنبيه",
      cancelEditAlert: "يلغي",
      deleteAlert: "يمسح",
      diableAlertText: "هل أنت متأكد أنك تريد تعطيل خدمة التنبيه؟",
      enableAlertText: "هل تريد تفعيل خدمة التنبيه؟",
      confirmation: "تأكيد",
      cancel: "إلغاء",
    },
    en: {
      addAlert: "Add alert",
      type: "type",
      period: "Period",
      expiration: "Expiration",
      domain: "Domain",
      gateway: "Gateway",
      status: "Status",
      month: "month",
      year: "year",
      alertMessage:
        "If the site is down after ten ping checks, the warning service will be disabled for your site, then activate it manually.",
      optionsAutoComplete: "No options available",
      editAlertIconHover: "Edit Alert",
      cancelEditAlert: "Cancel",
      deleteAlert: "Delete",
      diableAlertText: "Are you sure you want to disable the alert service?",
      enableAlertText: "Do you want to enable the alert service?",
      confirmation: "Yes",
      cancel: "Cancel",
    },
    fa: {
      addAlert: "افزودن هشدار",
      type: "نوع",
      period: "دوره",
      expiration: "تاریخ انقضا",
      domain: "دامنه",
      gateway: "روش",
      status: "وضعیت",
      month: "ماه",
      year: "سال",
      alertMessage:
        "اگر سایت پس از ده بررسی پینگ از کار بیفتد، سرویس هشدار برای سایت شما غیرفعال می شود، سپس آن را به صورت دستی فعال کنید.",
      optionsAutoComplete: "هیچ گزینه ای در دسترس نیست",
      editAlertIconHover: "ویرایش هشدار",
      cancelEditAlert: "لغو",
      deleteAlert: "حذف",
      diableAlertText:
        "آیا مطمئن هستید که می خواهید سرویس هشدار را غیرفعال کنید؟",
      enableAlertText: "آیا می خواهید سرویس هشدار را فعال کنید؟",
      confirmation: "تایید",
      cancel: "لغو",
    },
  },
  robotGenerator: {
    ar: {
      robot: "مولد الروبوت",
      crawlDelay: "تأخير الزحف",
      placeHolder: "اتركه فارغًا إذا لم يكن لديك",
      enterRoute: "أدخل الطريق",
      activeRobotText: "قم أولاً بتنشيط الروبوت",
      generate: "يولد",
      generatedRobotText: "تم إنشاء ملف Robots.txt",
      copied: "نسخ",
      second: "ثانية",
      allow: "يسمح",
      disAllow: "لا يجيز",
      sitemap: "خريطة الموقع",
      export: "يصدّر",
    },
    en: {
      robot: "Robot Generator",
      crawlDelay: "Crawl Delay",
      placeHolder: "Leave it blank if you don't have",
      enterRoute: "Enter a route",
      activeRobotText: "Activate the robot first",
      generate: "Generate",
      generatedRobotText: "Generated Robots.txt file",
      copied: "Copied",
      second: "second",
      allow: "Allow",
      disAllow: "DisAllow",
      sitemap: "Sitemap",
      export: "Export",
    },
    fa: {
      robot: "ایجاد ربات",
      crawlDelay: "تاخیر خزیدن",
      placeHolder: "اگر ندارید آن را خالی بگذارید",
      enterRoute: "یه مسیر را وارد کنید",
      activeRobotText: "ابتدا ربات را فعال کنید",
      generate: "ایجاد",
      generatedRobotText: "فایل Robots.txt ایجاد شد",
      copied: "کپی شد",
      second: "ثانیه",
      allow: "اجازه",
      disAllow: "عدم اجازه",
      sitemap: "نقشه سایت",
      export: "استخراج",
    },
  },
  jsonParser: {
    ar: {
      sampleJson: "عينة من بيانات json",
      importJson: "استيراد بيانات json",
      copyJson: "نسخ إلى الحافظة",
      search: "يبحث",
      formatError: "!تنسيق الإدخال جيسون خاطئ",
    },
    en: {
      sampleJson: "Sample JSON data",
      importJson: "Import JSON data",
      copyJson: "Copy to clipboard",
      search: "Search value",
      formatError: "The input Jason format is wrong!",
    },
    fa: {
      sampleJson: "نمونه json",
      importJson: "اضافه کردن josn",
      copyJson: "کپی کردن",
      search: "جستجوی مقدار ها",
      formatError: "!فرمت ورودی جیسون اشتباه است",
    },
  },
  manifest: {
    ar: {
      basic: "أساسي",
      Appearance: "مظهر",
      nameHelperText:
        "اسم الامتداد. يُستخدم لتحديد الامتداد في واجهة مستخدم المتصفح وفي مواقع مثل addons.mozilla.org.",
      shortNameHelperText:
        "اسم قصير للامتداد. إذا تم تقديمه، فسيتم استخدامه في السياقات التي يكون فيها حقل الاسم طويلًا جدًا. من المستحسن ألا يتجاوز الاسم المختصر 12 حرفًا. إذا لم يتم تضمين حقل الاسم المختصر في البيان.json، إذن سيتم استخدام الاسم بدلاً من ذلك وقد يتم اقتطاعه.",
      descriptionHelperText:
        "وصف مختصر للامتداد، مخصص للعرض في واجهة مستخدم المتصفح. في Firefox وChrome يمكن أن تصل هذه القيمة إلى 132 حرفًا. قد يختلف الحد الأقصى في المتصفحات الأخرى.",
      devToolsHelperText:
        "استخدم هذا المفتاح لتمكين الامتداد الخاص بك لتوسيع أدوات التطوير المضمنة في المتصفح.",
      localeHelperText:
        "يجب أن يكون هذا المفتاح موجودًا إذا كان الامتداد يحتوي على دليل _locales، ويجب أن يكون غائبًا بخلاف ذلك. فهو يحدد دليلاً فرعيًا لـ _locales، وسيتم استخدام هذا الدليل الفرعي للعثور على السلاسل الافتراضية لامتدادك.",
      homePageHelperText: "عنوان URL للصفحة الرئيسية للملحق.",
      autherHelperText:
        "مؤلف الامتداد، مخصص للعرض في واجهة مستخدم المتصفح. إذا تم توفير مفتاح المطور وكان يحتوي على خاصية الاسم، فسوف يتجاوز مفتاح المؤلف. لا توجد طريقة لتحديد مؤلفين متعددين.",
      versionHelperText: "سلسلة الإصدار للملحق.",

      manifestVersionHelperText:
        "يحدد هذا المفتاح إصدار البيان.json الذي يستخدمه هذا الملحق.",
      optionPageHelperText:
        "استخدم مفتاح التخزين لتحديد اسم ملف المخطط الذي يحدد بنية البيانات في التخزين المُدار.",
      storageHelperText:
        "استخدم مفتاح التخزين لتحديد اسم ملف المخطط الذي يحدد بنية البيانات في التخزين المُدار.",
      userScriptHelperText:
        "يوجه المتصفح إلى تحميل برنامج نصي معبأ في الامتداد، والمعروف باسم البرنامج النصي لواجهة برمجة التطبيقات (API)، ويتم استخدام هذا البرنامج النصي لتصدير مجموعة من أساليب واجهة برمجة التطبيقات المخصصة لاستخدامها في البرامج النصية للمستخدم. مسار البرنامج النصي لواجهة برمجة التطبيقات (API)، بالنسبة إلى ملف Manifest.json، يتم تعريفه كسلسلة في api_script",
      sidebarActionHelperText:
        "الشريط الجانبي هو جزء يتم عرضه على الجانب الأيسر من نافذة المتصفح، بجوار صفحة الويب. يوفر المتصفح واجهة مستخدم تمكن المستخدم من رؤية الأشرطة الجانبية المتاحة حاليًا وتحديد شريط جانبي لعرضه.",
      iconHelperText:
        "يحدد مفتاح الأيقونات أيقونات للامتداد الخاص بك. سيتم استخدام هذه الرموز لتمثيل الامتداد في مكونات مثل مدير الوظائف الإضافية.",
      generate: "مولد",
      notesIconHover: "ملاحظات مفيدة",
      helpfulNotesTitle: "ملاحظات مفيدة",
      helpulNotesText:
        "لكي يتعرف المتصفح على ملف البيان، يجب عليك وضع علامات التعريف وعلامات الارتباط هذه في رأس علامات html في ملف Index.html الخاص بمشروعك بحيث يتم تطبيق الميزات ذات الصلة بشكل صحيح.",
      gotIt: "فهمتها!",
      copyIconHover: "نسخ النص",
      exportIconHover: "ملف التصدير",
    },
    en: {
      basic: "Basic",
      Appearance: "Appearance",
      nameHelperText:
        "Name of the extension. This is used to identify the extension in the browser's user interface and on sites like addons.mozilla.org.",
      shortNameHelperText:
        "Short name for the extension. If given, this will be used in contexts where the name field is too long. It's recommended that the short name should not exceed 12 characters. If the short name field is not included in manifest.json, then name will be used instead and may be truncated.",
      descriptionHelperText:
        "A short description of the extension, intended for display in the browser's user interface. In Firefox and Chrome this value can be up to 132 characters. The limit in other browsers may differ.",
      devToolsHelperText:
        "Use this key to enable your extension to extend the browser's built-in devtools.",
      localeHelperText:
        "This key must be present if the extension contains the _locales directory, and must be absent otherwise. It identifies a subdirectory of _locales, and this subdirectory will be used to find the default strings for your extension.",
      homePageHelperText: "URL for the extension's home page.",
      autherHelperText:
        "The extension's author, intended for display in the browser's user interface. If the developer key is supplied and it contains the name property, it will override the author key. There's no way to specify multiple authors.",
      versionHelperText: "The version string for the extension.",
      manifestVersionHelperText:
        "This key specifies the version of manifest.json used by this extension.",
      optionPageHelperText:
        "Use the options_page key to define an options page that opens in a new tab. You use this page to enable users to modify your extension's settings.",
      storageHelperText:
        "Use the storage key to specify the name of the schema file that defines the structure of data in managed storage.",
      userScriptHelperText:
        "Instructs the browser to load a script packaged in the extension, known as the API script, this script is used to export a set of custom API methods for use in user scripts. The API script path, relative to the manifest.json file, is defined as a string in api_script",
      sidebarActionHelperText:
        "A sidebar is a pane that is displayed at the left-hand side of the browser window, next to the web page. The browser provides a UI that enables the user to see the currently available sidebars and to select a sidebar to display.",
      iconHelperText:
        "The icons key specifies icons for your extension. Those icons will be used to represent the extension in components such as the Add-ons Manager.",
      generate: "Generate",
      notesIconHover: "Helpful notes",
      helpfulNotesTitle: "Helpful notes",
      helpulNotesText:
        "In order to recognize the manifest file by the browser, you must put these meta tags and link tags in the head of html tags in the index.html file of your project so that the relevant features are applied correctly.",
      gotIt: "Got it!",
      copyIconHover: "Copy Text",
      exportIconHover: "Export File",
    },
    fa: {
      basic: "پایه",
      Appearance: "ظاهر",
      nameHelperText:
        "نام پسوند. این برای شناسایی برنامه افزودنی در رابط کاربری مرورگر و در سایت هایی مانند addons.mozilla.org استفاده می شود.",
      shortNameHelperText:
        "نام کوتاه برای پسوند. اگر داده شود، در زمینه هایی که فیلد نام خیلی طولانی است استفاده می شود. توصیه می شود نام کوتاه بیش از 12 کاراکتر نباشد. اگر قسمت نام کوتاه در manifest.json گنجانده نشده باشد، نام به جای آن استفاده خواهد شد و ممکن است کوتاه شود.",
      descriptionHelperText:
        "توضیح کوتاهی از برنامه افزودنی که برای نمایش در رابط کاربری مرورگر در نظر گرفته شده است. در فایرفاکس و کروم این مقدار می تواند تا 132 کاراکتر باشد. محدودیت در سایر مرورگرها ممکن است متفاوت باشد.",
      devToolsHelperText:
        "از این کلید برای فعال کردن برنامه افزودنی خود برای گسترش ابزارهای توسعه داخلی داخلی مرورگر استفاده کنید.",
      localeHelperText:
        "اگر پسوند حاوی دایرکتوری _locales باشد، این کلید باید وجود داشته باشد و در غیر این صورت باید وجود نداشته باشد. یک زیر شاخه از _locales را شناسایی می کند و از این زیر شاخه برای یافتن رشته های پیش فرض برای برنامه افزودنی شما استفاده می شود.",
      homePageHelperText: "URL برای صفحه اصلی برنامه افزودنی.",
      autherHelperText:
        "نویسنده برنامه افزودنی، برای نمایش در رابط کاربری مرورگر در نظر گرفته شده است. اگر کلید توسعه دهنده ارائه شده باشد و دارای ویژگی name باشد، کلید نویسنده را لغو می کند. راهی برای تعیین چندین نویسنده وجود ندارد.",
      versionHelperText: "رشته نسخه برای برنامه افزودنی.",
      manifestVersionHelperText:
        "این کلید نسخه manifest.json مورد استفاده توسط این افزونه را مشخص می کند.",
      optionPageHelperText:
        "از کلید options_page برای تعریف یک صفحه گزینه که در یک تب جدید باز می شود استفاده کنید. شما از این صفحه استفاده می کنید تا به کاربران امکان دهید تنظیمات افزونه شما را تغییر دهند.",
      storageHelperText:
        "از کلید ذخیره سازی برای تعیین نام فایل طرحواره استفاده کنید که ساختار داده ها را در ذخیره سازی مدیریت شده تعریف می کند.",
      userScriptHelperText:
        "به مرورگر دستور می‌دهد تا یک اسکریپت بسته‌بندی شده در برنامه افزودنی را که به نام اسکریپت API شناخته می‌شود، بارگیری کند، این اسکریپت برای صادر کردن مجموعه‌ای از روش‌های API سفارشی برای استفاده در اسکریپت‌های کاربر استفاده می‌شود. مسیر اسکریپت API، نسبت به فایل manifest.json، به عنوان یک رشته در api_script تعریف شده است.",
      sidebarActionHelperText:
        "یک نوار کناری پنجره ای است که در سمت چپ پنجره مرورگر، در کنار صفحه وب نمایش داده می شود. مرورگر یک رابط کاربری فراهم می کند که به کاربر امکان می دهد نوارهای کناری موجود در حال حاضر را ببیند و یک نوار کناری را برای نمایش انتخاب کند.",
      iconHelperText:
        "کلید نمادها نمادهایی را برای برنامه افزودنی شما مشخص می کند. این نمادها برای نمایش برنامه افزودنی در مؤلفه هایی مانند مدیر افزونه ها استفاده می شوند.",
      generate: "ایجاد",
      notesIconHover: "یادداشت های مفید",
      helpfulNotesTitle: "یادداشت های مفید",
      helpulNotesText:
        "برای تشخیص فایل مانیفست توسط مرورگر، باید این متا تگ ها و تگ های لینک را در سر تگ های html در فایل index.html پروژه خود قرار دهید تا امکانات مربوطه به درستی اعمال شود.",
      gotIt: "فهمیدم",
      copyIconHover: "کپی متن",
      exportIconHover: "استخراج فایل",
    },
  },
  siteMap: {
    ar: {
      enterUrlSitemap: "...ادخل الرابط",
      number: "رقم",
      notAvailableSitemap: "غير متاح",
    },
    en: {
      enterUrlSitemap: "Enter an url...",
      number: "Count",
      notAvailableSitemap: "not available",
    },
    fa: {
      enterUrlSitemap: "...آدرسی وارد کنید",
      number: "تعداد",
      notAvailableSitemap: "در دسترس نیست",
    },
  },
  robotCheck: {
    ar: {
      enterUrlRobotCheck: "...ادخل الرابط",
      notAvailableRobotCheck: "غير متاح",
      number: "رقم",
      helperText: "الموقع الذي تم إدخاله لا يحتوي على هذه الميزة",
      allow: "يسمح",
      disAllow: "لا يجيز",
    },
    en: {
      enterUrlRobotCheck: "Enter an url...",
      notAvailableRobotCheck: "not available",
      number: "Count",
      helperText: "The entered site does not contain this feature",
      allow: "Allow",
      disAllow: "DisAllow",
    },
    fa: {
      enterUrlRobotCheck: "...آدرسی وارد کنید",
      notAvailableRobotCheck: "در دسترس نیست",
      number: "تعداد",
      helperText: "سایت وارد شده این ویژگی را ندارد",
      allow: "اجازه",
      disAllow: "عدم اجازه",
    },
  },
  profile: {
    ar: {
      email: "بريد إلكتروني",
      mobile: "هاتف",
      logOutButton: "تسجيل خروج",
      creditNotAvailable: "لا الائتمان",
      alertTitle: "مركز التنبيه",
      devicesTitle: "الأجهزة",
      deviceTableDomain: "المجال",
      deviceTableExpiration: "تاريخ الانتهاء",
      deviceTableHost: "يستضيف",
      deviceTableISP: "ISP",
      deviceTableStatus: "الحالة",
      deviceTableVerifiedAt: "تم التحقق",
      notRegistered: "غير مسجل",
      activeDevice: "نشيط",
      inactiveDevice: "غير نشط",
      historyTitle: "تاريخ",
      historyEmpty: "لم يتم العثور على المجال!",
    },
    en: {
      email: "Email",
      mobile: "Phone",
      logOutButton: "Log Out",
      creditNotAvailable: "No credit",
      alertTitle: "Alert center",
      devicesTitle: "Devices",
      deviceTableDomain: "Domain",
      deviceTableExpiration: "Expiration",
      deviceTableHost: "Host",
      deviceTableISP: "ISP",
      deviceTableStatus: "Status",
      deviceTableVerifiedAt: "Verified At",
      notRegistered: "Not registered",
      activeDevice: "Active",
      inactiveDevice: "Inactive",
      historyTitle: "History",
      historyEmpty: "No domain found!",
    },
    fa: {
      email: "ایمیل",
      mobile: "موبایل",
      logOutButton: "خروج",
      creditNotAvailable: "فاقد اعتبار",
      alertTitle: "هشدار مرکزی",
      devicesTitle: "دستگاه ها",
      deviceTableDomain: "دامنه",
      deviceTableExpiration: "تاریخ انقضا",
      deviceTableHost: "میزبان",
      deviceTableISP: "ISP",
      deviceTableStatus: "وضعیت",
      deviceTableVerifiedAt: "تایید شده",
      notRegistered: "ثبت نشده",
      activeDevice: "فعال",
      inactiveDevice: "غیرفعال",
      historyTitle: "تاریخچه",
      historyEmpty: "دامنه ای یافت نشد!",
    },
  },
  login: {
    ar: {
      loginTitle: "تسجيل الدخول",
      loginButton: "تسجيل الدخول",
      mobileLabel: "هاتف",
      verifyButton: "يؤكد",
      resendButton: "إعادة إرسال",
    },
    en: {
      loginTitle: "Login",
      loginButton: "Login",
      mobileLabel: "Phone",
      verifyButton: "Verify",
      resendButton: "Resend",
    },
    fa: {
      loginTitle: "ورود",
      loginButton: "ورود",
      mobileLabel: "موبایل",
      verifyButton: "تایید",
      resendButton: "ارسال مجدد",
    },
  },
  updateDialog: {
    ar: {
      updateTitle: "تحديث الملف",
      firstName: "اسم",
      lastName: "اسم العائلة",
      updateEmail: "بريد إلكتروني",
      updatePhone: "هاتف",
      updateButton: "يحرر",
    },
    en: {
      updateTitle: "Update Profile",
      firstName: "First Name",
      lastName: "Last Name",
      updateEmail: "Email",
      updatePhone: "Phone",
      updateButton: "Update",
    },
    fa: {
      updateTitle: "ویرایش پروفایل",
      firstName: "نام",
      lastName: "نام خانوادگی",
      updateEmail: "ایمیل",
      updatePhone: "موبایل",
      updateButton: "ویرایش",
    },
  },
  logOut: {
    ar: {
      sureToLogOut: "هل أنت متأكد من تسجيل الخروج من حسابك؟",
    },
    en: {
      sureToLogOut: "Are you sure to log out of your account?",
    },
    fa: {
      sureToLogOut: "آیا از خروج حساب کاربری خود اطمینان دارید؟",
    },
  },
  sidebar: {
    ar: {
      sourceItem: "مصدر الرمز",
      pingWorldItem: "السرعة النقية",
      homeItem: "بيت",
      domainCheckItem: "فحص المجال",
      profileItem: "حساب تعريفي",
    },
    en: {
      sourceItem: "View source",
      pingWorldItem: "ping world",
      homeItem: "Home",
      domainCheckItem: "Domain check",
      profileItem: "Profile",
    },
    fa: {
      sourceItem: "منبع کد",
      pingWorldItem: "پینگ",
      homeItem: "خانه",
      domainCheckItem: "برسی دامنه",
      profileItem: "پروفایل",
    },
  },
};
