import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { AppContext } from "./context/AppContext";
export function DomainCheck({ setShowItems, darkMode }) {
  //initial states
  const { sidebar } = useContext(AppContext);
  const location = useLocation();
  //  for active sidebar & navigation item
  useEffect(() => {
    sidebar.setActiveItem("/call");
  }, []);
  return (
    <>
      <div
        style={{ position: "relative" }}
        className="flex flex-col items-center justify-center w-full h-full pb-48 gap-6"
      >
        <img
          className="w-32"
          src={
            darkMode
              ? "./icon/coming-soon-light.svg"
              : "./icon/coming-soon-dark.svg"
          }
        />
        <p style={{ fontWeight: "bold", fontSize: "30px" }}>Coming Soon</p>
        {location.pathname === "/call" ? null : (
          <p
            onClick={() => {
              setShowItems(true);
            }}
            style={{ position: "absolute", top: 9, left: 10 }}
          >
            <ArrowBackIcon fontSize="medium" />
          </p>
        )}
      </div>
    </>
  );
}
