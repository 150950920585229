import React, { useEffect } from "react";
import { StyledTextField } from "../../../../components/styled-components";
import { TextField } from "@mui/material";

export function UrlForm({
  setUrlInput,
  urlInput,
  setQrCodeValue,
  qrCodeValueStructures,
}) {
  return (
    <>
      <div className="w-full flex justify-start items-center">
        <StyledTextField
          value={urlInput}
          onChange={(e) => {
            setUrlInput(e.target.value);
            setQrCodeValue(qrCodeValueStructures.url);
          }}
          className="w-full"
          label="Website URL"
          placeholder="https://helpix.app"
        />
      </div>
    </>
  );
}
