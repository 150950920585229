import { Box, Grid } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import ColorPicker from "react-best-gradient-color-picker";
import { StyledTextField } from "../../../../components/styled-components";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import chroma from "chroma-js";

export const GradientChart = memo(
  ({
    firstSimilar,
    secendSimilar,
    fourthSimilar,
    thirdSimilar,
    secondColor,
    firstColor,
    r_color,
    b_color,
    g_color,
  }) => {
    // initial ststes
    const gradientItemsData = {
      firstItem: {
        content: <span className="text-lg text-white">test text</span>,
        colorHex: "#ffffff",
        gradientColor: `linear-gradient(90deg, ${firstSimilar} 0%, ${secendSimilar} 33.5%, ${thirdSimilar} 67%, ${fourthSimilar} 100%)`,
      },
      secondItem: {
        content: <span className="text-lg text-slate-300">test text</span>,
        colorHex: "#cbd5e1",
        gradientColor: `linear-gradient(170deg, ${firstSimilar} 0%, ${secendSimilar} 33.5%, ${thirdSimilar} 67%, ${fourthSimilar} 100%)`,
      },
      thirdItem: {
        content: <span className="text-lg text-slate-500">test text</span>,
        colorHex: "#64748b",
        gradientColor: `linear-gradient(90deg, ${firstColor} 0%, ${secondColor} 100%)`,
      },
      fourthItem: {
        content: <span className="text-lg text-slate-700">test text</span>,
        colorHex: "#334155",
        gradientColor: `linear-gradient(110deg, rgb(${r_color},0,0) 0%, rgb(0,${b_color},0) 45%, rgb(0,0,${g_color}) 100%)`,
      },
      fifthItem: {
        content: <span className="text-lg text-slate-800">test text</span>,
        colorHex: "#1e293b",
        gradientColor: `linear-gradient(190deg, ${firstSimilar} 0%, ${secendSimilar} 33.5%, ${thirdSimilar} 67%, ${fourthSimilar} 100%)`,
      },
      sixthItem: {
        content: <span className="text-lg text-black">test text</span>,
        colorHex: "#000000",
        gradientColor: `linear-gradient(270deg, ${firstSimilar} 0%, ${secendSimilar} 33.5%, ${thirdSimilar} 67%, ${fourthSimilar} 100%)`,
      },
    };
    const [gradientSimilarColors, setGradientSimilarColors] = useState(
      `linear-gradient(156deg, ${firstSimilar} 0%, ${secendSimilar} 33.5%, ${thirdSimilar} 67%, ${fourthSimilar} 100%)`
    );

    // handle calcute gradient
    useEffect(() => {
      console.log("gradient test");

      setGradientSimilarColors(
        `linear-gradient(160deg, ${firstSimilar} 0%, ${secendSimilar} 33.5%, ${thirdSimilar} 67%, ${fourthSimilar} 100%)`
      );
    }, [firstSimilar, secendSimilar, thirdSimilar, fourthSimilar]);
    return (
      <>
        <Grid container spacing={1} columns={12}>
          <Grid
            className="flex justify-start items-center"
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sl={12}
          >
            <span className="text-slate-100 text-lg">
              Gradint similarColors
            </span>
          </Grid>
          {Object.keys(gradientItemsData).map((item) => {
            return (
              <>
                <Grid item xs={6} sm={4} md={3} lg={2} xl={2}>
                  <Box
                    sx={{ background: gradientItemsData[item].gradientColor }}
                    className="rounded-lg flex justify-center items-center w-full h-16"
                  >
                    {gradientItemsData[item].content}
                  </Box>
                </Grid>
              </>
            );
          })}
        </Grid>
      </>
    );
  }
);
