import {
  Card,
  CardActionArea,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import * as yup from "yup";
import ClearIcon from "@mui/icons-material/Clear";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import EventRepeatOutlinedIcon from "@mui/icons-material/EventRepeatOutlined";
import StarRateIcon from "@mui/icons-material/StarRate";
import {
  StyledAutocomplete,
  StyledButton,
} from "../../components/styled-components";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { AppContext } from "../../context/AppContext";
import { lng } from "../../lang/languagesObjects";
import { useNavigate } from "react-router-dom";
import { handleAutoLogOut } from "../../includes/functions";
import { useApi } from "../../customAxios";
export function Sitemap({ darkMode, language }) {
  // for multi language
  const { t } = useTranslation("prints");
  i18next.addResourceBundle("fa", "prints", lng.siteMap.fa);
  i18next.addResourceBundle("en", "prints", lng.siteMap.en);
  i18next.addResourceBundle("ar", "prints", lng.siteMap.ar);
  //initial states
  const api = useApi();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [addressInput, setAddressInput] = useState("");
  const inputRef = useRef();
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [sitemapData, setSitemapData] = useState("");
  const [urlData, setUrlData] = useState(null);
  const [textFieldError, setTextFieldError] = useState(false);
  const [storedValues, setStoredValues] = useState(
    JSON.parse(localStorage.getItem("inputValues")) || []
  );
  const [shouldFetchData, setShouldFetchData] = useState(false);
  const [locContent, setLocContent] = useState();

  useEffect(() => {
    if (shouldFetchData) {
      console.log("first", addressInput);

      api
        .post("/tools/seo/sitemap", { url: addressInput })
        .then((res) => {
          console.log("res sitemap", res);
          if (Object.keys(res.data.body)[0] === "sitemap") {
            setSitemapData(res.data.body.sitemap);
            setUrlData(null);
          } else {
            setUrlData(res.data.body.url);
            setSitemapData(null);
          }
          setDisableSubmit(false);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log("err", err);
          setDisableSubmit(false);
          setIsLoading(false);
        });
    }
    return () => {
      setShouldFetchData(false);
    };
  }, [shouldFetchData]);

  // handle local storage
  useEffect(() => {
    const savedValues = localStorage.getItem("inputValues");
    if (savedValues) {
      setStoredValues(JSON.parse(savedValues));
    }
  }, []);

  const handleDeleteOption = (option) => {
    const updatedValues = storedValues.filter(
      (value) => value !== option.value
    );
    const updatedValuesString = JSON.stringify(updatedValues);
    localStorage.setItem("inputValues", updatedValuesString);
    setStoredValues(updatedValues);
  };

  const renderOption = (props, option, state) => (
    <li
      {...props}
      className="flex flex-row items-center justify-between mx-3 my-3"
    >
      {option.label}
      <button
        style={{ fontWeight: "bold", color: "#646666" }}
        onClick={(e) => {
          e.stopPropagation();
          handleDeleteOption(option);
        }}
      >
        <ClearIcon fontSize="small" />
      </button>
    </li>
  );

  const options = storedValues
    ? storedValues.map((value) => ({ label: value, value })).reverse()
    : [];

  //validation schema
  const validationSchema = yup.object().shape({
    url: yup
      .string()
      .url("The entered address is not valid")
      .required("Filling this field is mandatory"),
  });

  //validation's function
  function validation() {
    validationSchema
      .validate(
        {
          url: inputRef.current.value.startsWith("https://")
            ? inputRef.current.value
            : "https://" + inputRef.current.value,
        },
        { abortEarly: false }
      )
      .then((validData) => {
        const inputValue = inputRef.current.value;
        if (inputValue.trim() !== "") {
          const existingValues = new Set(storedValues);
          existingValues.add(inputValue);
          const updatedValues = Array.from(existingValues);
          setStoredValues(updatedValues);
          localStorage.setItem("inputValues", JSON.stringify(updatedValues));
        }
        setIsLoading(true);
        setDisableSubmit(true);
        setSitemapData(null);
        setUrlData(null);
        setTextFieldError(false);
        setShouldFetchData(true);
      })
      .catch((err) => {
        setTextFieldError(true);
        setShouldFetchData(false);
      });
  }

  function handleSubmit() {
    const inputValue = inputRef.current.value;
    if (inputValue.startsWith("https://")) {
      setAddressInput(inputValue);
    } else {
      setAddressInput("https://" + inputValue);
    }
    validation();
  }

  function handleOnClickCard(loc) {
    setSitemapData(null);
    setIsLoading(true);
    api
      .post("/tools/seo/sitemap", { sitemap: loc })
      .then((res) => {
        console.log("loc", res);
        setIsLoading(false);
        console.log("object", res.data.body.url);
        setUrlData(res.data.body.url);
      })
      .catch((err) => {
        console.log("loc", err);
        setIsLoading(false);
      });
  }

  return (
    <>
      <div className="flex flex-col items-center justify-start gap-2 mb-36 ">
        <Card
          className={`${
            localStorage.getItem("theme") === "dark-mode"
              ? "card-bg-gradient-dark"
              : "card-bg-gradient-light"
          }`}
          sx={{ width: "100%", backgroundColor: "#333C4D" }}
        >
          <CardContent className="flex flex-col items-center justify-center gap-1 w-full">
            <Grid
              container
              columns={12}
              spacing={1}
              className="max-w-[550px] pt-2"
            >
              <Grid item xs={8} sm={8} md={8} lg={8}>
                <StyledAutocomplete
                  disableClearable
                  size="small"
                  freeSolo
                  options={options}
                  PaperComponent={({ children }) => (
                    <div
                      className={
                        darkMode
                          ? `dark:bg-slate-900 dark:text-slate-300`
                          : `bg-slate-50 text-slate-700`
                      }
                    >
                      {children}
                    </div>
                  )}
                  renderInput={(params) => (
                    <TextField
                      error={textFieldError}
                      inputRef={inputRef}
                      label={t("enterUrlSitemap", {
                        lng: localStorage.getItem("lang"),
                      })}
                      {...params}
                      sx={{
                        width: "100%",

                        "& .MuiOutlinedInput-root": {
                          color: darkMode ? "#d6d3d1" : "black",
                          "& fieldset": {
                            borderColor: darkMode ? "#d6d3d1" : "black",
                          },
                          "&:hover fieldset": {
                            borderColor: darkMode ? "white" : "black", // change border color on hover
                          },
                          "& .MuiSvgIcon-root": {
                            color: darkMode ? "white" : "black", // change icon color
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: darkMode ? "#d6d3d1" : "black",
                        },
                      }}
                    />
                  )}
                  renderOption={renderOption}
                />
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4}>
                <StyledButton
                  className="bg-gradient-to-l dark:from-bgGradientFromCustomButtonDark dark:to-bgGradientToCustomButtonDark dark:hover:from-bgGradientFromCustomButtonHoverDark dark:hover:to-bgGradientToCustomButtonHoverDark bg-bgCustomButtonLight hover:bg-bgCustomButtonHoverLight  "
                  style={{
                    width: "100%",

                    color: "#e7e5e4",
                    border: "none",
                  }}
                  size="large"
                  disabled={disableSubmit}
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  <SearchOutlinedIcon fontSize="medium" />
                </StyledButton>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card
          className={`w-full ${
            localStorage.getItem("theme") === "dark-mode"
              ? "card-bg-gradient-dark"
              : "card-bg-gradient-light"
          }`}
        >
          <CardContent>
            <Grid container spacing={2} columns={12} className="pl-1 pr-1">
              <Grid item xs={9} sm={9} md={9} lg={9}>
                <div
                  className="flex flex-row items-center justify-start gap-1"
                  style={{
                    width: "100%",
                    overflow: "hidden",
                  }}
                >
                  {!urlData ? (
                    <>
                      <p className="text-[#e7e5e4]">Sitemap:</p>
                      <p className="text-[#e7e5e4]  whitespace-nowrap overflow-hidden text-ellipsis">
                        {addressInput}
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="text-[#e7e5e4]">URL:</p>
                      <p className="text-[#e7e5e4] whitespace-nowrap overflow-hidden text-ellipsis">
                        {locContent}
                      </p>
                    </>
                  )}
                </div>
              </Grid>
              <Grid
                item
                xs={3}
                sm={3}
                md={3}
                lg={3}
                className="flex flex-row items-center justify-end "
                sx={{
                  width: "100%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {" "}
                {sitemapData ? (
                  <p className="text-[#e7e5e4]">
                    {t("number", { lng: localStorage.getItem("lang") })}:{" "}
                    {sitemapData?.length}
                  </p>
                ) : (
                  <p className="text-[#e7e5e4]">
                    {t("number", { lng: localStorage.getItem("lang") })}:{" "}
                    {urlData ? urlData?.length : 0}
                  </p>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card
          className={`w-full ${
            localStorage.getItem("theme") === "dark-mode"
              ? "card-bg-gradient-dark"
              : "card-bg-gradient-light"
          }`}
        >
          <CardContent>
            <Grid spacing={1} columns={12} container className="">
              {sitemapData ? (
                sitemapData.map((item, index) => {
                  return (
                    <Grid item xs={6} sm={4} md={3} lg={3} xl={2}>
                      <Card
                        sx={{
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          background: "var(--bg-gradient-options-cards)",
                        }}
                      >
                        <CardActionArea
                          onClick={() => {
                            handleOnClickCard(item.loc);
                            setLocContent(item.loc);
                          }}
                        >
                          <CardContent className="flex flex-col items-center justify-center gap-3">
                            <div className="flex flex-row items-start justify-center gap-1">
                              <AccountTreeOutlinedIcon
                                fontSize="small"
                                color="primary"
                              />
                              <p
                                className="text-slate-300"
                                style={{
                                  flexShrink: 0,
                                  overflow: "visible",
                                  wordBreak: "break-all",
                                  maxWidth: "100%",
                                  padding: "0px",
                                  "&::-webkit-scrollbar": {
                                    height: "4px",
                                  },
                                  fontSize: "clamp(11px, 1.5vw, 14px)",
                                }}
                              >
                                {item.loc.replace(addressInput + "/", "")}
                              </p>
                            </div>

                            <Divider
                              sx={{
                                backgroundColor: "primary.main",
                                height: "1px",
                                width: "100%",
                              }}
                            />
                            {item.lastmod && (
                              <div className="flex flex-row items-start justify-center gap-1 ">
                                <EventRepeatOutlinedIcon
                                  fontSize="small"
                                  color="primary"
                                />
                                <p
                                  className="text-slate-200"
                                  style={{
                                    fontSize: "11px",
                                    flexShrink: 0,
                                  }}
                                >
                                  {item.lastmod.split(":")[0]}
                                </p>
                              </div>
                            )}
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  );
                })
              ) : urlData ? (
                urlData.map((item, index) => {
                  return (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                      <Card
                        className="bg-bgOptionsCardsLight dark:bg-bgOptionsCardsDark"
                        sx={{
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          background: "var(--bg-gradient-options-cards)",
                        }}
                      >
                        <a href={item.loc} target="blank" className="w-full">
                          <CardContent
                            sx={{
                              width: "100%",
                              height: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "start",
                              marginTop: "8px",

                              "&::-webkit-scrollbar": {
                                height: "4px", // ارتفاع نوار اسکرول بار را به 8 پیکسل کاهش دهید
                              },
                            }}
                          >
                            <Grid
                              container
                              columns={12}
                              spacing={1}
                              sx={{
                                width: "100%",
                                height: "100%",
                              }}
                              className="flex flex-row justify-start items-center "
                            >
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={7}
                                lg={7}
                                className="flex flex-row justify-start items-center  "
                              >
                                <Typography
                                  className="text-slate-300"
                                  variant="caption"
                                  sx={{
                                    flexShrink: 0,
                                    fontSize: "17px",
                                    width: "100%",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {item.loc.replace(addressInput + "/", "") ===
                                  "" ? (
                                    <StarRateIcon
                                      fontSize="small"
                                      htmlColor="#4b5563"
                                    />
                                  ) : (
                                    item.loc.replace(addressInput + "/", "")
                                  )}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                sm={3}
                                md={2}
                                lg={2}
                                className="flex flex-row justify-start items-center "
                              >
                                {item.changefreq && (
                                  <Typography
                                    variant="caption"
                                    sx={{
                                      flexShrink: 0,
                                      fontSize: "17px",
                                    }}
                                  >
                                    {item.changefreq}
                                  </Typography>
                                )}
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                sm={2}
                                md={1}
                                lg={1}
                                className="flex flex-row justify-start items-center"
                              >
                                {item.priority && (
                                  <Typography
                                    variant="caption"
                                    sx={{ flexShrink: 0, fontSize: "17px" }}
                                  >
                                    {item.priority}
                                  </Typography>
                                )}
                              </Grid>
                              <Grid
                                item
                                xs={5}
                                sm={5}
                                md={2}
                                lg={2}
                                className="flex flex-row justify-start items-center"
                              >
                                {item.lastmod && (
                                  <Typography
                                    variant="caption"
                                    sx={{
                                      flexShrink: 0,
                                      fontSize: "17px",
                                      width: "100%",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {item.lastmod.split(":")[0]}
                                  </Typography>
                                )}
                              </Grid>
                            </Grid>
                          </CardContent>
                        </a>
                      </Card>
                    </Grid>
                  );
                })
              ) : isLoading ? (
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="subtitle1" className="text-[#e7e5e4]">
                    Loading...
                  </Typography>
                </Grid>
              ) : (
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="subtitle1" className="text-[#e7e5e4]">
                    {t("notAvailableSitemap", {
                      lng: localStorage.getItem("lang"),
                    })}{" "}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </div>
    </>
  );
}
