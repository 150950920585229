import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  CardHeader,
  Grid,
  InputAdornment,
  styled,
  TextField,
} from "@mui/material";
import React, { memo, useRef, useState } from "react";
import {
  StyledAutocomplete,
  StyledTextField,
} from "../../../../components/styled-components";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { lng } from "../../../../lang/languagesObjects";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect } from "react";

export const AppearanceInputs = memo(
  ({ displayOptions, directionOption, jsonKeys }) => {
    // for multi language
    const { t } = useTranslation("prints");
    i18next.addResourceBundle("fa", "prints", lng.manifest.fa);
    i18next.addResourceBundle("en", "prints", lng.manifest.en);
    i18next.addResourceBundle("ar", "prints", lng.manifest.ar);
    const ColorInput = styled(TextField)`
      input[type="color"] {
        transform: scale(1.5); // افزایش اندازه تکست فیلد نوار تایپ کالر
        height: 25px;
        cursor: pointer;
      }
      & .MuiOutlinedInput-root {
        & fieldset {
          border: none;
          outline: none;
        }
        &:hover fieldset {
          border: none;
          outline: none;
        }
        &.Mui-focused fieldset {
          border: none;
          outline: none;
        }
      }
    `;
    //initial states
    const [test, setTest] = useState(true);
    const backgroundColorRef = useRef("#e11d48");
    const themeColorRef = useRef("#2563eb");
    const [direction, setDirection] = useState(null);
    const [orientation, setOrientation] = useState(null);
    const [display, setDisplay] = useState(null);
    // handle test
    useEffect(() => {
      setTest(!test);
    }, []);
    return (
      <>
        <Accordion
          className="w-full text-slate-100 rounded-md"
          sx={{
            background: "rgb(29,103,158)",
            background:
              "linear-gradient(90deg, rgba(13, 166, 194, 0.23573179271708689) 0%, rgba(38, 36, 80, 0.804359243697479) 100%)",
          }}
        >
          <AccordionSummary
            className="w-full h-16"
            expandIcon={
              <ExpandMoreIcon fontSize="medium" className="text-slate-100" />
            }
          >
            <p className="text-slate-200 w-fit">
              {t("Appearance", {
                lng: localStorage.getItem("lang"),
              })}
            </p>
          </AccordionSummary>
          <AccordionDetails className="max-h-60 overflow-y-auto">
            <Grid container spacing={2} columns={12}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <StyledTextField
                  className="w-full"
                  label="Theme Color"
                  id="theme-color-input"
                  size="small"
                  onChange={(e) => {
                    jsonKeys.current.themeColor = e.target.value;
                    themeColorRef.current = e.target.value;
                    document.getElementById("theme-color").value =
                      themeColorRef.current;
                  }}
                  InputProps={{
                    startAdornment: (
                      <>
                        <ColorInput
                          id="theme-color"
                          defaultValue={themeColorRef.current}
                          onChange={(e) => {
                            jsonKeys.current.themeColor = e.target.value;
                            themeColorRef.current = e.target.value;
                            document.getElementById("theme-color-input").value =
                              themeColorRef.current;
                          }}
                          size="small"
                          type="color"
                          className="w-20 h-full"
                          variant="outlined"
                        />
                      </>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <StyledTextField
                  className="w-full"
                  label="Background Color"
                  id="background-color-input"
                  size="small"
                  onChange={(e) => {
                    jsonKeys.current.backgroundColor = e.target.value;
                    backgroundColorRef.current = e.target.value;
                    document.getElementById("background-color").value =
                      backgroundColorRef.current;
                  }}
                  InputProps={{
                    startAdornment: (
                      <>
                        <ColorInput
                          id="background-color"
                          defaultValue={backgroundColorRef.current}
                          onChange={(e) => {
                            jsonKeys.current.backgroundColor = e.target.value;
                            backgroundColorRef.current = e.target.value;
                            document.getElementById(
                              "background-color-input"
                            ).value = backgroundColorRef.current;
                          }}
                          size="small"
                          type="color"
                          className="w-20 h-full"
                          variant="outlined"
                        />
                      </>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <StyledAutocomplete
                  sx={{
                    textAlign: "left",
                    width: "100%",
                  }}
                  size="small"
                  onChange={(e, value) => {
                    jsonKeys.current.display = e.target.value;
                    setDisplay(value?.value);
                  }}
                  value={display}
                  defaultValue={displayOptions[0]}
                  options={displayOptions}
                  PaperComponent={({ children }) => (
                    <div className={"dark:bg-slate-900 dark:text-slate-300"}>
                      {children}
                    </div>
                  )}
                  renderInput={(params) => (
                    <TextField
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <HelpOutlineIcon
                              sx={{
                                cursor: "pointer",
                                paddingLeft: "4px",
                              }}
                              fontSize="small"
                              htmlColor="#aaaaaa"
                            />
                          </InputAdornment>
                        ),
                      }}
                      {...params}
                      label={"Display"}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          color: "#d6d3d1",
                          "& fieldset": {
                            borderColor: "#d6d3d1",
                          },
                          "&:hover fieldset": {
                            borderColor: "white", // change border color on hover
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: "#d6d3d1",
                        },
                        "& .MuiSvgIcon-root": {
                          color: "white", // change icon color
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <StyledTextField
                  value={orientation}
                  onChange={(e) => {
                    jsonKeys.current.orientation = e.target.value;
                    setOrientation(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <HelpOutlineIcon
                          sx={{
                            cursor: "pointer",
                            paddingLeft: "4px",
                          }}
                          fontSize="small"
                          htmlColor="#aaaaaa"
                        />
                      </InputAdornment>
                    ),
                  }}
                  size="small"
                  label="Orientation"
                  className="w-full"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <StyledAutocomplete
                  value={direction}
                  onChange={(e, value) => {
                    jsonKeys.current.direction = value.value;
                    setDirection(value.value);
                  }}
                  sx={{
                    textAlign: "left",
                    width: "100%",
                  }}
                  size="small"
                  defaultValue={directionOption[0]}
                  options={directionOption}
                  PaperComponent={({ children }) => (
                    <div className={"dark:bg-slate-900 dark:text-slate-300"}>
                      {children}
                    </div>
                  )}
                  renderInput={(params) => (
                    <TextField
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <HelpOutlineIcon
                              sx={{
                                cursor: "pointer",
                                paddingLeft: "4px",
                              }}
                              fontSize="small"
                              htmlColor="#aaaaaa"
                            />
                          </InputAdornment>
                        ),
                      }}
                      {...params}
                      label={"direction"}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          color: "#d6d3d1",
                          "& fieldset": {
                            borderColor: "#d6d3d1",
                          },
                          "&:hover fieldset": {
                            borderColor: "white", // change border color on hover
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: "#d6d3d1",
                        },
                        "& .MuiSvgIcon-root": {
                          color: "white", // change icon color
                        },
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </>
    );
  }
);
