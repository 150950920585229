import { Grid } from "@mui/material";
import React from "react";
import { StyledTextField } from "../../../../components/styled-components";

export function EmailForm({
  emailInput,
  setEmailInput,
  subject,
  setSubject,
  message,
  setMessage,
  setQrCodeValue,
  qrCodeValueStructures,
}) {
  return (
    <Grid container spacing={2} columns={12}>
      <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
        {" "}
        <StyledTextField
          type="email"
          className="w-full"
          label="Email"
          value={emailInput}
          onChange={(e) => {
            setQrCodeValue(qrCodeValueStructures.email);
            setEmailInput(e.target.value);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
        {" "}
        <StyledTextField
          className="w-full"
          label="Subject"
          value={subject}
          onChange={(e) => {
            setQrCodeValue(qrCodeValueStructures.email);
            setSubject(e.target.value);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        {" "}
        <StyledTextField
          value={message}
          onChange={(e) => {
            setQrCodeValue(qrCodeValueStructures.email);
            setMessage(e.target.value);
          }}
          multiline
          minRows={4}
          maxRows={7}
          className="w-full"
          label="Message"
        />
      </Grid>
    </Grid>
  );
}
