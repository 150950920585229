import { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import NotificationsIcon from "@mui/icons-material/Notifications";

import { useNavigate } from "react-router-dom";

export const formatInputWithCommas = (input, fix = 3) => {
  if (!input && input !== 0) {
    return input;
  }

  var options = {
    style: "currency",
    currency: "IRR",
    minimumFractionDigits: 0,
    maximumFractionDigits: fix,
    currencyDisplay: "symbol",
  };
  var formatter = new Intl.NumberFormat("ir", options);
  return formatter
    .format(input.toString().replace(/,/g, ""))
    .replace("IRR", "")
    .trim();
};

export const formatInputWithOutCommas = (input) => {
  if (!input) {
    return input;
  }
  const cleanValue = input.toString().replace(/[^\d.]/g, "");
  return cleanValue;
};

export function AddAlertToDomain({ url }) {
  const { addAlertIconContext } = useContext(AppContext);
  const { loginDialog, sidebar } = useContext(AppContext);

  const navigate = useNavigate();
  const token = JSON.parse(window.localStorage.getItem("user"))?.user.token;
  const alerts = token ? JSON.parse(window.localStorage.getItem("alerts")) : [];

  const hasAlertForUrl = alerts?.some((item) => item.domain === url);
  return (
    <>
      {token ? (
        hasAlertForUrl ? (
          <NotificationsIcon
            fontSize="small"
            sx={{ cursor: "pointer" }}
            className="cursor-pointer pl-[2px]"
            color="warning"
          />
        ) : (
          <NotificationsOffIcon
            fontSize="small"
            sx={{ cursor: "pointer" }}
            className="cursor-pointer pl-[2px]"
            htmlColor="#888e95"
            onClick={() => {
              const isValidIPAddress = (ipAddress) => {
                const ipPattern = /^d{1,3}.d{1,3}.d{1,3}.d{1,3}$/;
                return ipPattern.test(ipAddress);
              };

              console.log("add alert test url: ", url);
              navigate("/alert-center");
              addAlertIconContext.setShowHome(false);
              sidebar.setActiveItem("/");

              if (!isValidIPAddress(url)) {
                addAlertIconContext.setAddURL(
                  url.startsWith("https://") ? url : "https://" + url
                );
              } else {
                addAlertIconContext.setAddURL(url);
              }
            }}
          />
        )
      ) : (
        <NotificationsOffIcon
          onClick={() => {
            navigate("/alert-center");
          }}
          className="cursor-pointer pl-[2px]"
          htmlColor="#888e95"
          fontSize="small"
        />
      )}
    </>
  );
}

export function handleAutoLogOut(navigate) {
  localStorage.removeItem("alerts");
  localStorage.removeItem("user");
  navigate("/");
}
