import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "./context/AppContext";

export function ProtectComponents() {
  const { loginDialog } = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    navigate("/");
    loginDialog.setPathToMove(location.pathname);
    loginDialog.setShowLoginComponent(true);
  }, []);
  return null;
  // return { navigate, location };
}

// const { navigate, location } = useProtectComponet();
