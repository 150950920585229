import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  Grid,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  StyledAutocomplete,
  StyledButton,
  StyledTextField,
} from "../../../components/styled-components";
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import AddIcon from "@mui/icons-material/Add";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import AutoFixHighSharpIcon from "@mui/icons-material/AutoFixHighSharp";
import beautify from "js-beautify";
import { saveAs } from "file-saver";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import * as yup from "yup";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { AppContext } from "../../../context/AppContext";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { lng } from "../../../lang/languagesObjects";
import createCache from "@emotion/cache";
import { prefixer } from "stylis";
import { CacheProvider } from "@emotion/react";
import { Icons } from "./components/icons components/Icons";
import { BasicInputs } from "./components/BasicInputs";
import { AppearanceInputs } from "./components/AppearanceInputs";
import { ScreenShots } from "./components/screen shots components/components/ScreenShots";
import { SideBarAction } from "./components/SideBarAction";

export const ManifestGenerator = memo(() => {
  const cacheLtr = createCache({
    key: "muiltr",
    stylisPlugins: [prefixer],
  });
  // for multi language
  const { t } = useTranslation("prints");
  i18next.addResourceBundle("fa", "prints", lng.manifest.fa);
  i18next.addResourceBundle("en", "prints", lng.manifest.en);
  i18next.addResourceBundle("ar", "prints", lng.manifest.ar);
  const directionOption = [
    { id: 1, label: "Auto", value: "auto" },
    { id: 2, label: "RTL", value: "rtl" },
    { id: 3, label: "LTR", value: "left" },
  ];
  const displayOptions = [
    { id: 1, label: "standalone (Recommended)", value: "standalone" },
    { id: 2, label: "fullscreen", value: "fullscreen" },
    { id: 3, label: "minimal-ui", value: "minimal-ui" },
    { id: 4, label: "browser", value: "browser" },
  ];

  const optionalPermissionsOptions = [
    { id: 1, label: "ActiveTab", value: "activeTab" },
    { id: 2, label: "Alarms", value: "alarms" },
    { id: 3, label: "background", value: "background" },
    { id: 4, label: "bookmarks", value: "bookmarks" },
    { id: 5, label: "browserSettings", value: "browserSettings" },
    { id: 6, label: "browsingData", value: "browsingData" },
    { id: 7, label: "clipboardRead", value: "clipboardRead" },
    { id: 8, label: "clipboardWrite", value: "clipboardWrite" },
    { id: 9, label: "contentSettings", value: "contentSettings" },
    { id: 10, label: "contextMenus", value: "contextMenus" },
    { id: 11, label: "cookies", value: "cookies" },
    { id: 12, label: "declarativeNetRequest", value: "declarativeNetRequest" },
    {
      id: 13,
      label: "declarativeNetRequestFeedback",
      value: "declarativeNetRequestFeedback",
    },
    {
      id: 14,
      label: "declarativeNetRequestWithHostAccess",
      value: "declarativeNetRequestWithHostAccess",
    },
    { id: 15, label: "devtools", value: "devtools" },
    { id: 16, label: "dns", value: "dns" },
    { id: 17, label: "downloads", value: "downloads" },
    { id: 18, label: "downloads.open", value: "downloads.open" },
    { id: 19, label: "find", value: "find" },
    { id: 20, label: "geolocation", value: "geolocation" },
    { id: 21, label: "history", value: "history" },
    { id: 22, label: "identity", value: "identity" },
    { id: 23, label: "idle", value: "idle" },
    { id: 24, label: "management", value: "management" },
    { id: 25, label: "menus", value: "menus" },
    { id: 26, label: "nativeMessaging", value: "nativeMessaging" },
    { id: 27, label: "notifications", value: "notifications" },
    { id: 28, label: "pageCapture", value: "pageCapture" },
    { id: 29, label: "pkcs11", value: "pkcs11" },
    { id: 30, label: "privacy", value: "privacy" },
    { id: 31, label: "proxy", value: "proxy" },
    { id: 32, label: "scripting", value: "scripting" },
    { id: 33, label: "search", value: "search" },
    { id: 34, label: "sessions", value: "sessions" },
    { id: 35, label: "storage", value: "storage" },
    { id: 36, label: "tabHide", value: "tabHide" },
    { id: 37, label: "tabs", value: "tabs" },
    { id: 38, label: "topSites", value: "topSites" },
    { id: 39, label: "unlimitedStorage", value: "unlimitedStorage" },
    { id: 40, label: "webNavigation", value: "webNavigation" },
    { id: 41, label: "webRequest", value: "webRequest" },
    { id: 42, label: "webRequestBlocking", value: "webRequestBlocking" },
    {
      id: 43,
      label: "webRequestFilterResponse",
      value: "webRequestFilterResponse",
    },
    {
      id: 44,
      label: "webRequestFilterResponse.serviceWorkerScript",
      value: "webRequestFilterResponse.serviceWorkerScript",
    },
  ];

  const offlineEnableOptions = [
    { id: 1, label: "Yes", value: true },
    { id: 2, label: "No", value: false },
  ];
  const openAtInstallOptions = [
    { id: 1, label: "Yes", value: true },
    { id: 2, label: "No", value: false },
  ];

  //initial states
  const jsonKeys = useRef({
    name: "",
    shortName: "",
    description: "",
    devtoolsPage: "",
    defaultLocale: "",
    startUrl: "",
    homePageURL: "",
    scope: "",
    auther: "",
    manifestVersion: "",
    lang: "",
    version: "",
    versionName: "",
    offlineEnable: "",
    optionPage: "",
    storage: "",
    userScripts: "",
    optionalPermissions: [],
    display: "",
    orientation: "",
    direction: "",
    themeColor: "#2563eb",
    backgroundColor: "#e11d48",
    default_panel: "",
    default_icon: "",
    default_title: "",
    openAtInstall: "",
    icons: [{ id: 0, data: { url: "", sizes: "", type: "" } }],
    screenShots: [
      {
        id: 0,
        data: { src: "", sizes: "", type: "", form_factor: "", label: "" },
      },
    ],
  });

  const [manifestJson, setManifestJson] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [scrollDialog, setScrollDialog] = useState("paper");
  const [errorMessage, setErrorMessage] = useState(null);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const handleClickOpenDialog = (scrollType) => () => {
    setOpenDialog(true);
    setScrollDialog(scrollType);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  //validation schema
  const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    shortName: yup.string().required("Short Name is required"),
    themeColor: yup.string().required("Theme Color is required"),
    backgroundColor: yup.string().required("Background Color is required"),
    display: yup.string().required("Display is required"),
    scope: yup.string().required("Scope is required"),
    startUrl: yup.string().required("Start Url is required"),
    orientation: yup.string().required("Orientation is required"),
  });
  //

  const handleManifestGenerator = () => {
    validationSchema
      .validate(
        {
          name: jsonKeys.current.name,
          shortName: jsonKeys.current.shortName,
          themeColor: jsonKeys.current.themeColor,
          backgroundColor: jsonKeys.current.backgroundColor,
          display: jsonKeys.current.display,
          scope: jsonKeys.current.scope,
          startUrl: jsonKeys.current.startUrl,
          orientation: jsonKeys.current.orientation,
        },
        { abortEarly: false }
      )
      .then((validData) => {
        let manifestContent = {
          name: jsonKeys.current.name,
          short_name: jsonKeys.current.shortName,
          description: jsonKeys.current.description,
          devtools_page: jsonKeys.current.devtoolsPage,
          default_locale: jsonKeys.current.defaultLocale,
          start_url: jsonKeys.current.startUrl,
          home_page_url: jsonKeys.current.homePageURL,
          scope: jsonKeys.current.scope,
          author: jsonKeys.current.auther,
          manifest_version: jsonKeys.current.manifestVersion,
          lang: jsonKeys.current.lang,
          version: jsonKeys.current.version,
          version_name: jsonKeys.current.versionName,
          offline_enabled: jsonKeys.current.offlineEnable,
          options_page: jsonKeys.current.optionPage,
          storage: jsonKeys.current.storage,
          user_scripts: jsonKeys.current.userScripts,
          optional_permissions: jsonKeys.current.optionalPermissions[0]
            ? jsonKeys.current.optionalPermissions.map((item) => {
                return item.value;
              })
            : "",
          icons:
            jsonKeys.current.icons.filter((element) => {
              return Object.values(element.data).some((item) => item);
            }).length > 0
              ? jsonKeys.current.icons
                  .filter((element) => {
                    return Object.values(element.data).some((item) => item);
                  })
                  .map((element) => element.data)
              : "",
          screenshots:
            jsonKeys.current.screenShots.filter((element) => {
              return Object.values(element.data).some((item) => item);
            }).length > 0
              ? jsonKeys.current.screenShots
                  .filter((element) => {
                    return Object.values(element.data).some((item) => item);
                  })
                  .map((element) => element.data)
              : "",
          display: jsonKeys.current.display,
          orientation: jsonKeys.current.orientation,
          direction: jsonKeys.current.direction,
          theme_color: jsonKeys.current.themeColor,
          background_color: jsonKeys.current.backgroundColor,
          sidebar_action:
            jsonKeys.current.default_panel ||
            jsonKeys.current.default_icon ||
            jsonKeys.current.default_title ||
            jsonKeys.current.openAtInstall === false ||
            jsonKeys.current.openAtInstall === true
              ? {
                  default_panel: jsonKeys.current.default_panel,
                  default_icon: jsonKeys.current.default_icon,
                  default_title: jsonKeys.current.default_title,
                  open_at_install: jsonKeys.current.openAtInstall,
                }
              : "",
        };
        const filteredManifestContent = Object.entries(manifestContent).reduce(
          (acc, [key, value]) => {
            if (value !== "" && value !== null && value !== undefined) {
              acc[key] = value;
            }
            return acc;
          },
          {}
        );

        const jsonString = JSON.stringify(filteredManifestContent, null, 2);
        const formattedJsonData = beautify(jsonString, {
          indent_size: 2,
          space_in_empty_paren: true,
        });
        setManifestJson(formattedJsonData);
      })
      .catch((error) => {
        setShowErrorMessage(true);
        const errorMessages = error.inner
          .map((err) => {
            return `${err.message}`;
          })
          .join(" & ");
        setErrorMessage(
          <div className="flex flex-row justify-center items-center gap-1">
            <ErrorOutlineOutlinedIcon fontSize="small" color="error" />
            {errorMessages}
          </div>
        );
      });
  };
  const handleCopyToClipboard = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(manifestJson);
    } else {
      console.log("Clipboard API not supported");
    }
  };

  const handleCopyMetaTagsToClipboard = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(metaTagsContent);
    } else {
      console.log("Document is not focused, cannot write to clipboard.");
    }
  };
  function exportFile() {
    const blob = new Blob([manifestJson], {
      type: "application/json;charset=utf-8",
    });
    saveAs(blob, "manifest.json");
  }

  // useEffect(() => {
  //   // document.getElementById("theme-color").value = keys.current.themeColor;
  //   document.getElementById("background-color").value =
  //     // keys.current.backgroundColor;
  //   document.getElementById("theme-color-input").value =
  //     // keys.current.themeColor;
  //   document.getElementById("background-color-input").value =
  //     // keys.current.backgroundColor;
  // }, []);

  const metaTagsContent =
    `<link rel="manifest" content="manifest.json generated by [Helpix] https://helpix.app" href="manifest.json">\n\n` +
    `<meta name="mobile-web-app-capable" content="yes">\n` +
    `<meta name="apple-mobile-web-app-capable" content="yes">\n` +
    `<meta name="msapplication-starturl" content="/">\n` +
    `<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">\n`;

  //default Value
  useEffect(() => {
    // keys.current.offlineEnable = true;
    // keys.current.openAtInstall = true;
    // keys.current.display = "standalone";
    // keys.current.direction = "auto";
  }, []);
  console.log("manifest");

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={3000}
        open={showErrorMessage}
        onClose={() => {
          setShowErrorMessage(false);
        }}
        message={errorMessage}
      />
      <CacheProvider value={cacheLtr}>
        <div className="mb-48">
          <Card
            className={`${
              localStorage.getItem("theme") === "dark-mode"
                ? "card-bg-gradient-dark"
                : "card-bg-gradient-light"
            }`}
          >
            <CardHeader
              title={
                <div
                  className="w-full h-14"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent:
                      localStorage.getItem("dir") === "ltr" ? "start" : "end",
                  }}
                >
                  <p className="text-slate-200 text-2xl w-fit ">
                    Manifest Generator
                  </p>
                </div>
              }
            />
            <CardContent sx={{ paddingBottom: "0px" }}>
              <Grid container spacing={3} columns={12}>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <BasicInputs
                    jsonKeys={jsonKeys}
                    optionalPermissionsOptions={optionalPermissionsOptions}
                    offlineEnableOptions={offlineEnableOptions}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <AppearanceInputs
                    jsonKeys={jsonKeys}
                    displayOptions={displayOptions}
                    directionOption={directionOption}
                  />
                </Grid>
                {/* <Card
                    className="w-full h-full section3"
                    sx={{
                      background: "var(--bg-gradient-options-cards)",
                    }}
                  > */}
                {/* <CardContent sx={{ paddingLeft: "8px", paddingRight: "8px" }}> */}
                {/* <Grid container spacing={3} columns={12}> */}
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <SideBarAction
                    jsonKeys={jsonKeys}
                    openAtInstallOptions={openAtInstallOptions}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Icons jsonKeys={jsonKeys} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ScreenShots jsonKeys={jsonKeys} />
                </Grid>
                {/* </Grid> */}
                {/* </CardContent> */}
                {/* </Card> */}
              </Grid>
            </CardContent>
            <CardActions
              sx={{
                paddingLeft: "15px",
                paddingRight: "15px",
                paddingTop: "14px",
                paddingBottom: "17px",
              }}
            >
              <Fab
                className={`bg-gradient-to-r 
             from-bgGradientFromFabLight 
             to-bgGradientToFabLight
             dark:from-bgGradientFromFabDark 
             dark:to-bgGradientToFabDark 
             dark:hover:to-bgGradientToFabHoverDark
             dark:hover:from-bgGradientFromFabHoverDark
            hover:to-bgGradientToFabHoverLight 
             hover:from-bgGradientFromFabHoverLight `}
                onClick={handleManifestGenerator}
                variant="extended"
                size="medium"
                sx={{
                  color: "#e2e8f0",
                  zIndex: 1,
                  width: "140px",
                }}
              >
                <AutoFixHighSharpIcon sx={{ mr: 0.5 }} fontSize="medium" />
                {t("generate", {
                  lng: localStorage.getItem("lang"),
                })}
              </Fab>
            </CardActions>
          </Card>
          {manifestJson && (
            <Grid container columns={12} spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                {" "}
                <Card
                  className={`${
                    localStorage.getItem("theme") === "dark-mode"
                      ? "card-bg-gradient-dark"
                      : "card-bg-gradient-light"
                  } w-full mt-5 h-full`}
                >
                  <CardHeader
                    className="bg-bgCardHeaderLight dark:bg-bgCardHeaderDark"
                    sx={{
                      paddingLeft: "12px",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingRight: "12px",
                      ".MuiCardHeader-title": {
                        width: "100%",
                        flex: 1,
                      },
                      ".MuiTextField-root": {
                        width: "100%",
                      },
                    }}
                    title={
                      <div className="flex flex-row justify-between items-center">
                        <Typography
                          variant="h6"
                          className="font-bold text-slate-300"
                        >
                          Manifest JSON
                        </Typography>{" "}
                        <div className="flex flex-row justify-end items-center gap-2">
                          <Tooltip
                            title={
                              <p
                                style={{
                                  textAlign:
                                    localStorage.getItem("dir") === "ltr"
                                      ? "left"
                                      : "right",
                                }}
                              >
                                {t("exportIconHover", {
                                  lng: localStorage.getItem("lang"),
                                })}
                              </p>
                            }
                          >
                            <FileUploadOutlinedIcon
                              onClick={exportFile}
                              className="cursor-pointer"
                              fontSize="medium"
                              htmlColor="#928b8b"
                            />
                          </Tooltip>
                          <Tooltip
                            title={
                              <p
                                style={{
                                  textAlign:
                                    localStorage.getItem("dir") === "ltr"
                                      ? "left"
                                      : "right",
                                }}
                              >
                                {t("copyIconHover", {
                                  lng: localStorage.getItem("lang"),
                                })}
                              </p>
                            }
                          >
                            <ContentCopyIcon
                              onClick={handleCopyToClipboard}
                              className="cursor-pointer"
                              fontSize="small"
                              htmlColor="#928b8b"
                            />
                          </Tooltip>
                        </div>
                      </div>
                    }
                  />
                  <CardContent
                    sx={{
                      paddingTop: "8px",
                    }}
                    className="text-[#abbbce] max-h-72 overflow-y-auto"
                  >
                    <pre>{manifestJson}</pre>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Card
                  className={`${
                    localStorage.getItem("theme") === "dark-mode"
                      ? "card-bg-gradient-dark"
                      : "card-bg-gradient-light"
                  } w-full mt-5 h-full`}
                >
                  <CardHeader
                    className="bg-bgCardHeaderLight dark:bg-bgCardHeaderDark"
                    sx={{
                      paddingLeft: "12px",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingRight: "12px",
                      ".MuiCardHeader-title": {
                        width: "100%",
                        flex: 1,
                      },
                      ".MuiTextField-root": {
                        width: "100%",
                      },
                    }}
                    title={
                      <div className="flex flex-row justify-between items-center">
                        <Typography
                          variant="h6"
                          className="font-bold text-slate-300"
                        >
                          {"<head>"}
                        </Typography>{" "}
                        <div className="flex flex-row justify-end items-center gap-2">
                          <Tooltip
                            title={
                              <p
                                style={{
                                  textAlign:
                                    localStorage.getItem("dir") === "ltr"
                                      ? "left"
                                      : "right",
                                }}
                              >
                                {t("notesIconHover", {
                                  lng: localStorage.getItem("lang"),
                                })}
                              </p>
                            }
                          >
                            <HelpOutlineOutlinedIcon
                              onClick={handleClickOpenDialog("paper")}
                              className="cursor-pointer mt-1"
                              fontSize="medium"
                              htmlColor="#928b8b"
                            />
                          </Tooltip>
                          <Tooltip
                            title={
                              <p
                                style={{
                                  textAlign:
                                    localStorage.getItem("dir") === "ltr"
                                      ? "left"
                                      : "right",
                                }}
                              >
                                {t("copyIconHover", {
                                  lng: localStorage.getItem("lang"),
                                })}
                              </p>
                            }
                          >
                            <ContentCopyIcon
                              onClick={handleCopyMetaTagsToClipboard}
                              className="cursor-pointer"
                              fontSize="small"
                              htmlColor="#928b8b"
                            />
                            <Dialog
                              open={openDialog}
                              onClose={handleClose}
                              scroll={scrollDialog}
                            >
                              <DialogTitle
                                sx={{
                                  background:
                                    "linear-gradient(81deg, rgba(45,45,79,0.9976365546218487) 0%, rgba(13,73,128,1) 100%)",
                                }}
                                id="scroll-dialog-title"
                              >
                                <Typography
                                  variant="h6"
                                  className="font-bold text-[#a0adc0]"
                                >
                                  {
                                    ("helpfulNotesTitle",
                                    {
                                      lng: localStorage.getItem("lang"),
                                    })
                                  }
                                </Typography>
                              </DialogTitle>
                              <DialogContent
                                sx={{
                                  background:
                                    "linear-gradient(81deg, rgba(45,45,79,0.9976365546218487) 0%, rgba(13,73,128,1) 100%)",
                                }}
                                dividers={scrollDialog === "paper"}
                              >
                                <DialogContentText>
                                  <Typography
                                    variant="body1"
                                    className="text-[#a0adc0]"
                                    sx={{
                                      textAlign: "justify",
                                    }}
                                  >
                                    {t("helpulNotesText", {
                                      lng: localStorage.getItem("lang"),
                                    })}
                                  </Typography>
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions
                                sx={{
                                  background:
                                    "linear-gradient(81deg, rgba(45,45,79,0.9976365546218487) 0%, rgba(13,73,128,1) 100%)",
                                }}
                              >
                                <StyledButton
                                  className="bg-gradient-to-l dark:from-bgGradientFromCustomButtonDark dark:to-bgGradientToCustomButtonDark dark:hover:from-bgGradientFromCustomButtonHoverDark dark:hover:to-bgGradientToCustomButtonHoverDark bg-bgCustomButtonLight hover:bg-bgCustomButtonHoverLight  "
                                  style={{ width: "80px" }}
                                  onClick={handleClose}
                                >
                                  {t("gotIt", {
                                    lng: localStorage.getItem("lang"),
                                  })}
                                </StyledButton>
                              </DialogActions>
                            </Dialog>
                          </Tooltip>
                        </div>
                      </div>
                    }
                  />
                  <CardContent
                    sx={{
                      paddingTop: "8px",
                      "&::-webkit-scrollbar": {
                        height: "6px", // ارتفاع نوار اسکرول بار را به 8 پیکسل کاهش دهید
                      },
                    }}
                    className="text-[#abbbce] max-h-96 overflow-y-auto max-w-full overflow-x-auto"
                  >
                    <pre>{metaTagsContent}</pre>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )}
        </div>
      </CacheProvider>
    </>
  );
});
