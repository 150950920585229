import { Skeleton } from "@mui/material";

export function ProgressAlerts() {
  return (
    <>
      <div className="w-full flex flex-col justify-center items-stretch ">
        <div className="h-8 w-full flex flex-row items-center justify-between gap-2 ">
          <Skeleton
            variant="text"
            width={70}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={100}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={90}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />{" "}
          <Skeleton
            variant="text"
            width={90}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>{" "}
        <div className="h-8 w-full flex flex-row items-center justify-between gap-2">
          <Skeleton
            variant="text"
            width={70}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={100}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={90}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />{" "}
          <Skeleton
            variant="text"
            width={90}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>{" "}
        <div className="h-8 w-full flex flex-row items-center justify-between gap-2">
          <Skeleton
            variant="text"
            width={70}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={100}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={90}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />{" "}
          <Skeleton
            variant="text"
            width={90}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>{" "}
        <div className="h-8 w-full flex flex-row items-center justify-between gap-2">
          <Skeleton
            variant="text"
            width={70}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={100}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={90}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />{" "}
          <Skeleton
            variant="text"
            width={90}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>{" "}
      </div>
    </>
  );
}
