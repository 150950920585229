import React from "react";
import { StyledTextField } from "../../../../components/styled-components";

export function TextForm({
  textInput,
  setTextInput,
  setQrCodeValue,
  qrCodeValueStructures,
}) {
  return (
    <div className="w-full flex justify-start items-center">
      <StyledTextField
        value={textInput}
        onChange={(e) => {
          setTextInput(e.target.value);
          setQrCodeValue(qrCodeValueStructures.text);
        }}
        multiline
        minRows={3}
        maxRows={7}
        className="w-full"
        label="Message "
        placeholder="Enter some text..."
      />
    </div>
  );
}
