import { ListItem, ListItemButton, ListItemText } from "@mui/material";
import React from "react";

export function DataItem({ value, name }) {
  return (
    <>
      <ListItem
        className="h-12"
        sx={{ borderBottom: "1px solid #222d50" }}
        // key={id}
        // disablePadding
        // secondaryAction={

        // }
      >
        {/* <ListItemButton dense className=""> */}
        <ListItemText
          //  id={id}
          primary={
            <p className="text-lg">
              {name}: {value}
            </p>
          }
          className="ml-2"
        />
        {/* <ListItemText tabIndex={} /> */}
        {/* </ListItemButton> */}
      </ListItem>
    </>
  );
}
