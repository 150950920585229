import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Fab,
  Grid,
  Snackbar,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  StyledAutocomplete,
  StyledTextField,
} from "../../components/styled-components";
import { useContext, useEffect, useRef, useState } from "react";
import { ProgressRobotGenerator } from "./robot generator components/ProgressRobotGenerator";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { saveAs } from "file-saver";
import AutoFixHighSharpIcon from "@mui/icons-material/AutoFixHighSharp";
import { RightColumn } from "./Robot-Generator-Item/RightColumn";
import { LeftColumn } from "./Robot-Generator-Item/LeftColumn";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { AppContext } from "../../context/AppContext";
import { lng } from "../../lang/languagesObjects";
import { useApi } from "../../customAxios";
export function RobotGenerator({ flagURL }) {
  // for multi language
  const { t } = useTranslation("prints");
  i18next.addResourceBundle("fa", "prints", lng.robotGenerator.fa);
  i18next.addResourceBundle("en", "prints", lng.robotGenerator.en);
  i18next.addResourceBundle("ar", "prints", lng.robotGenerator.ar);
  //initial states
  const api = useApi();
  const [helperText, setHelperText] = useState(null);
  const [robotsData, setRobotsData] = useState(null);
  const [doGenerat, setDoGenerat] = useState(false);
  const [txtFileContent, setTxtFileContent] = useState(null);
  const [showResultCopy, setShowResultCopy] = useState(false);
  const [showcrawlDelayValue, setShowcrawlDelayValue] = useState("No Delay");
  const siteMapInput = useRef(null);
  const [crawlDelayValue, setCrawlDelayValue] = useState("");
  const crawlDelayOption = [
    {
      id: 1,
      label: `5 ${t("second", { lng: localStorage.getItem("lang") })}`,
      value: 5,
    },
    {
      id: 2,
      label: `10 ${t("second", { lng: localStorage.getItem("lang") })}`,
      value: 10,
    },
    {
      id: 3,
      label: `15 ${t("second", { lng: localStorage.getItem("lang") })}`,
      value: 15,
    },
    {
      id: 4,
      label: `20 ${t("second", { lng: localStorage.getItem("lang") })}`,
      value: 20,
    },
  ];
  const [listOfAllowRobots, setListOfAllowRobots] = useState([]);
  const [listOfAllowUrls, setListOfAllowUrls] = useState([]);
  const [listOfDisAllowUrls, setListOfDisAllowUrls] = useState([]);

  // add robots to list of allow robots
  const addRobotToAllowList = (keyProp, id) => {
    let newListOfAllowRobots = [...listOfAllowRobots, { key: keyProp, id: id }];
    setListOfAllowRobots(newListOfAllowRobots);
  };
  // remove robots from list of allow robots
  const removeRobotFromAllowList = (id) => {
    let newListOfAllowRobots = listOfAllowRobots.filter(
      (item) => item.id !== id
    );
    setListOfAllowRobots(newListOfAllowRobots);
  };
  // add urls to list of allow urls
  const addUrlToAllowList = (url, id) => {
    let newListOfAllowUrls = [...listOfAllowUrls, { url: url, id: id }];
    setListOfAllowUrls(newListOfAllowUrls);
  };
  // remove urls from list of allow urls
  const removeUrlFromAllowList = (url) => {
    let newListOfAllowUrls = listOfAllowUrls.filter((item) => item.url !== url);
    setListOfAllowUrls(newListOfAllowUrls);
  };

  // add urls to list of disallow urls
  const addUrlToDisAllowList = (url, id) => {
    let newListOfDisAllowUrls = [...listOfDisAllowUrls, { url: url, id: id }];
    setListOfDisAllowUrls(newListOfDisAllowUrls);
  };
  // remove urls from list of disallow urls
  const removeUrlFromDisAllowList = (url) => {
    let newListOfDisAllowUrls = listOfDisAllowUrls.filter(
      (item) => item.url !== url
    );

    setListOfDisAllowUrls(newListOfDisAllowUrls);
  };

  //handle get robots
  useEffect(() => {
    api
      .get("/tools/seo/robots/list")
      .then((res) => {
        if (!res.data.status) {
          setHelperText(res.data.message);
          setRobotsData([]);
        }
        setRobotsData(res.data.data);

        console.log("robots res", res);
      })
      .catch((err) => {
        setRobotsData([]);
        setHelperText(err.message);
        console.log("robots err", err);
      });
  }, []);

  function handleGeneratorTxtFile() {
    const defaultContent1 =
      `# robots.txt generated by [Helpix] https://helpix.app\n` +
      `# Independent of user agent. Links in the sitemap are full URLs using https:// and need to match\n` +
      `# the protocol of the sitemap.\n` +
      `# Every bot that might possibly read and respect this file\n` +
      `# ========================================================\n`;
    const content = listOfAllowRobots.map((item, index) => {
      let disallowBlock = "";
      let allowBlock = "";
      console.log("listOfDisAllowUrls: ", listOfDisAllowUrls);

      if (
        !listOfDisAllowUrls.find((element) => element.id === item.id) &&
        !listOfAllowUrls.find((element) => element.id === item.id)
      ) {
        return `User-agent: ${item.key}\n\n`;
      }
      if (listOfDisAllowUrls.find((element) => element.id === item.id)) {
        disallowBlock = `User-agent: ${item.key}\n${listOfDisAllowUrls
          .filter((element) => element.id === item.id)
          .map((element) => `Disallow: ${element.url}`)
          .join("\n")}\n\n`;
      }
      if (listOfAllowUrls.find((element) => element.id === item.id)) {
        allowBlock = `User-agent: ${item.key}\n${listOfAllowUrls
          .filter((element) => element.id === item.id)
          .map((element) => `Allow: ${element.url}`)
          .join("\n")}\n\n`;
      }

      return disallowBlock + allowBlock;
    });
    if (crawlDelayValue) {
      content.push(`Crawl-delay: ${crawlDelayValue}\n\n`);
    }
    if (siteMapInput.current.value) {
      content.push(`Sitemap: ${siteMapInput.current.value}\n\n`);
    }
    const fullContent = defaultContent1 + content.join("");
    setTxtFileContent(fullContent);
  }

  const handleCopyToClipboard = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(txtFileContent);
      setShowResultCopy(true);
    } else {
      console.log("Clipboard API not supported");
    }
  };
  function exportFile() {
    const blob = new Blob([txtFileContent], {
      type: "text/plain;charset=utf-8",
    });
    saveAs(blob, "robots.txt");
  }
  return (
    <>
      <Snackbar
        autoHideDuration={1000}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "#e2e8f0",
            color: "#0f172a",
          },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={showResultCopy}
        onClose={() => {
          setShowResultCopy(false);
        }}
        message={t("copied", {
          lng: localStorage.getItem("lang"),
        })}
      />
      <div className="flex flex-col items-center justify-center gap-3 w-full tabsMargin">
        <Card
          className={`${
            localStorage.getItem("theme") === "dark-mode"
              ? "card-bg-gradient-dark"
              : "card-bg-gradient-light"
          }`}
          sx={{ width: "100%" }}
        >
          <CardHeader
            title={
              <div
                className="w-full"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent:
                    localStorage.getItem("dir") === "ltr" ? "start" : "end",
                }}
              >
                <p className="text-slate-200 text-2xl w-fit ">
                  {t("robot", {
                    lng: localStorage.getItem("lang"),
                  })}
                </p>
              </div>
            }
          />
          <CardContent
            sx={{ paddingTop: "0px" }}
            className="flex flex-col items-stretch justify-start gap-5"
          >
            <Grid container spacing={1} columns={12}>
              <Grid item xs={12} sm={3} md={3} lg={3}>
                <StyledAutocomplete
                  className="w-full"
                  size="small"
                  disablePortal
                  value={showcrawlDelayValue}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setCrawlDelayValue(newValue.value);
                      setShowcrawlDelayValue(newValue.label);
                    } else {
                      setCrawlDelayValue("");
                      setShowcrawlDelayValue("No Delay");
                    }
                  }}
                  options={crawlDelayOption}
                  PaperComponent={({ children }) => (
                    <div className={`dark:bg-slate-900 dark:text-slate-300`}>
                      {children}
                    </div>
                  )}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      color: "#d6d3d1",
                      "& fieldset": {
                        borderColor: "#d6d3d1",
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: "#d6d3d1",
                    },
                    "& .MuiSvgIcon-root": {
                      color: "white", // change icon color
                    },
                  }}
                  noOptionsText={<p className="text-slate-200">No option</p>}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("crawlDelay", {
                        lng: localStorage.getItem("lang"),
                      })}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={9} md={9} lg={9}>
                <StyledTextField
                  className="w-full"
                  size="small"
                  label={`${t("sitemap", {
                    lng: localStorage.getItem("lang"),
                  })}: ${t("placeHolder", {
                    lng: localStorage.getItem("lang"),
                  })}`}
                  ref={(input) => {
                    siteMapInput.current = input;
                  }}
                  onChange={(e) => {
                    siteMapInput.current.value = e.target.value;
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card
          className={`${
            localStorage.getItem("theme") === "dark-mode"
              ? "card-bg-gradient-dark"
              : "card-bg-gradient-light"
          }`}
          sx={{ width: "100%" }}
        >
          <CardContent
            sx={{ paddingBottom: "0px" }}
            className="max-h-[450px] overflow-y-auto"
          >
            <Grid container spacing={1} columns={12}>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                {robotsData ? (
                  robotsData
                    .slice(0, robotsData.length / 2)
                    .map((item, index) => {
                      /* Left column */
                      return (
                        <LeftColumn
                          flagURL={flagURL}
                          id={item.id}
                          name={item.name}
                          logo={item.logo}
                          keyProp={item.key}
                          article={item.article}
                          description={item.description}
                          addRobotToAllowList={addRobotToAllowList}
                          removeRobotFromAllowList={removeRobotFromAllowList}
                          addUrlToAllowList={addUrlToAllowList}
                          addUrlToDisAllowList={addUrlToDisAllowList}
                          removeUrlFromAllowList={removeUrlFromAllowList}
                          removeUrlFromDisAllowList={removeUrlFromDisAllowList}
                        />
                      );
                    })
                ) : (
                  <div className="w-full">
                    <ProgressRobotGenerator />
                  </div>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                {robotsData ? (
                  robotsData
                    .slice(robotsData?.length / 2)
                    .map((item, index) => {
                      return (
                        /* Right column */
                        <RightColumn
                          flagURL={flagURL}
                          id={item.id}
                          name={item.name}
                          logo={item.logo}
                          keyProp={item.key}
                          article={item.article}
                          description={item.description}
                          addRobotToAllowList={addRobotToAllowList}
                          removeRobotFromAllowList={removeRobotFromAllowList}
                          addUrlToAllowList={addUrlToAllowList}
                          addUrlToDisAllowList={addUrlToDisAllowList}
                          removeUrlFromAllowList={removeUrlFromAllowList}
                          removeUrlFromDisAllowList={removeUrlFromDisAllowList}
                        />
                      );
                    })
                ) : (
                  <div className="w-full">
                    <ProgressRobotGenerator />
                  </div>
                )}
              </Grid>
            </Grid>
            <div className="w-full pt-3  flex flex-row justify-center items-center ">
              <p className="text-[#EF4444]">{helperText}</p>
            </div>
          </CardContent>
          <CardActions
            sx={{
              paddingTop: "15px",
              paddingBottom: "20px",
              paddingLeft: "15px",
            }}
          >
            <Fab
              className={`bg-gradient-to-r 
             from-bgGradientFromFabLight 
             to-bgGradientToFabLight
             dark:from-bgGradientFromFabDark 
             dark:to-bgGradientToFabDark 
             dark:hover:to-bgGradientToFabHoverDark
             dark:hover:from-bgGradientFromFabHoverDark
            hover:to-bgGradientToFabHoverLight 
             hover:from-bgGradientFromFabHoverLight `}
              onClick={() => {
                setDoGenerat(true);
                handleGeneratorTxtFile();
                exportFile();
              }}
              variant="extended"
              size="small"
              sx={{
                color: "#e2e8f0",
                zIndex: 1,
                width: "140px",
              }}
            >
              <AutoFixHighSharpIcon sx={{ mr: 0.5 }} fontSize="medium" />
              {t("generate", {
                lng: localStorage.getItem("lang"),
              })}
            </Fab>
          </CardActions>
        </Card>

        {doGenerat && (
          <Card
            className={`w-full ${
              localStorage.getItem("theme") === "dark-mode"
                ? "card-bg-gradient-dark"
                : "card-bg-gradient-light"
            }`}
          >
            <CardHeader
              title={
                <p className="text-slate-200 text-xl">
                  {t("generatedRobotText", {
                    lng: localStorage.getItem("lang"),
                  })}
                </p>
              }
              action={
                <div className="flex flex-row justify-center items-center gap-5 pr-1 pt-[3px]">
                  <Tooltip
                    title={t("export", {
                      lng: localStorage.getItem("lang"),
                    })}
                  >
                    {" "}
                    <FileUploadIcon
                      onClick={exportFile}
                      sx={{ cursor: "pointer" }}
                      fontSize="medium"
                      color="info"
                    />
                  </Tooltip>
                  <Tooltip
                    title={t("copied", {
                      lng: localStorage.getItem("lang"),
                    })}
                  >
                    <ContentCopyIcon
                      onClick={handleCopyToClipboard}
                      sx={{ cursor: "pointer" }}
                      fontSize="medium"
                      color="info"
                    />
                  </Tooltip>
                </div>
              }
            />
            <CardContent
              sx={{
                whiteSpace: "pre",
                "&::-webkit-scrollbar": {
                  height: "4px", // ارتفاع نوار اسکرول بار را به 8 پیکسل کاهش دهید
                },
              }}
              className="w-full bg-gray-400 overflow-x-auto"
            >
              <pre>{txtFileContent}</pre>
            </CardContent>
          </Card>
        )}
      </div>
    </>
  );
}
