import "./Assets/Css/App.css";
import "./Assets/Css/theme.css";
import "./Assets/Css/Fonts.css";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Hidden,
  Paper,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ViewSourcePage } from "./ViewSourcePage";
import { PingWorldPage } from "./parts-of-pingWorldPage/PingWorldPage";
import CustomNavigation from "./bottom-navigation/CustomNavigation";
import { Route, Routes, useLocation } from "react-router-dom";
import { Home } from "./home/Home";
import { Profile } from "./Profile/Profile";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import axios from "axios";
import { DomainCheck } from "./DomainCheck";
import { CustomSidebar } from "./Sidebar/CustomSidebar";
import { AlertCenter } from "./home/components/alert-center/AlertCenter";
import { RobotGenerator } from "./home/components/RobotGenerator";
import { JsonParser } from "./home/components/JsonParser";
import { ManifestGenerator } from "./home/components/manifest Generator/ManifestGenerator";
import { Sitemap } from "./home/components/Sitemap";
import { Robot } from "./home/components/Robot";
import { ProtectComponents } from "./ProtectComponents";
import { AppContext } from "./context/AppContext";
import { InfoWorldItem } from "./parts-of-pingWorldPage/components/InfoWorldItem";
import { db } from "./db";
import { HashGenerator } from "./home/components/HashGenerator";
import { IPConvertor } from "./home/components/IPConvertor";
import { QRCodeGenerator } from "./home/components/qrcode/QRCodeGenerator";
import { BinaryCode } from "./home/components/BinaryCode";
import { HtaccessGenerator } from "./home/components/htaccess/HtaccessGenerator";
import { SpeedTest } from "./home/components/SpeedTest";
import { ColorsCode } from "./home/components/colors-code/ColorCode";
import { SubnetMask } from "./home/components/subnet-mask/SubnetMask";
import WifiOffIcon from "@mui/icons-material/WifiOff";
//
// axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
// axios.defaults.baseURL = "https://console.helpix.app/api/v1";
// axios.defaults.headers.common["Authorization"] = `Bearer ${
//   window.localStorage.getItem("user") &&
//   JSON.parse(window.localStorage.getItem("user")).user.token
// }`;

// axios.defaults.headers.common["Domain"] = window.location.hostname;

function App() {
  const flagURL = "https://media.helpix.app/storage/media";
  //initial states
  const [isOnline, setIsOnline] = useState(true);
  const location = useLocation();
  const [showHome, setShowHome] = useState(true);
  const [showItem, setShowItem] = useState(null);
  const [darkMode, setDarkMode] = useState(true);
  const [language, setLanguage] = useState(false);
  const [pathToMove, setPathToMove] = useState("");
  const [languages, setLanguages] = useState("en");
  const [jsonParserContent, setJsonParserContent] = useState("");
  const [parsedJsonData, setParsedJsonData] = useState("");
  const [showLoginComponent, setShowLoginComponent] = useState(false);
  //false : "en"
  const [protocols, setProtocols] = useState(false);
  const [addURL, setAddURL] = useState(null);
  const [activeItem, setActiveItem] = useState("/");
  const { sidebar } = useContext(AppContext);
  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [prefixer, rtlPlugin],
  });
  const cacheLtr = createCache({
    key: "muiltr",
    stylisPlugins: [prefixer],
  });
  // for handle defualt lang
  useEffect(() => {
    if (!localStorage.getItem("dir")) {
      localStorage.setItem("dir", "ltr");
    }
    if (!localStorage.getItem("lang")) {
      localStorage.setItem("lang", "en");
    }
    console.log("app render");
  }, []);
  // for handle theme
  useEffect(() => {
    if (!localStorage.getItem("theme")) {
      localStorage.setItem("theme", "dark-mode");
      document.documentElement.classList.add("dark");
    }
  }, []);
  useEffect(() => {
    const theme = localStorage.getItem("theme");
    if (theme === "dark-mode") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [localStorage.getItem("theme")]);

  //handle clear indexDB
  async function getViewSourceTable() {
    const viewSourceDB = await db.viewSource.toArray();
    return viewSourceDB;
  }
  async function getInfoTable() {
    const infoDB = await db.info.toArray();
    return infoDB;
  }

  useEffect(() => {
    getViewSourceTable()
      .then((res) => {
        console.log("res: ", res);
        const now = Date.now();
        const oneMinuteAgo = now - 86400000; // 1 min ago
        db.viewSource
          .get({ domain: res[0].domain })
          .then((item) => {
            console.log("item: ", item);
            if (item) {
              console.log("Expire value:", item.expire);
              if (item.expire < oneMinuteAgo) {
                db.viewSource.clear();
                console.log("Item deleted");
              }
            }
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((err) => {
        console.log("err: ", err);
      });

    getInfoTable()
      .then((res) => {
        const now = Date.now();
        const oneMinuteAgo = now - 54000000; // 1 min ago

        // Remove expired items from the database
        res.forEach((item) => {
          if (item.expire < oneMinuteAgo) {
            db.info.where("domain").equals(item.domain).delete();
          }
        });
      })
      .catch((err) => {
        console.log("info table err: ", err);
      });
  }, []);

  // handle connection event
  useEffect(() => {
    function handleOnline() {
      setIsOnline(true);
    }
    function handleOffline() {
      setIsOnline(false);
    }
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return (
    <>
      <AppContext.Provider
        value={{
          addAlertIconContext: {
            setAddURL: setAddURL,
            addURL: addURL,
            showItem: showItem,
            setShowItem: setShowItem,
            showHome: showHome,
            setShowHome: setShowHome,
            setShowLoginComponent: setShowLoginComponent,
            showLoginComponent: showLoginComponent,
          },
          loginDialog: {
            showLoginComponent: showLoginComponent,
            setShowLoginComponent: setShowLoginComponent,
            pathToMove: pathToMove,
            setPathToMove: setPathToMove,
          },
          lang: {
            setLanguages: setLanguages,
            languages: languages,
          },
          jsonParser: {
            setJsonParserContent: setJsonParserContent,
            jsonParserContent: jsonParserContent,
            setParsedJsonData: setParsedJsonData,
            parsedJsonData: parsedJsonData,
          },
          theme: {},
          sidebar: {
            activeItem: activeItem,
            setActiveItem: setActiveItem,
          },
          navigation: { activeItem: activeItem, setActiveItem: setActiveItem },
        }}
      >
        <CacheProvider
          value={localStorage.getItem("dir") === "ltr" ? cacheLtr : cacheRtl}
        >
          <Dialog
            open={!isOnline}
            fullWidth
            maxWidth={"xs"}
            sx={{
              "& .MuiDialog-paper": {
                borderRadius: "22px",
                background: "transparent",
              },
              backdropFilter: "blur(10px)",
            }}
          >
            <DialogContent
              className="h-36 flex flex-col gap-2 justify-center items-center bg-gradient-to-l dark:from-bgGradientFromContentDialogDark dark:to-bgGradientToContentDialogDark from-bgGradientFromContentDialogLight to-bgGradientToContentDialogLight"
              sx={{
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              <Typography className="text-slate-200 flex justify-center items-start gap-1">
                Your connection is not established
                <WifiOffIcon className="pb-[2px]" fontSize="medium" />
              </Typography>
              <p className="text-base text-slate-300">
                Please check your network
              </p>
            </DialogContent>
          </Dialog>
          <div className="app w-full h-screen dark:from-gradientFromAppDark dark:to-gradientToAppDark dark:bg-gradientBgAppDark  bg-gradientBgAppLight bg-gradient-to-t from-gradientFromApp to-gradientToApp text-textColorLight dark:text-textColorDark ">
            <div
              style={{
                paddingBottom: "10px",
                paddingTop: "16px",
                paddingLeft: "16px",
                paddingRight: "16px",
                // position: "relative",
              }}
              className={
                "container tabsMargin bg-bgContainerLight dark:bg-bgContainerDark overflow-auto"
              }
            >
              <div
                style={{
                  bottom: "6vh",
                  // left: "20.2vh",
                  right: "20vh",
                  // borderBottomRightRadius: "8px",
                  // borderBottomLeftRadius: "8px",
                }}
                className="h-9 z-[5] fixed font-bold pb-2 text-base versionDisplay"
              >
                <p className="text-slate-300 text-lg pt-2 mr-2">
                  Version: 1.1.1
                </p>
              </div>
              <CustomSidebar darkMode={darkMode} language={language} />
              <Routes>
                <Route
                  path="/"
                  element={
                    <Home
                      setProtocols={setProtocols}
                      language={language}
                      darkMode={darkMode}
                      flagURL={flagURL}
                    />
                  }
                />
                <Route
                  path="/view-source"
                  element={
                    <ViewSourcePage language={language} darkMode={darkMode} />
                  }
                />
                <Route
                  path="/ping-world"
                  element={
                    <PingWorldPage
                      flagURL={flagURL}
                      darkMode={darkMode}
                      protocols={false}
                      setProtocols={setProtocols}
                      language={language}
                    />
                  }
                />
                <Route
                  path="/info"
                  element={
                    <PingWorldPage
                      flagURL={flagURL}
                      darkMode={darkMode}
                      protocols={"info"}
                      setProtocols={setProtocols}
                      language={language}
                    />
                  }
                />{" "}
                <Route
                  path="/ping"
                  element={
                    <PingWorldPage
                      flagURL={flagURL}
                      darkMode={darkMode}
                      protocols={"ping"}
                      setProtocols={setProtocols}
                      language={language}
                    />
                  }
                />
                <Route
                  path="/dns"
                  element={
                    <PingWorldPage
                      flagURL={flagURL}
                      darkMode={darkMode}
                      protocols={"dns"}
                      setProtocols={setProtocols}
                      language={language}
                    />
                  }
                />
                <Route
                  path="/tcp"
                  element={
                    <PingWorldPage
                      flagURL={flagURL}
                      darkMode={darkMode}
                      protocols={"tcp"}
                      setProtocols={setProtocols}
                      language={language}
                    />
                  }
                />
                <Route
                  path="/udp"
                  element={
                    <PingWorldPage
                      flagURL={flagURL}
                      darkMode={darkMode}
                      protocols={"udp"}
                      setProtocols={setProtocols}
                      language={language}
                    />
                  }
                />
                <Route
                  path="/http"
                  element={
                    <PingWorldPage
                      flagURL={flagURL}
                      darkMode={darkMode}
                      protocols={"http"}
                      setProtocols={setProtocols}
                      language={language}
                    />
                  }
                />
                <Route path="/speed-test" element={<SpeedTest />} />
                <Route
                  path="/call"
                  element={<DomainCheck darkMode={darkMode} />}
                />
                <Route
                  path={"/profile"}
                  element={
                    window.localStorage.getItem("user") ? (
                      <Profile language={language} setLanguage={setLanguage} />
                    ) : (
                      <ProtectComponents />
                    )
                  }
                />
                <Route
                  path="/alert-center"
                  element={
                    window.localStorage.getItem("user") ? (
                      <AlertCenter addURL={addURL} setAddURL={setAddURL} />
                    ) : (
                      <ProtectComponents />
                    )
                  }
                />
                <Route
                  path="/robot-generator"
                  element={
                    window.localStorage.getItem("user") ? (
                      <RobotGenerator flagURL={flagURL} />
                    ) : (
                      <ProtectComponents />
                    )
                  }
                />{" "}
                <Route
                  path="/json-parser"
                  element={<JsonParser flagURL={flagURL} />}
                />
                <Route
                  path="/manifest-generator"
                  element={
                    window.localStorage.getItem("user") ? (
                      <ManifestGenerator flagURL={flagURL} />
                    ) : (
                      <ProtectComponents />
                    )
                  }
                />{" "}
                <Route
                  path="/hash-generator"
                  element={
                    window.localStorage.getItem("user") ? (
                      <HashGenerator flagURL={flagURL} />
                    ) : (
                      <ProtectComponents />
                    )
                  }
                />{" "}
                <Route
                  path="/ip-convertor"
                  element={
                    window.localStorage.getItem("user") ? (
                      <IPConvertor flagURL={flagURL} />
                    ) : (
                      <ProtectComponents />
                    )
                  }
                />{" "}
                <Route
                  path="/qrcode-generator"
                  element={
                    window.localStorage.getItem("user") ? (
                      <QRCodeGenerator flagURL={flagURL} />
                    ) : (
                      <ProtectComponents />
                    )
                  }
                />{" "}
                <Route
                  path="/binary-code"
                  element={
                    window.localStorage.getItem("user") ? (
                      <BinaryCode flagURL={flagURL} />
                    ) : (
                      <ProtectComponents />
                    )
                  }
                />{" "}
                <Route
                  path="/color-code"
                  element={
                    window.localStorage.getItem("user") ? (
                      <ColorsCode flagURL={flagURL} />
                    ) : (
                      <ProtectComponents />
                    )
                  }
                />{" "}
                <Route
                  path="/subnet-mask"
                  element={
                    window.localStorage.getItem("user") ? (
                      <SubnetMask flagURL={flagURL} />
                    ) : (
                      <ProtectComponents />
                    )
                  }
                />{" "}
                <Route
                  path="/htaccess-generator"
                  element={
                    window.localStorage.getItem("user") ? (
                      <HtaccessGenerator flagURL={flagURL} />
                    ) : (
                      <ProtectComponents />
                    )
                  }
                />{" "}
                <Route
                  path="/site-map"
                  element={<Sitemap darkMode={darkMode} language={language} />}
                />{" "}
                <Route
                  path="/check-robots"
                  element={
                    <Robot
                      language={language}
                      darkMode={darkMode}
                      flagURL={flagURL}
                    />
                  }
                />
              </Routes>
              {/* <Ping /> */}
              <Hidden mdUp>
                <Paper
                  sx={{
                    position: "fixed",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    zIndex: 200,
                    backgroundColor: "transparent",
                    height: "105px",
                    display: "flex",
                    alignItems: "end",
                    justifyContent: "center",
                    boxShadow: "none",
                  }}
                  elevation={3}
                >
                  <CustomNavigation
                    darkMode={darkMode}
                    protocols={protocols}
                    language={language}
                  />
                </Paper>
              </Hidden>
            </div>
          </div>
        </CacheProvider>
      </AppContext.Provider>
    </>
  );
}

export default App;
