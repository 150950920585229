import { Box, Grid, Skeleton } from "@mui/material";
import { AppContext } from "../context/AppContext";
import { useContext } from "react";
export function ProgressPingTable() {
  return (
    <>
      <div className="w-full flex flex-col justify-center items-start mr-3">
        <div className="h-9 w-full flex flex-row items-center justify-around gap-2">
          <div className="flex flex-row items-center justify-center gap-3 ml-2">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={120}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>
        <div className="h-9 w-full flex flex-row items-center justify-around gap-2">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={120}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>
        <div className="h-9 w-full flex flex-row items-center justify-around gap-2">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={120}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>
        <div className="h-9 w-full flex flex-row items-center justify-around gap-2">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={120}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>
        <div className="h-9 w-full flex flex-row items-center justify-around gap-2">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={120}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>
        <div className="h-9 w-full flex flex-row items-center justify-around gap-2">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={120}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>
        <div className="h-9 w-full flex flex-row items-center justify-around gap-2">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={120}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>
        <div className="h-9 w-full flex flex-row items-center justify-around gap-2">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={120}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>
        <div className="h-9 w-full flex flex-row items-center justify-around gap-2">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={120}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>
        <div className="h-9 w-full flex flex-row items-center justify-around gap-2">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={120}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>
        <div className="h-9 w-full flex flex-row items-center justify-around gap-2">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={120}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>
        <div className="h-9 w-full flex flex-row items-center justify-around gap-2">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={120}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>
      </div>
    </>
  );
}

export function ProgressDnsTable() {
  return (
    <>
      <div className="w-full flex flex-col justify-center items-start mr-3">
        <div className="h-9 w-full flex flex-row items-center justify-around gap-2">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={90}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>{" "}
        <div className="h-9 w-full flex flex-row items-center justify-around gap-2">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={90}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>{" "}
        <div className="h-9 w-full flex flex-row items-center justify-around gap-2">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={90}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>{" "}
        <div className="h-9 w-full flex flex-row items-center justify-around gap-2">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={90}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>{" "}
        <div className="h-9 w-full flex flex-row items-center justify-around gap-2">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={90}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>{" "}
        <div className="h-9 w-full flex flex-row items-center justify-around gap-2">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={90}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>{" "}
        <div className="h-9 w-full flex flex-row items-center justify-around gap-2">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={90}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>{" "}
        <div className="h-9 w-full flex flex-row items-center justify-around gap-2">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={90}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>{" "}
        <div className="h-9 w-full flex flex-row items-center justify-around gap-2">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={90}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>{" "}
        <div className="h-9 w-full flex flex-row items-center justify-around gap-2">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={90}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>{" "}
        <div className="h-9 w-full flex flex-row items-center justify-around gap-2">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={90}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>{" "}
        <div className="h-9 w-full flex flex-row items-center justify-around gap-2">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={90}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>{" "}
      </div>
    </>
  );
}

export function ProgressTcpTable() {
  return (
    <>
      <div className="w-full flex flex-col justify-center items-start mr-3">
        <div className="h-9 w-full flex flex-row items-center justify-around gap-2 ">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={110}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={70}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>{" "}
        <div className="h-9 w-full flex flex-row items-center justify-around gap-2 ">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={110}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={70}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>{" "}
        <div className="h-9 w-full flex flex-row items-center justify-around gap-2 ">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={110}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={70}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>{" "}
        <div className="h-9 w-full flex flex-row items-center justify-around gap-2 ">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={110}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={70}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>{" "}
        <div className="h-9 w-full flex flex-row items-center justify-around gap-2 ">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={110}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={70}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>{" "}
        <div className="h-9 w-full flex flex-row items-center justify-around gap-2 ">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={110}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={70}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>{" "}
        <div className="h-9 w-full flex flex-row items-center justify-around gap-2 ">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={110}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={70}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>{" "}
        <div className="h-9 w-full flex flex-row items-center justify-around gap-2 ">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={110}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={70}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>{" "}
        <div className="h-9 w-full flex flex-row items-center justify-around gap-2 ">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={110}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={70}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>{" "}
        <div className="h-9 w-full flex flex-row items-center justify-around gap-2 ">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={110}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={70}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>{" "}
        <div className="h-9 w-full flex flex-row items-center justify-around gap-2 ">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={110}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={70}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>{" "}
        <div className="h-9 w-full flex flex-row items-center justify-around gap-2 ">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={110}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={70}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>{" "}
      </div>
    </>
  );
}

export function ProgressUdpTable() {
  return (
    <>
      {" "}
      <div className="w-full flex flex-col justify-center items-start mr-3">
        <div className="h-9 w-full flex flex-row items-center justify-around  gap-2">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>{" "}
        <div className="h-9 w-full flex flex-row items-center justify-around  gap-2">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>{" "}
        <div className="h-9 w-full flex flex-row items-center justify-around  gap-2">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>{" "}
        <div className="h-9 w-full flex flex-row items-center justify-around  gap-2">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>{" "}
        <div className="h-9 w-full flex flex-row items-center justify-around  gap-2">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>{" "}
        <div className="h-9 w-full flex flex-row items-center justify-around  gap-2">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>{" "}
        <div className="h-9 w-full flex flex-row items-center justify-around  gap-2">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>{" "}
        <div className="h-9 w-full flex flex-row items-center justify-around  gap-2">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>{" "}
        <div className="h-9 w-full flex flex-row items-center justify-around  gap-2">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>{" "}
        <div className="h-9 w-full flex flex-row items-center justify-around  gap-2">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>{" "}
        <div className="h-9 w-full flex flex-row items-center justify-around  gap-2">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>{" "}
        <div className="h-9 w-full flex flex-row items-center justify-around  gap-2">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>{" "}
      </div>
    </>
  );
}

export function ProgressHttpTable() {
  return (
    <>
      <div className="w-full flex flex-col justify-center items-start mr-3">
        <div className="h-9 w-full flex flex-row items-center justify-around gap-2 ">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={80}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={60}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>{" "}
        <div className="h-9 w-full flex flex-row items-center justify-around gap-2 ">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={80}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={60}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>{" "}
        <div className="h-9 w-full flex flex-row items-center justify-around gap-2 ">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={80}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={60}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>{" "}
        <div className="h-9 w-full flex flex-row items-center justify-around gap-2 ">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={80}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={60}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>{" "}
        <div className="h-9 w-full flex flex-row items-center justify-around gap-2 ">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={80}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={60}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>{" "}
        <div className="h-9 w-full flex flex-row items-center justify-around gap-2 ">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={80}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={60}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>{" "}
        <div className="h-9 w-full flex flex-row items-center justify-around gap-2 ">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={80}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={60}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>{" "}
        <div className="h-9 w-full flex flex-row items-center justify-around gap-2 ">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={80}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={60}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>{" "}
        <div className="h-9 w-full flex flex-row items-center justify-around gap-2 ">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={80}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={60}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>{" "}
        <div className="h-9 w-full flex flex-row items-center justify-around gap-2 ">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={80}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={60}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>{" "}
        <div className="h-9 w-full flex flex-row items-center justify-around gap-2 ">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={80}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={60}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>{" "}
        <div className="h-9 w-full flex flex-row items-center justify-around gap-2 ">
          <div className="flex flex-row items-center justify-center gap-3 ml-2 ">
            {" "}
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />{" "}
            <Skeleton
              variant="text"
              width={70}
              height={20}
              sx={{ bgcolor: "#374151" }}
            />
          </div>
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={80}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={60}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
          <Skeleton
            variant="text"
            width={130}
            height={20}
            sx={{ bgcolor: "#374151" }}
          />
        </div>{" "}
      </div>
    </>
  );
}

export function ProgressInfo() {
  return (
    <>
      {" "}
      <div
        className={`flex flex-col items-start justify-start gap-1 w-full ${
          localStorage.getItem("theme") === "dark-mode"
            ? "card-bg-gradient-dark"
            : "card-bg-gradient-light"
        } p-5`}
      >
        <Skeleton
          width={"100%"}
          height={180}
          variant="rounded"
          sx={{ bgcolor: "#374151", marginBottom: "5px" }}
        />
        <div className="flex flex-col items-start justify-start  w-full mb-32">
          <Skeleton
            variant="text"
            width={"40%"}
            height={15}
            sx={{ bgcolor: "#374151", marginBottom: "3px" }}
          />
          <Skeleton
            variant="text"
            width={"60%"}
            height={15}
            sx={{ bgcolor: "#374151", marginBottom: "3px" }}
          />
          <Skeleton
            variant="text"
            width={"50%"}
            height={15}
            sx={{ bgcolor: "#374151", marginBottom: "3px" }}
          />
          <Skeleton
            variant="text"
            width={"30%"}
            height={15}
            sx={{ bgcolor: "#374151" }}
          />
        </div>
      </div>
    </>
  );
}

export function ProgressRequest() {
  return (
    <>
      <Skeleton
        variant="text"
        width={"100%"}
        height={15}
        sx={{ bgcolor: "#374151" }}
      />
    </>
  );
}

export function ProgressViewSource() {
  const { lang } = useContext(AppContext);
  return (
    <>
      <div
        className="flex flex-col items-start justify-start w-full gap-3"
        style={{ direction: localStorage.getItem("dir") }}
      >
        <Skeleton
          variant="text"
          width={"10%"}
          height={15}
          sx={{ bgcolor: "#374151" }}
        />
        <Skeleton
          variant="text"
          width={"30%"}
          height={15}
          sx={{ bgcolor: "#374151" }}
        />
        <Skeleton
          variant="text"
          width={"40%"}
          height={15}
          sx={{ bgcolor: "#374151", ml: "20px" }}
        />
        <Skeleton
          variant="text"
          width={"70%"}
          height={15}
          sx={{ bgcolor: "#374151", ml: "40px" }}
        />{" "}
        <Skeleton
          variant="text"
          width={"70%"}
          height={15}
          sx={{ bgcolor: "#374151", ml: "80px" }}
        />{" "}
        <Skeleton
          variant="text"
          width={"40%"}
          height={15}
          sx={{ bgcolor: "#374151", ml: "50px" }}
        />
        <Skeleton
          variant="text"
          width={"50%"}
          height={15}
          sx={{ bgcolor: "#374151", ml: "30px" }}
        />
        <Skeleton
          variant="text"
          width={"30%"}
          height={15}
          sx={{ bgcolor: "#374151", ml: "50px" }}
        />{" "}
        <Skeleton
          variant="text"
          width={"40%"}
          height={15}
          sx={{ bgcolor: "#374151", ml: "70px" }}
        />{" "}
        <Skeleton
          variant="text"
          width={"40%"}
          height={15}
          sx={{ bgcolor: "#374151", ml: "90px" }}
        />{" "}
        <Skeleton
          variant="text"
          width={"30%"}
          height={15}
          sx={{ bgcolor: "#374151", ml: "50px" }}
        />{" "}
        <Skeleton
          variant="text"
          width={"40%"}
          height={15}
          sx={{ bgcolor: "#374151", ml: "20px" }}
        />{" "}
        <Skeleton
          variant="text"
          width={"20%"}
          height={15}
          sx={{ bgcolor: "#374151" }}
        />{" "}
        <Skeleton
          variant="text"
          width={"30%"}
          height={15}
          sx={{ bgcolor: "#374151" }}
        />
      </div>
    </>
  );
}

export function ProgressSubnetMaskList() {
  return (
    <>
      <Box
        variant="rectangular"
        width={"100%"}
        className="bg-[#374151] rounded-lg flex flex-col items-start justify-center gap-4 p-5"
        sx={{ direction: localStorage.getItem("dir") }}
      >
        <Skeleton
          className="rounded-lg"
          variant="text"
          width={"30%"}
          height={33}
          sx={{ bgcolor: "#7a808b" }}
        />{" "}
        <Skeleton
          className="rounded-lg"
          variant="text"
          width={"35%"}
          height={33}
          sx={{ bgcolor: "#7a808b" }}
        />{" "}
        <Skeleton
          className="rounded-lg"
          variant="text"
          width={"27%"}
          height={33}
          sx={{ bgcolor: "#7a808b" }}
        />{" "}
        <Skeleton
          className="rounded-lg"
          variant="text"
          width={"30%"}
          height={33}
          sx={{ bgcolor: "#7a808b" }}
        />
        <Skeleton
          className="rounded-lg"
          variant="text"
          width={"25%"}
          height={33}
          sx={{ bgcolor: "#7a808b" }}
        />
        <Skeleton
          className="rounded-lg"
          variant="text"
          width={"30%"}
          height={33}
          sx={{ bgcolor: "#7a808b" }}
        />{" "}
        <Skeleton
          className="rounded-lg"
          variant="text"
          width={"28%"}
          height={33}
          sx={{ bgcolor: "#7a808b" }}
        />
      </Box>
    </>
  );
}
