import {
  Card,
  Grid,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useContext, useEffect, useState } from "react";
import {
  ProgressDnsTable,
  ProgressRequest,
} from "../../loading-components/ProgressPingWorld";
import AddAlertIcon from "@mui/icons-material/AddAlert";
import { AddAlertToDomain, handleAutoLogOut } from "../../includes/functions";
import { AppContext } from "../../context/AppContext";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
// import { ar, en, fa } from "../../lang/ping-world-lang/dns/languagesObjects";
import { lng } from "../../lang/languagesObjects";
import { useNavigate } from "react-router-dom";
import { useApi } from "../../customAxios";
export function DnsWorldItem({
  dnsInput,
  darkMode,
  doRequest,
  flagURL,
  setDoRequest,
  language,
}) {
  // for multi language
  const { t } = useTranslation("prints");
  i18next.addResourceBundle("fa", "prints", lng.pingworld.dns.fa);
  i18next.addResourceBundle("en", "prints", lng.pingworld.dns.en);
  i18next.addResourceBundle("ar", "prints", lng.pingworld.dns.ar);
  //initial states
  const api = useApi();
  const navigate = useNavigate();
  const { lang } = useContext(AppContext);
  const [data, setData] = useState(null);
  const [lastData, setLastData] = useState();
  const [helperText, setHelperText] = useState(<ProgressDnsTable />);
  const [isResultLoaded, setIsResultLoaded] = useState(false);
  const [countRequest, setCountRequest] = useState(0);
  const [firstResponse, setFirstResponse] = useState(null);

  const columns = [
    {
      id: "location",
      label: "Location",
      minWidth: 90,
      align: "left",
    },
    {
      id: "result",
      label: "Result",
      minWidth: 110,
      align: "left",
    },
    {
      id: "ttl",
      label: "TTL",
      minWidth: 120,
      align: "left",
    },
  ];

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
      padding: "6px",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  }));

  useEffect(() => {
    if (doRequest) {
      setData(null);
      setLastData(null);
      api
        .post("/tools/check-world/hosts", {
          url: dnsInput,
          method: "dns",
        })
        .then((res) => {
          if (res.data.status === false) {
            throw Error("Server response was not ok.");
          }
          const dataArray = Object.values(res.data.body);
          setData(dataArray);
          setIsResultLoaded(countRequest <= 3 ? true : false);
          setFirstResponse(res.data);
          console.log("dns axios 1", res);
        })
        .catch((err) => {
          console.log("dns axios error 1", err);
          setHelperText(err.message);
        });
    }
  }, [doRequest]);
  function retryAxios() {
    api
      .post("/tools/check-world/request", {
        request_id: firstResponse.request_id,
      })

      .then((res2) => {
        if (res2.data.status === false) {
          throw Error("Server response was not ok.");
        }
        const dataArray2 = Object.values(res2.data.body);
        setLastData(dataArray2);
        setIsResultLoaded(true);
        setDoRequest(false);
        console.log("dns axios2", res2);
        setCountRequest((preValue) => preValue + 1);
      })
      .catch((err2) => {
        console.log("dns axios error 2", err2);
        setHelperText(err2.message);
        setDoRequest(false);
      });
  }

  useEffect(() => {
    if (firstResponse) retryAxios();
  }, [firstResponse]);

  useEffect(() => {
    if (countRequest < 3 && firstResponse) {
      setTimeout(retryAxios, 3000 * countRequest - 3000);
    } else if (countRequest === 3) {
      console.log("first", countRequest);
      setCountRequest(0);
      setFirstResponse(null);
      setIsResultLoaded(false);
    }
  }, [countRequest]);
  return (
    <>
      {" "}
      <Card
        className={`w-full tabsMargin ${
          localStorage.getItem("theme") === "dark-mode"
            ? "card-bg-gradient-dark"
            : "card-bg-gradient-light"
        }`}
        sx={{
          border: "1px solid #6b7280",
          borderRadius: "5px",
        }}
      >
        <div
          className={`flex flex-row items-center justify-center w-full h-8${
            darkMode ? "dark:bg-slate-800" : "bg-slate-50"
          }`}
        >
          <p
            style={{ fontWeight: "bold" }}
            className={darkMode ? "dark:text-slate-200" : null}
          >
            DNS : {dnsInput}
          </p>
        </div>
        <TableContainer
          sx={{
            maxHeight: "550px",
            overflowY: "auto",
            "& td": { borderBottom: "1px solid #6b7280" },
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    sx={{
                      minWidth: column.minWidth,
                      flexShrink: 1,
                      padding: "3px",
                      fontWeight: "bold",
                      color: darkMode ? "#e7e5e4" : null,
                      borderBottom: "1px solid #6b7280",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.map((item, index) => (
                  <StyledTableRow hover key={item.id && item.id}>
                    {columns.map((column) => {
                      return (
                        <>
                          <StyledTableCell align={column.align}>
                            {column.id === "location" ? (
                              <div className="flex flex-row items-center justify-start gap-1">
                                <img
                                  alt=""
                                  src={item.logo && flagURL + item.logo}
                                  style={{ width: "25px", height: "22px" }}
                                />
                                <p
                                  className={
                                    darkMode ? "dark:text-slate-200" : null
                                  }
                                >
                                  {item.server && item.server}
                                </p>
                              </div>
                            ) : column.id === "ttl" &&
                              lastData &&
                              lastData[index] &&
                              lastData[index][0] &&
                              lastData[index][0].TTL ? (
                              <p
                                className={
                                  darkMode ? "dark:text-slate-200" : null
                                }
                              >
                                {Math.floor(lastData[index][0]?.TTL / 3600)}h{" "}
                                {Math.floor(
                                  (lastData[index][0]?.TTL / 60) % 60
                                )}
                                m {lastData[index][0]?.TTL % 60}s
                              </p>
                            ) : column.id === "result" &&
                              lastData &&
                              lastData[index] &&
                              lastData[index][0] &&
                              lastData[index][0].A &&
                              lastData[index][0].A[0] ? (
                              <div className="flex flex-row justify-start items-center gap-2">
                                <AddAlertToDomain
                                  url={lastData[index][0].A[0]}
                                />
                                <p
                                  className={
                                    darkMode ? "dark:text-slate-200" : null
                                  }
                                >
                                  {lastData[index][0].A[0]}
                                </p>
                              </div>
                            ) : column.id === "result" ? (
                              isResultLoaded === true ? (
                                // <CircularProgress />
                                <ProgressRequest />
                              ) : (
                                <p
                                  style={{
                                    color: darkMode ? "#f87171" : "red",
                                  }}
                                >
                                  {t("notAvailable", {
                                    lng: localStorage.getItem("lang"),
                                  })}
                                </p>
                              )
                            ) : null}
                          </StyledTableCell>
                        </>
                      );
                    })}
                  </StyledTableRow>
                ))}
            </TableBody>{" "}
          </Table>
        </TableContainer>
        {data ? null : doRequest ? (
          <p className="w-full flex items-center justify-center ">
            {helperText}
          </p>
        ) : (
          <div className="w-full flex items-center justify-center pt-1 pb-2">
            {t("enterUrlDNS", { lng: localStorage.getItem("lang") })}
          </div>
        )}
      </Card>
    </>
  );
}
