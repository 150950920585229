import { Card, CardContent } from "@mui/material";
import React from "react";

export function SpeedTest() {
  return (
    <>
      <Card
        className={`${
          localStorage.getItem("theme") === "dark-mode"
            ? "card-bg-gradient-dark"
            : "card-bg-gradient-light"
        } w-full`}
      >
        <CardContent></CardContent>
      </Card>
    </>
  );
}
