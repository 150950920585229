import {
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Grid,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  StyledButton,
  StyledTextField,
} from "../../components/styled-components";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { IPv6 } from "ip-num/IPNumber";
import { IPv4 } from "ip-num/IPNumber";
import { Address6, Address4 } from "ip-address";
import { Link } from "react-router-dom";
export function IPConvertor() {
  //initial states
  const [ipv4, setIPV4] = useState("");
  const [ipv6, setIPV6] = useState("");
  const [label4, setLabel4] = useState("IPv4");
  const [label6, setLabel6] = useState("IPv6");
  const [typeIPConvert, setTypeIPConvert] = useState(true);
  // for changeIP
  const swapIPs = () => {
    setIPV4(ipv6);
    setIPV6(ipv4);
    setLabel4(label6);
    setLabel6(label4);
    setTypeIPConvert(!typeIPConvert);
  };
  //handle ip6 to ip4
  function IP6to4() {
    function parseIp6(ip6str) {
      const str = ip6str.toString();

      // Initialize
      const ar = new Array();
      for (var i = 0; i < 8; i++) ar[i] = 0;

      // Check for trivial IPs
      if (str === "::") return ar;

      // Parse
      const sar = str.split(":");
      let slen = sar.length;
      if (slen > 8) slen = 8;
      let j = 0;
      i = 0;
      for (i = 0; i < slen; i++) {
        // This is a "::", switch to end-run mode
        if (i && sar[i] === "") {
          j = 9 - slen + i;
          continue;
        }
        ar[j] = parseInt(`0x0${sar[i]}`);
        j++;
      }

      return ar;
    }

    var ip6parsed = parseIp6(ipv4.trim());
    const ip4 = `${ip6parsed[6] >> 8}.${ip6parsed[6] & 0xff}.${
      ip6parsed[7] >> 8
    }.${ip6parsed[7] & 0xff}`;
    return ip4;
  }
  //handle convert ip
  const convertIP = () => {
    if (typeIPConvert && ipv4) {
      console.log("convert 4 to 6");
      let ip4 = new IPv4(ipv4.trim());
      let ip6 = ip4.toIPv4MappedIPv6().toString();
      setIPV6(ip6);
    }
    if (!typeIPConvert) {
      console.log("convert 6 to 4");
      setIPV6(IP6to4(ipv6));
    }
  };
  return (
    <>
      <Card
        className={`${
          localStorage.getItem("theme") === "dark-mode"
            ? "card-bg-gradient-dark"
            : "card-bg-gradient-light"
        } w-full`}
      >
        {" "}
        <CardHeader
          title={
            <div
              className="w-full h-14"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent:
                  localStorage.getItem("dir") === "ltr" ? "start" : "end",
              }}
            >
              <p className="text-slate-200 text-2xl w-fit ">IP Convertor</p>
            </div>
          }
        />{" "}
        <CardContent>
          <Grid container spacing={4} columns={12}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid container spacing={2} columns={11}>
                <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
                  <StyledTextField
                    label={label4}
                    value={ipv4}
                    onChange={(e) => {
                      setIPV4(e.target.value);
                    }}
                    className="w-full h-full"
                  />
                </Grid>
                <Grid item xs={12} sm={1} md={1} lg={1} xl={1}>
                  <div className="h-full w-full flex flex-row justify-center items-center gap-2">
                    <StyledButton
                      onClick={swapIPs}
                      className=" w-full h-full bg-gradient-to-l dark:from-bgGradientFromCustomButtonDark dark:to-bgGradientToCustomButtonDark dark:hover:from-bgGradientFromCustomButtonHoverDark dark:hover:to-bgGradientToCustomButtonHoverDark bg-bgCustomButtonLight hover:bg-bgCustomButtonHoverLight"
                    >
                      <CompareArrowsIcon fontSize="medium" />
                    </StyledButton>
                    <StyledButton
                      onClick={() => {
                        convertIP();
                      }}
                      className="w-full h-full bg-gradient-to-l dark:from-bgGradientFromCustomButtonDark dark:to-bgGradientToCustomButtonDark dark:hover:from-bgGradientFromCustomButtonHoverDark dark:hover:to-bgGradientToCustomButtonHoverDark bg-bgCustomButtonLight hover:bg-bgCustomButtonHoverLight"
                    >
                      <ArrowForwardIcon fontSize="medium" />
                    </StyledButton>
                  </div>
                </Grid>
                <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
                  <StyledTextField
                    label={label6}
                    value={ipv6}
                    inputProps={{ readOnly: true }}
                    className="w-full h-full"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              {" "}
              <Grid
                container
                spacing={7}
                columns={12}
                className=" text-slate-200"
              >
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <div className="w-full h-full flex flex-col justify-start items-start gap-3">
                    <h4 className="font-bold">What is an IP address ?</h4>
                    <p style={{ textAlign: "justify", lineHeight: "30px" }}>
                      An IP address is a unique address that identifies a device
                      on the internet or a local network.{" "}
                      <Link
                        className="hover:text-blue-500 hover:underline text-blue-400"
                        to={"/info"}
                      >
                        "What is my IP"
                      </Link>{" "}
                      IP stands for "Internet Protocol," which is the set of
                      rules governing the format of data sent via the internet
                      or local network. In essence, IP addresses are the
                      identifier that allows information to be sent between
                      devices on a network: they contain location information
                      and make devices accessible for communication. The
                      internet needs a way to differentiate between different
                      computers, routers, and websites. IP addresses provide a
                      way of doing so and form an essential part of how the
                      internet works.
                    </p>
                  </div>
                </Grid>{" "}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <div className="w-full h-full flex flex-col justify-start items-start gap-3">
                    <h4 className="font-bold">
                      The key difference between IPv4 and IPv6 ?
                    </h4>
                    <ul>
                      <li>
                        <p style={{ textAlign: "justify", lineHeight: "30px" }}>
                          IPv4 utilizes a 32-bit scheme, whereas IPv6 utilizes a
                          128-bit scheme.
                        </p>
                      </li>
                      <li>
                        {" "}
                        <p style={{ textAlign: "justify", lineHeight: "30px" }}>
                          IPv4 contains only numeric values, whereas IPv6
                          contains alphanumeric values.
                        </p>
                      </li>
                      <li>
                        <p style={{ textAlign: "justify", lineHeight: "30px" }}>
                          A dot separates IPv4 binary bits (.), whereas a colon
                          separates IPv6 binary bits (:).
                        </p>
                      </li>
                      <li>
                        <p style={{ textAlign: "justify", lineHeight: "30px" }}>
                          IPv4 allows twelve header fields, whereas IPv6 allows
                          eight header fields.
                        </p>
                      </li>
                      <li>
                        <p style={{ textAlign: "justify", lineHeight: "30px" }}>
                          In IPv4, you have checksum fields, while IPv6 doesn't
                          have such fields.
                        </p>
                      </li>
                      <li>
                        <p style={{ textAlign: "justify", lineHeight: "30px" }}>
                          IPv4 supports broadcast, while IPv6 supports multicast
                          routing.
                        </p>
                      </li>
                    </ul>
                  </div>
                </Grid>{" "}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <div className="w-full h-full flex flex-col justify-start items-start gap-3">
                    <h4 className="font-bold">Whats IPV4 ?</h4>
                    <p style={{ textAlign: "justify", lineHeight: "30px" }}>
                      IPv4 is an IP version that is most widely used and
                      recognized by internet users. It was the first version of
                      IP used for production in the ARPANET in 1983. It utilizes
                      a 32-bit address scheme and has more than 4 billion IP
                      addresses. IPv4 is considered the primary internet
                      protocol and carries almost 94% of the total internet
                      traffic.
                    </p>
                  </div>
                </Grid>{" "}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <div className="w-full h-full flex flex-col justify-start items-start gap-3">
                    <h4 className="font-bold">Whats IPV6 ?</h4>
                    <p
                      style={{
                        textAlign: "justify",
                        lineHeight: "30px",
                        overflowWrap: "break-word",
                      }}
                    >
                      IPv6 is the latest IP version, also called IPng (Internet
                      Protocol next generation). Because of the unconstructive
                      distribution of the IPv4 address, soon there surfaced a
                      fear regarding the shortage of IPv4 addresses. IPv6 is
                      deployed to fulfill the need for more internet addresses.
                      In addition, it was aimed to resolve the issues associated
                      with IPv4 addresses. IPv6 utilizes a 128-bit scheme and
                      has more than 340 undecillion unique address space. An
                      example of uncompressed form of IPv6 is:
                      1050:0000:0000:0000:0005:0600:300c:326b. However, you can
                      compress the IPv6 as per your need.
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}></Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
