import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { StyledTextField } from "../../../../components/styled-components";

export const PreventHotlinking = memo(
  ({
    referringURL,
    setReferringURL,
    extensionInput,
    setExtensionInput,
    hotlinkingImage,
    setHotlinkingImage,
  }) => {
    // for test state
    const [test, setTest] = useState(false);
    useEffect(() => {
      setTest(true);
    }, []);
    return (
      <>
        <Accordion
          className="w-full text-slate-100 rounded-md"
          sx={{
            background: "rgb(29,103,158)",
            background:
              "linear-gradient(90deg, rgba(13, 166, 194, 0.23573179271708689) 0%, rgba(38, 36, 80, 0.804359243697479) 100%)",
          }}
        >
          <AccordionSummary
            className="h-14"
            expandIcon={<ExpandMoreIcon className="text-slate-100" />}
          >
            Prevent Hotlinking
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={1} columns={12}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                <StyledTextField
                  value={referringURL}
                  onChange={(e) => setReferringURL(e.target.value)}
                  className="w-full"
                  size="small"
                  label="Referring URL"
                />
              </Grid>{" "}
              <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                <StyledTextField
                  value={extensionInput}
                  onChange={(e) => setExtensionInput(e.target.value)}
                  className="w-full"
                  size="small"
                  label="File Extension"
                />
              </Grid>{" "}
              <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                <StyledTextField
                  value={hotlinkingImage}
                  onChange={(e) => setHotlinkingImage(e.target.value)}
                  className="w-full"
                  size="small"
                  label="No hotlinking Image"
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </>
    );
  }
);
