import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import QRCode, { QRCodeCanvas, QRCodeSVG } from "qrcode.react";
import React, { useEffect, useRef, useState } from "react";
import { StyledButton } from "../../../components/styled-components";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { UrlForm } from "./components/UrlForm";
import { TextForm } from "./components/TextForm";
import { WifiForm } from "./components/WifiForm";
import { VcardForm } from "./components/VcardForm";
import { EmailForm } from "./components/EmailForm";
import { SMSForm } from "./components/SMSForm";
import { saveAs } from "file-saver";
import domtoimage from "dom-to-image";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AirplaneTicketIcon from "@mui/icons-material/AirplaneTicket";
import PhoneIcon from "@mui/icons-material/Phone";
import PlaceIcon from "@mui/icons-material/Place";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import WifiIcon from "@mui/icons-material/Wifi";
import ContactEmergencyIcon from "@mui/icons-material/ContactEmergency";
import MarkunreadIcon from "@mui/icons-material/Markunread";
import FitScreenIcon from "@mui/icons-material/FitScreen";
import RoomServiceIcon from "@mui/icons-material/RoomService";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import CurrencyBitcoinIcon from "@mui/icons-material/CurrencyBitcoin";
import BlockIcon from "@mui/icons-material/Block";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
export function QRCodeGenerator() {
  //initial states
  const qrCodeRef = useRef();
  const [qrCodeValue, setQrCodeValue] = useState("https://helpix.app");
  const [level, setLevel] = useState("L");
  const [showItemsForm, setShowItemsForm] = useState("url");
  const [showQRCode, setShowQRCode] = useState(
    <div className="w-full flex justify-center items-center">
      <QrCodeScannerIcon
        className="w-full h-full text-slate-400"
        fontSize="large"
      />
    </div>
  );
  const [showLogo, setShowLogo] = useState(true);
  //for url input value
  const [urlInput, setUrlInput] = useState(null);
  //for text input value
  const [textInput, setTextInput] = useState(null);
  //for wifi input value
  const [netWorkName, setNetWorkName] = useState(null);
  const [netWorkPassword, setNetWorkPassword] = useState(null);
  const [netWorkType, setNetWorkType] = useState(null);
  //for vCard Inputs
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState(null);
  const [url, setUrl] = useState(null);
  //for email inputs
  const [emailInput, setEmailInput] = useState(null);
  const [subject, setSubject] = useState(null);
  const [message, setMessage] = useState(null);
  //for SMS inputs
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [messageSMS, setMessageSMS] = useState(null);

  //for handle generate qr code
  function handleGenerateQRCode() {
    setShowQRCode(
      <QRCode
        value={qrCodeValue}
        level={level}
        renderAs="svg"
        includeMargin={true}
        size={255}
        style={{ width: "100%", height: "100%", borderRadius: "12px" }}
        imageSettings={
          showLogo
            ? {
                src: "./assets/logo/logo-192.png",
                height: 35,
                width: 35,
                excavate: true,
              }
            : null
        }
      />
    );
  }
  useEffect(() => {
    handleGenerateQRCode();
  }, [showLogo]);
  // for structure qrcode value data
  const qrCodeValueStructures = {
    url: urlInput,
    text: textInput,
    wifi: `WIFI:T:${netWorkType};S:${netWorkName};P:${netWorkPassword};;`,
    vcard: `BEGIN:VCARD
VERSION:3.0
FN:${firstName} ${lastName}
TEL;TYPE=voice,work,pref:${phone}
EMAIL;TYPE=internet,pref:${email}
URL;TYPE=work:${url}
END:VCARD`,
    email: `mailto:${emailInput}?subject=${subject}&body=${message}`,
    sms: `SMSTO:${phoneNumber}: ${messageSMS}`,
  };

  // for show items form
  function handleShowItemsForm(itemForm) {
    setShowItemsForm(itemForm);
  }

  //handle export qr code
  function exportQRCode(content, name) {
    domtoimage.toBlob(content.current).then(function (blob) {
      saveAs(blob, `${name}`);
    });
  }
  // handle change level qrcode
  useEffect(() => {
    handleGenerateQRCode();
  }, [level]);

  return (
    <>
      <Card
        className={`${
          localStorage.getItem("theme") === "dark-mode"
            ? "card-bg-gradient-dark"
            : "card-bg-gradient-light"
        } w-full  mb-32`}
      >
        <CardHeader
          title={
            <div
              className="w-full h-14"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent:
                  localStorage.getItem("dir") === "ltr" ? "start" : "end",
              }}
            >
              <p className="text-slate-200 text-2xl w-fit ">QRCode Generator</p>
            </div>
          }
        />{" "}
        <CardContent className="h-full">
          <Grid container spacing={4} columns={12}>
            <Grid item xs={12} sm={9} md={9} lg={9} xl={9}>
              <div className="flex flex-col items-center justify-start gap-5 h-full">
                <Grid container spacing={2} columns={16}>
                  <Grid item xs={4} sm={4} md={4} lg={2} xl={2}>
                    <StyledButton
                      onClick={() => {
                        handleShowItemsForm("url");
                      }}
                      className="w-full bg-gradient-to-l dark:from-bgGradientFromCustomButtonDark dark:to-bgGradientToCustomButtonDark dark:hover:from-bgGradientFromCustomButtonHoverDark dark:hover:to-bgGradientToCustomButtonHoverDark bg-bgCustomButtonLight hover:bg-bgCustomButtonHoverLight"
                    >
                      URL
                    </StyledButton>
                  </Grid>
                  <Grid item xs={4} sm={4} md={4} lg={2} xl={2}>
                    <StyledButton
                      onClick={() => {
                        handleShowItemsForm("text");
                      }}
                      className="w-full bg-gradient-to-l dark:from-bgGradientFromCustomButtonDark dark:to-bgGradientToCustomButtonDark dark:hover:from-bgGradientFromCustomButtonHoverDark dark:hover:to-bgGradientToCustomButtonHoverDark bg-bgCustomButtonLight hover:bg-bgCustomButtonHoverLight"
                    >
                      Text
                    </StyledButton>
                  </Grid>
                  <Grid item xs={4} sm={4} md={4} lg={2} xl={2}>
                    <StyledButton
                      onClick={() => {
                        handleShowItemsForm("wifi");
                      }}
                      className="w-full bg-gradient-to-l dark:from-bgGradientFromCustomButtonDark dark:to-bgGradientToCustomButtonDark dark:hover:from-bgGradientFromCustomButtonHoverDark dark:hover:to-bgGradientToCustomButtonHoverDark bg-bgCustomButtonLight hover:bg-bgCustomButtonHoverLight"
                    >
                      Wifi
                    </StyledButton>
                  </Grid>
                  <Grid item xs={4} sm={4} md={4} lg={2} xl={2}>
                    <StyledButton
                      onClick={() => {
                        handleShowItemsForm("vcard");
                      }}
                      className="w-full bg-gradient-to-l dark:from-bgGradientFromCustomButtonDark dark:to-bgGradientToCustomButtonDark dark:hover:from-bgGradientFromCustomButtonHoverDark dark:hover:to-bgGradientToCustomButtonHoverDark bg-bgCustomButtonLight hover:bg-bgCustomButtonHoverLight"
                    >
                      VCard
                    </StyledButton>
                  </Grid>
                  <Grid item xs={4} sm={4} md={4} lg={2} xl={2}>
                    <StyledButton
                      onClick={() => {
                        handleShowItemsForm("email");
                      }}
                      className="w-full bg-gradient-to-l dark:from-bgGradientFromCustomButtonDark dark:to-bgGradientToCustomButtonDark dark:hover:from-bgGradientFromCustomButtonHoverDark dark:hover:to-bgGradientToCustomButtonHoverDark bg-bgCustomButtonLight hover:bg-bgCustomButtonHoverLight"
                    >
                      Email
                    </StyledButton>
                  </Grid>
                  <Grid item xs={4} sm={4} md={4} lg={2} xl={2}>
                    <StyledButton
                      onClick={() => {
                        handleShowItemsForm("sms");
                      }}
                      className="w-full bg-gradient-to-l dark:from-bgGradientFromCustomButtonDark dark:to-bgGradientToCustomButtonDark dark:hover:from-bgGradientFromCustomButtonHoverDark dark:hover:to-bgGradientToCustomButtonHoverDark bg-bgCustomButtonLight hover:bg-bgCustomButtonHoverLight"
                    >
                      SMS
                    </StyledButton>
                  </Grid>{" "}
                  <Grid item xs={4} sm={4} md={4} lg={2} xl={2}>
                    <StyledButton className="w-full bg-gradient-to-l dark:from-bgGradientFromCustomButtonDark dark:to-bgGradientToCustomButtonDark dark:hover:from-bgGradientFromCustomButtonHoverDark dark:hover:to-bgGradientToCustomButtonHoverDark bg-bgCustomButtonLight hover:bg-bgCustomButtonHoverLight">
                      MP3
                    </StyledButton>
                  </Grid>
                  <Grid item xs={4} sm={4} md={4} lg={2} xl={2}>
                    <StyledButton className="w-full bg-gradient-to-l dark:from-bgGradientFromCustomButtonDark dark:to-bgGradientToCustomButtonDark dark:hover:from-bgGradientFromCustomButtonHoverDark dark:hover:to-bgGradientToCustomButtonHoverDark bg-bgCustomButtonLight hover:bg-bgCustomButtonHoverLight">
                      Apps
                    </StyledButton>
                  </Grid>{" "}
                  <Grid item xs={4} sm={4} md={4} lg={2} xl={2}>
                    <StyledButton className="w-full bg-gradient-to-l dark:from-bgGradientFromCustomButtonDark dark:to-bgGradientToCustomButtonDark dark:hover:from-bgGradientFromCustomButtonHoverDark dark:hover:to-bgGradientToCustomButtonHoverDark bg-bgCustomButtonLight hover:bg-bgCustomButtonHoverLight">
                      PDF
                    </StyledButton>
                  </Grid>
                  <Grid item xs={4} sm={4} md={4} lg={2} xl={2}>
                    <StyledButton className="w-full bg-gradient-to-l dark:from-bgGradientFromCustomButtonDark dark:to-bgGradientToCustomButtonDark dark:hover:from-bgGradientFromCustomButtonHoverDark dark:hover:to-bgGradientToCustomButtonHoverDark bg-bgCustomButtonLight hover:bg-bgCustomButtonHoverLight">
                      Images
                    </StyledButton>
                  </Grid>
                </Grid>
                <Divider
                  variant="fullWidth"
                  className="h-[1px] rounded-md bg-slate-200 w-full"
                />
                <Grid container spacing={1} columns={12}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {showItemsForm === "url" ? (
                      <UrlForm
                        urlInput={urlInput}
                        setUrlInput={setUrlInput}
                        setQrCodeValue={setQrCodeValue}
                        qrCodeValueStructures={qrCodeValueStructures}
                      />
                    ) : showItemsForm === "text" ? (
                      <TextForm
                        setTextInput={setTextInput}
                        textInput={textInput}
                        setQrCodeValue={setQrCodeValue}
                        qrCodeValueStructures={qrCodeValueStructures}
                      />
                    ) : showItemsForm === "wifi" ? (
                      <WifiForm
                        setNetWorkName={setNetWorkName}
                        netWorkName={netWorkName}
                        setNetWorkPassword={setNetWorkPassword}
                        netWorkPassword={netWorkPassword}
                        netWorkType={netWorkType}
                        setNetWorkType={setNetWorkType}
                        setQrCodeValue={setQrCodeValue}
                        qrCodeValueStructures={qrCodeValueStructures}
                      />
                    ) : showItemsForm === "vcard" ? (
                      <VcardForm
                        firstName={firstName}
                        setFirstName={setFirstName}
                        lastName={lastName}
                        setLastName={setLastName}
                        phone={phone}
                        setPhone={setPhone}
                        email={email}
                        setEmail={setEmail}
                        url={url}
                        setUrl={setUrl}
                        setQrCodeValue={setQrCodeValue}
                        qrCodeValueStructures={qrCodeValueStructures}
                      />
                    ) : showItemsForm === "email" ? (
                      <EmailForm
                        setEmailInput={setEmailInput}
                        emailInput={emailInput}
                        subject={subject}
                        setSubject={setSubject}
                        message={message}
                        setMessage={setMessage}
                        setQrCodeValue={setQrCodeValue}
                        qrCodeValueStructures={qrCodeValueStructures}
                      />
                    ) : (
                      showItemsForm === "sms" && (
                        <SMSForm
                          phoneNumber={phoneNumber}
                          setPhoneNumber={setPhoneNumber}
                          messageSMS={messageSMS}
                          setMessageSMS={setMessageSMS}
                          setQrCodeValue={setQrCodeValue}
                          qrCodeValueStructures={qrCodeValueStructures}
                        />
                      )
                    )}
                  </Grid>
                </Grid>
                <Divider
                  variant="fullWidth"
                  className="h-[1px] rounded-md bg-slate-200 w-full"
                />
                <Accordion
                  className="w-full text-slate-100 rounded-md"
                  sx={{
                    background: "rgb(29,103,158)",
                    background:
                      "linear-gradient(90deg, rgba(13, 166, 194, 0.23573179271708689) 0%, rgba(38, 36, 80, 0.804359243697479) 100%)",
                  }}
                >
                  <AccordionSummary
                    className="h-16"
                    expandIcon={<ExpandMoreIcon className="text-slate-100" />}
                  >
                    <TaskAltOutlinedIcon fontSize="medium" />
                    <p className="text-xl ml-2"> Select Logo</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2} columns={12}>
                      <Grid item xs={3} sm={2} md={2} lg={1}>
                        <div
                          onClick={() => {
                            setShowLogo(false);
                          }}
                          className="flex justify-center items-center w-full rounded-lg p-2 cursor-pointer opacity-70 hover:opacity-100 border-2 border-slate-100 border-solid"
                        >
                          <BlockIcon fontSize="medium" htmlColor="#f1f5f9" />
                        </div>
                      </Grid>{" "}
                      <Grid item xs={3} sm={2} md={2} lg={1}>
                        {" "}
                        <label
                          htmlFor="file-input"
                          className="flex justify-center items-center w-full rounded-lg p-2 cursor-pointer opacity-70 hover:opacity-100 border-2 border-slate-100 border-solid"
                        >
                          <AddIcon fontSize="medium" htmlColor="#f1f5f9" />
                        </label>
                        <input
                          id="file-input"
                          type="file"
                          style={{ display: "none" }}
                          // onChange={handleFileChange}
                        />
                      </Grid>
                      <Grid item xs={3} sm={2} md={2} lg={1}>
                        <div className="flex justify-center items-center w-full rounded-lg p-2 cursor-pointer opacity-70 hover:opacity-100 border-2 border-slate-100 border-solid">
                          <AccountBalanceIcon
                            fontSize="medium"
                            htmlColor="#f1f5f9"
                          />
                        </div>
                      </Grid>
                      <Grid item xs={3} sm={2} md={2} lg={1}>
                        {" "}
                        <div className="flex justify-center items-center w-full rounded-lg p-2 cursor-pointer opacity-70 hover:opacity-100 border-2 border-slate-100 border-solid">
                          <AirplaneTicketIcon
                            fontSize="medium"
                            htmlColor="#f1f5f9"
                          />
                        </div>
                      </Grid>
                      <Grid item xs={3} sm={2} md={2} lg={1}>
                        {" "}
                        <div className="flex justify-center items-center w-full rounded-lg p-2 cursor-pointer opacity-70 hover:opacity-100 border-2 border-slate-100 border-solid">
                          <PhoneIcon fontSize="medium" htmlColor="#f1f5f9" />
                        </div>
                      </Grid>
                      <Grid item xs={3} sm={2} md={2} lg={1}>
                        <div className="flex justify-center items-center w-full rounded-lg p-2 cursor-pointer opacity-70 hover:opacity-100 border-2 border-slate-100 border-solid">
                          <PlaceIcon fontSize="medium" htmlColor="#f1f5f9" />
                        </div>
                      </Grid>
                      <Grid item xs={3} sm={2} md={2} lg={1}>
                        {" "}
                        <div className="flex justify-center items-center w-full rounded-lg p-2 cursor-pointer opacity-70 hover:opacity-100 border-2 border-slate-100 border-solid">
                          <InsertLinkIcon
                            fontSize="medium"
                            htmlColor="#f1f5f9"
                          />
                        </div>
                      </Grid>{" "}
                      <Grid item xs={3} sm={2} md={2} lg={1}>
                        {" "}
                        <div className="flex justify-center items-center w-full rounded-lg p-2 cursor-pointer opacity-70 hover:opacity-100 border-2 border-slate-100 border-solid">
                          <WifiIcon fontSize="medium" htmlColor="#f1f5f9" />
                        </div>
                      </Grid>
                      <Grid item xs={3} sm={2} md={2} lg={1}>
                        {" "}
                        <div className="flex justify-center items-center w-full rounded-lg p-2 cursor-pointer opacity-70 hover:opacity-100 border-2 border-slate-100 border-solid">
                          <ContactEmergencyIcon
                            fontSize="medium"
                            htmlColor="#f1f5f9"
                          />
                        </div>
                      </Grid>{" "}
                      <Grid item xs={3} sm={2} md={2} lg={1}>
                        {" "}
                        <div className="flex justify-center items-center w-full rounded-lg p-2 cursor-pointer opacity-70 hover:opacity-100 border-2 border-slate-100 border-solid">
                          <MarkunreadIcon
                            fontSize="medium"
                            htmlColor="#f1f5f9"
                          />
                        </div>
                      </Grid>{" "}
                      <Grid item xs={3} sm={2} md={2} lg={1}>
                        {" "}
                        <div className="flex justify-center items-center w-full rounded-lg p-2 cursor-pointer opacity-70 hover:opacity-100 border-2 border-slate-100 border-solid">
                          <FitScreenIcon
                            fontSize="medium"
                            htmlColor="#f1f5f9"
                          />
                        </div>
                      </Grid>{" "}
                      <Grid item xs={3} sm={2} md={2} lg={1}>
                        {" "}
                        <div className="flex justify-center items-center w-full rounded-lg p-2 cursor-pointer opacity-70 hover:opacity-100 border-2 border-slate-100 border-solid">
                          <RoomServiceIcon
                            fontSize="medium"
                            htmlColor="#f1f5f9"
                          />
                        </div>
                      </Grid>{" "}
                      <Grid item xs={3} sm={2} md={2} lg={1}>
                        {" "}
                        <div className="flex justify-center items-center w-full rounded-lg p-2 cursor-pointer opacity-70 hover:opacity-100 border-2 border-slate-100 border-solid">
                          <FastfoodIcon fontSize="medium" htmlColor="#f1f5f9" />
                        </div>
                      </Grid>
                      <Grid item xs={3} sm={2} md={2} lg={1}>
                        {" "}
                        <div className="flex justify-center items-center w-full rounded-lg p-2 cursor-pointer opacity-70 hover:opacity-100 border-2 border-slate-100 border-solid">
                          <CurrencyBitcoinIcon
                            fontSize="medium"
                            htmlColor="#f1f5f9"
                          />
                        </div>
                      </Grid>{" "}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              md={3}
              lg={3}
              xl={3}
              className="flex justify-center items-center"
            >
              <Grid container spacing={2} columns={12} className="">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div
                    ref={qrCodeRef}
                    className="w-full h-full  rounded-xl bg-white"
                  >
                    {showQRCode}
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <FormControl
                    sx={{
                      "& .MuiTypography-root": {
                        fontSize: 20, // increase font size of the label text
                      },
                    }}
                    className="w-full"
                  >
                    <RadioGroup
                      className=" w-full flex justify-center items-center"
                      row
                      value={level}
                      onChange={(e) => {
                        setLevel(e.target.value);
                      }}
                    >
                      <Grid container spacing={3} columns={12}>
                        <Grid
                          item
                          xs={6}
                          sm={6}
                          md={6}
                          lg={6}
                          xl={6}
                          className="flex justify-center items-center"
                        >
                          {" "}
                          <FormControlLabel
                            className="text-slate-100 "
                            value="L"
                            control={
                              <Radio
                                sx={{
                                  "& .MuiSvgIcon-root": {
                                    fontSize: 30,
                                  },
                                }}
                                className="text-slate-200"
                              />
                            }
                            label="Low"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sm={6}
                          md={6}
                          lg={6}
                          xl={6}
                          className="flex justify-center items-center"
                        >
                          <FormControlLabel
                            className="text-slate-100"
                            value="M"
                            control={
                              <Radio
                                sx={{
                                  "& .MuiSvgIcon-root": {
                                    fontSize: 30,
                                  },
                                }}
                                className="text-slate-200"
                              />
                            }
                            label="Medium"
                          />{" "}
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sm={6}
                          md={6}
                          lg={6}
                          xl={6}
                          className="flex justify-center items-center"
                        >
                          {" "}
                          <FormControlLabel
                            className="text-slate-100"
                            value="H"
                            control={
                              <Radio
                                sx={{
                                  "& .MuiSvgIcon-root": {
                                    fontSize: 30,
                                  },
                                }}
                                className="text-slate-200"
                              />
                            }
                            label="High"
                          />{" "}
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sm={6}
                          md={6}
                          lg={6}
                          xl={6}
                          className="flex justify-center items-center"
                        >
                          <FormControlLabel
                            className="text-slate-100"
                            value="Q"
                            control={
                              <Radio
                                sx={{
                                  "& .MuiSvgIcon-root": {
                                    fontSize: 30,
                                  },
                                }}
                                className="text-slate-200"
                              />
                            }
                            label="Quartile"
                          />
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <StyledButton
                    onClick={handleGenerateQRCode}
                    className="w-full bg-gradient-to-l dark:from-bgGradientFromCustomButtonDark dark:to-bgGradientToCustomButtonDark dark:hover:from-bgGradientFromCustomButtonHoverDark dark:hover:to-bgGradientToCustomButtonHoverDark bg-bgCustomButtonLight hover:bg-bgCustomButtonHoverLight"
                  >
                    Generate
                  </StyledButton>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  {" "}
                  <StyledButton
                    onClick={() => {
                      exportQRCode(qrCodeRef, "qrCode.png");
                    }}
                    className="w-full bg-gradient-to-l dark:from-bgGradientFromCustomButtonDark dark:to-bgGradientToCustomButtonDark dark:hover:from-bgGradientFromCustomButtonHoverDark dark:hover:to-bgGradientToCustomButtonHoverDark bg-bgCustomButtonLight hover:bg-bgCustomButtonHoverLight"
                  >
                    Download
                  </StyledButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
