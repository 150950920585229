import { Grid, Skeleton } from "@mui/material";

export function ProgressRobotGenerator() {
  return (
    <>
      <div className="flex flex-col items-stretch justify-start gap-3">
        <div
          className="w-full h-10 flex flex-row justify-between items-center rounded-md pr-3 pl-3"
          style={{ backgroundColor: "rgb(32 33 56 / 96%)" }}
        >
          <Grid container columns={12} spacing={2}>
            <Grid
              className="flex flex-row justify-start items-center gap-2"
              item
              xs={9}
              sm={9}
              md={9}
              lg={9}
            >
              <Skeleton
                variant="circular"
                height={25}
                width={25}
                sx={{ bgcolor: "#374151" }}
              />
              <Skeleton
                variant="text"
                height={17}
                sx={{ bgcolor: "#374151", width: "140px" }}
              />
            </Grid>
            <Grid
              item
              xs={3}
              sm={3}
              md={3}
              lg={3}
              className="flex flex-row justify-end items-center"
            >
              <Skeleton
                variant="rounded"
                height={15}
                width={15}
                sx={{ bgcolor: "#374151" }}
              />
            </Grid>
          </Grid>
        </div>
        <div
          className="w-full h-10 flex flex-row justify-between items-center rounded-md pr-3 pl-3"
          style={{ backgroundColor: "rgb(32 33 56 / 96%)" }}
        >
          <Grid container columns={12} spacing={2}>
            <Grid
              className="flex flex-row justify-start items-center gap-2"
              item
              xs={9}
              sm={9}
              md={9}
              lg={9}
            >
              <Skeleton
                variant="circular"
                height={25}
                width={25}
                sx={{ bgcolor: "#374151" }}
              />
              <Skeleton
                variant="text"
                height={17}
                sx={{ bgcolor: "#374151", width: "140px" }}
              />
            </Grid>
            <Grid
              item
              xs={3}
              sm={3}
              md={3}
              lg={3}
              className="flex flex-row justify-end items-center"
            >
              <Skeleton
                variant="rounded"
                height={15}
                width={15}
                sx={{ bgcolor: "#374151" }}
              />
            </Grid>
          </Grid>
        </div>{" "}
        <div
          className="w-full h-10 flex flex-row justify-between items-center rounded-md pr-3 pl-3"
          style={{ backgroundColor: "rgb(32 33 56 / 96%)" }}
        >
          <Grid container columns={12} spacing={2}>
            <Grid
              className="flex flex-row justify-start items-center gap-2"
              item
              xs={9}
              sm={9}
              md={9}
              lg={9}
            >
              <Skeleton
                variant="circular"
                height={25}
                width={25}
                sx={{ bgcolor: "#374151" }}
              />
              <Skeleton
                variant="text"
                height={17}
                sx={{ bgcolor: "#374151", width: "140px" }}
              />
            </Grid>
            <Grid
              item
              xs={3}
              sm={3}
              md={3}
              lg={3}
              className="flex flex-row justify-end items-center"
            >
              <Skeleton
                variant="rounded"
                height={15}
                width={15}
                sx={{ bgcolor: "#374151" }}
              />
            </Grid>
          </Grid>
        </div>{" "}
        <div
          className="w-full h-10 flex flex-row justify-between items-center rounded-md pr-3 pl-3"
          style={{ backgroundColor: "rgb(32 33 56 / 96%)" }}
        >
          <Grid container columns={12} spacing={2}>
            <Grid
              className="flex flex-row justify-start items-center gap-2"
              item
              xs={9}
              sm={9}
              md={9}
              lg={9}
            >
              <Skeleton
                variant="circular"
                height={25}
                width={25}
                sx={{ bgcolor: "#374151" }}
              />
              <Skeleton
                variant="text"
                height={17}
                sx={{ bgcolor: "#374151", width: "140px" }}
              />
            </Grid>
            <Grid
              item
              xs={3}
              sm={3}
              md={3}
              lg={3}
              className="flex flex-row justify-end items-center"
            >
              <Skeleton
                variant="rounded"
                height={15}
                width={15}
                sx={{ bgcolor: "#374151" }}
              />
            </Grid>
          </Grid>
        </div>{" "}
        <div
          className="w-full h-10 flex flex-row justify-between items-center rounded-md pr-3 pl-3"
          style={{ backgroundColor: "rgb(32 33 56 / 96%)" }}
        >
          <Grid container columns={12} spacing={2}>
            <Grid
              className="flex flex-row justify-start items-center gap-2"
              item
              xs={9}
              sm={9}
              md={9}
              lg={9}
            >
              <Skeleton
                variant="circular"
                height={25}
                width={25}
                sx={{ bgcolor: "#374151" }}
              />
              <Skeleton
                variant="text"
                height={17}
                sx={{ bgcolor: "#374151", width: "140px" }}
              />
            </Grid>
            <Grid
              item
              xs={3}
              sm={3}
              md={3}
              lg={3}
              className="flex flex-row justify-end items-center"
            >
              <Skeleton
                variant="rounded"
                height={15}
                width={15}
                sx={{ bgcolor: "#374151" }}
              />
            </Grid>
          </Grid>
        </div>{" "}
        <div
          className="w-full h-10 flex flex-row justify-between items-center rounded-md pr-3 pl-3"
          style={{ backgroundColor: "rgb(32 33 56 / 96%)" }}
        >
          <Grid container columns={12} spacing={2}>
            <Grid
              className="flex flex-row justify-start items-center gap-2"
              item
              xs={9}
              sm={9}
              md={9}
              lg={9}
            >
              <Skeleton
                variant="circular"
                height={25}
                width={25}
                sx={{ bgcolor: "#374151" }}
              />
              <Skeleton
                variant="text"
                height={17}
                sx={{ bgcolor: "#374151", width: "140px" }}
              />
            </Grid>
            <Grid
              item
              xs={3}
              sm={3}
              md={3}
              lg={3}
              className="flex flex-row justify-end items-center"
            >
              <Skeleton
                variant="rounded"
                height={15}
                width={15}
                sx={{ bgcolor: "#374151" }}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}
