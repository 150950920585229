import { Card, CardContent, CardHeader, Grid, Tooltip } from "@mui/material";
import { StyledTextField } from "../../components/styled-components";
import { useContext, useEffect, useState } from "react";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";

import UnfoldLessOutlinedIcon from "@mui/icons-material/UnfoldLessOutlined";
import QuizIcon from "@mui/icons-material/Quiz";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import beautify from "js-beautify";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { saveAs } from "file-saver";
import JsonView from "react18-json-view";
import "react18-json-view/src/style.css";
import "react18-json-view/src/dark.css";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { AppContext } from "../../context/AppContext";
import { lng } from "../../lang/languagesObjects";
export function JsonParser() {
  // for multi language
  const { t } = useTranslation("prints");
  i18next.addResourceBundle("fa", "prints", lng.jsonParser.fa);
  i18next.addResourceBundle("en", "prints", lng.jsonParser.en);
  i18next.addResourceBundle("ar", "prints", lng.jsonParser.ar);
  //initial states
  const { lang, jsonParser } = useContext(AppContext);
  // const [parsedJsonData, setParsedJsonData] = useState(null);
  const [textAreaContent, setTextAreaContent] = useState("");
  const [isCollapsJsonData, setIsCollapsJsonData] = useState(false);
  const [searchValues, setSearchValues] = useState("");
  const [newJsonFileContent, setNewJsonFileContent] = useState(null);

  useEffect(() => {
    setNewJsonFileContent(jsonParser.parsedJsonData);
  }, [jsonParser.parsedJsonData]);
  const data = {
    id: "https://example.com/address.schema.json",
    schema: "https://json-schema.org/draft/2020-12/schema",
    description: "An address similar to http://microformats.org/wiki/h-card",
    type: "object",
    properties: {
      postOfficeBox: {
        type: 5,
      },
      extendedAddress: {
        type: null,
      },
      streetAddress: {
        type: null,
      },
      locality: {
        type: null,
      },
      region: {
        type: null,
      },
      postalCode: {
        type: 25,
      },
      countryName: {
        type: null,
      },
    },
    required: ["locality", "region", "countryName"],
    dependentRequired: {
      postOfficeBox: ["streetAddress"],
      extendedAddress: ["streetAddress"],
    },
  };

  const placeHolderJson = {
    widget: {
      debug: "on",
      window: {
        title: "Sample Konfabulator Widget",
        name: "main_window",
        width: 500,
        height: 500,
      },
    },
  };

  const placeHolderContent = beautify(
    JSON.stringify(placeHolderJson, null, 2),
    {
      indent_size: 2,
      space_in_empty_paren: true,
    }
  );

  const handleChangeTextArea = (e) => {
    const textAreaContent = e.target.value;
    // setTextAreaContent(textAreaContent);
    jsonParser.setJsonParserContent(textAreaContent);
    try {
      const jsonData = JSON.parse(textAreaContent);
      jsonParser.setParsedJsonData(jsonData);
    } catch (error) {
      jsonParser.setParsedJsonData(
        t("formatError", { lng: localStorage.getItem("lang") })
      );
    }
  };

  const handleCollapsChange = () => {
    setIsCollapsJsonData(!isCollapsJsonData);
  };

  const handleCopyToClipboard = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(jsonParser.jsonParserContent);
    } else {
      console.log("Clipboard API not supported");
    }
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    console.log(file);
    if (!file) return;

    const reader = new FileReader();
    reader.onload = () => {
      const fileContent = beautify(JSON.stringify(reader.result, null, 2), {
        indent_size: 2,
        space_in_empty_paren: true,
      });
      jsonParser.setJsonParserContent(JSON.parse(fileContent));
      // setTextAreaContent(JSON.parse(fileContent));
      handleChangeTextArea({ target: { value: JSON.parse(fileContent) } });
    };
    reader.readAsText(file);
  };

  const handleJsonFile = () => {
    const jsonContent = JSON.stringify(newJsonFileContent, null, 2);
    const blob = new Blob([jsonContent], {
      type: "application/json;charset=utf-8",
    });
    saveAs(blob, "update-json.json");
  };

  return (
    <>
      {/* <div className="h-full  mb-36 bg-green-500 "> */}
      <Grid container spacing={2} columns={12} className="jsonGridContainer">
        <Grid item xs={12} sm={12} md={6} lg={6} className="h-full ">
          <Card
            className={`jsonParserHeight ${
              localStorage.getItem("theme") === "dark-mode"
                ? "card-bg-gradient-dark"
                : "card-bg-gradient-light"
            }`}
          >
            <CardHeader
              sx={{
                paddingLeft: "7px",

                paddingRight: "7px",
                ".MuiCardHeader-title": {
                  width: "100%",
                  flex: 1,
                },
                ".MuiTextField-root": {
                  width: "100%",
                },
                height: "44px",
              }}
              className="bg-bgCardHeaderLight dark:bg-bgCardHeaderDark"
              title={
                <>
                  <div className="flex flex-row justify-end items-center">
                    <div className="flex flex-row justify-center items-center gap-2  ">
                      <Tooltip
                        title={t("sampleJson", {
                          lng: localStorage.getItem("lang"),
                        })}
                        placement="bottom-end"
                      >
                        <QuizIcon
                          htmlColor="#e5e7eb"
                          className="cursor-pointer"
                          fontSize="small"
                          onClick={() => {
                            const jsonString = JSON.stringify(data, null, 2);
                            const formattedJsonData = beautify(jsonString, {
                              indent_size: 2,
                              space_in_empty_paren: true,
                            });
                            // setTextAreaContent(formattedJsonData);
                            jsonParser.setJsonParserContent(formattedJsonData);
                            jsonParser.setParsedJsonData(data);
                          }}
                        />
                      </Tooltip>

                      <Tooltip
                        title={t("importJson", {
                          lng: localStorage.getItem("lang"),
                        })}
                        placement="bottom-end"
                      >
                        <label className="cursor-pointer">
                          <FolderOpenOutlinedIcon
                            fontSize="small"
                            htmlColor="#e5e7eb"
                          />
                          <input
                            type="file"
                            accept=".txt"
                            style={{ display: "none" }}
                            onChange={(e) => {
                              handleFileUpload(e);
                              console.log("test");
                            }}
                          />
                        </label>
                      </Tooltip>

                      <Tooltip
                        title={t("copyJson", {
                          lng: localStorage.getItem("lang"),
                        })}
                        placement="bottom-end"
                      >
                        <ContentCopyIcon
                          onClick={handleCopyToClipboard}
                          fontSize="small"
                          htmlColor="#e5e7eb"
                          className="cursor-pointer"
                        />
                      </Tooltip>
                    </div>
                  </div>
                </>
              }
            />
            <CardContent
              className="jsonParserHeight overflow-y-auto"
              sx={{ paddingLeft: "5px", paddingRight: "5px" }}
            >
              <textarea
                style={{
                  resize: "none",
                  paddingLeft: "6px",
                  paddingRight: "6px",
                  paddingBottom: "18px",

                  height: "100%",
                }}
                spellCheck={false}
                className="w-full"
                value={jsonParser.jsonParserContent}
                placeholder={placeHolderContent}
                onChange={(e) => {
                  handleChangeTextArea(e);
                }}
              />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6} className="h-full ">
          <Card
            className={`jsonParserHeight ${
              localStorage.getItem("theme") === "dark-mode"
                ? "card-bg-gradient-dark"
                : "card-bg-gradient-light"
            }`}
          >
            <CardHeader
              className="bg-bgCardHeaderLight dark:bg-bgCardHeaderDark"
              sx={{
                paddingLeft: "7px",

                paddingRight: "7px",
                ".MuiCardHeader-title": {
                  width: "100%",
                  flex: 1,
                },
                ".MuiTextField-root": {
                  width: "100%",
                },
                height: "44px",
              }}
              title={
                <>
                  <div className="flex flex-row justify-between items-center gap-2">
                    {/* <div className="flex flex-row justify-center items-center gap-2"> */}{" "}
                    <StyledTextField
                      value={searchValues}
                      onChange={(e) => {
                        setSearchValues(e.target.value);
                      }}
                      label={t("search", { lng: localStorage.getItem("lang") })}
                      size="small"
                      className="bg-[#202037] h-9"
                    />{" "}
                    <div className="flex flex-row justify-center items-center gap-1">
                      <FileUploadOutlinedIcon
                        onClick={handleJsonFile}
                        className="cursor-pointer"
                        fontSize="small"
                        htmlColor="#e5e7eb"
                      />

                      {isCollapsJsonData ? (
                        <UnfoldMoreIcon
                          onClick={handleCollapsChange}
                          fontSize="medium"
                          htmlColor="#e5e7eb"
                          className="cursor-pointer"
                        />
                      ) : (
                        <UnfoldLessOutlinedIcon
                          onClick={handleCollapsChange}
                          fontSize="medium"
                          htmlColor="#e5e7eb"
                          className="cursor-pointer"
                        />
                      )}
                    </div>
                  </div>
                </>
              }
            />
            <CardContent
              className="jsoneditor jsonParserHeight overflow-y-auto"
              sx={{
                paddingLeft: "5px",
                paddingRight: "5px",
              }}
            >
              <JsonView
                customizeNode={(item, context) => {
                  const searchValue = searchValues.trim().toLowerCase();
                  const node = item.node;
                  console.log("item11", item);

                  if (
                    node &&
                    searchValue &&
                    node?.toString().toLowerCase().includes(searchValue) &&
                    typeof node !== "object"
                  ) {
                    const highlightedValueText = node
                      .toString()
                      .toLowerCase()
                      .replace(new RegExp(searchValue, "gi"), (match) => {
                        return `<span class="bg-yellow-400 text-black">${match}</span>`;
                      });
                    return (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: highlightedValueText,
                        }}
                        style={{ color: "#c57e29" }}
                      />
                    );
                  }

                  return item;
                }}
                dark={true}
                src={jsonParser.parsedJsonData}
                collapsed={isCollapsJsonData}
                editable={{ edit: true, add: true, delete: true }}
                onDelete={(newData) => {
                  console.log("Updated JSON data after delete:", newData.src);
                  setNewJsonFileContent(newData.src);
                }}
                onEdit={(newData) => {
                  console.log("Updated JSON data after edit:", newData.src);
                  setNewJsonFileContent(newData.src);
                }}
                onAdd={(newData) => {
                  console.log("Updated JSON data after add:", newData.src);
                  setNewJsonFileContent(newData.src);
                }}
                style={{
                  minWidth: "100%",

                  paddingBottom: "35px",
                  padding: 3,
                  backgroundColor: "transparent",
                }}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {/* </div> */}
    </>
  );
}
