import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Grid,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddTaskIcon from "@mui/icons-material/AddTask";
import { StyledTextField } from "../../../components/styled-components";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DirectionsOffIcon from "@mui/icons-material/DirectionsOff";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import DirectionsIcon from "@mui/icons-material/Directions";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import { useContext, useLayoutEffect, useRef, useState } from "react";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { lng } from "../../../lang/languagesObjects";
import { AppContext } from "../../../context/AppContext";
export function LeftColumn({
  flagURL,
  id,
  name,
  logo,
  keyProp,
  article,
  description,
  addRobotToAllowList,
  removeRobotFromAllowList,
  addUrlToAllowList,
  addUrlToDisAllowList,
  removeUrlFromAllowList,
  removeUrlFromDisAllowList,
}) {
  // for multi language
  const { t } = useTranslation("prints");
  i18next.addResourceBundle("fa", "prints", lng.robotGenerator.fa);
  i18next.addResourceBundle("en", "prints", lng.robotGenerator.en);
  i18next.addResourceBundle("ar", "prints", lng.robotGenerator.ar);
  //initial states
  const { lang } = useContext(AppContext);

  // const [items, setItems] = useState(null);
  const [allow, setAllow] = useState(false);
  const [allowURLs, setAllowURLs] = useState([]);
  const [disAllowUrls, setDisAllowUrls] = useState([]);
  const inputAllowRef = useRef("");
  const inputDisAllowRef = useRef("");
  useLayoutEffect(() => {
    console.log("key: ", keyProp, 1111);
  }, [keyProp]);
  const handleAddDisAllowUrl = () => {
    const inputValue = inputDisAllowRef.current.value;
    if (inputValue?.trim()) {
      setDisAllowUrls([...disAllowUrls, inputValue]);
      addUrlToDisAllowList(inputValue, id);
    }
  };
  const handleAddAllowUrl = () => {
    const inputValue = inputAllowRef.current.value;
    if (inputValue?.trim()) {
      setAllowURLs([...allowURLs, inputValue]);
      addUrlToAllowList(inputValue, id);
    }
  };
  const handleRemoveAllowUrl = (url) => {
    const newAllowUrls = allowURLs.filter((item) => item !== url);
    setAllowURLs(newAllowUrls);
    removeUrlFromAllowList(url);
  };
  const handleRemoveDisAllowUrl = (url) => {
    const newDisAllowUrls = disAllowUrls.filter((item) => item !== url);
    setDisAllowUrls(newDisAllowUrls);
    removeUrlFromDisAllowList(url);
  };

  return (
    <>
      <Accordion
        className="bg-bgOptionsCardsLight dark:bg-bgOptionsCardsDark bg-gradient-to-r from-bgGradientFromCardsLight to-bgGradientToCardsLight dark:from-bgGradientFromCardsDark dark:to-bgGradientToCardsDark "
        sx={{
          mb: 1,
        }}
      >
        <AccordionSummary
          sx={{
            paddingTop: "0",
            paddingBottom: "0px",
            paddingLeft: "10px",
          }}
          expandIcon={<ExpandMoreIcon htmlColor="#e2e8f0" fontSize="small" />}
        >
          <Grid container spacing={1} columns={12}>
            <Grid
              item
              xs={10}
              sm={10}
              md={10}
              lg={10}
              className="flex flex-row justify-start items-center gap-2 text-ellipsis whitespace-nowrap overflow-hidden w-full"
            >
              <img
                alt=""
                src={flagURL + logo}
                style={{ width: "30px", height: "30px" }}
              />
              <Tooltip title={description} placement="bottom">
                <p className="text-slate-200 text-ellipsis overflow-hidden  whitespace-nowrap ">
                  {name}
                </p>
              </Tooltip>
            </Grid>
            <Grid
              item
              xs={2}
              sm={2}
              md={2}
              lg={2}
              className="flex flex-row items-center justify-end"
            >
              <Checkbox
                checked={allow}
                onChange={(e) => {
                  setAllow(e.target.checked);
                  !allow
                    ? addRobotToAllowList(keyProp, id)
                    : removeRobotFromAllowList(id);
                }}
                icon={
                  <RemoveCircleOutlineIcon htmlColor="red" fontSize="medium" />
                }
                checkedIcon={
                  <AddTaskIcon htmlColor="green" fontSize="medium" />
                }
              />
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails sx={{ paddingTop: "0px" }}>
          {allow ? (
            <Grid container spacing={1} columns={12}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{
                  height: "100%",
                }}
              >
                <Card
                  sx={{
                    backgroundColor: "#22233a",
                    height: "100%",
                  }}
                >
                  <CardHeader
                    sx={{
                      "& .MuiCardHeader-avatar": {
                        mr: 1, // کاهش فاصله بین عنوان و آواتار
                        pb: 0.5,
                      },
                      ".MuiCardHeader-title": {
                        width: "100%",
                        flex: 1,
                      },
                      ".MuiTextField-root": {
                        width: "100%",
                      },
                      // background: "rgb(2,0,36)",
                      background:
                        localStorage.getItem("dir") === "ltr"
                          ? "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(20,131,3,0.2553396358543417) 35%)"
                          : " linear-gradient(90deg,rgba(2,0,36,1) 100%,  rgba(20,131,3,0.2553396358543417) 50%)",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      paddingTop: "5px",
                      paddingBottom: "8px",
                      paddingRight: "7px",
                      paddingLeft: "7px",
                    }}
                    title={
                      <StyledTextField
                        ref={inputAllowRef}
                        onChange={(e) => {
                          inputAllowRef.current.value = e.target.value;
                        }}
                        sx={{
                          backgroundColor: "#17153a",
                          marginTop: "7px",
                        }}
                        label={t("enterRoute", {
                          lng: localStorage.getItem("lang"),
                        })}
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <AddOutlinedIcon
                                onClick={() => {
                                  handleAddAllowUrl();
                                }}
                                sx={{
                                  cursor: "pointer",
                                }}
                                fontSize="medium"
                                color="info"
                              />
                            </InputAdornment>
                          ),
                          startAdornment: (
                            <>
                              <VerifiedOutlinedIcon
                                fontSize="small"
                                htmlColor="green"
                              />
                              <p className="text-slate-200 pl-1 pr-3 w-20">
                                {t("allow", {
                                  lng: localStorage.getItem("lang"),
                                })}
                              </p>
                            </>
                          ),
                        }}
                      />
                    }
                  />
                  <CardContent
                    sx={{
                      "&::-webkit-scrollbar": {
                        height: "4px", // ارتفاع نوار اسکرول بار را به 8 پیکسل کاهش دهید
                      },
                      color: "#e7e5e4",
                      maxHeight: "140px",
                      overflowY: "auto",
                    }}
                    className="flex flex-col items-stretch justify-start gap-3 "
                  >
                    {allowURLs.map((element) => (
                      <div
                        key={element}
                        className="flex flex-row justify-between items-center "
                      >
                        <div className="flex flex-row items-center justify-center gap-1">
                          <DirectionsIcon
                            fontSize="small"
                            htmlColor="#7efa70"
                          />
                          <p className="text-slate-200">{element}</p>
                        </div>
                        <ClearOutlinedIcon
                          onClick={() => handleRemoveAllowUrl(element)}
                          fontSize="small"
                          color="error"
                          className="cursor-pointer"
                        />
                      </div>
                    ))}
                  </CardContent>
                </Card>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{
                  height: "100%",
                }}
              >
                <Card
                  sx={{
                    backgroundColor: "#22233a",
                    height: "100%",
                  }}
                >
                  <CardHeader
                    title={
                      <StyledTextField
                        ref={inputDisAllowRef}
                        onChange={(e) => {
                          inputDisAllowRef.current.value = e.target.value;
                        }}
                        sx={{
                          backgroundColor: "#17153a",
                          marginTop: "7px",
                        }}
                        label={t("enterRoute", {
                          lng: localStorage.getItem("lang"),
                        })}
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <AddOutlinedIcon
                                onClick={() => {
                                  handleAddDisAllowUrl();
                                }}
                                sx={{ cursor: "pointer" }}
                                fontSize="medium"
                                color="info"
                              />
                            </InputAdornment>
                          ),
                          startAdornment: (
                            <>
                              <RemoveCircleOutlineOutlinedIcon
                                fontSize="small"
                                htmlColor="red"
                              />
                              <p className="text-slate-200 pl-1 pr-1 w-36">
                                {t("disAllow", {
                                  lng: localStorage.getItem("lang"),
                                })}{" "}
                              </p>
                            </>
                          ),
                        }}
                      />
                    }
                    sx={{
                      "& .MuiCardHeader-avatar": {
                        mr: 1, // کاهش فاصله بین عنوان و آواتار
                        pb: 0.5,
                      },
                      ".MuiCardHeader-title": {
                        width: "100%",

                        flex: 1,
                      },
                      ".MuiTextField-root": {
                        width: "100%",
                      },
                      // background: " rgb(2,0,36)",
                      background:
                        localStorage.getItem("dir") === "ltr"
                          ? "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(131,6,6,0.2553396358543417) 35%)"
                          : "linear-gradient(90deg, rgba(2,0,36,1) 100%, rgba(131,6,6,0.2553396358543417) 50%)",
                      paddingTop: "5px",
                      paddingBottom: "8px",
                      paddingRight: "7px",
                      paddingLeft: "7px",
                    }}
                  />
                  <CardContent
                    sx={{
                      "&::-webkit-scrollbar": {
                        height: "4px", // ارتفاع نوار اسکرول بار را به 8 پیکسل کاهش دهید
                      },
                      color: "#e7e5e4",

                      maxHeight: "140px",
                      overflowY: "auto",
                    }}
                    className=" flex flex-col items-stretch justify-start gap-3 "
                  >
                    {disAllowUrls.map((element) => (
                      <div className="flex flex-row justify-between items-center ">
                        <div className="flex flex-row justify-center items-center gap-1">
                          <DirectionsOffIcon
                            fontSize="small"
                            htmlColor="#fa7070"
                          />
                          <p className="text-slate-200">{element}</p>
                        </div>
                        <ClearOutlinedIcon
                          onClick={() => handleRemoveDisAllowUrl(element)}
                          fontSize="small"
                          color="error"
                          className="cursor-pointer"
                        />
                      </div>
                    ))}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          ) : (
            <div className="w-full flex flex-row justify-center">
              <p className="text-red-500">
                {t("activeRobotText", { lng: localStorage.getItem("lang") })}
              </p>
            </div>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
}
