import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { memo, useState } from "react";
import {
  StyledAutocomplete,
  StyledTextField,
} from "../../../../components/styled-components";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { lng } from "../../../../lang/languagesObjects";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect } from "react";

export const SideBarAction = memo(({ openAtInstallOptions, jsonKeys }) => {
  // for multi language
  const { t } = useTranslation("prints");
  i18next.addResourceBundle("fa", "prints", lng.manifest.fa);
  i18next.addResourceBundle("en", "prints", lng.manifest.en);
  i18next.addResourceBundle("ar", "prints", lng.manifest.ar);
  //initial states
  const [openAtInstall, setOpenAtInstall] = useState(null);
  const [defaultTitle, setDefaultTitle] = useState(null);
  const [defaultIcon, setDefaultIcon] = useState(null);
  const [defaulPanel, setDefaulPanel] = useState(null);
  const [test, setTest] = useState(true);
  // handle test
  useEffect(() => {
    setTest(!test);
  }, []);
  return (
    <>
      {" "}
      <Accordion
        className="w-full text-slate-100 rounded-md"
        sx={{
          background: "rgb(29,103,158)",
          background:
            "linear-gradient(90deg, rgba(13, 166, 194, 0.23573179271708689) 0%, rgba(38, 36, 80, 0.804359243697479) 100%)",
        }}
      >
        <AccordionSummary
          className="w-full h-16"
          expandIcon={
            <ExpandMoreIcon fontSize="medium" className="text-slate-100" />
          }
        >
          <div className="w-full flex justify-between items-center mr-1">
            <p className="text-xl ml-2">SideBar Action</p>
            <Tooltip
              title={
                <p
                  style={{
                    textAlign:
                      localStorage.getItem("dir") === "ltr" ? "left" : "right",
                  }}
                >
                  {t("sidebarActionHelperText", {
                    lng: localStorage.getItem("lang"),
                  })}
                </p>
              }
            >
              <HelpOutlineIcon
                className="pt-1"
                sx={{
                  cursor: "pointer",
                  paddingLeft: "4px",
                  paddingBottom: "2px",
                }}
                fontSize="medium"
                htmlColor="#aaaaaa"
              />
            </Tooltip>
          </div>
        </AccordionSummary>

        <AccordionDetails>
          <Grid container spacing={2} columns={12}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <StyledTextField
                value={defaultIcon}
                onChange={(e) => {
                  jsonKeys.current.default_icon = e.target.value;
                  setDefaultIcon(e.target.value);
                }}
                size="small"
                label="Default Icon"
                className="w-full"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <StyledTextField
                value={defaulPanel}
                onChange={(e) => {
                  jsonKeys.current.default_panel = e.target.value;
                  setDefaulPanel(e.target.value);
                }}
                size="small"
                label="Default Panel"
                className="w-full"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <StyledTextField
                value={defaultTitle}
                onChange={(e) => {
                  jsonKeys.current.default_title = e.target.value;
                  setDefaultTitle(e.target.value);
                }}
                size="small"
                label="Default Title"
                className="w-full"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={2}
              lg={2}
              className="flex flex-row justify-evenly items-center"
            >
              <StyledAutocomplete
                defaultValue={openAtInstallOptions[0]}
                value={openAtInstall}
                onChange={(e, value) => {
                  jsonKeys.current.openAtInstall = value?.value;
                  setOpenAtInstall(value?.value);
                }}
                sx={{
                  textAlign: "left",
                  width: "100%",
                }}
                size="small"
                options={openAtInstallOptions}
                PaperComponent={({ children }) => (
                  <div className={"dark:bg-slate-900 dark:text-slate-300"}>
                    {children}
                  </div>
                )}
                renderInput={(params) => (
                  <TextField
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <HelpOutlineIcon
                            sx={{
                              cursor: "pointer",
                              paddingLeft: "4px",
                            }}
                            fontSize="small"
                            htmlColor="#aaaaaa"
                          />
                        </InputAdornment>
                      ),
                    }}
                    {...params}
                    label={"Open At Install"}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        color: "#d6d3d1",
                        "& fieldset": {
                          borderColor: "#d6d3d1",
                        },
                        "&:hover fieldset": {
                          borderColor: "white", // change border color on hover
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: "#d6d3d1",
                      },
                      "& .MuiSvgIcon-root": {
                        color: "white", // change icon color
                      },
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
});
