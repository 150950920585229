import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { StyledButton } from "../../components/styled-components";
import { handleAutoLogOut } from "../../includes/functions";
import { t } from "i18next";
import { lng } from "../../lang/languagesObjects";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useNavigate } from "react-router-dom";

export function LogOutDialogComponent({
  setOpenLogOutDialog,
  openLogOutDialog,
}) {
  // for multi language
  const { t } = useTranslation("prints");
  i18next.addResourceBundle("fa", "prints", lng.logOut.fa);
  i18next.addResourceBundle("en", "prints", lng.logOut.en);
  i18next.addResourceBundle("ar", "prints", lng.logOut.ar);
  //initial states
  const navigate = useNavigate();
  return (
    <>
      <Dialog
        open={openLogOutDialog}
        fullWidth
        maxWidth={"xs"}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "22px",
            background: "transparent",
          },
        }}
      >
        <DialogContent
          className="bg-gradient-to-l dark:from-bgGradientFromContentDialogDark dark:to-bgGradientToContentDialogDark from-bgGradientFromContentDialogLight to-bgGradientToContentDialogLight"
          sx={{
            paddingLeft: "10px",
            paddingRight: "10px",
            direction: "ltr",
          }}
        >
          <p className="text-[#cbd5e1]">
            {t("sureToLogOut", { lng: localStorage.getItem("lang") })}
          </p>
        </DialogContent>
        <DialogActions
          className=" bg-gradient-to-l dark:from-bgGradientFromActionDialogDark dark:to-bgGradientToActionDialogDark from-bgGradientFromContentDialogLight to-bgGradientToContentDialogLight"
          sx={{
            borderTop: "1px solid #666666",
            direction: "ltr",
          }}
        >
          <StyledButton
            className="mr-2 ml-2 w-28 bg-gradient-to-l dark:from-bgGradientFromCustomButtonDark dark:to-bgGradientToCustomButtonDark dark:hover:from-bgGradientFromCustomButtonHoverDark dark:hover:to-bgGradientToCustomButtonHoverDark bg-bgCustomButtonLight hover:bg-bgCustomButtonHoverLight"
            onClick={() => {
              setOpenLogOutDialog(false);
            }}
          >
            {t("cancel", {
              lng: localStorage.getItem("lang"),
            })}{" "}
          </StyledButton>
          <StyledButton
            className="mr-2 ml-2 w-28 bg-gradient-to-l dark:from-bgGradientFromCustomButtonDark dark:to-bgGradientToCustomButtonDark dark:hover:from-bgGradientFromCustomButtonHoverDark dark:hover:to-bgGradientToCustomButtonHoverDark bg-bgCustomButtonLight hover:bg-bgCustomButtonHoverLight"
            onClick={() => {
              handleAutoLogOut(navigate);
              setOpenLogOutDialog(false);
            }}
          >
            {t("confirmation", {
              lng: localStorage.getItem("lang"),
            })}{" "}
          </StyledButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
