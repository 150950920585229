import { Autocomplete, TextField } from "@mui/material";
import { themeMode } from "../index";
import tw from "twin.macro";
import styled from "styled-components";

export const StyledAutocomplete = styled(Autocomplete)`
  & .MuiAutocomplete-inputRoot {
    border-radius: 15px;
    ${() =>
      document.documentElement.classList.contains("dark")
        ? tw`bg-bgCustomAutoCompleteDark`
        : tw`bg-bgCustomAutoCompleteLight`}
  }

  & .MuiInputLabel-root {
    color: azure !important;
  }

  & .MuiOutlinedInput-root {
    & fieldset {
      border: none;
      outline: none;
    }
    &:hover fieldset {
      border: none;
      outline: none;
    }
    &.Mui-focused fieldset {
      border: none;
      outline: none;
    }
  }
`;

export const StyledTextField = styled(TextField)`
  color: azure !important;
  border-radius: 15px;
  ${() =>
    document.documentElement.classList.contains("dark")
      ? tw`bg-bgCustomTextFieldDark`
      : tw`bg-bgCustomTextFieldLight`}

  & .MuiOutlinedInput-root {
    & fieldset {
      border: none;
      outline: none;
    }
    ظ &:hover fieldset {
      border: none;
      outline: none;
    }
  }
  & .MuiInputBase-input {
    color: azure !important;
  }
  & .MuiFormLabel-root {
    color: azure !important;
  }
  & .MuiTextField-root {
    & fieldset {
      border: none;
      outline: none;
    }
    &:hover fieldset {
      border: none;
      outline: none;
    }
    &.Mui-focused fieldset {
      border: none;
      outline: none;
    }
  }
`;

export const StyledButton = styled.button`
  border-radius: 15px;
  color: azure;
  padding: 0.5rem;
  box-shadow: 1px 1px 1px #0000008c;
  transition: box-shadow 0.5s;
  &:hover {
    background: var(--bg-custom-button-hover);
    box-shadow: 2px 2px 5px #0000008c;
    transition: box-shadow 0.5s;
  }
`;
