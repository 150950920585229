import React, { useContext, useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import "../bottom-navigation/custom-navigation.css";
import LanguageIcon from "@mui/icons-material/Language";
import SubtitlesIcon from "@mui/icons-material/Subtitles";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CallIcon from "@mui/icons-material/Call";
import { AppContext } from "../context/AppContext";
import DomainVerificationIcon from "@mui/icons-material/DomainVerification";

const CustomNavigation = ({
  darkMode,
  protocols,
  //  user,
  language,
}) => {
  // const location = useLocation();
  // const [indicator, setIndicator] = useState(null);

  // useEffect(() => {
  //   if (location.pathname === "/view-source") {
  //     setValue("viewsource");
  //   } else if (location.pathname === "/ping-world") {
  //     setValue("pingworld");
  //   } else if (location.pathname === "/") {
  //     setValue("home");
  //   } else if (location.pathname === "/call") {
  //     setValue("call");
  //   } else if (location.pathname === "/profile") {
  //     setValue("profile");
  //   }
  // }, [location.pathname]);
  //
  // const pathValues = {
  //   "/view-source": "viewsource",
  //   "/ping-world": "pingworld",
  //   "/": "home",
  //   "/call": "call",
  //   "/profile": "profile",
  // };

  // useEffect(() => {
  //   setValue(pathValues[location.pathname] || "home");
  // }, [location.pathname]);

  // const activeLink = (e) => {
  //   const targetElement = e.currentTarget.parentElement;
  //   const boundingRect = targetElement.getBoundingClientRect();
  //   const navigationRect = document
  //     .querySelector(".navigation")
  //     .getBoundingClientRect();
  //   const indicatorWidth = 70; // width of the indicator element
  //   const indicatorLeft =
  //     boundingRect.left -
  //     navigationRect.left +
  //     (boundingRect.width - indicatorWidth) / 2;
  //   setIndicator(indicatorLeft);
  // };

  // useEffect(() => {
  //   const handleResize = () => {
  //     const navigationContainer = document.querySelector(".navigation");
  //     const activeItem = document.querySelector(".list.active");
  //     if (activeItem && navigationContainer) {
  //       const navigationRect = navigationContainer.getBoundingClientRect();
  //       const activeItemRect = activeItem.getBoundingClientRect();
  //       setIndicator(activeItemRect.left - navigationRect.left);
  //     }
  //   };
  //   window.addEventListener("resize", handleResize);
  //   handleResize();

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, [location.pathname]);

  // const activeLink = () => {
  //   const activeItem = document.querySelector(".list.active");
  //   if (activeItem) {
  //     const boundingRect = activeItem.getBoundingClientRect();
  //     const navigationRect = document
  //       .querySelector(".navigation")
  //       .getBoundingClientRect();
  //     const indicatorWidth = 70; // width of the indicator element
  //     const indicatorLeft =
  //       boundingRect.left -
  //       navigationRect.left +
  //       (boundingRect.width - indicatorWidth) / 2;
  //     setIndicator(indicatorLeft);
  //   }
  // };

  // useEffect(() => {
  //   activeLink();

  //   const handleResize = () => {
  //     const navigationContainer = document.querySelector(".navigation");
  //     const activeItem = document.querySelector(".list.active");
  //     if (activeItem && navigationContainer) {
  //       const navigationRect = navigationContainer.getBoundingClientRect();
  //       const activeItemRect = activeItem.getBoundingClientRect();
  //       setIndicator(activeItemRect.left - navigationRect.left);
  //     }
  //   };
  //   window.addEventListener("resize", handleResize);
  //   handleResize();

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, [location.pathname]);

  //initial states
  const { loginDialog } = useContext(AppContext);
  const { navigation } = useContext(AppContext);
  const navigate = useNavigate("/");
  const location = useLocation();

  return (
    <>
      <div className="navigation">
        <div className="ul">
          <div
            style={{
              borderBottomLeftRadius: "12px",
              borderTopLeftRadius: "12px",
            }}
            onClick={() => {
              navigation.setActiveItem("/view-source");
              navigate("/view-source");
            }}
            className={`item ${
              navigation.activeItem === "/view-source" ? "active" : ""
            }`}
          >
            <span className="icon">
              <SubtitlesIcon fontSize="medium" sx={{ marginBottom: "12px" }} />
            </span>
            <div
              className={`indicator hideBefore ${
                navigation.activeItem === "/view-source" ? "active" : ""
              }`}
            >
              <span></span>
            </div>
          </div>
          <div
            onClick={() => {
              navigation.setActiveItem("/ping-world");
              navigate("/ping-world");
            }}
            className={`item ${
              navigation.activeItem === "/ping-world" ? "active" : ""
            }`}
          >
            <span className="icon">
              <LanguageIcon fontSize="medium" sx={{ marginBottom: "12px" }} />
            </span>
            <div
              className={`indicator ${
                navigation.activeItem === "/ping-world" ? "active" : ""
              }`}
            >
              <span></span>
            </div>
          </div>
          <div
            onClick={() => {
              navigation.setActiveItem("/");
              navigate("/");
            }}
            className={`item ${navigation.activeItem === "/" ? "active" : ""}`}
          >
            <span className="icon">
              <ion-icon
                name="home-outline"
                style={{ marginBottom: "2px" }}
              ></ion-icon>{" "}
            </span>
            <div
              className={`indicator ${
                navigation.activeItem === "/" ? "active" : ""
              }`}
            >
              <span></span>
            </div>
          </div>
          <div
            onClick={() => {
              navigation.setActiveItem("/call");
              navigate("/call");
            }}
            className={`item ${
              navigation.activeItem === "/call" ? "active" : ""
            }`}
          >
            <span className="icon">
              <DomainVerificationIcon
                fontSize="medium"
                sx={{ marginBottom: "14px" }}
              />
            </span>
            <div
              className={`indicator ${
                navigation.activeItem === "/call" ? "active" : ""
              }`}
            >
              <span></span>
            </div>
          </div>
          <div
            style={{
              borderBottomRightRadius: "12px",
              borderTopRightRadius: "12px",
            }}
            onClick={() => {
              if (localStorage.getItem("user")) {
                navigation.setActiveItem("/profile");
                navigate("/profile");
              } else {
                loginDialog.setShowLoginComponent(true);
                loginDialog.setPathToMove("/profile");
              }
            }}
            className={`item ${
              navigation.activeItem === "/profile" ? "active" : ""
            }`}
          >
            <span className="icon">
              <AccountCircleIcon
                fontSize="medium"
                sx={{ marginBottom: "12px" }}
              />
            </span>
            <div
              className={`indicator hideAfter ${
                navigation.activeItem === "/profile" ? "active" : ""
              }`}
            >
              <span></span>
            </div>
          </div>
        </div>
      </div>
      {/* <div
        className="navigation mb-7  "
        style={{
          backgroundColor: darkMode ? "#ffffff17" : " #a8a29e",
          width: "88%",
          // width: "100%",

          borderRadius: "12px",
        }}
      >
        <ul>
          <li
            // className="list active "
            className={`list ${
              location.pathname === "/view-source" ? "active" : ""
            }`}
          >
            <NavLink
              to={"/view-source"}
              onClick={(e) => {
                activeLink(e);
              }}
            >
              <span className="icon">
                <SubtitlesIcon
                  fontSize="medium"
                  sx={{ marginBottom: "13px" }}
                />
              </span>
              <span className="circle"></span>
            </NavLink>
          </li>
          <li
            className={`list ${
              location.pathname === "/ping-world" ? "active" : ""
            }`}
          >
            <NavLink
              to="/ping-world"
              onClick={(e) => {
                activeLink(e);
              }}
            >
              <span className="icon">
                <LanguageIcon fontSize="medium" sx={{ marginBottom: "12px" }} />
              </span>
              <span className="circle"></span>
            </NavLink>
          </li>{" "}
          <li
            // className="list active"
            className={`list ${location.pathname === "/" ? "active" : ""}`}
          >
            <NavLink
              to="/"
              onClick={(e) => {
                activeLink(e);
              }}
            >
              <span className="icon">
                <ion-icon
                  name="home-outline"
                  style={{ marginBottom: "2px" }}
                ></ion-icon>{" "}
              </span>
              <span className="circle"></span>
            </NavLink>
          </li>
          <li
            className={`list ${location.pathname === "/call" ? "active" : ""}`}
          >
            <NavLink
              to="/call"
              onClick={(e) => {
                activeLink(e);
              }}
            >
              <span className="icon">
                <CallIcon fontSize="medium" sx={{ marginBottom: "12px" }} />
              </span>
              <span class="circle"></span>
            </NavLink>
          </li>{" "}
          <li
            // className="list"
            className={`list ${
              location.pathname === "/profile" ? "active" : ""
            }`}
          >
            <NavLink
              to="/profile"
              onClick={(e) => {
                activeLink(e);
              }}
            >
              <span className="icon">
                <AccountCircleIcon
                  fontSize="medium"
                  sx={{ marginBottom: "12px" }}
                />
              </span>
              <span className="circle"></span>
            </NavLink>
          </li>
          <div
            className="indicator"
            style={{
              border: darkMode ? "6px solid #1E293B" : "6px solid #ffffff",
              position: "absolute",
              top: "-50%",
              left: 0,
              width: "70px",
              height: "70px",
              background: darkMode ? "#323c4d" : " #a8a29e",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              transition: "0.5s",
              transform: `translateX(${indicator}px)`,
            }}
          >
            {" "}
          </div>
        </ul>
      </div> */}
    </>

    //   <BottomNavigation
    //     className="navigation-shadow"
    //     sx={{
    //       width: "88%",
    //       backdropFilter: "blur(10px)",
    //       backgroundColor: darkMode ? "#ffffff17" : " #a8a29e",
    //       height: 65,
    //       marginBottom: "26px",
    //       borderRadius: "12px",

    //       WebkitBoxShadow: darkMode
    //         ? "0px 0px 2px 0.5px rgba(245, 245, 244, 0.75)"
    //         : " 0px 0px 2px 0.5px rgba(120,113,108,1)",
    //       MozBoxShadow: darkMode
    //         ? "0px 0px 2px 0.5px rgba(245, 245, 244, 0.75)"
    //         : " 0px 0px 2px 0.5px rgba(120,113,108,1)",
    //       boxShadow: darkMode
    //         ? "0px 0px 2px 0.5px rgba(245, 245, 244, 0.75)"
    //         : "0px 0px 2px 0.5px rgba(120,113,108,1)",
    //     }}
    //     value={value}
    //     onChange={(e, newValue) => {
    //       setValue(newValue);
    //     }}
    //   >
    //     <BottomNavigationAction
    //       selected={value === "viewsource"}
    //       onClick={() => {
    //         navigate("/view-source");
    //       }}
    //       value="viewsource"
    //       icon={
    //         <SubtitlesIcon
    //           sx={{ marginLeft: language ? "-24px" : "24px" }}
    //           fontSize="medium"
    //           htmlColor={value === "viewsource" ? "#1976d2" : "#f5f5f4"}
    //         />
    //       }
    //     />
    //     <BottomNavigationAction
    //       onClick={() => {
    //         navigate("/ping-world");
    //       }}
    //       value="pingworld"
    //       icon={
    //         <LanguageIcon
    //           fontSize="medium"
    //           htmlColor={value === "pingworld" ? "#1976d2" : "#f5f5f4"}
    //         />
    //       }
    //     />
    //     <BottomNavigationAction
    //       onClick={() => {
    //         navigate("/");
    //       }}
    //       value="home"
    //       icon={
    //         <HomeIcon
    //           fontSize="medium"
    //           htmlColor={value === "home" ? "#1976d2" : "#f5f5f4"}
    //         />
    //       }
    //     />
    //     <BottomNavigationAction
    //       onClick={() => {
    //         navigate("/call");
    //       }}
    //       value="call"
    //       icon={
    //         <CallIcon
    //           fontSize="medium"
    //           htmlColor={value === "call" ? "#1976d2" : "#f5f5f4"}
    //         />
    //       }
    //     />{" "}
    //     <BottomNavigationAction
    //       onClick={() => {
    //         navigate("/profile");
    //       }}
    //       value="profile"
    //       icon={
    //         <AccountCircleIcon
    //           sx={{ marginRight: language ? "-24px" : "24px" }}
    //           fontSize="medium"
    //           htmlColor={value === "profile" ? "#1976d2" : "#f5f5f4"}
    //         />
    //       }
    //     />
    //   </BottomNavigation>
    // </>
  );
};

export default CustomNavigation;
