import { createContext } from "react";

export const AppContext = createContext({
  addAlertIconContext: {},
  loginDialog: {},
  lang: {},
  jsonParser: {},
  theme: {},
  sidebar: {},
  navigation: {},
});
