import { Card, CardContent, CardHeader, Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  StyledAutocomplete,
  StyledButton,
  StyledTextField,
} from "../../components/styled-components";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import TwentyZeroMpIcon from "@mui/icons-material/TwentyZeroMp";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import EngineeringIcon from "@mui/icons-material/Engineering";
export function BinaryCode() {
  //initial states
  const [methodToConvert, setMethodToConvert] = useState(null);
  const [lastConvertMethod, setLastConvertMethod] = useState(null);
  const [firstConvertMethod, setFirstConvertMethod] = useState(null);
  const [input, setInput] = useState(null);
  const [output, setOutput] = useState(null);
  const [placeHolderInput, setPlaceHolderInput] = useState(null);

  // method to convert string options
  const methodsOptions = [
    {
      id: 1,
      label: "Text to Binary",
      value: "text2bin",
      convertedMethod: "Binary",
      firstMethod: "Text",
      inputLabel: "Enter text...",
    },
    {
      id: 2,
      label: "Binary to Text",
      value: "bin2text",
      convertedMethod: "Text",
      firstMethod: "Binary",
      inputLabel: "Enter binary code...",
    },
    {
      id: 3,
      label: "Decimal to Binary",
      value: "dec2bin",
      convertedMethod: "Binary",
      firstMethod: "Decimal",
      inputLabel: "Enter decimal number...",
    },
    {
      id: 4,
      label: "Binary to Decimal",
      value: "bin2dec",
      convertedMethod: "Decimal",
      firstMethod: "Binary",
      inputLabel: "Enter binary code...",
    },
    {
      id: 5,
      label: "Hex to Binary",
      value: "hex2bin",
      convertedMethod: "Binary",
      firstMethod: "Hex",
      inputLabel: "Enter hex number...",
    },
    {
      id: 6,
      label: "Binary to Hex",
      value: "bin2Hex",
      convertedMethod: "Hex",
      firstMethod: "Binary",
      inputLabel: "Enter binary code...",
    },
  ];
  // handle convert binary to text
  const binaryToText = (str) => {
    let output = [];
    str.split(" ").forEach((element) => {
      output.push(String.fromCharCode(parseInt(element, 2)));
    });
    setOutput(output.join(""));
  };

  // handle convert text to binary
  const textToBinary = (str) => {
    let output = "";
    str.split("").forEach((element) => {
      let char = element.charCodeAt(0).toString(2);
      output += ("00000000" + char).slice(-8).concat(" ");
    });
    setOutput(output);
  };

  // handle convert decimal to binary
  const dec2bin = (dec) => {
    setOutput((dec >>> 0).toString(2));
  };

  // handle convert binary to decimal
  const bin2dec = (bin) => {
    setOutput(parseInt(bin, 2).toString(10));
  };

  // handle convert hex to binary
  function hex2Binary(n) {
    setOutput(parseInt(n, 16).toString(2).padStart(8, "0"));
  }

  // handle convert binary to hex
  function binary2Hex(n) {
    setOutput(parseInt(n, 2).toString(16).toUpperCase());
  }
  // for methods converter
  const methodsConvertor = {
    text2bin: textToBinary,
    bin2text: binaryToText,
    dec2bin: dec2bin,
    bin2dec: bin2dec,
    hex2bin: hex2Binary,
    bin2Hex: binary2Hex,
  };

  // handle convert
  const handleConvert = () => {
    if (typeof methodsConvertor[methodToConvert] === "function") {
      methodsConvertor[methodToConvert](input);
    }
  };

  //default value
  useEffect(() => {
    setMethodToConvert(methodsOptions[0].value);
    setLastConvertMethod(methodsOptions[0].convertedMethod);
    setFirstConvertMethod(methodsOptions[0].firstMethod);
    setPlaceHolderInput(methodsOptions[0].inputLabel);
  }, []);
  //handle copy content
  const handleCopyContent = (content) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(content);
    } else {
      console.log("Clipboard API not supported");
    }
  };

  return (
    <>
      <Card
        className={`${
          localStorage.getItem("theme") === "dark-mode"
            ? "card-bg-gradient-dark"
            : "card-bg-gradient-light"
        } w-full mb-32`}
      >
        <CardHeader
          title={
            <div
              className="w-full h-14"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent:
                  localStorage.getItem("dir") === "ltr" ? "start" : "end",
              }}
            >
              <p className="text-slate-200 text-2xl w-fit ">String Convertor</p>
            </div>
          }
        />{" "}
        <CardContent className="">
          <Grid container spacing={4} columns={12}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid container spacing={1} columns={12}>
                <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                  <StyledAutocomplete
                    disableClearable
                    sx={{
                      textAlign: "left",
                      width: "100%",
                      direction: localStorage.getItem("dir"),
                    }}
                    value={firstConvertMethod}
                    onChange={(e, value) => {
                      setMethodToConvert(value?.value);
                      setLastConvertMethod(value?.convertedMethod);
                      setFirstConvertMethod(value?.firstMethod);

                      setPlaceHolderInput(value?.inputLabel);
                    }}
                    size="small"
                    options={methodsOptions}
                    PaperComponent={({ children }) => (
                      <div className={`dark:bg-slate-900 dark:text-slate-300`}>
                        {children}
                      </div>
                    )}
                    renderInput={(params) => (
                      <TextField
                        label="From"
                        size="medium"
                        {...params}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            color: "#d6d3d1",
                            "& fieldset": {
                              borderColor: "#d6d3d1",
                            },
                            "&:hover fieldset": {
                              borderColor: "white", // change border color on hover
                            },
                          },
                          "& .MuiInputLabel-root": {
                            color: "#d6d3d1",
                          },
                          "& .MuiSvgIcon-root": {
                            color: "white", // change icon color
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <StyledButton
                    onClick={() => {
                      input?.trim() && handleConvert();
                    }}
                    className="w-full h-full bg-gradient-to-l dark:from-bgGradientFromCustomButtonDark dark:to-bgGradientToCustomButtonDark dark:hover:from-bgGradientFromCustomButtonHoverDark dark:hover:to-bgGradientToCustomButtonHoverDark bg-bgCustomButtonLight hover:bg-bgCustomButtonHoverLight"
                  >
                    <ArrowForwardIcon fontSize="medium" />
                  </StyledButton>
                </Grid>
                <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                  <StyledTextField
                    label="To"
                    size="small"
                    className="w-full"
                    value={lastConvertMethod}
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid container spacing={1} columns={12}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  {" "}
                  <textarea
                    placeholder={placeHolderInput}
                    value={input}
                    onChange={(e) => {
                      setInput(e.target.value);
                    }}
                    rows={10}
                    spellCheck={false}
                    style={{
                      resize: "none",
                      backgroundColor: "rgb(193 189 189)",
                    }}
                    className="p-3 w-full rounded-md"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className="w-full h-full relative bg-transparent">
                    {" "}
                    <textarea
                      value={output}
                      readOnly
                      rows={10}
                      spellCheck={false}
                      style={{
                        resize: "none",
                        backgroundColor: "rgb(193 189 189)",
                      }}
                      className="p-3 w-full rounded-md"
                    />
                    <ContentCopyIcon
                      onClick={() => {
                        handleCopyContent(output);
                      }}
                      fontSize="medium"
                      className="cursor-pointer absolute top-2 right-2 pl-1"
                      htmlColor="#1e293b"
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid
                container
                spacing={6}
                columns={12}
                className=" text-slate-200"
              >
                <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                  <div className="w-full h-full flex flex-col justify-start items-start gap-3">
                    <div className="w-full flex justify-start items-center gap-1">
                      <HelpOutlineIcon fontSize="small" />
                      <h4 className="font-bold">Whats Binary Code ?</h4>
                    </div>
                    <p style={{ textAlign: "justify", lineHeight: "30px" }}>
                      Binary code is the most basic form of computer code,
                      consisting of two numbers: 0 and 1. These numbers form the
                      basic layer of all computing systems and are the primary
                      language of digital technologies. Binary code uses
                      combinations of these two numbers to represent numbers,
                      letters, or other types of information.
                    </p>
                  </div>
                </Grid>{" "}
                <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                  <div className="w-full h-full flex flex-col justify-start items-start gap-3">
                    <div className="w-full flex justify-start items-center gap-1">
                      <EngineeringIcon fontSize="small" />
                      <h4 className="font-bold">How binary code works ?</h4>
                    </div>
                    <p style={{ textAlign: "justify", lineHeight: "30px" }}>
                      Binary code represents information in a format that
                      computers or other electronic devices can understand,
                      interpret, and use. Devices typically organize the code
                      into segments called “bits” or “bytes.” Bits are single
                      digits, either 1s or 0s. Because one bit is very small and
                      impractical for use, computers group them into bytes,
                      which are eight-bit units.
                    </p>
                  </div>
                </Grid>{" "}
                <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                  <div className="w-full h-full flex flex-col justify-start items-start gap-3">
                    <div className="w-full flex justify-start items-center gap-1">
                      <HelpOutlineIcon fontSize="small" />
                      <h4 className="font-bold">What is decimal ?</h4>
                    </div>
                    <p style={{ textAlign: "justify", lineHeight: "30px" }}>
                      Binary-coded decimal is a system of writing numerals that
                      assigns a four-digit binary code to each digit 0 through 9
                      in a decimal (base 10) number. Simply put, binary-coded
                      decimal is a way to convert decimal numbers into their
                      binary equivalents. However, binary-coded decimal is not
                      the same as simple binary representation.
                    </p>
                  </div>
                </Grid>{" "}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
