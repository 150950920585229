import {
  Checkbox,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useState } from "react";

export function RobotComponent({
  name,
  logo,
  id,
  flagURL,
  keyBots,
  setBlockBotList,
  blockBotList,
}) {
  // initial states
  const [selectRobot, setSelectRobot] = useState(false);

  return (
    <>
      <ListItem
        className=""
        sx={{ borderBottom: "1px solid #222d50" }}
        key={id}
        // disablePadding
        secondaryAction={
          <Checkbox
            className="text-green-900"
            checked={selectRobot}
            onChange={(e) => {
              setSelectRobot(e.target.checked);
              if (e.target.checked) {
                if (!blockBotList.includes(keyBots)) {
                  setBlockBotList([...blockBotList, keyBots]);
                }
              } else {
                setBlockBotList(
                  blockBotList.filter((item) => item !== keyBots)
                );
              }
            }}
          />
        }
      >
        <ListItemButton
          dense
          className=""
          onClick={() => {
            setSelectRobot(!selectRobot);
            if (!selectRobot) {
              if (!blockBotList.includes(keyBots)) {
                setBlockBotList([...blockBotList, keyBots]);
              }
            } else {
              setBlockBotList(blockBotList.filter((item) => item !== keyBots));
            }
          }}
        >
          <img
            alt=""
            src={flagURL + logo}
            style={{ width: "30px", height: "30px" }}
          />
          <ListItemText id={id} primary={name} className="ml-2" />
          <ListItemText />
        </ListItemButton>
      </ListItem>
    </>
  );
}
