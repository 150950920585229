import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  tableCellClasses,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { forwardRef, useContext, useState } from "react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SmsIcon from "@mui/icons-material/Sms";
import TelegramIcon from "@mui/icons-material/Telegram";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import AddAlertIcon from "@mui/icons-material/AddAlert";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { AppContext } from "../../../../context/AppContext";

import { lng } from "../../../../lang/languagesObjects";
import { StyledButton } from "../../../../components/styled-components";
import { AlertsItem } from "./components/AlertsItem";
export function AlertsTable({
  helperText,
  alertData,
  setAlertData,
  handleUpdateAlert,
  disableDeleteIcon,
  handleInActiveAlert,
  periodAlertOption,
  openInActiveDialog,
  setOpenInActiveDialog,
  showLoadingInActive,
  setInActiveMessage,
  setShowInActiveSnackBar,
  setDeleteMessage,
  setDisableDeleteIcon,
  // setShowRemoveSnackBar,
}) {
  // for multi language
  const { t } = useTranslation("prints");
  i18next.addResourceBundle("fa", "prints", lng.alertCenter.fa);
  i18next.addResourceBundle("en", "prints", lng.alertCenter.en);
  i18next.addResourceBundle("ar", "prints", lng.alertCenter.ar);
  //initial states
  const { lang } = useContext(AppContext);
  const location = useLocation();

  const columns = [
    {
      id: "id",
      label: "#",
      minWidth: 40,
      align: "left",
    },
    {
      id: "domain",
      label: t("domain", {
        lng: localStorage.getItem("lang"),
      }),
      minWidth: 120,
      align: "left",
    },

    {
      id: "expiration",
      label: t("expiration", {
        lng: localStorage.getItem("lang"),
      }),
      minWidth: 150,
      align: "left",
    },
    {
      id: "gateway",
      label: t("gateway", {
        lng: localStorage.getItem("lang"),
      }),
      minWidth: 100,
      align: "left",
    },
    {
      id: "period",
      label: t("period", {
        lng: localStorage.getItem("lang"),
      }),
      minWidth: 80,
      align: "left",
    },

    {
      id: "type",
      label: t("type", {
        lng: localStorage.getItem("lang"),
      }),
      minWidth: 60,
      align: "left",
    },
    {
      id: "status",
      label: t("status", {
        lng: localStorage.getItem("lang"),
      }),
      minWidth: 80,
      align: "left",
    },
  ];

  //customize  table
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
      fontSize: 11,
      padding: "6px",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  }));

  // for gradient progress
  function GradientCircularProgress() {
    return (
      <>
        <svg width={0} height={0}>
          <defs>
            <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#e01cd5" />
              <stop offset="100%" stopColor="#1CB5E0" />
            </linearGradient>
          </defs>
        </svg>
        <CircularProgress
          size={40}
          sx={{ "svg circle": { stroke: "url(#my_gradient)" } }}
        />
      </>
    );
  }

  return (
    <>
      <Card
        sx={{ width: "100%" }}
        className={`${
          localStorage.getItem("theme") === "dark-mode"
            ? "card-bg-gradient-dark"
            : "card-bg-gradient-light"
        }`}
      >
        <CardContent>
          <TableContainer
            sx={{
              direction: "ltr",
              width: "100%",
              maxHeight: "100%",
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                height: "4px",
              },
            }}
          >
            {alertData ? (
              <Table>
                <TableHead>
                  <TableRow sx={{ borderBottom: "1px solid #b9bcc1" }}>
                    {columns.map((column, index) => (
                      <TableCell
                        key={index}
                        align={column.align}
                        sx={{
                          minWidth: column.minWidth,
                          flexShrink: 1,
                          padding: "7px",
                          fontWeight: "bold",
                          fontSize: 12,
                          color: "#e7e5e4",
                          border: "none",
                        }}
                        className="overflow-hidden text-ellipsis whitespace-nowrap "
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody sx={{ height: "100%", width: "100%" }}>
                  {alertData.map((item, index) => {
                    return (
                      <AlertsItem
                        StyledTableRow={StyledTableRow}
                        StyledTableCell={StyledTableCell}
                        columns={columns}
                        id={item.id}
                        status={item.status}
                        openInActiveDialog={openInActiveDialog}
                        created_at={item.created_at}
                        handleUpdateAlert={handleUpdateAlert}
                        domain={item.domain}
                        result={item.result}
                        index={index}
                        expiration={item.expiration}
                        gateway={item.gateway}
                        periodAlertOption={periodAlertOption}
                        domain_type={item.domain_type}
                        period={item.period}
                        type={item.type}
                        location={location}
                        GradientCircularProgress={GradientCircularProgress}
                        showLoadingInActive={showLoadingInActive}
                        handleInActiveAlert={handleInActiveAlert}
                        disableDeleteIcon={disableDeleteIcon}
                        object={item.object}
                        setOpenInActiveDialog={setOpenInActiveDialog}
                        setInActiveMessage={setInActiveMessage}
                        alertData={alertData}
                        setAlertData={setAlertData}
                        setShowInActiveSnackBar={setShowInActiveSnackBar}
                        setDeleteMessage={setDeleteMessage}
                        setDisableDeleteIcon={setDisableDeleteIcon}
                        // setShowRemoveSnackBar={setShowRemoveSnackBar}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            ) : (
              helperText
            )}
          </TableContainer>
        </CardContent>
      </Card>
    </>
  );
}
