import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import {
  StyledAutocomplete,
  StyledTextField,
} from "../../../../components/styled-components";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const CachingJavascript = memo(
  ({
    fileExtensions,
    setFileExtensions,
    setTime,
    setUnit,
    time,
    unit,
    reValid,
    setReValid,
    type,
    setType,
  }) => {
    //initial states
    const [showUnit, setShowUnit] = useState("minutes");

    // for File Extensions auto complete
    const fileExtensionsOptions = [
      { id: 1, label: "css" },
      { id: 2, label: "js" },
      { id: 3, label: "png" },
      { id: 4, label: "jpg" },
      { id: 5, label: "svg" },
    ];

    // for type of time options
    const timeOptionsType = [
      { id: 1, label: "minutes", value: 60 },
      { id: 2, label: "hours", value: 60 * 60 },
      { id: 3, label: "days", value: 24 * 60 * 60 },
      { id: 4, label: "weeks", value: 7 * 24 * 60 * 60 },
      { id: 5, label: "months", value: 30 * 24 * 60 * 60 },
    ];
    // for test state
    const [test, setTest] = useState(false);
    useEffect(() => {
      setTest(true);
    }, []);
    return (
      <>
        <Accordion
          className="w-full text-slate-100 rounded-md"
          sx={{
            background: "rgb(29,103,158)",
            background:
              "linear-gradient(90deg, rgba(13, 166, 194, 0.23573179271708689) 0%, rgba(38, 36, 80, 0.804359243697479) 100%)",
          }}
        >
          <AccordionSummary
            className="h-14"
            expandIcon={<ExpandMoreIcon className="text-slate-100" />}
          >
            Caching Javascript, CSS, and Images
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={3} columns={12}>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <StyledAutocomplete
                  disableClearable
                  sx={{
                    textAlign: "left",
                    width: "100%",
                    direction: localStorage.getItem("dir"),
                  }}
                  value={fileExtensions}
                  onChange={(e, value) => {
                    setFileExtensions(value?.label);
                  }}
                  size="small"
                  options={fileExtensionsOptions}
                  PaperComponent={({ children }) => (
                    <div className={`dark:bg-slate-900 dark:text-slate-300`}>
                      {children}
                    </div>
                  )}
                  renderInput={(params) => (
                    <TextField
                      label="File Extensions"
                      size="medium"
                      {...params}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          color: "#d6d3d1",
                          "& fieldset": {
                            borderColor: "#d6d3d1",
                          },
                          "&:hover fieldset": {
                            borderColor: "white", // change border color on hover
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: "#d6d3d1",
                        },
                        "& .MuiSvgIcon-root": {
                          color: "white", // change icon color
                        },
                      }}
                    />
                  )}
                />{" "}
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <div className="w-full flex justify-center items-center gap-1">
                  <StyledTextField
                    type="tel"
                    placeholder="60"
                    value={time}
                    onChange={(e) => {
                      setTime(e.target.value);
                    }}
                    className="w-full"
                    label="Time"
                    size="small"
                  />
                  <StyledAutocomplete
                    disableClearable
                    sx={{
                      textAlign: "left",
                      width: "100%",
                      direction: localStorage.getItem("dir"),
                    }}
                    value={showUnit}
                    onChange={(e, value) => {
                      setUnit(value?.value);
                      setShowUnit(value?.label);
                    }}
                    size="small"
                    options={timeOptionsType}
                    PaperComponent={({ children }) => (
                      <div className={`dark:bg-slate-900 dark:text-slate-300`}>
                        {children}
                      </div>
                    )}
                    renderInput={(params) => (
                      <TextField
                        label="Unit"
                        size="medium"
                        {...params}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            color: "#d6d3d1",
                            "& fieldset": {
                              borderColor: "#d6d3d1",
                            },
                            "&:hover fieldset": {
                              borderColor: "white", // change border color on hover
                            },
                          },
                          "& .MuiInputLabel-root": {
                            color: "#d6d3d1",
                          },
                          "& .MuiSvgIcon-root": {
                            color: "white", // change icon color
                          },
                        }}
                      />
                    )}
                  />{" "}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <FormControl>
                  <RadioGroup
                    value={type}
                    onChange={(e) => {
                      setType(e.target.value);
                    }}
                    row
                    className="flex justify-center items-center gap-px"
                  >
                    <FormControlLabel
                      value={"public"}
                      control={<Radio />}
                      label="Public"
                    />
                    <FormControlLabel
                      value={"private"}
                      control={<Radio />}
                      label="Private"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => setReValid(e.target.checked)}
                      checked={reValid}
                      //  defaultChecked
                    />
                  }
                  label="Must Revalidate"
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </>
    );
  }
);
