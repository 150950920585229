import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { StyledTextField } from "../../../../components/styled-components";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const PasswordProtectFile = memo(
  ({ fileName, setFileName, filePath, setFilePath }) => {
    // for test state
    const [test, setTest] = useState(false);
    useEffect(() => {
      setTest(true);
    }, []);
    return (
      <>
        <Accordion
          className="w-full text-slate-100 rounded-md"
          sx={{
            background: "rgb(29,103,158)",
            background:
              "linear-gradient(90deg, rgba(13, 166, 194, 0.23573179271708689) 0%, rgba(38, 36, 80, 0.804359243697479) 100%)",
          }}
        >
          <AccordionSummary
            className="h-14"
            expandIcon={<ExpandMoreIcon className="text-slate-100" />}
          >
            Password protect file (requires .htpasswd file)
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={1} columns={12}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <StyledTextField
                  value={fileName}
                  onChange={(e) => setFileName(e.target.value)}
                  label="File Name"
                  className="w-full"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <StyledTextField
                  value={filePath}
                  onChange={(e) => setFilePath(e.target.value)}
                  label=".htpasswd File Path"
                  className="w-full"
                  size="small"
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </>
    );
  }
);
