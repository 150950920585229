import {
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Snackbar,
  Tooltip,
} from "@mui/material";
import React, { useEffect } from "react";
import SmsIcon from "@mui/icons-material/Sms";
import TelegramIcon from "@mui/icons-material/Telegram";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useTranslation } from "react-i18next";
import { lng } from "../../../../../lang/languagesObjects";
import i18next from "i18next";
import { StyledButton } from "../../../../../components/styled-components";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useState } from "react";
import { handleAutoLogOut } from "../../../../../includes/functions";
import { useNavigate } from "react-router-dom";
import { useApi } from "../../../../../customAxios";

export function AlertsItem({
  StyledTableRow,
  StyledTableCell,
  columns,
  id,
  status,
  created_at,
  handleUpdateAlert,
  domain,
  result,
  index,
  expiration,
  gateway,
  periodAlertOption,
  domain_type,
  period,
  type,
  GradientCircularProgress,
  location,
  disableDeleteIcon,
  object,
  setInActiveMessage,
  alertData,
  setAlertData,
  setShowInActiveSnackBar,
  setDisableDeleteIcon,
}) {
  // for multi language
  const { t } = useTranslation("prints");
  i18next.addResourceBundle("fa", "prints", lng.alertCenter.fa);
  i18next.addResourceBundle("en", "prints", lng.alertCenter.en);
  i18next.addResourceBundle("ar", "prints", lng.alertCenter.ar);

  //initial states
  const api = useApi();
  const [showRemoveSnackBar, setShowRemoveSnackBar] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [allowInActiveReq, setAllowInActiveReq] = useState(false);
  const [showLoadingInActive, setShowLoadingInActive] = useState(false);
  const [openInActiveDialog, setOpenInActiveDialog] = useState(false);
  const [newId, setNewId] = useState(id);
  const navigate = useNavigate();
  // handle open dialog
  const handleClickOpen = () => {
    location.pathname === "/alert-center" && setOpenInActiveDialog(true);
  };
  //handle cancel inactive
  const handleCanselInActive = () => {
    setOpenInActiveDialog(false);
  };
  // handle inactive alert
  const handleInActiveAlert = (id, status) => {
    if (openInActiveDialog) {
      setShowLoadingInActive(true);
      console.log("handle inactive");

      const newAlertData = alertData.map((item) => {
        if (item.id === id) {
          return { ...item, status: status === 1 ? 2 : 1 };
        } else {
          return item;
        }
      });
      api
        .post("/tools/alert/update", {
          action: "status",
          data: { id: id, status: status === 1 ? 2 : 1 },
        })
        .then((res) => {
          if (!res.data.status) {
            console.log("InActive Alert err status -> false", res);
            setInActiveMessage(res.data.message);
          }
          setInActiveMessage(res.data.message);
          setShowInActiveSnackBar(true);
          setAlertData(newAlertData);
          setShowLoadingInActive(false);
          setOpenInActiveDialog(false);
          console.log("InActive Alert res", res);
        })
        .catch((err) => {
          setShowInActiveSnackBar(true);
          setInActiveMessage(err.message);
          setShowLoadingInActive(false);

          console.log("InActive Alert Err: ", err);
        });
    }
  };
  //handle remove Alert
  const handleRemoveAlert = (id) => {
    console.log(id);

    // setAllowRemoveRequest(false);
    setDisableDeleteIcon((prevDisabledItems) => ({
      ...prevDisabledItems,
      [id]: true,
    }));
    const tempArray = alertData.filter((item) => item.id !== id);
    // if (allowRemoveRequest) {
    api
      .post("/tools/alert/update", { action: "delete", data: { id: id } })
      .then((res) => {
        if (!res.data.status) {
          console.log("remove Alert err status -> false", res);
          setDeleteMessage(res.data.message);
        }
        setDeleteMessage(res.data.message);
        setShowRemoveSnackBar(true);
        // setAllowRemoveRequest(true);
        setAlertData(tempArray);
        setDisableDeleteIcon((prevDisabledItems) => ({
          ...prevDisabledItems,
          [id]: false,
        }));

        console.log("remove Alert res", res);
      })
      .catch((err) => {
        setShowRemoveSnackBar(true);
        // setAllowRemoveRequest(true);
        setDeleteMessage(err.message);
        setDisableDeleteIcon((prevDisabledItems) => ({
          ...prevDisabledItems,
          [id]: false,
        }));
        console.log("alert remove err ", err);
      });
    // }
  };

  useEffect(() => {
    handleInActiveAlert(newId, status);
  }, [allowInActiveReq]);

  return (
    <>
      <Snackbar
        autoHideDuration={2000}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "#e2e8f0",
            color: "#0f172a",
          },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={showRemoveSnackBar}
        onClose={() => {
          setShowRemoveSnackBar(false);
        }}
        message={deleteMessage}
      />{" "}
      <StyledTableRow hover key={newId}>
        {columns.map((column) => {
          return (
            <StyledTableCell align={column.align}>
              {column.id === "id" ? (
                <Tooltip
                  placement="left-start"
                  title={`Created: ${created_at?.split(" ")[0]}`}
                >
                  <p className="text-slate-200">{index + 1}</p>
                </Tooltip>
              ) : column.id === "domain" ? (
                <Tooltip placement="bottom-end" title={`${domain_type}`}>
                  <p
                    style={{
                      color: result === "0" ? "#ef4444" : "darkgreen",
                    }}
                  >
                    {domain}
                  </p>
                </Tooltip>
              ) : column.id === "expiration" ? (
                <p className="text-slate-200">{expiration}</p>
              ) : column.id === "gateway" ? (
                <Box className="flex items-center gap-2">
                  {gateway.map((element) => (
                    <Tooltip title={element}>
                      {element === "sms" ? (
                        <SmsIcon fontSize="small" color="primary" />
                      ) : element === "email" ? (
                        <AlternateEmailIcon fontSize="small" color="primary" />
                      ) : element === "telegram" ? (
                        <TelegramIcon fontSize="small" color="primary" />
                      ) : (
                        ""
                      )}
                    </Tooltip>
                  ))}
                </Box>
              ) : column.id === "period" ? (
                <p className="text-slate-200 ">
                  {
                    periodAlertOption?.find(
                      (element) => element.value * 60 === period
                    )?.label
                  }
                </p>
              ) : column.id === "type" ? (
                <p className="text-slate-200">{type}</p>
              ) : (
                column.id === "status" && (
                  <>
                    <Chip
                      onClick={() => {
                        handleClickOpen();
                        // setNewId(id);
                      }}
                      size="small"
                      variant="filled"
                      color={status === 1 ? "primary" : "error"}
                      sx={{
                        color: "var(--color-text)",
                      }}
                      label={`${status === 1 ? "Active" : "Inactive"}`}
                    />
                    <Dialog
                      open={openInActiveDialog}
                      onClose={() => {
                        setOpenInActiveDialog(false);
                      }}
                      fullWidth
                      maxWidth={"xs"}
                      sx={{
                        "& .MuiDialog-paper": {
                          borderRadius: "22px",
                          background: "transparent",
                        },
                        direction: "ltr",
                      }}
                    >
                      <DialogContent
                        className="bg-gradient-to-l dark:from-bgGradientFromContentDialogDark dark:to-bgGradientToContentDialogDark from-bgGradientFromContentDialogLight to-bgGradientToContentDialogLight"
                        sx={{
                          paddingLeft: "10px",
                          paddingRight: "10px",
                        }}
                      >
                        <DialogContentText sx={{ color: "#cbd5e1" }}>
                          {status === 1
                            ? t("diableAlertText", {
                                lng: localStorage.getItem("lang"),
                              })
                            : t("enableAlertText", {
                                lng: localStorage.getItem("lang"),
                              })}
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions
                        className="bg-gradient-to-l dark:from-bgGradientFromActionDialogDark dark:to-bgGradientToActionDialogDark from-bgGradientFromContentDialogLight to-bgGradientToContentDialogLight"
                        sx={{
                          borderTop: "1px solid #666666",
                        }}
                      >
                        {showLoadingInActive ? (
                          <div className="w-full h-full flex items-center justify-center">
                            <GradientCircularProgress />{" "}
                          </div>
                        ) : (
                          <>
                            <StyledButton
                              className="bg-gradient-to-l dark:from-bgGradientFromCustomButtonDark dark:to-bgGradientToCustomButtonDark dark:hover:from-bgGradientFromCustomButtonHoverDark dark:hover:to-bgGradientToCustomButtonHoverDark bg-bgCustomButtonLight hover:bg-bgCustomButtonHoverLight mr-2 ml-2 w-28"
                              onClick={() => {
                                handleCanselInActive();
                              }}
                            >
                              {t("cancel", {
                                lng: localStorage.getItem("lang"),
                              })}{" "}
                            </StyledButton>
                            <StyledButton
                              className="mr-2 ml-2 w-28 bg-gradient-to-l dark:from-bgGradientFromCustomButtonDark dark:to-bgGradientToCustomButtonDark dark:hover:from-bgGradientFromCustomButtonHoverDark dark:hover:to-bgGradientToCustomButtonHoverDark bg-bgCustomButtonLight hover:bg-bgCustomButtonHoverLight  "
                              onClick={() => {
                                setAllowInActiveReq(!allowInActiveReq);
                              }}
                            >
                              {t("confirmation", {
                                lng: localStorage.getItem("lang"),
                              })}
                            </StyledButton>
                          </>
                        )}
                      </DialogActions>
                    </Dialog>
                  </>
                )
              )}
            </StyledTableCell>
          );
        })}
        {location.pathname !== "/profile" && (
          <>
            <StyledTableCell align="center">
              <Tooltip
                title={t("editAlertIconHover", {
                  lng: localStorage.getItem("lang"),
                })}
              >
                <EditOutlinedIcon
                  sx={{ cursor: "pointer" }}
                  fontSize="small"
                  htmlColor={"#ed6c02"}
                  onClick={() => {
                    handleUpdateAlert(
                      newId,
                      object,
                      period,
                      type,
                      expiration,
                      gateway,
                      domain,
                      created_at
                    );
                  }}
                />
              </Tooltip>
            </StyledTableCell>
            <StyledTableCell align="center">
              <Tooltip
                title={t("deleteAlert", {
                  lng: localStorage.getItem("lang"),
                })}
              >
                <DeleteOutlineIcon
                  sx={{
                    cursor: "pointer",
                  }}
                  fontSize="small"
                  htmlColor={disableDeleteIcon?.[newId] ? "#4b5563" : "#d32f2f"}
                  onClick={() => {
                    handleRemoveAlert(newId);
                  }}
                />
              </Tooltip>
            </StyledTableCell>
          </>
        )}
      </StyledTableRow>
    </>
  );
}
