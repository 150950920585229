import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
  Snackbar,
  TextField,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  StyledAutocomplete,
  StyledButton,
} from "../../../components/styled-components";
import { ProgressAlerts } from "../../../Profile/components/ProgressPartsOfProfile";
import SmsIcon from "@mui/icons-material/Sms";
import TelegramIcon from "@mui/icons-material/Telegram";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import AddAlertIcon from "@mui/icons-material/AddAlert";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import { AlertsTable } from "./components/AlertsTable";
import moment from "moment/moment";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { lng } from "../../../lang/languagesObjects";
import { AppContext } from "../../../context/AppContext";
import {
  handleAutoLogOut,
  useHandleAutoLogOut,
} from "../../../includes/functions";
import { useNavigate } from "react-router-dom";
import { useApi } from "../../../customAxios";
export function AlertCenter({ addURL, setAddURL }) {
  // for multi language
  const { t } = useTranslation("prints");
  i18next.addResourceBundle("fa", "prints", lng.alertCenter.fa);
  i18next.addResourceBundle("en", "prints", lng.alertCenter.en);
  i18next.addResourceBundle("ar", "prints", lng.alertCenter.ar);
  //initial states
  const api = useApi();
  const navigate = useNavigate();
  const [alertData, setAlertData] = useState(null);
  const [devicesData, setDevicesData] = useState([]);
  const [typeAlert, setTypeAlert] = useState("Ping");
  const [domainList, setDomainList] = useState(addURL ? addURL : null);
  const [domainId, setDomainId] = useState();
  const [domain, setDomain] = useState(addURL ? addURL : null);
  const [periodAlert, setPeriodAlert] = useState(10);
  const [periodList, setPeriodList] = useState("10m");
  const [expirationAlert, setExpirationAlert] = useState(
    new Date(
      new Date().getFullYear(),
      new Date().getMonth() + 12,
      new Date().getDate()
    )
      .toISOString()
      .slice(0, 10)
  );
  const [expirationInput, setExpirationInput] = useState({
    label: "1 year",
    value: 12,
  });
  const [pathSendAlert, setPathSendAlert] = useState(["email"]);
  const [helperText, setHelperText] = useState(<ProgressAlerts />);
  const [showAlertSnackBar, setShowAlertSnackBar] = useState(false);
  const [showEditSnackBar, setShowEditSnackBar] = useState(false);
  const [showInActiveSnackBar, setShowInActiveSnackBar] = useState(false);
  const [showAlerts, setShowAlerts] = useState(true);
  const [showErrorMessage, setShowErrorMessage] = useState(null);
  const [pathCheckBoxesValue, setPathCheckBoxesValue] = useState({
    sms: false,
    telegram: false,
    email: true,
  });
  const [disableDeleteIcon, setDisableDeleteIcon] = useState({});
  const [allowRemoveRequest, setAllowRemoveRequest] = useState(true);
  const [allowUpdateRequest, setAllowUpdateRequest] = useState(true);
  const [dialogStates, setDialogStates] = useState({});
  const [allowInActiveRequest, setAllowInActiveRequest] = useState(true);
  const [handleEdit, setHandleEdit] = useState(false);
  const [disableDomainAutocomplete, setDisableDomainAutocomplete] =
    useState(false);
  const [alertsItemId, setAlertsItemId] = useState(null);
  const [alertsItemObject, setAlertsItemObject] = useState(null);
  const [disableEditButton, setDisableEditButton] = useState(false);
  const [updateMessage, setUpdateMessage] = useState(null);
  const [inActiveMessage, setInActiveMessage] = useState(null);

  const typeAlertOption = [{ id: 1, type: "Ping" }];

  const expirationAlertOption = [
    {
      label: `1 ${t("month", { lng: localStorage.getItem("lang") })}`,
      value: 1,
    },
    {
      label: `3 ${t("month", { lng: localStorage.getItem("lang") })}`,
      value: 3,
    },
    {
      label: `6 ${t("month", { lng: localStorage.getItem("lang") })}`,
      value: 6,
    },
    {
      label: `1 ${t("year", { lng: localStorage.getItem("lang") })}`,
      value: 12,
    },
  ];
  const periodAlertOption = [
    { id: 1, label: "1m", value: 1 },
    { id: 2, label: "3m", value: 3 },
    { id: 3, label: "5m", value: 5 },
    { id: 4, label: "10m", value: 10 },
    { id: 5, label: "30m", value: 30 },
  ];

  //handle clear domain input && handle snackBar
  useEffect(() => {
    setShowAlertSnackBar(true);
    return () => {
      setAddURL(null);
    };
  }, []);
  //
  const handleCloseAlert = () => {
    setShowAlertSnackBar(false);
  };

  // handle profile details
  useEffect(() => {
    api
      .post("/profile/details")
      .then((res) => {
        if (!res.data.status) {
          throw Error(
            "The connection with the server could not be established"
          );
        }
        console.log("Alert & Device details response", res);
        setAlertData(res.data.alerts);
        if (Array.isArray(res.data.domains)) {
          setDevicesData(res.data.domains); // Only update if it's an array
        }
      })
      .catch((err) => {
        console.log("ip details error", err);
        setHelperText(
          <p className="text-slate-200 w-full flex flex-row justify-center">
            {err.message}
          </p>
        );
      });
  }, [showAlerts]);

  //handle submit
  function handleSubmit() {
    setShowAlerts(false);
    let data = {
      type: typeAlert,
      period: periodAlert,
      expiration: expirationAlert + " 23:59:59",
      gateway: pathSendAlert,
    };

    if (domain) {
      data.url = domain;
    } else {
      data.object = domainId;
    }
    api
      .post("/tools/alert/add", {
        data,
      })
      .then((res) => {
        if (!res.data.status) {
          console.log("add alert status->false: ", res);
          throw Error("Could not connect to the server");
        }
        console.log("add alert res: ", res);
        setShowAlerts(true);
        setShowErrorMessage(null);
        setDomainId(null);
        setDomain(null);
      })
      .catch((err) => {
        console.log("add alert error: ", err);
        setShowAlerts(true);
        setShowErrorMessage(<p className="text-[#EF4444]">{err.message}</p>);
      });
  }

  //handle update alert

  const handleUpdateRequest = () => {
    const updateAlertsItem = alertData.map((item) => {
      if (item.id === alertsItemId) {
        return {
          ...item,
          id: alertsItemId,
          object: alertsItemObject,
          type: typeAlert,
          period: periodAlert * 60,
          expiration: expirationAlert + " 23:59:59",
          gateway: pathSendAlert,
        };
      } else {
        return item;
      }
    });
    setAllowUpdateRequest(false);
    setDisableEditButton(true);
    if (allowUpdateRequest) {
      api
        .post("/tools/alert/update", {
          action: "update",
          data: {
            id: alertsItemId,
            object: alertsItemObject,
            type: typeAlert,
            period: periodAlert,
            expiration: expirationAlert + " 23:59:59",
            gateway: pathSendAlert,
          },
        })
        .then((res) => {
          if (!res.data.status) {
            console.log("update Alert err status -> false", res);
            setUpdateMessage(res.data.message);
          }
          setUpdateMessage(res.data.message);
          setDisableEditButton(false);
          setHandleEdit(false);
          setDisableDomainAutocomplete(false);
          setDomainList("");
          setTypeAlert("Ping");
          setHandleEdit(false);
          setPathCheckBoxesValue({
            sms: false,
            telegram: false,
            email: true,
          });
          setPathSendAlert(["email"]);
          setPeriodList("10m");
          setPeriodAlert(10);
          setExpirationInput({
            label: "1 year",
            value: 12,
          });
          setAlertData(updateAlertsItem);
          setShowEditSnackBar(true);
          setAllowUpdateRequest(true);

          console.log("update Alert", res);
        })
        .catch((err) => {
          setShowEditSnackBar(true);
          setUpdateMessage(err.message);
          setAllowUpdateRequest(true);
          setDisableEditButton(false);
          console.log("update Alert Err: ", err);
        });
    }
  };

  const handleUpdateAlert = (
    id,
    object,
    period,
    type,
    expiration,
    gateway,
    domain,
    created
  ) => {
    setAlertsItemId(id);
    setAlertsItemObject(object);
    setDisableDomainAutocomplete(true);
    setDomainList(domain);
    setTypeAlert(type);
    setHandleEdit(true);
    const newPathCheckBoxesValue = Object.keys(pathCheckBoxesValue).reduce(
      (acc, key) => {
        acc[key] = gateway.includes(key);
        return acc;
      },
      {}
    );
    setPathCheckBoxesValue(newPathCheckBoxesValue);
    setPathSendAlert(gateway);
    setPeriodList(
      periodAlertOption.find((item) => item.value === period / 60)?.label
    );

    const startDate = moment(created);
    const timeEnd = moment(expiration);
    const diff = timeEnd.diff(startDate);
    const diffInMonths = Math.round(diff / (1000 * 3600 * 24 * 30)); // approximate number of months
    setExpirationInput(
      expirationAlertOption.find((item) => item.value === diffInMonths)?.label
    );
    console.log("difMonths", diffInMonths);
  };

  //handle Cansell update
  const handleCansellEdit = () => {
    setDisableDomainAutocomplete(false);
    setDomainList("");
    setTypeAlert("Ping");
    setHandleEdit(false);
    setPathCheckBoxesValue({
      sms: false,
      telegram: false,
      email: true,
    });
    setPathSendAlert(["email"]);
    setPeriodList("10m");
    setPeriodAlert(10);
    setExpirationInput({
      label: "1 year",
      value: 12,
    });
  };

  return (
    <>
      <Snackbar
        autoHideDuration={2000}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "#e2e8f0",
            color: "#0f172a",
          },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={showInActiveSnackBar}
        onClose={() => {
          setShowInActiveSnackBar(false);
        }}
        message={inActiveMessage}
      />
      <Snackbar
        autoHideDuration={2000}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "#e2e8f0",
            color: "#0f172a",
          },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={showEditSnackBar}
        onClose={() => {
          setShowEditSnackBar(false);
        }}
        message={updateMessage}
      />
      <Snackbar
        autoHideDuration={3000}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "#e2e8f0",
            color: "#0f172a",
          },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={showAlertSnackBar}
        onClose={handleCloseAlert}
        message={t("alertMessage", { lng: localStorage.getItem("lang") })}
      />
      <Grid container spacing={2} columns={12}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Card
            className={`w-full ${
              localStorage.getItem("theme") === "dark-mode"
                ? "card-bg-gradient-dark"
                : "card-bg-gradient-light"
            }`}
            sx={{ direction: "ltr" }}
          >
            <CardHeader
              title={
                <p className="text-slate-200">
                  {t("addAlert", { lng: localStorage.getItem("lang") })}
                </p>
              }
            />
            <CardContent className="w-full flex flex-col items-end justify-start gap-8 ">
              <Grid className="w-full " container spacing={2} columns={16}>
                <Grid item xs={16} sm={3} md={3} lg={3} xl={3}>
                  <StyledAutocomplete
                    readOnly={disableDomainAutocomplete}
                    freeSolo
                    disableClearable
                    loading={devicesData ? false : true}
                    noOptionsText={
                      <p className="text-slate-200 text-sm text-justify">
                        {t("optionsAutoComplete", {
                          lng: localStorage.getItem("lang"),
                        })}
                      </p>
                    }
                    size="small"
                    value={domainList}
                    onInputChange={(e, newValue) => {
                      setDomainList(newValue);
                      setDomain(newValue);
                    }}
                    onChange={(e, value) => {
                      setDomainList(value);

                      if (value) {
                        setDomainId(
                          devicesData?.find((element) => {
                            return element?.domain === value;
                          }).id
                        );
                      }
                    }}
                    options={devicesData
                      ?.filter((item) => item && item.domain)
                      .map((item) => item.domain)}
                    PaperComponent={({ children }) => (
                      <div className={`dark:bg-slate-900 dark:text-slate-300`}>
                        {children}
                      </div>
                    )}
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          direction:
                            localStorage.getItem("dir") === "ltr"
                              ? "ltr"
                              : "rtl",
                        }}
                        {...params}
                        label={t("domain", {
                          lng: localStorage.getItem("lang"),
                        })}
                        variant="outlined"
                      />
                    )}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        color: "#d6d3d1",
                        "& fieldset": {
                          borderColor: "#d6d3d1",
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: "#d6d3d1",
                      },
                      "& .MuiSvgIcon-root": {
                        color: "white", // change icon color
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={16} sm={2} md={2} lg={2} xl={2}>
                  <StyledAutocomplete
                    disableClearable
                    size="small"
                    value={typeAlert}
                    onChange={(e, value) => {
                      setTypeAlert(value);
                    }}
                    getOptionDisabled={(option) => {
                      return (
                        typeAlertOption.findIndex(
                          (item) => item.type === option
                        ) !== 0
                      );
                    }}
                    options={typeAlertOption.map((item, index) => {
                      return item.type;
                    })}
                    PaperComponent={({ children }) => (
                      <div className={`dark:bg-slate-900 dark:text-slate-300`}>
                        {children}
                      </div>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("type", { lng: localStorage.getItem("lang") })}
                        variant="outlined"
                      />
                    )}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        color: "#d6d3d1",
                        "& fieldset": {
                          borderColor: "#d6d3d1",
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: "#d6d3d1",
                      },
                      "& .MuiSvgIcon-root": {
                        color: "white", // change icon color
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={16} sm={3} md={3} lg={3} xl={3}>
                  <StyledAutocomplete
                    defaultValue={{
                      label: `1 ${t("year", {
                        lng: localStorage.getItem("lang"),
                      })}`,
                      value: 12,
                    }}
                    disableClearable
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        color: "#d6d3d1",
                        "& fieldset": {
                          borderColor: "#d6d3d1",
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: "#d6d3d1",
                      },
                      "& .MuiSvgIcon-root": {
                        color: "white", // change icon color
                      },
                    }}
                    size="small"
                    value={expirationInput}
                    onChange={(e, value) => {
                      if (value) {
                        const today = new Date();
                        const nextMonthDate = new Date(
                          today.getFullYear(),
                          today.getMonth() + value.value,
                          today.getDate()
                        );
                        const dateString = nextMonthDate
                          .toISOString()
                          .slice(0, 10);
                        const formattedDate = dateString.replace(/\//g, "-");
                        setExpirationAlert(formattedDate);

                        setExpirationInput(value?.label);
                      } else {
                        setExpirationInput("");
                      }
                    }}
                    options={expirationAlertOption}
                    PaperComponent={({ children }) => (
                      <div className={`dark:bg-slate-900 dark:text-slate-300`}>
                        {children}
                      </div>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("expiration", {
                          lng: localStorage.getItem("lang"),
                        })}
                        variant="outlined"
                        defaultValue=""
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={16} sm={2} md={2} lg={2} xl={2}>
                  <StyledAutocomplete
                    disableClearable
                    size="small"
                    value={periodList}
                    onChange={(e, value) => {
                      setPeriodAlert(value.value);
                      setPeriodList(value?.label);
                    }}
                    options={periodAlertOption}
                    PaperComponent={({ children }) => (
                      <div className={`dark:bg-slate-900 dark:text-slate-300`}>
                        {children}
                      </div>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("period", {
                          lng: localStorage.getItem("lang"),
                        })}
                        variant="outlined"
                      />
                    )}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        color: "#d6d3d1",
                        "& fieldset": {
                          borderColor: "#d6d3d1",
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: "#d6d3d1",
                      },
                      "& .MuiSvgIcon-root": {
                        color: "white", // change icon color
                      },
                    }}
                  />
                </Grid>
                <Grid
                  className="flex flex-row items-center justify-center "
                  item
                  xs={16}
                  sm={3}
                  md={3}
                  lg={3}
                  xl={3}
                >
                  <div className="flex flex-row items-center justify-center rounded-2xl w-full h-10 bg-bgCustomTextFieldLight dark:bg-bgCustomTextFieldDark">
                    <Checkbox
                      defaultChecked
                      checked={pathCheckBoxesValue.email}
                      onChange={(e) => {
                        pathCheckBoxesValue.email = e.target.checked;
                        if (e.target.checked) {
                          setPathSendAlert([...pathSendAlert, "email"]);
                        } else {
                          setPathSendAlert(
                            pathSendAlert.filter((item) => item !== "email")
                          );
                        }
                      }}
                      icon={
                        <AlternateEmailIcon
                          htmlColor="#5f5f5f"
                          fontSize="medium"
                        />
                      }
                      checkedIcon={
                        <AlternateEmailIcon color="primary" fontSize="medium" />
                      }
                    />
                    <Checkbox
                      checked={pathCheckBoxesValue.telegram}
                      onChange={(e) => {
                        pathCheckBoxesValue.telegram = e.target.checked;
                        if (e.target.checked) {
                          setPathSendAlert([...pathSendAlert, "telegram"]);
                        } else {
                          setPathSendAlert(
                            pathSendAlert.filter((item) => item !== "telegram")
                          );
                        }
                      }}
                      icon={
                        <TelegramIcon htmlColor="#5f5f5f" fontSize="medium" />
                      }
                      checkedIcon={
                        <TelegramIcon color="primary" fontSize="medium" />
                      }
                    />
                    <Checkbox
                      checked={pathCheckBoxesValue.sms}
                      onChange={(e) => {
                        pathCheckBoxesValue.sms = e.target.checked;
                        if (e.target.checked) {
                          setPathSendAlert([...pathSendAlert, "sms"]);
                        } else {
                          setPathSendAlert(
                            pathSendAlert.filter((item) => item !== "sms")
                          );
                        }
                      }}
                      icon={<SmsIcon htmlColor="#5f5f5f" fontSize="medium" />}
                      checkedIcon={
                        <SmsIcon color="primary" fontSize="medium" />
                      }
                    />
                  </div>
                </Grid>
                <Grid
                  item
                  xs={16}
                  sm={3}
                  md={3}
                  lg={3}
                  xl={3}
                  className="flex flex-row items-center justify-end"
                >
                  <Grid
                    container
                    columns={12}
                    className="flex flex-row justify-end w-full "
                  >
                    <Grid item xs={12} sm={5} md={5} lg={5} className="">
                      {!handleEdit ? (
                        <Tooltip
                          title={t("addAlert", {
                            lng: localStorage.getItem("lang"),
                          })}
                        >
                          <StyledButton
                            className="bg-gradient-to-l dark:from-bgGradientFromCustomButtonDark dark:to-bgGradientToCustomButtonDark dark:hover:from-bgGradientFromCustomButtonHoverDark dark:hover:to-bgGradientToCustomButtonHoverDark bg-bgCustomButtonLight hover:bg-bgCustomButtonHoverLight w-full "
                            size="small"
                            variant="contained"
                            onClick={handleSubmit}
                          >
                            <AddAlertIcon fontSize="medium" />
                          </StyledButton>
                        </Tooltip>
                      ) : (
                        <div className="flex flex-row justify-center items-center gap-1">
                          {" "}
                          <Tooltip
                            title={t("editAlertIconHover", {
                              lng: localStorage.getItem("lang"),
                            })}
                          >
                            <StyledButton
                              disabled={disableEditButton}
                              className="bg-gradient-to-l dark:from-bgGradientFromCustomButtonDark dark:to-bgGradientToCustomButtonDark dark:hover:from-bgGradientFromCustomButtonHoverDark dark:hover:to-bgGradientToCustomButtonHoverDark bg-bgCustomButtonLight hover:bg-bgCustomButtonHoverLight w-full "
                              size="small"
                              variant="contained"
                              onClick={() => handleUpdateRequest()}
                            >
                              <EditOutlinedIcon fontSize="medium" />
                            </StyledButton>
                          </Tooltip>
                          <Tooltip
                            title={t("cancelEditAlert", {
                              lng: localStorage.getItem("lang"),
                            })}
                          >
                            <StyledButton
                              sx={{ cursor: "pointer" }}
                              className="bg-gradient-to-l dark:from-bgGradientFromCustomButtonDark dark:to-bgGradientToCustomButtonDark dark:hover:from-bgGradientFromCustomButtonHoverDark dark:hover:to-bgGradientToCustomButtonHoverDark bg-bgCustomButtonLight hover:bg-bgCustomButtonHoverLight w-full "
                              size="small"
                              variant="contained"
                              onClick={() => handleCansellEdit()}
                            >
                              <ClearOutlinedIcon fontSize="medium" />
                            </StyledButton>
                          </Tooltip>
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>{" "}
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          {showAlerts ? (
            <>
              <AlertsTable
                alertData={alertData}
                setAlertData={setAlertData}
                helperText={helperText}
                handleUpdateAlert={handleUpdateAlert}
                disableDeleteIcon={disableDeleteIcon}
                setDisableDeleteIcon={setDisableDeleteIcon}
                allowInActiveRequest={allowInActiveRequest}
                periodList={periodList}
                periodAlertOption={periodAlertOption}
                periodAlert={periodAlert}
                setInActiveMessage={setInActiveMessage}
                setShowInActiveSnackBar={setShowInActiveSnackBar}
                // setDeleteMessage={setDeleteMessage}
                // setShowRemoveSnackBar={setShowRemoveSnackBar}
              />
              {showErrorMessage}
            </>
          ) : (
            <ProgressAlerts />
          )}
        </Grid>
      </Grid>
    </>
  );
}
