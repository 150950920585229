import { Grid, Tooltip } from "@mui/material";
import React, { memo } from "react";
import { colorList } from "./colorList";

export const TailwindColorsItem = memo(
  ({ item, tailwindColors, setPalletInput }) => {
    //handle copy color code
    const handleCopyContent = (content) => {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(content);
      } else {
        console.log("Clipboard API not supported");
      }
    };
    return (
      <>
        {" "}
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className="w-full flex flex-col justify-center items-start gap-1">
            <span className="font-bold">{item}</span>
            <Grid container spacing={1} columns={11}>
              {Object.values(colorList[item]).map((element) => {
                return (
                  <>
                    <Grid item xs={2.2} sm={1} md={1} lg={2.2} xl={1}>
                      <Tooltip title={element} placement="top">
                        {" "}
                        <div
                          onClick={() => {
                            handleCopyContent(element);
                            document
                              .getElementsByClassName("container")[0]
                              .scrollTo({ top: 0, behavior: "smooth" });

                            setPalletInput(element);
                          }}
                          className="cursor-pointer w-full flex flex-col justify-center items-center gap-1"
                        >
                          <div
                            className="h-10 w-full rounded-md"
                            style={{
                              backgroundColor: element,
                            }}
                          ></div>
                          <span className="text-sm">
                            {tailwindColors[element].shade}
                          </span>
                        </div>
                      </Tooltip>
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </div>{" "}
        </Grid>
      </>
    );
  }
);
