import React, { memo, useState } from "react";
import {
  StyledButton,
  StyledTextField,
} from "../../../../../../../components/styled-components";
import { Divider, Grid, InputAdornment } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";

export const ScreenShotsItem = memo(
  ({
    handleRemoveScreenShotItem,
    id,
    screenShotKeys,
    jsonKeys,
    index,
    handleAddScreenShot,
  }) => {
    //initial states
    const [size, setSize] = useState(null);
    const [src, setSrc] = useState(null);
    const [type, setType] = useState(null);
    const [formFactor, setFormFactor] = useState(null);
    const [label, setLabel] = useState(null);

    return (
      <>
        {" "}
        <Grid container spacing={1} columns={12}>
          <Grid item xs={12} sm={12} md={6} lg={2}>
            <StyledTextField
              value={src}
              onChange={(e) => {
                jsonKeys.current.screenShots[index].data.src = e.target.value;
                setSrc(e.target.value);
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end"></InputAdornment>,
              }}
              size="small"
              label="src"
              className="w-full"
              placeholder="assets/screenshot/screenshot.webp"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={2}>
            {" "}
            <StyledTextField
              value={size}
              onChange={(e) => {
                jsonKeys.current.screenShots[index].data.sizes = e.target.value;

                setSize(e.target.value);
              }}
              size="small"
              label="sizes"
              className="w-full"
              placeholder="1280x720"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={2}>
            {" "}
            <StyledTextField
              value={type}
              onChange={(e) => {
                jsonKeys.current.screenShots[index].data.type = e.target.value;

                setType(e.target.value);
              }}
              size="small"
              label="type"
              className="w-full"
              placeholder="image/webp"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={2}>
            {" "}
            <StyledTextField
              value={formFactor}
              onChange={(e) => {
                jsonKeys.current.screenShots[index].data.form_factor =
                  e.target.value;

                setFormFactor(e.target.value);
              }}
              size="small"
              label="form_factor"
              className="w-full"
              placeholder="wide"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3}>
            {" "}
            <StyledTextField
              value={label}
              onChange={(e) => {
                jsonKeys.current.screenShots[index].data.label = e.target.value;
                setLabel(e.target.value);
              }}
              size="small"
              label="label"
              className="w-full"
              placeholder="HELPIX | Tech Tools"
            />
          </Grid>
          {index !== 0 ? (
            <Grid
              className="flex flex-row justify-center items-center"
              item
              xs={12}
              sm={12}
              md={12}
              lg={1}
            >
              <StyledButton
                onClick={() => {
                  handleRemoveScreenShotItem(id);
                }}
                w-full
                flex
                flex-row
                items-center
                justify-center
                min-h-full
                className="w-full flex flex-row items-center justify-center min-h-full bg-gradient-to-l dark:from-bgGradientFromCustomButtonDark dark:to-bgGradientToCustomButtonDark dark:hover:from-bgGradientFromCustomButtonHoverDark dark:hover:to-bgGradientToCustomButtonHoverDark bg-bgCustomButtonLight hover:bg-bgCustomButtonHoverLight  "
              >
                <ClearIcon fontSize="medium" color="error" />
              </StyledButton>
            </Grid>
          ) : (
            <Grid
              className="flex flex-row justify-center items-center"
              item
              xs={12}
              sm={12}
              md={12}
              lg={1}
            >
              <StyledButton
                onClick={() => {
                  handleRemoveScreenShotItem(id);
                }}
                w-full
                flex
                flex-row
                items-center
                justify-center
                min-h-full
                className="w-full flex flex-row items-center justify-center min-h-full bg-gradient-to-l dark:from-bgGradientFromCustomButtonDark dark:to-bgGradientToCustomButtonDark dark:hover:from-bgGradientFromCustomButtonHoverDark dark:hover:to-bgGradientToCustomButtonHoverDark bg-bgCustomButtonLight hover:bg-bgCustomButtonHoverLight  "
              >
                <AddIcon
                  fontSize="medium"
                  color="primary"
                  className="cursor-pointer"
                  onClick={() => {
                    handleAddScreenShot(id);
                  }}
                />
              </StyledButton>
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Divider variant="fullWidth" className="h-px bg-slate-400" />
          </Grid>
        </Grid>
      </>
    );
  }
);
