import { Grid, Tooltip } from "@mui/material";
import chroma from "chroma-js";
import React, { memo, useEffect } from "react";
import { useState } from "react";

export const SimilarColors = memo(
  ({
    rgbInput,
    rgbToHex,
    hexInput,
    setPalletInput,
    firstSimilar,
    setFirstSimilar,
    secendSimilar,
    setSecondSimilar,
    thirdSimilar,
    setThirdSimilar,
    fourthSimilar,
    setFourthSimilar,
  }) => {
    //initial states

    //handle calcute first similar
    const handleFirstSimilar = () => {
      const scale = chroma.scale([hexInput, "#fff"]).colors(7);
      console.log("scale: ", scale);
      setFirstSimilar(scale[0]);
      setSecondSimilar(scale[1]);
      setThirdSimilar(scale[2]);
      setFourthSimilar(scale[3]);
    };
    useEffect(() => {
      console.log("hexInput: ", hexInput);
    }, []);
    useEffect(() => {
      handleFirstSimilar();
    }, [hexInput]);
    // handle set pallet input
    const handleSetPalletInput = (hexCode) => {
      setPalletInput(hexCode);
    };
    // handle copy text
    const handleCopyHashText = (content) => {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(content);
      } else {
        console.log("Clipboard API not supported");
      }
    };

    return (
      <>
        <Grid container spacing={1} columns={12}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="flex justify-start items-center"
          >
            <span className="text-lg text-slate-100">Similar Colors</span>
          </Grid>

          <Grid
            className="flex justify-start items-center gap-5"
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
          >
            <Tooltip title={firstSimilar} placement="top">
              {" "}
              <div
                onClick={() => {
                  document
                    .getElementsByClassName("container")[0]
                    .scrollTo({ top: 0, behavior: "smooth" });
                  handleSetPalletInput(firstSimilar);
                  handleCopyHashText(firstSimilar);
                }}
                className="cursor-pointer w-12 h-12 rounded-full flex flex-col justify-center items-start pl-2"
                style={{
                  backgroundColor: firstSimilar,
                  border: "0.8px solid gray",
                }}
              ></div>
            </Tooltip>
            <Tooltip title={secendSimilar} placement="top">
              {" "}
              <div
                onClick={() => {
                  document
                    .getElementsByClassName("container")[0]
                    .scrollTo({ top: 0, behavior: "smooth" });

                  handleSetPalletInput(secendSimilar);
                  handleSetPalletInput(secendSimilar);
                }}
                className="cursor-pointer w-12 h-12 rounded-full flex flex-col justify-center items-start pl-2"
                style={{
                  backgroundColor: secendSimilar,
                  border: "0.8px solid gray",
                }}
              ></div>
            </Tooltip>{" "}
            <Tooltip title={thirdSimilar} placement="top">
              <div
                onClick={() => {
                  document
                    .getElementsByClassName("container")[0]
                    .scrollTo({ top: 0, behavior: "smooth" });

                  handleSetPalletInput(thirdSimilar);
                  handleSetPalletInput(thirdSimilar);
                }}
                className="cursor-pointer w-12 h-12 rounded-full flex flex-col justify-center items-start pl-2"
                style={{
                  backgroundColor: thirdSimilar,
                  border: "0.8px solid gray",
                }}
              ></div>
            </Tooltip>{" "}
            <Tooltip title={fourthSimilar} placement="top">
              <div
                onClick={() => {
                  document
                    .getElementsByClassName("container")[0]
                    .scrollTo({ top: 0, behavior: "smooth" });

                  handleSetPalletInput(fourthSimilar);
                  handleSetPalletInput(fourthSimilar);
                }}
                className="cursor-pointer w-12 h-12 rounded-full flex flex-col justify-center items-start pl-2"
                style={{
                  backgroundColor: fourthSimilar,
                  border: "0.8px solid gray",
                }}
              ></div>
            </Tooltip>
          </Grid>
        </Grid>
      </>
    );
  }
);
