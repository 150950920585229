import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { StyledTextField } from "../../../../components/styled-components";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";

export const RedirectFileOrDirectory = memo(
  ({
    oldFilePath,
    setOldFilePath,
    oldDirectoryPath,
    setOldDirectoryPath,
    newFilePath,
    setNewFilePath,
    newDirectoryPath,
    setNewDirectoryPath,
  }) => {
    // for test state
    const [test, setTest] = useState(false);
    useEffect(() => {
      setTest(true);
    }, []);
    return (
      <>
        <Accordion
          className="w-full text-slate-100 rounded-md"
          sx={{
            background:
              "linear-gradient(90deg, rgba(13, 166, 194, 0.23573179271708689) 0%, rgba(38, 36, 80, 0.804359243697479) 100%)",
          }}
        >
          <AccordionSummary
            className="h-14"
            expandIcon={<ExpandMoreIcon className="text-slate-100" />}
          >
            301 Redirect File or Directory
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2} columns={12}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container spacing={1} columns={13}>
                  <Grid item xs={6.5} sm={6.5} md={6.5} lg={3} xl={3}>
                    <StyledTextField
                      size="small"
                      label="Old File Path"
                      value={oldFilePath}
                      onChange={(e) => {
                        setOldFilePath(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6.5} sm={6.5} md={6.5} lg={3} xl={3}>
                    <StyledTextField
                      size="small"
                      label="Old Directory Path"
                      value={oldDirectoryPath}
                      onChange={(e) => {
                        setOldDirectoryPath(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid
                    className="flex justify-center items-center"
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={1}
                    xl={1}
                  >
                    <TrendingFlatIcon className="w-full" fontSize="large" />
                  </Grid>
                  <Grid item xs={6.5} sm={6.5} md={6.5} lg={3} xl={3}>
                    <StyledTextField
                      size="small"
                      label="New File Path"
                      value={newFilePath}
                      onChange={(e) => {
                        setNewFilePath(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6.5} sm={6.5} md={6.5} lg={3} xl={3}>
                    <StyledTextField
                      size="small"
                      label="New Directory Path"
                      value={newDirectoryPath}
                      onChange={(e) => {
                        setNewDirectoryPath(e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </>
    );
  }
);
