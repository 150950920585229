import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  Grid,
  InputAdornment,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { StyledTextField } from "../../../components/styled-components";
import { AccountCircle } from "@mui/icons-material";
import { HexColorPicker } from "react-colorful";
import { use } from "i18next";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { colorList } from "./components/colorList";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import { object } from "yup";
import { TailwindColorsItem } from "./components/TailwindColorsItem";
import { SimilarColors } from "./components/SimilarColors";
import { ThreeBaseColors } from "./components/ThreeBaseColors";
import { ComplementaryColors } from "./components/ComplementaryColors";
import Color from "color";
import ColorPicker, { useColorPicker } from "react-best-gradient-color-picker";
import { GradientChart } from "./components/GradientChart";
import chroma from "chroma-js";
export function ColorsCode() {
  //initial states
  const [firstSimilar, setFirstSimilar] = useState("#9d0000");
  const [secendSimilar, setSecondSimilar] = useState("#cb0000");
  const [thirdSimilar, setThirdSimilar] = useState("#fa3030");
  const [fourthSimilar, setFourthSimilar] = useState("#f47070");
  const [hexInput, setHexInput] = useState("#e12626");
  const [rgbInput, setRgbInput] = useState("rgb(185, 44, 44)");
  const [hslInput, setHslInput] = useState(null);
  const [tailwindInput, setTailwindInput] = useState("red-600");
  const [palletInput, setPalletInput] = useState("#aabbcc");
  const [tailwindColors, setTailwindColors] = useState(
    convertColors(colorList)
  );
  const [firstColor, setFirstColor] = useState(
    chroma(hexInput).brighten().hex()
  );
  const [secondColor, setSecondColor] = useState(
    chroma(hexInput).darken().hex()
  );
  const [r_color, set_r_color] = useState(Color(rgbInput).array()[0]);

  const [g_color, set_g_color] = useState(Color(rgbInput).array()[1]);

  const [b_color, set_b_color] = useState(Color(rgbInput).array()[2]);
  useEffect(() => {
    console.log("Color: ", Color(rgbInput).array()[2]);
  }, [b_color]);
  // handle convert hex to rgb
  function hexToRgb(palletInput) {
    const rgb = [
      ("0x" + palletInput[1] + palletInput[2]) | 0,
      ("0x" + palletInput[3] + palletInput[4]) | 0,
      ("0x" + palletInput[5] + palletInput[6]) | 0,
    ];
    return `rgb(${rgb[0]},${rgb[1]},${rgb[2]})`;
  }
  //handle convert rgb to hex
  function rgbToHex(r, g, b) {
    return "#" + ((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1);
  }
  // handle convert rgb to hsl
  function hexToHsl(hex) {
    return Color(hex).hsl().string();
  }

  // handle copy text
  const handleCopyHashText = (content) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(content);
    } else {
      console.log("Clipboard API not supported");
    }
  };

  //handle custome colors
  function convertColors(colors) {
    const newColors = {};

    Object.keys(colors).forEach((type) => {
      Object.keys(colors[type]).forEach((shade) => {
        const colorCode = colors[type][shade];
        newColors[colorCode] = { type, shade };
      });
    });

    return newColors;
  }

  useEffect(() => {
    setHexInput(palletInput);
    setRgbInput(hexToRgb(palletInput));
    setHslInput(hexToHsl(palletInput));
    if (tailwindColors[palletInput]) {
      setTailwindInput(
        `${tailwindColors[palletInput].type}-${tailwindColors[palletInput].shade}`
      );
    }
  }, [palletInput]);
  //default value
  useEffect(() => {
    setPalletInput("#e12626");
    setHexInput("#e12626");
    setRgbInput(hexToRgb("#e12626"));
    setHslInput(hexToHsl("#e12626"));
  }, []);

  const evenIndex = Object.keys(colorList).filter((item, index) => {
    return index % 2 === 0;
  });

  const oddIndex = Object.keys(colorList).filter((item, index) => {
    return index % 2 !== 0;
  });

  return (
    <>
      <div className="pb-28 w-full">
        <Card
          className={`${
            localStorage.getItem("theme") === "dark-mode"
              ? "card-bg-gradient-dark"
              : "card-bg-gradient-light"
          } w-full`}
        >
          <CardContent>
            <Grid container spacing={4} columns={12}>
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <Grid container spacing={4} columns={12} className="h-full">
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="flex justify-center items-end"
                  >
                    <StyledTextField
                      className="w-full"
                      size="small"
                      value={hexInput}
                      onChange={(e) => {
                        setPalletInput(e.target.value);
                        setHexInput(e.target.value);
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <div
                              className={`w-6 h-6 rounded-full`}
                              style={{ backgroundColor: hexInput }}
                            ></div>{" "}
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <ContentCopyIcon
                              onClick={() => {
                                handleCopyHashText(hexInput);
                              }}
                              fontSize="small"
                              className="text-slate-200 cursor-pointer"
                            />
                          </InputAdornment>
                        ),
                      }}
                      label="Hex"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="flex justify-center items-end"
                  >
                    <StyledTextField
                      className="w-full"
                      size="small"
                      value={rgbInput}
                      onChange={(e) => {
                        const rgbValues = e.target.value
                          .match(/\d+/g)
                          ?.map(Number);
                        setPalletInput(
                          rgbToHex(
                            rgbValues?.[0],
                            rgbValues?.[1],
                            rgbValues?.[2]
                          )
                        );
                        setRgbInput(e.target.value);
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <div
                              className="w-6 h-6 rounded-full"
                              style={{ backgroundColor: rgbInput }}
                            ></div>{" "}
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <ContentCopyIcon
                              onClick={() => {
                                handleCopyHashText(rgbInput);
                              }}
                              fontSize="small"
                              className="text-slate-200 cursor-pointer"
                            />{" "}
                          </InputAdornment>
                        ),
                      }}
                      label="RGB"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="flex justify-center items-end"
                  >
                    <StyledTextField
                      className="w-full"
                      size="small"
                      value={hslInput}
                      onChange={(e) => {
                        setHslInput(e.target.value);
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <div
                              className="w-6 h-6 rounded-full"
                              style={{
                                backgroundColor: hslInput,
                              }}
                            ></div>{" "}
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <ContentCopyIcon
                              onClick={() => {
                                handleCopyHashText(hslInput);
                              }}
                              fontSize="small"
                              className="text-slate-200 cursor-pointer"
                            />{" "}
                          </InputAdornment>
                        ),
                      }}
                      label="HSL"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="flex justify-center items-end"
                  >
                    <StyledTextField
                      className="w-full"
                      size="small"
                      value={tailwindInput}
                      onChange={(e) => {
                        setTailwindInput(e.target.value);

                        if (
                          colorList[e.target.value?.split("-")?.[0]]?.[
                            e.target.value?.split("-")?.[1]
                          ] &&
                          e.target.value.length > 0
                        ) {
                          console.log("ok");

                          setPalletInput(
                            colorList[e.target.value.split("-")[0]][
                              e.target.value.split("-")[1]
                            ]
                          );
                        }
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <div
                              style={{
                                backgroundColor:
                                  colorList[tailwindInput.split("-")[0]]?.[
                                    tailwindInput.split("-")[1]
                                  ],
                              }}
                              className={`w-6 h-6 rounded-full bg-${tailwindInput}`}
                            ></div>{" "}
                            {console.log("tailwindInput: ", tailwindInput)}
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <ContentCopyIcon
                              fontSize="small"
                              className="text-slate-200 cursor-pointer"
                            />{" "}
                          </InputAdornment>
                        ),
                      }}
                      label="Tailwind"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <HexColorPicker
                  className="w-full"
                  color={palletInput}
                  onChange={(e) => {
                    setPalletInput(e);
                  }}
                />{" "}
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <SimilarColors
                  firstSimilar={firstSimilar}
                  setFirstSimilar={setFirstSimilar}
                  secendSimilar={secendSimilar}
                  setSecondSimilar={setSecondSimilar}
                  setThirdSimilar={setThirdSimilar}
                  fourthSimilar={fourthSimilar}
                  setFourthSimilar={setFourthSimilar}
                  thirdSimilar={thirdSimilar}
                  rgbInput={rgbInput}
                  rgbToHex={rgbToHex}
                  hexInput={hexInput}
                  setPalletInput={setPalletInput}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <ThreeBaseColors
                  setPalletInput={setPalletInput}
                  rgbInput={rgbInput}
                  rgbToHex={rgbToHex}
                  r_color={r_color}
                  set_r_color={set_r_color}
                  b_color={b_color}
                  set_b_color={set_b_color}
                  g_color={g_color}
                  set_g_color={set_g_color}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <ComplementaryColors
                  setSecondColor={setSecondColor}
                  secondColor={secondColor}
                  firstColor={firstColor}
                  setFirstColor={setFirstColor}
                  hexInput={hexInput}
                  setPalletInput={setPalletInput}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <GradientChart
                  firstSimilar={hexToRgb(firstSimilar)}
                  setFirstSimilar={setFirstSimilar}
                  secendSimilar={hexToRgb(secendSimilar)}
                  setSecondSimilar={setSecondSimilar}
                  setThirdSimilar={setThirdSimilar}
                  fourthSimilar={hexToRgb(fourthSimilar)}
                  setFourthSimilar={setFourthSimilar}
                  thirdSimilar={hexToRgb(thirdSimilar)}
                  hexInput={hexInput}
                  hexToRgb={hexToRgb}
                  setPalletInput={setPalletInput}
                  firstColor={firstColor}
                  secondColor={secondColor}
                  r_color={r_color}
                  b_color={b_color}
                  g_color={g_color}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Accordion
                  className="w-full text-slate-100 rounded-md"
                  sx={{
                    background: "rgb(29,103,158)",
                    background:
                      "linear-gradient(90deg, rgba(13, 166, 194, 0.23573179271708689) 0%, rgba(38, 36, 80, 0.804359243697479) 100%)",
                  }}
                >
                  <AccordionSummary
                    className="h-16"
                    expandIcon={<ExpandMoreIcon className="text-slate-100" />}
                  >
                    <ColorLensIcon fontSize="medium" />
                    <p className="text-xl ml-2">Tailwind colors</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={4} columns={12}>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Grid container spacing={1} columns={12}>
                          {oddIndex.map((item) => {
                            return (
                              <>
                                <TailwindColorsItem
                                  item={item}
                                  tailwindColors={tailwindColors}
                                  setPalletInput={setPalletInput}
                                />
                              </>
                            );
                          })}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Grid container spacing={1} columns={12}>
                          {evenIndex.map((item) => {
                            return (
                              <>
                                <TailwindColorsItem
                                  item={item}
                                  tailwindColors={tailwindColors}
                                  setPalletInput={setPalletInput}
                                />
                              </>
                            );
                          })}
                        </Grid>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </>
  );
}
