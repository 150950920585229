import { Grid, TextField } from "@mui/material";
import React from "react";
import {
  StyledAutocomplete,
  StyledTextField,
} from "../../../../components/styled-components";

export function WifiForm({
  setNetWorkName,
  netWorkName,
  setNetWorkPassword,
  netWorkPassword,
  netWorkType,
  setNetWorkType,
  setQrCodeValue,
  qrCodeValueStructures,
}) {
  // Type of encryption options
  const encryptionOptions = [
    { id: 1, label: "WEP" },
    { id: 2, label: "WPA" },
    { id: 3, label: "WPA2" },
    { id: 4, label: "nopass" },
  ];
  return (
    <Grid container spacing={2} columns={12}>
      <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
        <StyledTextField
          value={netWorkName}
          onChange={(e) => {
            setQrCodeValue(qrCodeValueStructures.wifi);
            setNetWorkName(e.target.value);
          }}
          className="w-full"
          label="Network name (SSID)"
          size="small"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
        <StyledTextField
          value={netWorkPassword}
          onChange={(e) => {
            setQrCodeValue(qrCodeValueStructures.wifi);

            setNetWorkPassword(e.target.value);
          }}
          size="small"
          className="w-full"
          label="Network password
"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
        {" "}
        <StyledAutocomplete
          disableClearable
          sx={{
            textAlign: "left",
            width: "100%",
            direction: localStorage.getItem("dir"),
          }}
          defaultValue={{ id: 1, label: "WEP" }}
          value={netWorkType}
          onChange={(e, value) => {
            setQrCodeValue(qrCodeValueStructures.wifi);

            setNetWorkType(value?.label);
          }}
          size="small"
          options={encryptionOptions}
          PaperComponent={({ children }) => (
            <div className={`dark:bg-slate-900 dark:text-slate-300`}>
              {children}
            </div>
          )}
          renderInput={(params) => (
            <TextField
              label="Type of encryption"
              {...params}
              sx={{
                "& .MuiOutlinedInput-root": {
                  color: "#d6d3d1",
                  "& fieldset": {
                    borderColor: "#d6d3d1",
                  },
                  "&:hover fieldset": {
                    borderColor: "white", // change border color on hover
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "#d6d3d1",
                },
                "& .MuiSvgIcon-root": {
                  color: "white", // change icon color
                },
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}
