import {
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  tableCellClasses,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { AddAlertToDomain } from "../../includes/functions";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { lng } from "../../lang/languagesObjects";

export function DevicesPart({ devicesData, helperText }) {
  // for multi language
  const { t } = useTranslation("prints");
  i18next.addResourceBundle("fa", "prints", lng.profile.fa);
  i18next.addResourceBundle("en", "prints", lng.profile.en);
  i18next.addResourceBundle("ar", "prints", lng.profile.ar);
  // initial States
  const { lang } = useContext(AppContext);
  const columns = [
    {
      id: "id",
      label: "#",
      minWidth: 40,
      align: "left",
    },
    {
      id: "domain",
      label: t("deviceTableDomain", { lng: localStorage.getItem("lang") }),
      minWidth: 130,
      align: "left",
    },
    {
      id: "expiration",
      label: t("deviceTableExpiration", { lng: localStorage.getItem("lang") }),
      minWidth: 110,
      align: "left",
    },
    {
      id: "host",
      label: t("deviceTableHost", { lng: localStorage.getItem("lang") }),
      minWidth: 110,
      align: "left",
    },
    {
      id: "isp",
      label: t("deviceTableISP", { lng: localStorage.getItem("lang") }),
      minWidth: 130,
      align: "left",
    },
    {
      id: "status",
      label: t("deviceTableStatus", { lng: localStorage.getItem("lang") }),
      minWidth: 130,
      align: "left",
    },
    {
      id: "verified_at",
      label: t("deviceTableVerifiedAt", { lng: localStorage.getItem("lang") }),
      minWidth: 130,
      align: "left",
    },
  ];
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
      fontSize: 11,
      padding: "6px",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  }));
  return (
    <>
      <TableContainer
        sx={{
          width: "100%",
          maxHeight: "100%",
          overflowY: "auto",
          "& td": { borderBottom: "1px solid #6b7280" },
          "&::-webkit-scrollbar": {
            height: "4px",
          },
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  align={column.align}
                  sx={{
                    minWidth: column.minWidth,
                    flexShrink: 1,
                    padding: "7px",
                    fontWeight: "bold",
                    fontSize: 12,
                    color: "#e7e5e4",
                    borderBottom: "1px solid #6b7280",
                  }}
                  className="overflow-hidden text-ellipsis whitespace-nowrap"
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {devicesData ? (
            <TableBody sx={{ height: "100%", width: "100%" }}>
              {devicesData.map((item, index) => {
                return (
                  <StyledTableRow hover>
                    {columns.map((column) => {
                      return (
                        <StyledTableCell align={column.align}>
                          {column.id === "id" ? (
                            <Tooltip
                              PopperProps={{ disablePortal: true }}
                              placement="left-start"
                              title={`Created: ${
                                item.created_at?.split(" ")[0]
                              }`}
                            >
                              <p className="text-slate-200">{index + 1}</p>
                            </Tooltip>
                          ) : column.id === "domain" ? (
                            <div className="flex flex-row justify-start items-center gap-2">
                              {item.domain && (
                                <AddAlertToDomain url={item.domain} />
                              )}
                              <Tooltip
                                placement="bottom-end"
                                title={`${item.type}`}
                              >
                                <p className="text-slate-200">{item.domain}</p>
                              </Tooltip>
                            </div>
                          ) : column.id === "expiration" ? (
                            <p className={"text-slate-200"}>
                              {!item.expiration
                                ? t("notRegistered", {
                                    lng: localStorage.getItem("lang"),
                                  })
                                : item.expiration}
                            </p>
                          ) : column.id === "host" ? (
                            <p
                              className={
                                "text-slate-200 max-w-40 overflow-hidden text-ellipsis whitespace-nowrap"
                              }
                            >
                              {!item.host
                                ? t("notRegistered", {
                                    lng: localStorage.getItem("lang"),
                                  })
                                : item.host}
                            </p>
                          ) : column.id === "isp" ? (
                            <p className={"text-slate-200"}>
                              {!item.isp
                                ? t("notRegistered", {
                                    lng: localStorage.getItem("lang"),
                                  })
                                : item.isp}
                            </p>
                          ) : column.id === "status" ? (
                            <Chip
                              size="small"
                              variant="filled"
                              color="primary"
                              sx={{
                                color: "var(--color-text)",
                              }}
                              label={`${
                                item.status === 1
                                  ? t("activeDevice", {
                                      lng: localStorage.getItem("lang"),
                                    })
                                  : t("inactiveDevice", {
                                      lng: localStorage.getItem("lang"),
                                    })
                              }`}
                            />
                          ) : (
                            column.id === "verified_at" && (
                              <p className={"text-slate-200"}>
                                {!item.verified_at
                                  ? t("notRegistered", {
                                      lng: localStorage.getItem("lang"),
                                    })
                                  : item.verified_at}
                              </p>
                            )
                          )}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                );
              })}
            </TableBody>
          ) : (
            <TableBody className="w-full h-full ">
              <TableRow>
                <TableCell colSpan={columns.length} sx={{ textAlign: "start" }}>
                  {helperText}
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
}
