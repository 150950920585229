import React, { memo, useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Grid,
} from "@mui/material";

export const PreventViewing = memo(
  ({ preventViewingInput, setPreventViewingInput }) => {
    // for test state
    const [test, setTest] = useState(false);
    useEffect(() => {
      setTest(true);
    }, []);
    return (
      <>
        <Accordion
          className="w-full text-slate-100 rounded-md"
          sx={{
            background: "rgb(29,103,158)",
            background:
              "linear-gradient(90deg, rgba(13, 166, 194, 0.23573179271708689) 0%, rgba(38, 36, 80, 0.804359243697479) 100%)",
          }}
        >
          <AccordionSummary
            className="h-14"
            expandIcon={<ExpandMoreIcon className="text-slate-100" />}
          >
            Prevent viewing of .htaccess file
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={1} columns={12}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <FormControlLabel
                  label="Enable"
                  control={
                    <Checkbox
                      checked={preventViewingInput}
                      onChange={(e) => setPreventViewingInput(e.target.checked)}
                    />
                  }
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </>
    );
  }
);
