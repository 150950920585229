import { Card, CardContent, Grid, Typography } from "@mui/material";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import * as yup from "yup";
import ClearIcon from "@mui/icons-material/Clear";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import "../node_modules/highlight.js/styles/atom-one-dark.css";
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
} from "react-virtualized";
import {
  StyledAutocomplete,
  StyledButton,
  StyledTextField,
} from "./components/styled-components";
import SyntaxHighlighter from "react-syntax-highlighter";
import { monokai } from "react-syntax-highlighter/dist/esm/styles/hljs";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import { ProgressViewSource } from "./loading-components/ProgressPingWorld";
import { db } from "./db";
import { AppContext } from "./context/AppContext";
import { useTranslation } from "react-i18next";
import { lng } from "./lang/languagesObjects";
import i18next from "i18next";
import { theme, themeMode } from "./index";
import { useApi } from "./customAxios";

export function ViewSourcePage({ darkMode, language }) {
  // for multi language
  const { t } = useTranslation("prints");
  i18next.addResourceBundle("fa", "prints", lng.viewSource.fa);
  i18next.addResourceBundle("en", "prints", lng.viewSource.en);
  i18next.addResourceBundle("ar", "prints", lng.viewSource.ar);

  //items from section html code
  const sections = [
    { section: "HTML", id: 1 },
    { section: "HEAD", id: 2 },
    { section: "BODY", id: 3 },
    { section: "FOOTER", id: 4 },
  ];
  // initial states
  const api = useApi();
  const { lang } = useContext(AppContext);
  const { sidebar } = useContext(AppContext);
  const [htmlCode, setHtmlCode] = useState("");
  const [url, setUrl] = useState(null);
  const [submit, setSubmit] = useState(false);
  const [textHelper, setTextHelper] = useState(
    <p style={{ color: "#f5f5f4" }}>
      {t("noAddress", { lng: localStorage.getItem("lang") })}
    </p>
  );
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [shouldFetchData, setShouldFetchData] = useState(false);
  const [TextFieldError, setTextfieldError] = useState(false);
  const [storedValues, setStoredValues] = useState(
    JSON.parse(localStorage.getItem("inputValues")) || []
  );
  const inputRef = useRef("");
  const searchRef = useRef(null);
  const [sectionSource, setSectionSource] = useState("HTML");
  const [formattedHtmlCode, setFormattedHtmlCode] = useState(null);
  const [allowSearch, setAllowSearch] = useState(false);

  // Add this function to filter the HTML code based on the selected section
  const filterHtmlCodeBySection = (htmlCode, section) => {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(htmlCode, "text/html");
    const elements = {
      HTML: htmlDoc.documentElement,
      HEAD: htmlDoc.head,
      BODY: htmlDoc.body,
      FOOTER: htmlDoc.querySelector("footer"),
    };
    const beautify = require("js-beautify").html;
    const formattedCode = beautify(
      elements[section] ? elements[section].outerHTML : "",
      {
        indent_size: 2,
        space_in_empty_paren: true,
      }
    );

    return formattedCode;
  };

  useEffect(() => {
    console.log("shouldFetchData", shouldFetchData);
    if (shouldFetchData) {
      db.viewSource
        .where("domain")
        .equals(url)
        .first((result) => {
          if (result) {
            console.log("Cache hit!", result);
            setSubmit(false);
            setHtmlCode(result.htmlCode);
            setFormattedHtmlCode(
              filterHtmlCodeBySection(result.htmlCode, sectionSource)
            );
            setDisableSubmit(false);
          } else {
            console.log("Cache miss!");
            api
              .post("/tools/view-source", { url })
              .then((res) => {
                db.viewSource.clear().then(() => {
                  db.viewSource.add({
                    domain: url,
                    htmlCode: res.data.body,
                    expire: Date.now(),
                  });
                });
                setSubmit(false);
                setHtmlCode(res.data.body);
                setFormattedHtmlCode(
                  filterHtmlCodeBySection(res.data.body, sectionSource)
                );
                setDisableSubmit(false);
              })
              .catch((err) => {
                console.log("viewsource err: ", err);
                setTextHelper(
                  <p style={{ color: "#f5f5f4" }}>
                    {t("errorText", { lng: localStorage.getItem("lang") })}
                  </p>
                );
                setHtmlCode(null);
                setDisableSubmit(false);
                setSubmit(false);
              });
          }
        });
    } else {
      setDisableSubmit(false);
    }
  }, [submit, shouldFetchData, sectionSource]);

  // handle local storage
  useEffect(() => {
    const savedValues = localStorage.getItem("inputValues");
    if (savedValues) {
      setStoredValues(JSON.parse(savedValues));
    }
    //  for active sidebar & navigation item
    sidebar.setActiveItem("/view-source");
  }, []);

  const handleDeleteOption = (option) => {
    const updatedValues = storedValues.filter(
      (value) => value !== option.value
    );
    const updatedValuesString = JSON.stringify(updatedValues);
    localStorage.setItem("inputValues", updatedValuesString);
    setStoredValues(updatedValues);
  };

  const renderOption = (props, option, state) => (
    <li
      {...props}
      className="flex flex-row items-center justify-between mx-3 my-3"
    >
      {option.label}
      <button
        style={{ fontWeight: "bold", color: "#646666" }}
        onClick={(e) => {
          e.stopPropagation();
          handleDeleteOption(option);
        }}
      >
        <ClearIcon fontSize="small" />
      </button>
    </li>
  );

  const options = storedValues
    ? storedValues.map((value) => ({ label: value, value })).reverse()
    : [];

  const handleSubmit = useCallback(() => {
    setHtmlCode(null);
    validation();
    setSubmit(true);
    const inputValue = inputRef.current.value;
    if (inputValue.startsWith("https://")) {
      setUrl(inputValue);
    } else {
      setUrl("https://" + inputValue);
    }
    // Add this line to call searchItem() after handleSubmit()
    searchItem();
  }, [inputRef, setHtmlCode, setSubmit, setUrl]);

  //validation schema
  const validationSchema = yup.object().shape({
    url: yup
      .string()
      .url("The entered address is not valid")
      .required("Filling this field is mandatory"),
  });
  //validation's function
  function validation() {
    const inputValue = inputRef.current.value;
    validationSchema
      .validate(
        {
          url: inputValue.startsWith("https://")
            ? inputValue
            : "https://" + inputValue,
        },
        { abortEarly: false }
      )
      .then((validData) => {
        setTextfieldError(false);
        setDisableSubmit(true);
        setShouldFetchData(true);
        setTextHelper(<ProgressViewSource />);
      })
      .catch((error) => {
        setTextfieldError(true);
        setDisableSubmit(false);
        setShouldFetchData(false);
        setTextHelper(t("noAddress", { lng: localStorage.getItem("lang") }));
      });
  }

  //handle guideText from textField component
  const guideText = useMemo(() => {
    if (TextFieldError === true) {
      return (
        <p
          style={{
            padding: "0px",
            margin: "0px",
            fontSize: "10px",
            display: "flex",
            justifyContent: "end",
          }}
        >
          {"The entered values ​​are not valid"}
        </p>
      );
    }
  }, [TextFieldError]);

  const rowHeight = 0;
  const filteredHtmlCode = formattedHtmlCode
    ?.split("\n")
    .filter((line) => line.trim() !== "");

  const rowCount = filteredHtmlCode?.length;

  const cache = new CellMeasurerCache({
    fixedWidth: true,
    minHeight: rowHeight,
  });
  const customStyle = {
    backgroundColor: "transparent", // or any other color you prefer
    lineHeight: "10px",
    Opacity: 0.6,
  };

  const rowRenderer = ({ index, key, style, parent }) => {
    const content = (
      <SyntaxHighlighter style={monokai} customStyle={customStyle}>
        {filteredHtmlCode?.[index]?.toString()}
      </SyntaxHighlighter>
    );

    return (
      searchItem(),
      (
        <CellMeasurer
          cache={cache}
          columnIndex={0}
          key={key}
          parent={parent}
          rowIndex={index}
        >
          <div
            style={{
              ...style,
            }}
            className="pl-3 pr-3 flex flex-row justify-start items-center gap-2"
          >
            <span className="text-slate-300 text-xs">{index + 1}</span>
            {content}
          </div>
        </CellMeasurer>
      )
    );
  };
  //handle search
  function searchItem() {
    let spans = document.querySelectorAll(".view-source span");

    spans.forEach((span) => {
      if (span.textContent === searchRef.current.value.trim()) {
        span.style.backgroundColor = "yellow";
      } else {
        span.style.backgroundColor = "transparent";
      }
    });
  }

  return (
    <>
      <div
        className="flex flex-col items-start justify-start gap-2"
        style={{ direction: localStorage.getItem("dir") }}
      >
        <Card
          className={
            localStorage.getItem("theme") === "dark-mode"
              ? "card-bg-gradient-dark"
              : "card-bg-gradient-light"
          }
          sx={{
            width: "100%",
            padding: "16px 14px",
          }}
        >
          <Grid
            container
            spacing={1}
            columns={12}
            className="flex flex-row justify-center items-center"
          >
            <Grid item xs={8} sm={8} md={4} lg={4}>
              <StyledAutocomplete
                disableClearable
                size="small"
                freeSolo
                options={options}
                PaperComponent={({ children }) => (
                  <div
                    className={
                      darkMode
                        ? `dark:bg-slate-900 dark:text-slate-300`
                        : `bg-slate-50 text-slate-700`
                    }
                  >
                    {children}
                  </div>
                )}
                renderInput={(params) => (
                  <StyledTextField
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        handleSubmit();
                        const inputValue = inputRef.current.value;

                        if (inputValue.trim() !== "") {
                          const existingValues = new Set(storedValues);
                          existingValues.add(inputValue);
                          const updatedValues = Array.from(existingValues);
                          setStoredValues(updatedValues);
                          localStorage.setItem(
                            "inputValues",
                            JSON.stringify(updatedValues)
                          );
                        }
                      }
                    }}
                    helperText={guideText}
                    error={TextFieldError}
                    inputRef={inputRef}
                    label={t("enterUrl", { lng: localStorage.getItem("lang") })}
                    {...params}
                    sx={{
                      width: "100%",
                      direction: localStorage.getItem("dir"),
                    }}
                  />
                )}
                renderOption={renderOption}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={1} lg={1}>
              <StyledButton
                className="bg-gradient-to-l dark:from-bgGradientFromCustomButtonDark dark:to-bgGradientToCustomButtonDark dark:hover:from-bgGradientFromCustomButtonHoverDark dark:hover:to-bgGradientToCustomButtonHoverDark bg-bgCustomButtonLight hover:bg-bgCustomButtonHoverLight  "
                style={{
                  color: "#e7e5e4",
                  border: "none",
                  width: "100%",
                }}
                size="small"
                disabled={disableSubmit}
                onClick={() => {
                  handleSubmit();
                  const inputValue = inputRef.current.value;

                  if (inputValue.trim() !== "") {
                    const existingValues = new Set(storedValues);
                    existingValues.add(inputValue);
                    const updatedValues = Array.from(existingValues);
                    setStoredValues(updatedValues);
                    localStorage.setItem(
                      "inputValues",
                      JSON.stringify(updatedValues)
                    );
                  }
                }}
                variant="contained"
              >
                <ContentPasteSearchIcon fontSize="medium" />
              </StyledButton>
            </Grid>
            <Grid item xs={5} sm={5} md={3} lg={3}>
              <StyledTextField
                onChange={(e) => {}}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    allowSearch ? setAllowSearch(false) : setAllowSearch(true);
                  }
                }}
                label={t("search", { lng: localStorage.getItem("lang") })}
                inputRef={searchRef}
                size="small"
                sx={{
                  width: "100%",
                  direction: localStorage.getItem("dir"),
                }}
              />
            </Grid>
            <Grid item xs={3} sm={3} md={2} lg={2}>
              {" "}
              <StyledAutocomplete
                value={sectionSource}
                onChange={(e, value) => {
                  setSectionSource(value);
                }}
                size="small"
                disablePortal
                options={sections.map((item) => {
                  return item.section;
                })}
                noOptionsText={
                  <Typography style={{ color: darkMode ? "#d6d3d1" : "black" }}>
                    no option
                  </Typography>
                }
                PaperComponent={({ children }) => (
                  <div
                    style={{ fontSize: "13px" }}
                    className={
                      darkMode
                        ? `dark:bg-slate-900 dark:text-slate-300`
                        : `bg-slate-50 text-slate-700`
                    }
                  >
                    {children}
                  </div>
                )}
                renderInput={(params) => (
                  <StyledTextField
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        color: darkMode ? "#d6d3d1" : "black",
                        "& fieldset": {
                          borderColor: "#d6d3d1",
                        },
                        "&:hover fieldset": {
                          borderColor: darkMode ? "white" : "black", // change border color on hover
                        },
                        "& .MuiSvgIcon-root": {
                          color: darkMode ? "white" : "black", // change icon color
                        },
                      },
                    }}
                    {...params}
                    label=""
                  />
                )}
              />
            </Grid>

            <Grid item xs={4} sm={4} md={2} lg={2}>
              <StyledButton
                className="bg-gradient-to-l dark:from-bgGradientFromCustomButtonDark dark:to-bgGradientToCustomButtonDark dark:hover:from-bgGradientFromCustomButtonHoverDark dark:hover:to-bgGradientToCustomButtonHoverDark bg-bgCustomButtonLight hover:bg-bgCustomButtonHoverLight  "
                size="large"
                variant="contained"
                color="primary"
                onClick={() => {
                  allowSearch ? setAllowSearch(false) : setAllowSearch(true);
                }}
                style={{
                  width: "100%",
                  color: "#e7e5e4",
                  border: "none",
                }}
              >
                <ManageSearchIcon fontSize="medium" />
              </StyledButton>
            </Grid>
          </Grid>
        </Card>
        <Card
          className={`w-full ${
            localStorage.getItem("theme") === "dark-mode"
              ? "card-bg-gradient-dark"
              : "card-bg-gradient-light"
          }`}
        >
          <CardContent>
            <div className="w-full flex flex-col items-center justify-center gap-0 ">
              <div className=" w-full ">
                {htmlCode ? (
                  <div
                    style={{
                      height: "610px",
                    }}
                    className="tabsMargin w-full mt-px rounded-lg view-source "
                  >
                    {formattedHtmlCode &&
                      typeof formattedHtmlCode === "string" && (
                        <AutoSizer>
                          {({ width, height }) => {
                            return (
                              <List
                                style={{
                                  WebkitBoxShadow: darkMode
                                    ? "0px 0px 2px 0.5px rgba(245, 245, 244, 0.75)"
                                    : " 0px 0px 2px 0.5px rgba(120,113,108,1)",
                                  MozBoxShadow: darkMode
                                    ? "0px 0px 2px 0.5px rgba(245, 245, 244, 0.75)"
                                    : " 0px 0px 2px 0.5px rgba(120,113,108,1)",
                                  boxShadow: darkMode
                                    ? "0px 0px 2px 0.5px rgba(245, 245, 244, 0.75)"
                                    : "0px 0px 2px 0.5px rgba(120,113,108,1)",
                                  borderRadius: "9px",
                                }}
                                width={width}
                                height={height}
                                rowCount={rowCount}
                                rowHeight={35}
                                rowRenderer={rowRenderer}
                                overscanRowCount={3}
                              />
                            );
                          }}
                        </AutoSizer>
                      )}
                  </div>
                ) : (
                  <div className="flex flex-row items-center justify-center w-full h-full ">
                    {textHelper}
                  </div>
                )}
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </>
  );
}
