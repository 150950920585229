import { Divider, Grid, InputAdornment } from "@mui/material";
import React, { memo, useState } from "react";
import {
  StyledButton,
  StyledTextField,
} from "../../../../../../components/styled-components";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";

export const IconsItem = memo(
  ({ id, handleRemoveIcon, iconKeys, jsonKeys, index, handleAddIcons }) => {
    const [url, setUrl] = useState(null);
    const [type, setType] = useState(null);
    const [size, setSize] = useState(null);

    return (
      <>
        <Grid container spacing={1} columns={12}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <StyledTextField
              value={url}
              onChange={(e) => {
                jsonKeys.current.icons[index].data.url = e.target.value;
                setUrl(e.target.value);
              }}
              size="small"
              label="URL"
              className="w-full"
              placeholder="homescreen.png"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            {" "}
            <StyledTextField
              value={size}
              onChange={(e) => {
                jsonKeys.current.icons[index].data.sizes = e.target.value;
                setSize(e.target.value);
              }}
              size="small"
              label="Sizes"
              className="w-full"
              placeholder="192*192"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            {" "}
            <StyledTextField
              value={type}
              onChange={(e) => {
                jsonKeys.current.icons[index].data.type = e.target.value;
                setType(e.target.value);
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end"></InputAdornment>,
              }}
              size="small"
              label="Type"
              className="w-full"
              placeholder="image/png"
            />
          </Grid>
          {index !== 0 ? (
            <Grid
              className="flex flex-row justify-center items-center"
              item
              xs={12}
              sm={12}
              md={1}
              lg={1}
            >
              <StyledButton
                className="w-full flex flex-row items-center justify-center min-h-full  bg-gradient-to-l dark:from-bgGradientFromCustomButtonDark dark:to-bgGradientToCustomButtonDark dark:hover:from-bgGradientFromCustomButtonHoverDark dark:hover:to-bgGradientToCustomButtonHoverDark bg-bgCustomButtonLight hover:bg-bgCustomButtonHoverLight  "
                onClick={() => {
                  handleRemoveIcon(id);
                }}
              >
                <ClearIcon fontSize="medium" color="error" />
              </StyledButton>
            </Grid>
          ) : (
            <Grid
              className="flex flex-row justify-center items-center"
              item
              xs={12}
              sm={12}
              md={1}
              lg={1}
            >
              <StyledButton
                className="w-full flex flex-row items-center justify-center min-h-full  bg-gradient-to-l dark:from-bgGradientFromCustomButtonDark dark:to-bgGradientToCustomButtonDark dark:hover:from-bgGradientFromCustomButtonHoverDark dark:hover:to-bgGradientToCustomButtonHoverDark bg-bgCustomButtonLight hover:bg-bgCustomButtonHoverLight  "
                onClick={() => {
                  handleRemoveIcon(id);
                }}
              >
                <AddIcon
                  onClick={handleAddIcons}
                  fontSize="medium"
                  color="primary"
                  className="cursor-pointer"
                />
              </StyledButton>
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Divider variant="fullWidth" className="h-px bg-slate-400" />
          </Grid>
        </Grid>
      </>
    );
  }
);
