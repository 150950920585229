import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { StyledTextField } from "../../../../components/styled-components";

export const RewriteTo = memo(({ setRewriteTo, rewriteTo }) => {
  // for test state
  const [test, setTest] = useState(false);
  useEffect(() => {
    setTest(true);
  }, []);
  return (
    <>
      <Accordion
        className="w-full text-slate-100 rounded-md"
        sx={{
          background: "rgb(29,103,158)",
          background:
            "linear-gradient(90deg, rgba(13, 166, 194, 0.23573179271708689) 0%, rgba(38, 36, 80, 0.804359243697479) 100%)",
        }}
      >
        <AccordionSummary
          className="h-14"
          expandIcon={<ExpandMoreIcon className="text-slate-100" />}
        >
          Rewrite to www
        </AccordionSummary>
        <AccordionDetails>
          <StyledTextField
            value={rewriteTo}
            onChange={(e) => setRewriteTo(e.target.value)}
            size="small"
            label="Domain Name"
            className="w-full"
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
});
