import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { RobotComponent } from "./components/RobotComponent";
import { useApi } from "../../../../../customAxios";

export const BlockBots = memo(({ flagURL, blockBotList, setBlockBotList }) => {
  // inital states
  const api = useApi();
  const [loading, setloading] = useState(true);
  const [robotList, setRobotList] = useState(null);
  //handle get robots list
  useEffect(() => {
    api
      .get("/tools/seo/robots/list")
      .then((res) => {
        if (!res.data.status) {
          console.log("robot status false-> ", res);
        }
        setloading(false);
        setRobotList(res.data.data);
        console.log("robots list res", res);
      })
      .catch((err) => {
        console.log("robots err", err);
        setloading(false);
      });
  }, []);
  return (
    <>
      <Accordion
        className="w-full text-slate-100 rounded-md"
        sx={{
          background:
            "linear-gradient(90deg, rgba(13, 166, 194, 0.23573179271708689) 0%, rgba(38, 36, 80, 0.804359243697479) 100%)",
        }}
      >
        <AccordionSummary
          className="h-14"
          expandIcon={<ExpandMoreIcon className="text-slate-100" />}
        >
          Block bots
        </AccordionSummary>
        <AccordionDetails>
          {robotList ? (
            <List
              className="w-full text-slate-100 bg-gray-500   rounded-xl"
              sx={{ maxHeight: "350px", overflowY: "auto" }}
            >
              {robotList.map((item) => {
                return (
                  <RobotComponent
                    flagURL={flagURL}
                    id={item.id}
                    name={item.name}
                    logo={item.logo}
                    keyBots={item.key}
                    setBlockBotList={setBlockBotList}
                    blockBotList={blockBotList}
                  />
                );
              })}
            </List>
          ) : (
            "wait..."
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
});
