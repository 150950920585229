import axios from "axios";
import { handleAutoLogOut } from "./includes/functions";
import { useNavigate } from "react-router-dom";

export const useApi = () => {
  const navigate = useNavigate();
  let api = axios.create({
    baseURL: "https://console.helpix.app/api/v1",
    headers: {
      Authorization: `Bearer ${
        window.localStorage.getItem("user") &&
        JSON.parse(window.localStorage.getItem("user")).user.token
      }`,
      Domain: window.location.hostname,
    },
  });
  api.interceptors.response.use(
    (response) => response,
    (error) => {
      // whatever you want to do with the error
      if (error.response?.status === 511) {
        handleAutoLogOut(navigate);
      }
      throw error;
    }
  );

  return api;
};
