import { Card, CardActionArea, CardContent, Grid } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DnsIcon from "@mui/icons-material/Dns";
import TerminalRoundedIcon from "@mui/icons-material/TerminalRounded";
import HttpsOutlinedIcon from "@mui/icons-material/HttpsOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import HttpOutlinedIcon from "@mui/icons-material/HttpOutlined";
import NetworkCheckOutlinedIcon from "@mui/icons-material/NetworkCheckOutlined";
import WifiFindOutlinedIcon from "@mui/icons-material/WifiFindOutlined";
import QueryStatsOutlinedIcon from "@mui/icons-material/QueryStatsOutlined";
import RssFeedOutlinedIcon from "@mui/icons-material/RssFeedOutlined";
import SettingsInputCompositeOutlinedIcon from "@mui/icons-material/SettingsInputCompositeOutlined";
import LinkRoundedIcon from "@mui/icons-material/LinkRounded";
import WifiTetheringErrorRoundedIcon from "@mui/icons-material/WifiTetheringErrorRounded";
import DomainAddRoundedIcon from "@mui/icons-material/DomainAddRounded";
import MoveDownRoundedIcon from "@mui/icons-material/MoveDownRounded";
import ReceiptRoundedIcon from "@mui/icons-material/ReceiptRounded";
import SmartToyOutlinedIcon from "@mui/icons-material/SmartToyOutlined";
import AccountTreeRoundedIcon from "@mui/icons-material/AccountTreeRounded";
import TabUnselectedIcon from "@mui/icons-material/TabUnselected";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import SpeedIcon from "@mui/icons-material/Speed";
import CodeIcon from "@mui/icons-material/Code";
import EngineeringIcon from "@mui/icons-material/Engineering";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import DataObjectIcon from "@mui/icons-material/DataObject";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import VerifiedIcon from "@mui/icons-material/Verified";
import RouteIcon from "@mui/icons-material/Route";
import HelpIcon from "@mui/icons-material/Help";
import EditLocationIcon from "@mui/icons-material/EditLocation";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";
import LockIcon from "@mui/icons-material/Lock";
import SourceIcon from "@mui/icons-material/Source";
import QrCodeIcon from "@mui/icons-material/QrCode";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import { AppContext } from "../context/AppContext";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import TagIcon from "@mui/icons-material/Tag";
import { lng } from "../lang/languagesObjects";
import TransformIcon from "@mui/icons-material/Transform";
import FormatPaintIcon from "@mui/icons-material/FormatPaint";
import SensorOccupiedIcon from "@mui/icons-material/SensorOccupied";
export function Home({ setProtocols, language, darkMode, flagURL }) {
  // for multi languages
  const { t } = useTranslation("prints");
  i18next.addResourceBundle("fa", "prints", lng.home.fa);
  i18next.addResourceBundle("en", "prints", lng.home.en);
  i18next.addResourceBundle("ar", "prints", lng.home.ar);

  //initial states
  const { lang } = useContext(AppContext);
  const { sidebar } = useContext(AppContext);
  const { loginDialog } = useContext(AppContext);
  const navigate = useNavigate();
  const { addAlertIconContext } = useContext(AppContext);
  return (
    <>
      {/* { */}
      {/* addAlertIconContext.showHome ? ( */}
      <div className="" style={{ direction: localStorage.getItem("dir") }}>
        <div className="h-[100px] bg-slate-100 rounded-2xl flex justify-center items-center mb-10">
          <img
            className="w-full object-none h-full rounded-2xl"
            src={"./assets/banner/banner-1.jpg"}
          />
        </div>
        <div className="mb-10">
          <div className="flex flex-row items-end justify-start gap-1 mb-2">
            <span className="font-bold  text-base">General Service</span>
          </div>
          <Grid container columns={12} spacing={3}>
            <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
              <Card
                className="background-position bg-bgHomeItemsLight dark:bg-bgHomeItemsDark "
                sx={{
                  backgroundImage:
                    "url('./assets/media/pattern/b-" +
                    (Math.round(Math.random() * 9) + 1) +
                    ".png')",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "60% auto",
                  color: "aliceblue",
                  maxWidth: 400,
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "end",
                  height: 70,
                }}
              >
                <CardActionArea
                  onClick={() => {
                    if (localStorage.getItem("user")) {
                      navigate("/alert-center");
                      sidebar.setActiveItem("/");
                    } else {
                      // navigate("/profile");
                      loginDialog.setShowLoginComponent(true);
                      loginDialog.setPathToMove("/alert-center");
                    }
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    height: "100%",
                  }}
                >
                  <WifiTetheringErrorRoundedIcon fontSize="large" />
                  <CardContent sx={{ padding: "0px", margin: "0px" }}>
                    <div className="flex justify-center items-center">
                      <span className="font-bold text-xs">Alert center</span>
                    </div>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>{" "}
            <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
              <Card
                className="background-position bg-bgHomeItemsLight dark:bg-bgHomeItemsDark "
                sx={{
                  backgroundImage:
                    "url('./assets/media/pattern/b-" +
                    (Math.round(Math.random() * 9) + 1) +
                    ".png')",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "60% auto",
                  color: "aliceblue",
                  maxWidth: 400,
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "end",
                  height: 70,
                }}
              >
                <CardActionArea
                  onClick={() => {
                    if (localStorage.getItem("user")) {
                      navigate("/hash-generator");
                      sidebar.setActiveItem("/");
                    } else {
                      loginDialog.setShowLoginComponent(true);
                      loginDialog.setPathToMove("/hash-generator");
                    }
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    height: "100%",
                  }}
                >
                  <TagIcon fontSize="large" />
                  <CardContent sx={{ padding: "0px", margin: "0px" }}>
                    <div className="flex justify-center items-center">
                      <span className="font-bold text-xs">Hash</span>
                    </div>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>{" "}
            <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
              <Card
                className="background-position bg-bgHomeItemsLight dark:bg-bgHomeItemsDark "
                sx={{
                  backgroundImage:
                    "url('./assets/media/pattern/b-" +
                    (Math.round(Math.random() * 9) + 1) +
                    ".png')",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "60% auto",
                  color: "aliceblue",
                  maxWidth: 400,
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "end",
                  height: 70,
                }}
              >
                <CardActionArea
                  onClick={() => {
                    if (localStorage.getItem("user")) {
                      navigate("/ip-convertor");
                      sidebar.setActiveItem("/");
                    } else {
                      loginDialog.setShowLoginComponent(true);
                      loginDialog.setPathToMove("/ip-convertor");
                    }
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    height: "100%",
                  }}
                >
                  <TransformIcon fontSize="large" />
                  <CardContent sx={{ padding: "0px", margin: "0px" }}>
                    <div className="flex justify-center items-center">
                      <span className="font-bold text-xs">Conver IP</span>
                    </div>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
              <Card
                className="background-position bg-bgHomeItemsLight dark:bg-bgHomeItemsDark "
                sx={{
                  backgroundImage:
                    "url('./assets/media/pattern/b-" +
                    (Math.round(Math.random() * 9) + 1) +
                    ".png')",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "60% auto",
                  color: "aliceblue",
                  maxWidth: 400,
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "end",
                  height: 70,
                }}
              >
                <CardActionArea
                  onClick={() => {
                    if (localStorage.getItem("user")) {
                      navigate("/qrcode-generator");
                      sidebar.setActiveItem("/");
                    } else {
                      loginDialog.setShowLoginComponent(true);
                      loginDialog.setPathToMove("/qrcode-generator");
                    }
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    height: "100%",
                  }}
                >
                  <QrCodeIcon fontSize="large" />
                  <CardContent sx={{ padding: "0px", margin: "0px" }}>
                    <div className="flex justify-center items-center">
                      <span className="font-bold text-xs">QR Code</span>
                    </div>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>{" "}
            <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
              <Card
                className="background-position bg-bgHomeItemsLight dark:bg-bgHomeItemsDark "
                sx={{
                  backgroundImage:
                    "url('./assets/media/pattern/b-" +
                    (Math.round(Math.random() * 9) + 1) +
                    ".png')",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "60% auto",
                  color: "aliceblue",
                  maxWidth: 400,
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "end",
                  height: 70,
                }}
              >
                <CardActionArea
                  onClick={() => {
                    if (localStorage.getItem("user")) {
                      navigate("/binary-code");
                      sidebar.setActiveItem("/");
                    } else {
                      loginDialog.setShowLoginComponent(true);
                      loginDialog.setPathToMove("/binary-code");
                    }
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    height: "100%",
                  }}
                >
                  <TextSnippetIcon fontSize="large" />
                  <CardContent sx={{ padding: "0px", margin: "0px" }}>
                    <div className="flex justify-center items-center">
                      <span className="font-bold text-xs">Binary Code</span>
                    </div>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
              <Card
                className="background-position bg-bgHomeItemsLight dark:bg-bgHomeItemsDark "
                sx={{
                  backgroundImage:
                    "url('./assets/media/pattern/b-" +
                    (Math.round(Math.random() * 9) + 1) +
                    ".png')",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "60% auto",
                  color: "aliceblue",
                  maxWidth: 400,
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "end",
                  height: 70,
                }}
              >
                <CardActionArea
                  onClick={() => {
                    navigate("/info");
                    setProtocols("info");
                    sidebar.setActiveItem("/");
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    height: "100%",
                  }}
                >
                  <HelpIcon fontSize="large" />
                  <CardContent sx={{ padding: "0px", margin: "0px" }}>
                    <div className="flex justify-center items-center">
                      <span className="font-bold text-xs">What's my ISP</span>
                    </div>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>{" "}
            <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
              <Card
                className="background-position bg-bgHomeItemsLight dark:bg-bgHomeItemsDark "
                sx={{
                  backgroundImage:
                    "url('./assets/media/pattern/b-" +
                    (Math.round(Math.random() * 9) + 1) +
                    ".png')",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "60% auto",
                  color: "aliceblue",
                  maxWidth: 400,
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "end",
                  height: 70,
                }}
              >
                <CardActionArea
                  onClick={() => {
                    if (localStorage.getItem("user")) {
                      navigate("/color-code");
                      sidebar.setActiveItem("/");
                    } else {
                      loginDialog.setShowLoginComponent(true);
                      loginDialog.setPathToMove("/color-code");
                    }
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    height: "100%",
                  }}
                >
                  <FormatPaintIcon fontSize="large" />
                  <CardContent sx={{ padding: "0px", margin: "0px" }}>
                    <div className="flex justify-center items-center">
                      <span className="font-bold text-xs">Color Code</span>
                    </div>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>{" "}
            <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
              <Card
                className="background-position bg-bgHomeItemsLight dark:bg-bgHomeItemsDark "
                sx={{
                  backgroundImage:
                    "url('./assets/media/pattern/b-" +
                    (Math.round(Math.random() * 9) + 1) +
                    ".png')",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "60% auto",
                  color: "aliceblue",
                  maxWidth: 400,
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "end",
                  height: 70,
                }}
              >
                <CardActionArea
                  onClick={() => {
                    if (localStorage.getItem("user")) {
                      navigate("/subnet-mask");
                      sidebar.setActiveItem("/");
                    } else {
                      loginDialog.setShowLoginComponent(true);
                      loginDialog.setPathToMove("/subnet-mask");
                    }
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    height: "100%",
                  }}
                >
                  <SensorOccupiedIcon fontSize="large" />
                  <CardContent sx={{ padding: "0px", margin: "0px" }}>
                    <div className="flex justify-center items-center">
                      <span className="font-bold text-xs">Subnet Mask</span>
                    </div>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>{" "}
            <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
              <Card
                className="background-position bg-bgHomeItemsDisableLight dark:bg-bgHomeItemsDisableDark"
                sx={{
                  backgroundImage:
                    "url('./assets/media/pattern/b-" +
                    (Math.round(Math.random() * 9) + 1) +
                    ".png')",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "60% auto",
                  color: "aliceblue",
                  maxWidth: 400,
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "end",
                  height: 70,
                }}
              >
                <CardActionArea
                  disabled
                  onClick={() => {
                    // setShowItems(false);
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    height: "100%",
                  }}
                >
                  <ColorLensIcon fontSize="large" />
                  <CardContent sx={{ padding: "0px", margin: "0px" }}>
                    <div className="flex justify-center items-center">
                      <span className="font-bold text-xs">Site Palette</span>
                    </div>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>{" "}
            <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
              <Card
                className="background-position bg-bgHomeItemsDisableLight dark:bg-bgHomeItemsDisableDark"
                sx={{
                  backgroundImage:
                    "url('./assets/media/pattern/b-" +
                    (Math.round(Math.random() * 9) + 1) +
                    ".png')",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "60% auto",
                  color: "aliceblue",
                  maxWidth: 400,
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "end",
                  height: 70,
                }}
              >
                <CardActionArea
                  disabled
                  onClick={() => {
                    // setShowItems(false);
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    height: "100%",
                  }}
                >
                  <TravelExploreIcon fontSize="large" />
                  <CardContent sx={{ padding: "0px", margin: "0px" }}>
                    <div className="flex justify-center items-center">
                      <span className="font-bold text-xs">DNS Lookup</span>
                    </div>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>{" "}
            <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
              <Card
                className="background-position bg-bgHomeItemsDisableLight dark:bg-bgHomeItemsDisableDark"
                sx={{
                  backgroundImage:
                    "url('./assets/media/pattern/b-" +
                    (Math.round(Math.random() * 9) + 1) +
                    ".png')",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "60% auto",
                  color: "aliceblue",
                  maxWidth: 400,
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "end",
                  height: 70,
                }}
              >
                <CardActionArea
                  disabled
                  onClick={() => {
                    // setShowItems(false);
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    height: "100%",
                  }}
                >
                  <SettingsBackupRestoreIcon fontSize="large" />
                  <CardContent sx={{ padding: "0px", margin: "0px" }}>
                    <div className="flex justify-center items-center">
                      <span className="font-bold text-xs">Revers IP</span>
                    </div>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>{" "}
            <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
              <Card
                className="background-position bg-bgHomeItemsDisableLight dark:bg-bgHomeItemsDisableDark"
                sx={{
                  backgroundImage:
                    "url('./assets/media/pattern/b-" +
                    (Math.round(Math.random() * 9) + 1) +
                    ".png')",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "60% auto",
                  color: "aliceblue",
                  maxWidth: 400,
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "end",
                  height: 70,
                }}
              >
                <CardActionArea
                  disabled
                  onClick={() => {
                    // setShowItems(false);
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    height: "100%",
                  }}
                >
                  <HelpIcon fontSize="large" />
                  <CardContent sx={{ padding: "0px", margin: "0px" }}>
                    <div className="flex justify-center items-center">
                      <span className="font-bold text-xs">Whats my DNS</span>
                    </div>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>{" "}
            <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
              <Card
                className="background-position bg-bgHomeItemsDisableLight dark:bg-bgHomeItemsDisableDark"
                sx={{
                  backgroundImage:
                    "url('./assets/media/pattern/b-" +
                    (Math.round(Math.random() * 9) + 1) +
                    ".png')",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "60% auto",
                  color: "aliceblue",
                  maxWidth: 400,
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "end",
                  height: 70,
                }}
              >
                <CardActionArea
                  disabled
                  onClick={() => {
                    // setShowItems(false);
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    height: "100%",
                  }}
                >
                  <VerifiedIcon fontSize="large" />
                  <CardContent sx={{ padding: "0px", margin: "0px" }}>
                    <div className="flex justify-center items-center">
                      <span className="font-bold text-xs">DKIM Checker</span>
                    </div>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>{" "}
            <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
              <Card
                className="background-position bg-bgHomeItemsDisableLight dark:bg-bgHomeItemsDisableDark"
                sx={{
                  backgroundImage:
                    "url('./assets/media/pattern/b-" +
                    (Math.round(Math.random() * 9) + 1) +
                    ".png')",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "60% auto",
                  color: "aliceblue",
                  maxWidth: 400,
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "end",
                  height: 70,
                }}
              >
                <CardActionArea
                  disabled
                  onClick={() => {
                    // setShowItems(false);
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    height: "100%",
                  }}
                >
                  <RouteIcon fontSize="large" />
                  <CardContent sx={{ padding: "0px", margin: "0px" }}>
                    <div className="flex justify-center items-center">
                      <span className="font-bold text-xs">Trace route</span>
                    </div>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>{" "}
            <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
              <Card
                className="background-position bg-bgHomeItemsDisableLight dark:bg-bgHomeItemsDisableDark"
                sx={{
                  backgroundImage:
                    "url('./assets/media/pattern/b-" +
                    (Math.round(Math.random() * 9) + 1) +
                    ".png')",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "60% auto",
                  color: "aliceblue",
                  maxWidth: 400,
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "end",
                  height: 70,
                }}
              >
                <CardActionArea
                  disabled
                  onClick={() => {
                    // setShowItems(false);
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    height: "100%",
                  }}
                >
                  <NoAccountsIcon fontSize="large" />
                  <CardContent sx={{ padding: "0px", margin: "0px" }}>
                    <div className="flex justify-center items-center">
                      <span className="font-bold text-xs">IP WHOIS</span>
                    </div>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>{" "}
            <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
              <Card
                className="background-position bg-bgHomeItemsDisableLight dark:bg-bgHomeItemsDisableDark"
                sx={{
                  backgroundImage:
                    "url('./assets/media/pattern/b-" +
                    (Math.round(Math.random() * 9) + 1) +
                    ".png')",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "60% auto",
                  color: "aliceblue",
                  maxWidth: 400,
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "end",
                  height: 70,
                }}
              >
                <CardActionArea
                  disabled
                  onClick={() => {
                    // setShowItems(false);
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    height: "100%",
                  }}
                >
                  <NoAccountsIcon fontSize="large" />
                  <CardContent sx={{ padding: "0px", margin: "0px" }}>
                    <div className="flex justify-center items-center">
                      <span className="font-bold text-xs">IPV6 WHOIS</span>
                    </div>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>{" "}
          </Grid>
        </div>
        <div className="mb-10">
          <div className="flex flex-row items-end justify-start gap-1 mb-2">
            <span className="font-bold  text-base">Dev Service</span>
          </div>
          <Grid container columns={12} spacing={3}>
            <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
              <Card
                className="background-position bg-bgHomeItemsLight dark:bg-bgHomeItemsDark "
                sx={{
                  backgroundImage:
                    "url('./assets/media/pattern/b-" +
                    (Math.round(Math.random() * 9) + 1) +
                    ".png')",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "60% auto",
                  color: "aliceblue",
                  maxWidth: 400,
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "end",
                  height: 70,
                }}
              >
                <CardActionArea
                  onClick={() => {
                    if (localStorage.getItem("user")) {
                      navigate("/robot-generator");
                      sidebar.setActiveItem("/");
                    } else {
                      loginDialog.setShowLoginComponent(true);
                      loginDialog.setPathToMove("/robot-generator");
                    }
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    height: "100%",
                  }}
                >
                  <PrecisionManufacturingIcon fontSize="large" />
                  <CardContent sx={{ padding: "0px", margin: "0px" }}>
                    <div className="flex justify-center items-center">
                      <span className="font-bold text-xs">Robot Generator</span>
                    </div>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
              <Card
                className="background-position bg-bgHomeItemsLight dark:bg-bgHomeItemsDark "
                sx={{
                  backgroundImage:
                    "url('./assets/media/pattern/b-" +
                    (Math.round(Math.random() * 9) + 1) +
                    ".png')",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "60% auto",
                  color: "aliceblue",
                  maxWidth: 400,
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "end",
                  height: 70,
                }}
              >
                <CardActionArea
                  onClick={() => {
                    navigate("/json-parser");
                    sidebar.setActiveItem("/");
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    height: "100%",
                  }}
                >
                  <DataObjectIcon fontSize="large" />
                  <CardContent sx={{ padding: "0px", margin: "0px" }}>
                    <div className="flex justify-center items-center">
                      <span className="font-bold text-xs">Json Parser</span>
                    </div>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
              <Card
                className="background-position bg-bgHomeItemsLight dark:bg-bgHomeItemsDark "
                sx={{
                  backgroundImage:
                    "url('./assets/media/pattern/b-" +
                    (Math.round(Math.random() * 9) + 1) +
                    ".png')",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "60% auto",
                  color: "aliceblue",
                  maxWidth: 400,
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "end",
                  height: 70,
                }}
              >
                <CardActionArea
                  onClick={() => {
                    if (localStorage.getItem("user")) {
                      navigate("/manifest-generator");
                      sidebar.setActiveItem("/");
                    } else {
                      loginDialog.setShowLoginComponent(true);
                      loginDialog.setPathToMove("/manifest-generator");
                    }
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    height: "100%",
                  }}
                >
                  <TabUnselectedIcon fontSize="large" />
                  <CardContent sx={{ padding: "0px", margin: "0px" }}>
                    <div className="flex justify-center items-center">
                      <span className="font-bold text-xs">
                        Manifest generator
                      </span>
                    </div>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
              <Card
                className="background-position bg-bgHomeItemsLight dark:bg-bgHomeItemsDark "
                sx={{
                  backgroundImage:
                    "url('./assets/media/pattern/b-" +
                    (Math.round(Math.random() * 9) + 1) +
                    ".png')",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "60% auto",
                  color: "aliceblue",
                  maxWidth: 400,
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "end",
                  height: 70,
                }}
              >
                <CardActionArea
                  // disabled
                  onClick={() => {
                    if (localStorage.getItem("user")) {
                      navigate("/htaccess-generator");
                      sidebar.setActiveItem("/");
                    } else {
                      loginDialog.setShowLoginComponent(true);
                      loginDialog.setPathToMove("/htaccess-generator");
                    }
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    height: "100%",
                  }}
                >
                  <SourceIcon fontSize="large" />
                  <CardContent sx={{ padding: "0px", margin: "0px" }}>
                    <div className="flex justify-center items-center">
                      <span className="font-bold text-xs">htaccess</span>
                    </div>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>{" "}
            <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
              <Card
                className="background-position bg-bgHomeItemsDisableLight dark:bg-bgHomeItemsDisableDark"
                sx={{
                  backgroundImage:
                    "url('./assets/media/pattern/b-" +
                    (Math.round(Math.random() * 9) + 1) +
                    ".png')",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "60% auto",
                  color: "aliceblue",
                  maxWidth: 400,
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "end",
                  height: 70,
                }}
              >
                <CardActionArea
                  disabled
                  onClick={() => {
                    // setShowItems(false);
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    height: "100%",
                  }}
                >
                  <CodeIcon fontSize="large" />
                  <CardContent sx={{ padding: "0px", margin: "0px" }}>
                    <div className="flex justify-center items-center">
                      <span className="font-bold text-xs">Decoder</span>
                    </div>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
              <Card
                className="background-position bg-bgHomeItemsDisableLight dark:bg-bgHomeItemsDisableDark"
                sx={{
                  backgroundImage:
                    "url('./assets/media/pattern/b-" +
                    (Math.round(Math.random() * 9) + 1) +
                    ".png')",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "60% auto",
                  color: "aliceblue",
                  maxWidth: 400,
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "end",
                  height: 70,
                }}
              >
                <CardActionArea
                  disabled
                  onClick={() => {}}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    height: "100%",
                  }}
                >
                  <EngineeringIcon fontSize="large" />
                  <CardContent sx={{ padding: "0px", margin: "0px" }}>
                    <div className="flex justify-center items-center">
                      <span className="font-bold text-xs">Code convertor</span>
                    </div>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        </div>
        <div className="mb-10 ">
          <div className="flex flex-row items-end justify-start gap-1 mb-2">
            <span className="font-bold  text-base ">
              {t("network", { lng: localStorage.getItem("lang") })}{" "}
            </span>
          </div>
          <Grid container columns={12} spacing={3}>
            <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
              <Card
                className="background-position bg-bgHomeItemsLight dark:bg-bgHomeItemsDark "
                sx={{
                  backgroundImage:
                    "url('./assets/media/pattern/b-" +
                    (Math.round(Math.random() * 9) + 1) +
                    ".png')",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "60% auto",
                  color: "aliceblue",
                  maxWidth: 400,
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "end",
                  height: 70,
                }}
              >
                <CardActionArea
                  onClick={() => {
                    navigate("/info");
                    setProtocols("info");
                    sidebar.setActiveItem("/");
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    height: "100%",
                  }}
                >
                  <InfoOutlinedIcon fontSize="large" />
                  <CardContent sx={{ padding: "0px", margin: "0px" }}>
                    <div className="flex justify-center items-center">
                      <span className="font-bold text-xs">
                        INFO(IP details)
                      </span>
                    </div>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
              <Card
                className="background-position bg-bgHomeItemsLight dark:bg-bgHomeItemsDark "
                sx={{
                  backgroundImage:
                    "url('./assets/media/pattern/b-" +
                    (Math.round(Math.random() * 9) + 1) +
                    ".png')",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "60% auto",
                  color: "aliceblue",
                  maxWidth: 400,
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "end",
                  height: 70,
                }}
              >
                <CardActionArea
                  onClick={() => {
                    navigate("/ping");
                    setProtocols("ping");
                    sidebar.setActiveItem("/");
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    height: "100%",
                  }}
                >
                  <NetworkCheckOutlinedIcon fontSize="large" />
                  <CardContent sx={{ padding: "0px", margin: "0px" }}>
                    <div className="flex justify-center items-center">
                      <span className="font-bold text-xs">PING</span>
                    </div>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
              <Card
                className="background-position bg-bgHomeItemsLight dark:bg-bgHomeItemsDark "
                sx={{
                  backgroundImage:
                    "url('./assets/media/pattern/b-" +
                    (Math.round(Math.random() * 9) + 1) +
                    ".png')",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "60% auto",
                  color: "aliceblue",
                  maxWidth: 400,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: 70,
                }}
              >
                <CardActionArea
                  onClick={() => {
                    navigate("/dns");
                    setProtocols("dns");
                    sidebar.setActiveItem("/");
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    height: "100%",
                  }}
                >
                  <DnsIcon fontSize="large" />
                  <CardContent sx={{ padding: "0px", margin: "0px" }}>
                    <div className="flex justify-center items-center ">
                      <span className="font-bold text-xs">DNS</span>
                    </div>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
              <Card
                className="background-position bg-bgHomeItemsLight dark:bg-bgHomeItemsDark "
                sx={{
                  backgroundImage:
                    "url('./assets/media/pattern/b-" +
                    (Math.round(Math.random() * 9) + 1) +
                    ".png')",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "60% auto",
                  color: "aliceblue",
                  maxWidth: 400,
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "end",
                  height: 70,
                }}
              >
                <CardActionArea
                  onClick={() => {
                    navigate("/tcp");
                    setProtocols("tcp");
                    sidebar.setActiveItem("/");
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    height: "100%",
                  }}
                >
                  <HttpsOutlinedIcon fontSize="large" />{" "}
                  <CardContent sx={{ padding: "0px", margin: "0px" }}>
                    <div className="flex justify-center items-center">
                      <span className="font-bold text-xs">TCP port(TCP)</span>
                    </div>
                  </CardContent>
                </CardActionArea>
              </Card>{" "}
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
              <Card
                className="background-position bg-bgHomeItemsLight dark:bg-bgHomeItemsDark "
                sx={{
                  backgroundImage:
                    "url('./assets/media/pattern/b-" +
                    (Math.round(Math.random() * 9) + 1) +
                    ".png')",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "60% auto",
                  color: "aliceblue",
                  maxWidth: 400,
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "end",
                  height: 70,
                }}
              >
                <CardActionArea
                  onClick={() => {
                    navigate("/udp");
                    setProtocols("udp");
                    sidebar.setActiveItem("/");
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    height: "100%",
                  }}
                >
                  {" "}
                  <LinkRoundedIcon fontSize="large" />{" "}
                  <CardContent sx={{ padding: "0px", margin: "0px" }}>
                    <div className="flex justify-center items-center">
                      <span className="font-bold text-xs">UDP PORT</span>
                    </div>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
              <Card
                className="background-position bg-bgHomeItemsLight dark:bg-bgHomeItemsDark "
                sx={{
                  backgroundImage:
                    "url('./assets/media/pattern/b-" +
                    (Math.round(Math.random() * 9) + 1) +
                    ".png')",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "60% auto",
                  color: "aliceblue",
                  maxWidth: 400,
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "end",
                  height: 70,
                }}
              >
                <CardActionArea
                  onClick={() => {
                    navigate("/http");
                    setProtocols("http");
                    sidebar.setActiveItem("/");
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    height: "100%",
                  }}
                >
                  {" "}
                  <HttpOutlinedIcon fontSize="large" />
                  <CardContent sx={{ padding: "0px", margin: "0px" }}>
                    <div className="flex justify-center items-center">
                      <span className="font-bold text-xs">HTTP</span>
                    </div>
                  </CardContent>
                </CardActionArea>
              </Card>{" "}
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
              <Card
                className="background-position bg-bgHomeItemsDisableLight dark:bg-bgHomeItemsDisableDark"
                sx={{
                  backgroundImage:
                    "url('./assets/media/pattern/b-" +
                    (Math.round(Math.random() * 9) + 1) +
                    ".png')",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "60% auto",
                  color: "aliceblue",
                  maxWidth: 400,
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "end",
                  height: 70,
                }}
              >
                <CardActionArea
                  disabled
                  onClick={() => {
                    if (localStorage.getItem("user")) {
                      navigate("/speed-test");
                      sidebar.setActiveItem("/");
                    } else {
                      loginDialog.setShowLoginComponent(true);
                      loginDialog.setPathToMove("/speed-test");
                    }
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    height: "100%",
                  }}
                >
                  <SpeedIcon fontSize="large" />
                  <CardContent sx={{ padding: "0px", margin: "0px" }}>
                    <div className="flex justify-center items-center">
                      <span className="font-bold text-xs">Speed Test</span>
                    </div>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
              <Card
                className="background-position bg-bgHomeItemsDisableLight dark:bg-bgHomeItemsDisableDark"
                sx={{
                  backgroundImage:
                    "url('./assets/media/pattern/b-" +
                    (Math.round(Math.random() * 9) + 1) +
                    ".png')",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "60% auto",
                  color: "aliceblue",
                  maxWidth: 400,
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "end",
                  height: 70,
                }}
              >
                <CardActionArea
                  disabled
                  onClick={() => {
                    // setShowItems(false);
                    // setShowComingSoon(true);
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    height: "100%",
                  }}
                >
                  <WifiFindOutlinedIcon fontSize="large" />{" "}
                  <CardContent sx={{ padding: "0px", margin: "0px" }}>
                    <div className="flex justify-center items-center">
                      <span className="font-bold text-xs">SCAN</span>
                    </div>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
              <Card
                className="background-position bg-bgHomeItemsDisableLight dark:bg-bgHomeItemsDisableDark"
                sx={{
                  backgroundImage:
                    "url('./assets/media/pattern/b-" +
                    (Math.round(Math.random() * 9) + 1) +
                    ".png')",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "60% auto",
                  color: "aliceblue",
                  maxWidth: 400,
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "end",
                  height: 70,
                }}
              >
                <CardActionArea
                  disabled
                  onClick={() => {
                    // setShowItems(false);
                    // setShowComingSoon(true);
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    height: "100%",
                  }}
                >
                  <RssFeedOutlinedIcon fontSize="large" />
                  <CardContent sx={{ padding: "0px", margin: "0px" }}>
                    <div className="flex justify-center items-center">
                      <span className="font-bold text-xs">WIFI</span>
                    </div>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        </div>
        <div className="mb-10">
          <div className="flex flex-row items-end justify-start gap-1 mb-2">
            <span className="font-bold  text-base ">
              {t("site", { lng: localStorage.getItem("lang") })}{" "}
            </span>
          </div>
          <Grid container columns={12} spacing={3}>
            <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
              <Card
                className="background-position bg-bgHomeItemsLight dark:bg-bgHomeItemsDark "
                sx={{
                  backgroundImage:
                    "url('./assets/media/pattern/b-" +
                    (Math.round(Math.random() * 9) + 1) +
                    ".png')",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "60% auto",
                  color: "aliceblue",
                  maxWidth: 400,
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "end",
                  height: 70,
                }}
              >
                <CardActionArea
                  onClick={() => {
                    navigate("/view-source");
                    sidebar.setActiveItem("/viewsource");
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    height: "100%",
                  }}
                >
                  <TerminalRoundedIcon fontSize="large" />
                  <CardContent sx={{ padding: "0px", margin: "0px" }}>
                    <div className="flex justify-center items-center">
                      <span className="font-bold text-xs">Source</span>
                    </div>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
              <Card
                className="background-position bg-bgHomeItemsLight dark:bg-bgHomeItemsDark "
                sx={{
                  backgroundImage:
                    "url('./assets/media/pattern/b-" +
                    (Math.round(Math.random() * 9) + 1) +
                    ".png')",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "60% auto",
                  color: "aliceblue",
                  maxWidth: 400,
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "end",
                  height: 70,
                }}
              >
                <CardActionArea
                  onClick={() => {
                    addAlertIconContext.setShowHome(false);
                    addAlertIconContext.setShowItem("siteMap");
                    sidebar.setActiveItem("/");

                    navigate("/site-map");
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    height: "100%",
                  }}
                >
                  {" "}
                  <AccountTreeRoundedIcon fontSize="large" />
                  <CardContent sx={{ padding: "0px", margin: "0px" }}>
                    <div className="flex justify-center items-center">
                      <span className="font-bold text-xs">Site map</span>
                    </div>{" "}
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
              <Card
                className="background-position bg-bgHomeItemsLight dark:bg-bgHomeItemsDark "
                sx={{
                  backgroundImage:
                    "url('./assets/media/pattern/b-" +
                    (Math.round(Math.random() * 9) + 1) +
                    ".png')",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "60% auto",
                  color: "aliceblue",
                  maxWidth: 400,
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "end",
                  height: 70,
                }}
              >
                <CardActionArea
                  onClick={() => {
                    addAlertIconContext.setShowHome(false);
                    addAlertIconContext.setShowItem("robot");
                    sidebar.setActiveItem("/");

                    navigate("/check-robots");
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    height: "100%",
                  }}
                >
                  <SmartToyOutlinedIcon fontSize="large" />
                  <CardContent sx={{ padding: "0px", margin: "0px" }}>
                    <div className="flex justify-center items-center">
                      <span className="font-bold text-xs">Robot check</span>
                    </div>{" "}
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
              <Card
                className="background-position bg-bgHomeItemsDisableLight dark:bg-bgHomeItemsDisableDark"
                sx={{
                  backgroundImage:
                    "url('./assets/media/pattern/b-" +
                    (Math.round(Math.random() * 9) + 1) +
                    ".png')",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "60% auto",
                  color: "aliceblue",
                  maxWidth: 400,
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "end",
                  height: 70,
                }}
              >
                <CardActionArea
                  disabled
                  onClick={() => {
                    // setShowItems(false);
                    // setShowComingSoon(true);
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    height: "100%",
                  }}
                >
                  {" "}
                  <QueryStatsOutlinedIcon fontSize="large" />
                  <CardContent sx={{ padding: "0px", margin: "0px" }}>
                    <div className="flex justify-center items-center">
                      <span className="font-bold text-xs">Seo check</span>
                    </div>{" "}
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
              {" "}
              <Card
                className="background-position bg-bgHomeItemsDisableLight dark:bg-bgHomeItemsDisableDark"
                sx={{
                  backgroundImage:
                    "url('./assets/media/pattern/b-" +
                    (Math.round(Math.random() * 9) + 1) +
                    ".png')",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "60% auto",
                  color: "aliceblue",
                  maxWidth: 400,
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "end",
                  height: 70,
                }}
              >
                <CardActionArea
                  disabled
                  onClick={() => {
                    // setShowItems(false);
                    // setShowComingSoon(true);
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    height: "100%",
                  }}
                >
                  <SettingsInputCompositeOutlinedIcon fontSize="large" />
                  <CardContent sx={{ padding: "0px", margin: "0px" }}>
                    <div className="flex justify-center items-center">
                      <span className="font-bold text-xs">Site analyze</span>
                    </div>{" "}
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        </div>

        <div className="tabsMargin">
          <div className="flex flex-row items-end justify-start gap-1 mb-2">
            <span className="font-bold  text-base">
              {t("domainHost", { lng: localStorage.getItem("lang") })}
            </span>
          </div>
          <Grid container columns={12} spacing={3}>
            <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
              {" "}
              <Card
                className="background-position bg-bgHomeItemsDisableLight dark:bg-bgHomeItemsDisableDark"
                sx={{
                  backgroundImage:
                    "url('./assets/media/pattern/b-" +
                    (Math.round(Math.random() * 9) + 1) +
                    ".png')",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "60% auto",
                  color: "aliceblue",
                  maxWidth: 400,
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "end",
                  height: 70,
                }}
              >
                <CardActionArea
                  disabled
                  onClick={() => {
                    // setShowItems(false);
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    height: "100%",
                  }}
                >
                  <DomainAddRoundedIcon fontSize="large" />
                  <CardContent sx={{ padding: "0px", margin: "0px" }}>
                    <div className="flex justify-center items-center">
                      <span className="font-bold text-xs">Check domain</span>
                    </div>
                  </CardContent>
                </CardActionArea>
              </Card>{" "}
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
              {" "}
              <Card
                className="background-position bg-bgHomeItemsDisableLight dark:bg-bgHomeItemsDisableDark"
                sx={{
                  backgroundImage:
                    "url('./assets/media/pattern/b-" +
                    (Math.round(Math.random() * 9) + 1) +
                    ".png')",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "60% auto",
                  color: "aliceblue",
                  maxWidth: 400,
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "end",
                  height: 70,
                }}
              >
                <CardActionArea
                  disabled
                  onClick={() => {
                    // setShowItems(false);
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    height: "100%",
                  }}
                >
                  <MoveDownRoundedIcon fontSize="large" />
                  <CardContent sx={{ padding: "0px", margin: "0px" }}>
                    <div className="flex justify-center items-center">
                      <span className="font-bold text-xs">Transfer domain</span>
                    </div>
                  </CardContent>
                </CardActionArea>
              </Card>{" "}
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
              <Card
                className="background-position bg-bgHomeItemsDisableLight dark:bg-bgHomeItemsDisableDark"
                sx={{
                  backgroundImage:
                    "url('./assets/media/pattern/b-" +
                    (Math.round(Math.random() * 9) + 1) +
                    ".png')",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "60% auto",
                  color: "aliceblue",
                  maxWidth: 400,
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "end",
                  height: 70,
                }}
              >
                <CardActionArea
                  disabled
                  onClick={() => {
                    // setShowItems(false);
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    height: "100%",
                  }}
                >
                  <ReceiptRoundedIcon fontSize="large" />
                  <CardContent sx={{ padding: "0px", margin: "0px" }}>
                    <div className="flex justify-center items-center">
                      <span className="font-bold text-xs">Domain sale</span>
                    </div>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}
