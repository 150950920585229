import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  Grid,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { memo, useState } from "react";
import {
  StyledAutocomplete,
  StyledTextField,
} from "../../../../components/styled-components";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { lng } from "../../../../lang/languagesObjects";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect } from "react";

export const BasicInputs = memo(
  ({ optionalPermissionsOptions, offlineEnableOptions, jsonKeys }) => {
    // for multi language
    const { t } = useTranslation("prints");
    i18next.addResourceBundle("fa", "prints", lng.manifest.fa);
    i18next.addResourceBundle("en", "prints", lng.manifest.en);
    i18next.addResourceBundle("ar", "prints", lng.manifest.ar);
    //initial states
    const [name, setName] = useState(null);
    const [shortName, setShortName] = useState(null);
    const [description, setDescription] = useState(null);
    const [devtoolsPage, setDevToolsPage] = useState(null);
    const [defaultLocale, setDefaultLocale] = useState(null);
    const [startUrl, setStartUrl] = useState(null);
    const [homePageURL, setHomePageURL] = useState(null);
    const [scope, setScope] = useState(null);
    const [auther, setAuther] = useState(null);
    const [manifestVersion, setManifestVersion] = useState(null);
    const [language, setLanguage] = useState(null);
    const [version, serVersion] = useState(null);
    const [versionName, setVersionName] = useState(null);
    const [offlineEnable, setOfflineEnable] = useState(null);
    const [optionPage, setOptionPage] = useState(null);
    const [storage, setStorage] = useState(null);
    const [userScripts, setUserScript] = useState(null);
    const [optionalPermissions, setOptionalPermissions] = useState([]);
    const [test, setTest] = useState(true);
    // handle test
    useEffect(() => {
      setTest(!test);
    }, []);
    return (
      <>
        <Accordion
          className="w-full text-slate-100 rounded-md"
          sx={{
            background: "rgb(29,103,158)",
            background:
              "linear-gradient(90deg, rgba(13, 166, 194, 0.23573179271708689) 0%, rgba(38, 36, 80, 0.804359243697479) 100%)",
          }}
        >
          <AccordionSummary
            className="w-full h-16"
            expandIcon={
              <ExpandMoreIcon fontSize="medium" className="text-slate-100" />
            }
          >
            <p className="text-slate-200 w-fit">
              {t("basic", { lng: localStorage.getItem("lang") })}
            </p>
          </AccordionSummary>
          <AccordionDetails className="max-h-60 overflow-y-auto">
            <Grid container spacing={2} columns={12}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <StyledTextField
                  value={name}
                  onChange={(e) => {
                    jsonKeys.current.name = e.target.value;
                    setName(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip
                          title={
                            <p
                              style={{
                                textAlign:
                                  localStorage.getItem("dir") === "ltr"
                                    ? "left"
                                    : "right",
                              }}
                            >
                              {t("nameHelperText", {
                                lng: localStorage.getItem("lang"),
                              })}
                            </p>
                          }
                        >
                          <HelpOutlineIcon
                            sx={{
                              cursor: "pointer",
                              paddingLeft: "4px",
                            }}
                            fontSize="small"
                            htmlColor="#aaaaaa"
                          />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  size="small"
                  label={"Name"}
                  className="w-full"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <StyledTextField
                  value={shortName}
                  onChange={(e) => {
                    jsonKeys.current.shortName = e.target.value;
                    setShortName(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip
                          title={
                            <p
                              style={{
                                textAlign:
                                  localStorage.getItem("dir") === "ltr"
                                    ? "left"
                                    : "right",
                              }}
                            >
                              {t("shortNameHelperText", {
                                lng: localStorage.getItem("lang"),
                              })}
                            </p>
                          }
                        >
                          <HelpOutlineIcon
                            sx={{
                              cursor: "pointer",
                              paddingLeft: "4px",
                            }}
                            fontSize="small"
                            htmlColor="#aaaaaa"
                          />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  size="small"
                  label="Short Name"
                  className="w-full"
                />
              </Grid>{" "}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <StyledTextField
                  value={description}
                  onChange={(e) => {
                    jsonKeys.current.description = e.target.value;
                    setDescription(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip
                          title={
                            <p
                              style={{
                                textAlign:
                                  localStorage.getItem("dir") === "ltr"
                                    ? "left"
                                    : "right",
                              }}
                            >
                              {t("descriptionHelperText", {
                                lng: localStorage.getItem("lang"),
                              })}
                            </p>
                          }
                        >
                          {" "}
                          <HelpOutlineIcon
                            sx={{
                              cursor: "pointer",
                              paddingLeft: "4px",
                            }}
                            fontSize="small"
                            htmlColor="#aaaaaa"
                          />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  size="small"
                  label="Description"
                  className="w-full"
                />
              </Grid>{" "}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <StyledTextField
                  value={devtoolsPage}
                  onChange={(e) => {
                    jsonKeys.current.devtoolsPage = e.target.value;
                    setDevToolsPage(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip
                          title={
                            <p
                              style={{
                                textAlign:
                                  localStorage.getItem("dir") === "ltr"
                                    ? "left"
                                    : "right",
                              }}
                            >
                              {t("devToolsHelperText", {
                                lng: localStorage.getItem("lang"),
                              })}
                            </p>
                          }
                        >
                          <HelpOutlineIcon
                            sx={{
                              cursor: "pointer",
                              paddingLeft: "4px",
                            }}
                            fontSize="small"
                            htmlColor="#aaaaaa"
                          />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  size="small"
                  label="Devtools Page"
                  className="w-full"
                />
              </Grid>{" "}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <StyledTextField
                  value={defaultLocale}
                  onChange={(e) => {
                    jsonKeys.current.setDefaultLocale = e.target.value;
                    setDefaultLocale(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip
                          title={
                            <p
                              style={{
                                textAlign:
                                  localStorage.getItem("dir") === "ltr"
                                    ? "left"
                                    : "right",
                              }}
                            >
                              {t("localeHelperText", {
                                lng: localStorage.getItem("lang"),
                              })}
                            </p>
                          }
                        >
                          <HelpOutlineIcon
                            sx={{
                              cursor: "pointer",
                              paddingLeft: "4px",
                            }}
                            fontSize="small"
                            htmlColor="#aaaaaa"
                          />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  size="small"
                  label="Default_Locale"
                  className="w-full"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <StyledTextField
                  value={startUrl}
                  onChange={(e) => {
                    jsonKeys.current.startUrl = e.target.value;
                    setStartUrl(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <HelpOutlineIcon
                          sx={{
                            cursor: "pointer",
                            paddingLeft: "4px",
                          }}
                          fontSize="small"
                          htmlColor="#aaaaaa"
                        />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="/"
                  size="small"
                  label="Start URL"
                  className="w-full"
                />
              </Grid>{" "}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <StyledTextField
                  value={homePageURL}
                  onChange={(e) => {
                    jsonKeys.current.homePageURL = e.target.value;
                    setHomePageURL(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip
                          title={
                            <p
                              style={{
                                textAlign:
                                  localStorage.getItem("dir") === "ltr"
                                    ? "left"
                                    : "right",
                              }}
                            >
                              {t("homePageHelperText", {
                                lng: localStorage.getItem("lang"),
                              })}
                            </p>
                          }
                        >
                          {" "}
                          <HelpOutlineIcon
                            sx={{
                              cursor: "pointer",
                              paddingLeft: "4px",
                            }}
                            fontSize="small"
                            htmlColor="#aaaaaa"
                          />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  size="small"
                  label="Home page url"
                  className="w-full"
                />
              </Grid>{" "}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <StyledTextField
                  value={scope}
                  onChange={(e) => {
                    jsonKeys.current.scope = e.target.value;
                    setScope(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <HelpOutlineIcon
                          sx={{
                            cursor: "pointer",
                            paddingLeft: "4px",
                          }}
                          fontSize="small"
                          htmlColor="#aaaaaa"
                        />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="/"
                  size="small"
                  label="Scope"
                  className="w-full"
                />
              </Grid>{" "}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <StyledTextField
                  value={auther}
                  onChange={(e) => {
                    jsonKeys.current.auther = e.target.value;
                    setAuther(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip
                          title={
                            <p
                              style={{
                                textAlign:
                                  localStorage.getItem("dir") === "ltr"
                                    ? "left"
                                    : "right",
                              }}
                            >
                              {t("autherHelperText", {
                                lng: localStorage.getItem("lang"),
                              })}
                            </p>
                          }
                        >
                          {" "}
                          <HelpOutlineIcon
                            sx={{
                              cursor: "pointer",
                              paddingLeft: "4px",
                            }}
                            fontSize="small"
                            htmlColor="#aaaaaa"
                          />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  size="small"
                  label="Auther"
                  className="w-full"
                />
              </Grid>{" "}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <StyledTextField
                  value={manifestVersion}
                  onChange={(e) => {
                    jsonKeys.current.manifestVersion = e.target.value;
                    setManifestVersion(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip
                          title={
                            <p
                              style={{
                                textAlign:
                                  localStorage.getItem("dir") === "ltr"
                                    ? "left"
                                    : "right",
                              }}
                            >
                              {t("manifestVersionHelperText", {
                                lng: localStorage.getItem("lang"),
                              })}
                            </p>
                          }
                        >
                          {" "}
                          <HelpOutlineIcon
                            sx={{
                              cursor: "pointer",
                              paddingLeft: "4px",
                            }}
                            fontSize="small"
                            htmlColor="#aaaaaa"
                          />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  size="small"
                  label="Manifest Version"
                  className="w-full"
                  inputProps={{
                    style: {
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    },
                  }}
                />
              </Grid>{" "}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <StyledTextField
                  value={language}
                  onChange={(e) => {
                    jsonKeys.current.lang = e.target.value;
                    setLanguage(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <HelpOutlineIcon
                          sx={{
                            cursor: "pointer",
                            paddingLeft: "4px",
                          }}
                          fontSize="small"
                          htmlColor="#aaaaaa"
                        />
                      </InputAdornment>
                    ),
                  }}
                  size="small"
                  label="Language"
                  className="w-full"
                />
              </Grid>{" "}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <StyledTextField
                  value={version}
                  onChange={(e) => {
                    serVersion(e.target.value);
                    jsonKeys.current.version = e.target.value;
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip
                          title={
                            <p
                              style={{
                                textAlign:
                                  localStorage.getItem("dir") === "ltr"
                                    ? "left"
                                    : "right",
                              }}
                            >
                              {t("versionHelperText", {
                                lng: localStorage.getItem("lang"),
                              })}
                            </p>
                          }
                        >
                          {" "}
                          <HelpOutlineIcon
                            sx={{
                              cursor: "pointer",
                              paddingLeft: "4px",
                            }}
                            fontSize="small"
                            htmlColor="#aaaaaa"
                          />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  size="small"
                  label="Version"
                  className="w-full"
                />
              </Grid>{" "}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <StyledTextField
                  value={versionName}
                  onChange={(e) => {
                    jsonKeys.current.versionName = e.target.value;
                    setVersionName(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <HelpOutlineIcon
                          sx={{
                            cursor: "pointer",
                            paddingLeft: "4px",
                          }}
                          fontSize="small"
                          htmlColor="#aaaaaa"
                        />
                      </InputAdornment>
                    ),
                  }}
                  size="small"
                  label="Version Name"
                  className="w-full"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <StyledAutocomplete
                  defaultValue={offlineEnableOptions[0]}
                  value={offlineEnable}
                  onChange={(e, value) => {
                    jsonKeys.current.offlineEnable = value?.value;
                    setOfflineEnable(value?.value);
                  }}
                  sx={{
                    textAlign: "left",
                    width: "100%",
                  }}
                  size="small"
                  options={offlineEnableOptions}
                  PaperComponent={({ children }) => (
                    <div className={"dark:bg-slate-900 dark:text-slate-300"}>
                      {children}
                    </div>
                  )}
                  renderInput={(params) => (
                    <TextField
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <HelpOutlineIcon
                              sx={{
                                cursor: "pointer",
                                paddingLeft: "4px",
                              }}
                              fontSize="small"
                              htmlColor="#aaaaaa"
                            />
                          </InputAdornment>
                        ),
                      }}
                      {...params}
                      label={"offline enable"}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          color: "#d6d3d1",
                          "& fieldset": {
                            borderColor: "#d6d3d1",
                          },
                          "&:hover fieldset": {
                            borderColor: "white", // change border color on hover
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: "#d6d3d1",
                        },
                        "& .MuiSvgIcon-root": {
                          color: "white", // change icon color
                        },
                      }}
                    />
                  )}
                />
              </Grid>{" "}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <StyledTextField
                  value={optionPage}
                  onChange={(e) => {
                    setOptionPage(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip
                          title={
                            <p
                              style={{
                                textAlign:
                                  localStorage.getItem("dir") === "ltr"
                                    ? "left"
                                    : "right",
                              }}
                            >
                              {t("optionPageHelperText", {
                                lng: localStorage.getItem("lang"),
                              })}
                            </p>
                          }
                        >
                          <HelpOutlineIcon
                            sx={{
                              cursor: "pointer",
                              paddingLeft: "4px",
                            }}
                            fontSize="small"
                            htmlColor="#aaaaaa"
                          />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  size="small"
                  label="options page"
                  className="w-full"
                />
              </Grid>{" "}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <StyledTextField
                  value={storage}
                  onChange={(e) => {
                    jsonKeys.current.storage = e.target.value;
                    setStorage(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip
                          title={
                            <p
                              style={{
                                textAlign:
                                  localStorage.getItem("dir") === "ltr"
                                    ? "left"
                                    : "right",
                              }}
                            >
                              {t("storageHelperText", {
                                lng: localStorage.getItem("lang"),
                              })}
                            </p>
                          }
                        >
                          <HelpOutlineIcon
                            sx={{
                              cursor: "pointer",
                              paddingLeft: "4px",
                            }}
                            fontSize="small"
                            htmlColor="#aaaaaa"
                          />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  size="small"
                  label="storage"
                  className="w-full"
                />
              </Grid>{" "}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <StyledTextField
                  value={userScripts}
                  onChange={(e) => {
                    jsonKeys.current.userScripts = e.target.value;
                    setUserScript(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip
                          title={
                            <p
                              style={{
                                textAlign:
                                  localStorage.getItem("dir") === "ltr"
                                    ? "left"
                                    : "right",
                              }}
                            >
                              {t("userScriptHelperText", {
                                lng: localStorage.getItem("lang"),
                              })}
                            </p>
                          }
                        >
                          {" "}
                          <HelpOutlineIcon
                            sx={{
                              cursor: "pointer",
                              paddingLeft: "4px",
                            }}
                            fontSize="small"
                            htmlColor="#aaaaaa"
                          />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  size="small"
                  label="User scripts"
                  className="w-full"
                />
              </Grid>{" "}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <StyledAutocomplete
                  value={optionalPermissions}
                  onChange={(e, value) => {
                    jsonKeys.current.optionalPermissions = value;
                    setOptionalPermissions(value);
                  }}
                  size="small"
                  multiple
                  options={optionalPermissionsOptions}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.label}
                  PaperComponent={({ children }) => (
                    <div className={"dark:bg-slate-900 dark:text-slate-300"}>
                      {children}
                    </div>
                  )}
                  renderOption={(props, option, { selected }) => {
                    const { key, ...optionProps } = props;
                    return (
                      <li key={key} {...optionProps}>
                        <Checkbox
                          icon={
                            <CheckBoxOutlineBlankIcon
                              fontSize="small"
                              htmlColor="white"
                            />
                          }
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.label}
                      </li>
                    );
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        size="small"
                        color="primary"
                        key={index}
                        label={option.label}
                        style={{ color: "white" }}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <HelpOutlineIcon
                              sx={{
                                cursor: "pointer",
                                paddingLeft: "4px",
                              }}
                              fontSize="small"
                              htmlColor="#aaaaaa"
                            />
                          </InputAdornment>
                        ),
                      }}
                      {...params}
                      label="Optional Permissions"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          color: "#d6d3d1",
                          "& fieldset": {
                            borderColor: "#d6d3d1",
                          },
                          "&:hover fieldset": {
                            borderColor: "white", // change border color on hover
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: "#d6d3d1",
                        },
                        "& .MuiSvgIcon-root": {
                          color: "white", // change icon color
                        },
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </>
    );
  }
);
