import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import * as yup from "yup";
import EditIcon from "@mui/icons-material/Edit";
import { StyledButton } from "../components/styled-components";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import PieChartOutlineIcon from "@mui/icons-material/PieChartOutline";
import AddIcon from "@mui/icons-material/Add";
import { DevicesPart } from "./components/DevicesPart";
import { useNavigate } from "react-router-dom";
import { ProgressAlerts } from "./components/ProgressPartsOfProfile";
import {
  AddAlertToDomain,
  formatInputWithCommas,
  handleAutoLogOut,
} from "../includes/functions";
import { AlertsTable } from "../home/components/alert-center/components/AlertsTable";
import { AppContext } from "../context/AppContext";
import { UpdateProfileComponent } from "./components/UpdateProfileComponent";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { lng } from "../lang/languagesObjects";
import { LogOutDialogComponent } from "./components/LogOutDialogComponent";
import { useApi } from "../customAxios";
import axios from "axios";

export function Profile({ language, setLanguage }) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${
    window.localStorage.getItem("user") &&
    JSON.parse(window.localStorage.getItem("user")).user.token
  }`;
  // for multi language
  const { t } = useTranslation("prints");
  i18next.addResourceBundle("fa", "prints", lng.profile.fa);
  i18next.addResourceBundle("en", "prints", lng.profile.en);
  i18next.addResourceBundle("ar", "prints", lng.profile.ar);
  // initial States
  const api = useApi();
  const { sidebar } = useContext(AppContext);
  const [darkTheme, setDarkTheme] = useState(
    window.localStorage.getItem("theme") === "dark-mode" ? true : false
  );
  const navigate = useNavigate();
  const { lang } = useContext(AppContext);
  const [alertData, setAlertData] = useState(null);
  const [devicesData, setDevicesData] = useState(null);
  const [helperText, setHelperText] = useState(<ProgressAlerts />);
  const { loginDialog } = useContext(AppContext);
  const [updateFirstName, setUpdateFirstName] = useState(
    JSON.parse(localStorage.getItem("user"))?.user.data.data.firstName
  );
  const [updateLastName, setUpdateLastName] = useState(
    JSON.parse(localStorage.getItem("user"))?.user.data.data.lastName
  );
  const [updateEmail, setUpdateEmail] = useState(
    JSON.parse(localStorage.getItem("user"))?.user.data.data.email
  );
  const [updatePhone, setUpdatePhone] = useState(
    JSON.parse(localStorage.getItem("user"))?.user.data.data.mobile
  );
  const { addAlertIconContext } = useContext(AppContext);
  // update states
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(true);
  const [textResultUpdate, setTextResultUpdate] = useState("");
  const [showResult, setShowResult] = useState(false);
  const [test, setTest] = useState(true);
  // log out dialog states
  const [openLogOutDialog, setOpenLogOutDialog] = useState(false);
  const periodAlertOption = [
    { id: 1, label: "1m", value: 1 },
    { id: 2, label: "3m", value: 3 },
    { id: 3, label: "5m", value: 5 },
    { id: 4, label: "10m", value: 10 },
    { id: 5, label: "30m", value: 30 },
    { id: 6, label: "1h", value: 60 },
    { id: 7, label: "5h", value: 5 * 60 },
    { id: 8, label: "15h", value: 15 * 60 },
  ];

  const CustomizedTheme = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    "& .MuiSwitch-switchBase": {
      margin: 1,
      padding: 0,
      transform: "translateX(5px)",
      "&.Mui-checked": {
        color: "#fff",
        transform: "translateX(25px)",
        "& .MuiSwitch-thumb:before": {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
            "#fff"
            // "red"
          )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
        },
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: "#8796A5",
          // "red",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: "#78716c",
      // darkMode ? "#003892" : "#001e3c",
      width: 30,
      height: 30,
      "&::before": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
          // "red"
        )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
      },
    },
    "& .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: "#8796A5",
      // "red",
      borderRadius: 20 / 2,
    },
  }));

  useEffect(() => {
    if (localStorage.getItem("user")) {
      api
        .post("/profile/details")
        .then((res) => {
          if (!res.data.status) {
            throw Error(
              "The connection with the server could not be established"
            );
          }
          localStorage.setItem("alerts", JSON.stringify(res.data.alerts));
          console.log("ip details response", res);
          setAlertData(res.data.alerts);
          setDevicesData(res.data.domains);
        })
        .catch((err) => {
          console.log("ip details error", err);
          setHelperText(
            <p className="text-slate-200 w-full flex flex-row justify-center">
              {err.message}
            </p>
          );
        });
    }
  }, [localStorage.getItem("user")]);

  // for open update dialog
  const handleOpenUpdateDialog = () => {
    setOpenUpdateDialog(true);
  };

  // for close update dialog
  const handleCloseUpdateDialog = () => {
    setOpenUpdateDialog(false);
    setShowUpdateForm(true);
    setShowResult(false);
    setTextResultUpdate("");
  };

  //  for active sidebar & navigation item

  useEffect(() => {
    sidebar.setActiveItem("/profile");
  }, []);
  useEffect(() => {
    const theme = localStorage.getItem("theme");
    if (theme === "dark-mode") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [localStorage.getItem("theme")]);
  return (
    <>
      <UpdateProfileComponent
        showLoading={showLoading}
        setShowLoading={setShowLoading}
        // update dialog states
        openUpdateDialog={openUpdateDialog}
        handleOpenUpdateDialog={handleOpenUpdateDialog}
        handleCloseUpdateDialog={handleCloseUpdateDialog}
        //update states
        updateFirstName={updateFirstName}
        updateLastName={updateLastName}
        updateEmail={updateEmail}
        updatePhone={updatePhone}
        setUpdateFirstName={setUpdateFirstName}
        setUpdateLastName={setUpdateLastName}
        setUpdateEmail={setUpdateEmail}
        setUpdatePhone={setUpdatePhone}
        setShowUpdateForm={setShowUpdateForm}
        showUpdateForm={showUpdateForm}
        setTextResultUpdate={setTextResultUpdate}
        textResultUpdate={textResultUpdate}
        showResult={showResult}
        setShowResult={setShowResult}
      />
      <div className=""></div>
      <div className="profile flex flex-col items-center justify-between w-full">
        {localStorage.getItem("user") && (
          <div
            className="
            flex
            flex-col
            items-start
            justify-center
            gap-4
            w-full
            text-textColor
            "
          >
            <Grid container columns={12} spacing={2}>
              <Grid item xs={12} sm={5} md={5} lg={5}>
                <Card
                  sx={{
                    width: "100%",
                    height: "100%",
                    direction: "ltr",
                  }}
                  className={` ${
                    localStorage.getItem("theme") === "dark-mode"
                      ? "card-bg-gradient-dark"
                      : "card-bg-gradient-light"
                  }`}
                >
                  <CardHeader
                    onClick={handleOpenUpdateDialog}
                    sx={{
                      paddingLeft: "20px",
                      paddingTop: "20px",
                      color: "azure",
                    }}
                    avatar={
                      <Avatar
                        sx={{
                          bgcolor: "#6b7280",
                          height: "48px",
                          width: "48px",
                          "& .MuiCardHeader-avatar": {
                            marginRight: 0, // کاهش فاصله بین عنوان و آواتار
                          },
                        }}
                      >
                        {!JSON.parse(localStorage.getItem("user")).user.data
                          .data.firstName &&
                        !JSON.parse(localStorage.getItem("user")).user.data.data
                          .lastName
                          ? "H"
                          : JSON.parse(localStorage.getItem("user")).user.data
                              .data.firstName &&
                            JSON.parse(localStorage.getItem("user")).user.data
                              .data.firstName[0]}
                        {JSON.parse(localStorage.getItem("user")).user.data.data
                          .lastName &&
                          JSON.parse(localStorage.getItem("user")).user.data
                            .data.lastName[0]}
                      </Avatar>
                    }
                    title={
                      <div
                        className="w-fit"
                        style={{
                          display: "flex",
                          flexDirection: "row-reverse",
                          justifyContent: "center",
                          alignItems: "start",
                          gap: 5,
                        }}
                      >
                        <EditIcon
                          onClick={handleOpenUpdateDialog}
                          className="cursor-pointer"
                        />{" "}
                        <div
                          className="font-bold p-0 m-0 gap-2 flex w-fit "
                          style={{
                            fontSize: "20px",
                            // flexDirection: "row-reverse",
                            direction: "ltr",
                          }}
                        >
                          <p>
                            {JSON.parse(localStorage.getItem("user")).user.data
                              .data.firstName &&
                              JSON.parse(localStorage.getItem("user")).user.data
                                .data.firstName}
                          </p>
                          <p>
                            {JSON.parse(localStorage.getItem("user")).user.data
                              .data.lastName &&
                              JSON.parse(localStorage.getItem("user")).user.data
                                .data.lastName}
                          </p>
                        </div>{" "}
                      </div>
                    }
                  />
                  <CardContent
                    className="flex flex-col items-start justify-center gap-5"
                    sx={{ padding: "5px" }}
                  >
                    <div className="pl-2 pr-2 flex flex-col items-start justify-center gap-2 text-white">
                      <div className="flex flex-row items-center justify-center gap-1">
                        <p sx={{ color: "azure" }} className="flex-shrink-0">
                          {t("email", { lng: localStorage.getItem("lang") })}:
                        </p>
                        <p className="font-bold whitespace-nowrap text-ellipsis overflow-hidden">
                          {JSON.parse(localStorage.getItem("user")).user.data
                            .data.email && localStorage.getItem("user") ? (
                            <>
                              {
                                JSON.parse(localStorage.getItem("user")).user
                                  .data.data.email
                              }
                            </>
                          ) : (
                            "______"
                          )}
                        </p>
                      </div>
                      <div className="flex flex-row  items-center justify-center gap-1">
                        <p>
                          {" "}
                          {t("mobile", { lng: localStorage.getItem("lang") })}:
                        </p>
                        <p className="font-bold">
                          {JSON.parse(localStorage.getItem("user")).user.data
                            .data.mobile &&
                            JSON.parse(localStorage.getItem("user")).user.data
                              .data.mobile}
                        </p>
                      </div>
                    </div>

                    <Divider
                      sx={{ width: "100%", backgroundColor: "#a8a29e" }}
                    />
                    <div className=" flex flex-row justify-between w-full px-5 font-bold text-white">
                      <div className="flex flex-row items-center justify-center gap-1">
                        <SupervisedUserCircleIcon></SupervisedUserCircleIcon>
                        <p className="font-bold">
                          {
                            JSON.parse(localStorage.getItem("user"))?.user.data
                              .group
                          }
                        </p>
                      </div>
                      <div className="flex flex-row items-center justify-center gap-1 text-white">
                        <PieChartOutlineIcon></PieChartOutlineIcon>
                        <p className="font-bold">
                          {JSON.parse(localStorage.getItem("user")).user.data
                            .financial.credit && localStorage.getItem("user")
                            ? formatInputWithCommas(
                                JSON.parse(localStorage.getItem("user")).user
                                  .data.financial.credit
                              )
                            : t("creditNotAvailable", {
                                lng: localStorage.getItem("lang"),
                              })}
                        </p>
                      </div>
                    </div>
                    <Divider
                      sx={{ width: "100%", backgroundColor: "#a8a29e" }}
                    />
                    <Grid
                      container
                      columns={12}
                      spacing={2}
                      className="flex flex-row  items-center px-3"
                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        className="flex flex-row justify-start pl-0"
                      >
                        <div className="flex flex-row justify-center items-center gap-2 w-full">
                          <CustomizedTheme
                            onClick={() => {
                              setDarkTheme(!darkTheme);
                              localStorage.setItem(
                                "theme",
                                localStorage.getItem("theme") !== "dark-mode"
                                  ? "dark-mode"
                                  : "light-mode"
                              );
                            }}
                            checked={darkTheme}
                          />
                          <Divider
                            orientation="vertical"
                            flexItem
                            style={{ backgroundColor: "#a8a29e" }}
                          />
                          <div className="flex flex-row items-center justify-center gap-2 ">
                            <Typography
                              className="change-lan text-[#f5f5f4]"
                              onClick={() => {
                                localStorage.setItem("lang", "ar");
                                localStorage.setItem("dir", "rtl");
                                setLanguage(true);
                                setTest(!test);
                              }}
                              variant="h6"
                            >
                              AR
                            </Typography>
                            <Typography variant="h6" className="text-[#f5f5f4]">
                              /
                            </Typography>
                            <Typography
                              className="change-lan text-[#f5f5f4]"
                              onClick={() => {
                                localStorage.setItem("lang", "en");
                                localStorage.setItem("dir", "ltr");
                                setLanguage(false);
                                setTest(!test);
                              }}
                              variant="h6"
                            >
                              EN
                            </Typography>
                            <Typography variant="h6" className="text-[#f5f5f4]">
                              /
                            </Typography>
                            <Typography
                              className="change-lan text-[#f5f5f4]"
                              onClick={() => {
                                localStorage.setItem("lang", "fa");
                                localStorage.setItem("dir", "rtl");
                                setLanguage(true);
                                setTest(!test);
                              }}
                              variant="h6"
                            >
                              FA
                            </Typography>
                          </div>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        className="flex flex-row justify-end"
                      >
                        <StyledButton
                          className="min-w-28 w-full bg-gradient-to-l dark:from-bgGradientFromCustomButtonDark dark:to-bgGradientToCustomButtonDark dark:hover:from-bgGradientFromCustomButtonHoverDark dark:hover:to-bgGradientToCustomButtonHoverDark bg-bgCustomButtonLight hover:bg-bgCustomButtonHoverLight  "
                          onClick={() => {
                            setOpenLogOutDialog(true);
                          }}
                        >
                          {t("logOutButton", {
                            lng: localStorage.getItem("lang"),
                          })}
                        </StyledButton>
                        <LogOutDialogComponent
                          openLogOutDialog={openLogOutDialog}
                          setOpenLogOutDialog={setOpenLogOutDialog}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={7} md={7} lg={7}>
                <Card
                  sx={{
                    width: "100%",
                    height: "100%",
                    direction: "ltr",
                  }}
                  className={`${
                    localStorage.getItem("theme") === "dark-mode"
                      ? "card-bg-gradient-dark"
                      : "card-bg-gradient-light"
                  }`}
                >
                  <CardHeader
                    title={t("alertTitle", {
                      lng: localStorage.getItem("lang"),
                    })}
                    action={
                      <AddIcon
                        onClick={() => {
                          navigate("/alert-center");
                          addAlertIconContext.setShowHome(false);
                          addAlertIconContext.setShowItem("alertCenter");
                        }}
                        sx={{ marginRight: "5px", cursor: "pointer" }}
                        fontSize="large"
                      />
                    }
                    sx={{ color: "azure" }}
                  />
                  <CardContent
                    sx={{
                      maxHeight: "240px",
                      overflowY: "auto",
                    }}
                  >
                    <AlertsTable
                      alertData={alertData}
                      setAlertData={setAlertData}
                      helperText={helperText}
                      periodAlertOption={periodAlertOption}
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid container columns={12} spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Card
                  sx={{ width: "100%", height: "100%", direction: "ltr" }}
                  className={`${
                    localStorage.getItem("theme") === "dark-mode"
                      ? "card-bg-gradient-dark"
                      : "card-bg-gradient-light"
                  }`}
                >
                  <CardHeader
                    title={t("devicesTitle", {
                      lng: localStorage.getItem("lang"),
                    })}
                    sx={{ color: "azure", paddingBottom: "0px" }}
                  />
                  <CardContent
                    sx={{
                      maxHeight: "250px",
                      overflowY: "auto",
                    }}
                  >
                    <DevicesPart
                      helperText={helperText}
                      setHelperText={setHelperText}
                      setDevicesData={setDevicesData}
                      devicesData={devicesData}
                    />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Card
                  sx={{
                    width: "100%",
                    height: "100%",
                    direction: "ltr",
                  }}
                  className={`${
                    localStorage.getItem("theme") === "dark-mode"
                      ? "card-bg-gradient-dark"
                      : "card-bg-gradient-light"
                  }`}
                >
                  <CardHeader
                    title={t("historyTitle", {
                      lng: localStorage.getItem("lang"),
                    })}
                    sx={{
                      paddingRight: "0px",
                      paddingBottom: "0px",
                      color: "azure",
                    }}
                  />
                  <CardContent
                    sx={{
                      maxHeight: "250px",
                      overflowY: "auto",
                    }}
                  >
                    <ul>
                      {JSON.parse(localStorage.getItem("inputValues")) ? (
                        JSON.parse(localStorage.getItem("inputValues")).map(
                          (item, index) => {
                            return (
                              <div
                                id={index}
                                className="flex flex-row items-start justify-start gap-1 p-2"
                              >
                                <AddAlertToDomain url={item} />
                                <li className="li-link mb-1 font-bold">
                                  <a
                                    href={item}
                                    target="_blank"
                                    className="text-white"
                                  >
                                    {item}
                                  </a>
                                </li>
                              </div>
                            );
                          }
                        )
                      ) : (
                        <p
                          className="font-bold"
                          style={{
                            fontSize: "20px",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "10px",
                            color: "azure",
                          }}
                        >
                          {t("historyEmpty", {
                            lng: localStorage.getItem("lang"),
                          })}{" "}
                        </p>
                      )}
                    </ul>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Card
              sx={{ width: "100%", height: "100%" }}
              className={`${
                localStorage.getItem("theme") === "dark-mode"
                  ? "card-bg-gradient-dark"
                  : "card-bg-gradient-light"
              }`}
            >
              <CardHeader title="Transfers" sx={{ color: "azure" }} />
              <CardContent></CardContent>
            </Card>
          </div>
        )}
      </div>
    </>
  );
}
