import React, { useState } from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import AddIcon from "@mui/icons-material/Add";
import { ScreenShotsItem } from "./components/ScreenShotsItem";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect } from "react";

export function ScreenShots({ jsonKeys }) {
  //initial states
  const [test, setTest] = useState(true);
  const [screenShotIdCounter, setScreenShotIdCounter] = useState(0);
  const [screenShotKeys, setScreenShotKeys] = useState([
    {
      id: screenShotIdCounter,
      data: { src: "", sizes: "", type: "", form_factor: "", label: "" },
    },
  ]);
  // handle test
  useEffect(() => {
    setTest(!test);
  }, []);
  // handle add screenShots
  function handleAddScreenShot(itemId) {
    // setScreenShotIdCounter(itemId + 1);
    const newScreenShots = [
      ...screenShotKeys,
      {
        id: itemId + 1,
        data: { src: "", sizes: "", type: "", form_factor: "", label: "" },
      },
    ];
    jsonKeys.current.screenShots = newScreenShots;
    setScreenShotKeys(newScreenShots);
  }

  //handle remove screenShot item
  const handleRemoveScreenShotItem = (id) => {
    const newScreenShotList = screenShotKeys.filter((item) => item.id !== id);
    jsonKeys.current.screenShots = newScreenShotList;

    setScreenShotKeys(newScreenShotList);
  };
  return (
    <>
      <Accordion
        className="w-full text-slate-100 rounded-md"
        sx={{
          background: "rgb(29,103,158)",
          background:
            "linear-gradient(90deg, rgba(13, 166, 194, 0.23573179271708689) 0%, rgba(38, 36, 80, 0.804359243697479) 100%)",
        }}
      >
        <AccordionSummary
          className="w-full h-16"
          expandIcon={
            <ExpandMoreIcon fontSize="medium" className="text-slate-100" />
          }
        >
          <div className="w-full flex justify-between items-center mr-1">
            <p className="text-xl ml-2">Screen shots</p>
            <HelpOutlineIcon
              className="pt-1"
              sx={{
                cursor: "pointer",
                paddingLeft: "4px",
                paddingBottom: "2px",
              }}
              fontSize="medium"
              htmlColor="#aaaaaa"
            />
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container columns={12} spacing={3}>
            {screenShotKeys.map((item, index) => {
              return (
                <>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ScreenShotsItem
                      index={index}
                      jsonKeys={jsonKeys}
                      screenShotKeys={screenShotKeys}
                      setScreenShotKeys={setScreenShotKeys}
                      id={item.id}
                      handleRemoveScreenShotItem={handleRemoveScreenShotItem}
                      handleAddScreenShot={handleAddScreenShot}
                    />
                  </Grid>
                </>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
