import React, { memo, useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@mui/material";
import { StyledTextField } from "../../../../components/styled-components";

export const CustomErrorPages = memo(
  ({
    error400,
    setError400,
    error401,
    setError401,
    error403,
    setError403,
    error404,
    setError404,
    error500,
    setError500,
  }) => {
    // for test state
    const [test, setTest] = useState(false);
    useEffect(() => {
      setTest(true);
    }, []);
    return (
      <>
        <Accordion
          className="w-full text-slate-100 rounded-md"
          sx={{
            background: "rgb(29,103,158)",
            background:
              "linear-gradient(90deg, rgba(13, 166, 194, 0.23573179271708689) 0%, rgba(38, 36, 80, 0.804359243697479) 100%)",
          }}
        >
          <AccordionSummary
            className="h-14"
            expandIcon={<ExpandMoreIcon className="text-slate-100" />}
          >
            Custom Error Pages (400, 404, 500, etc)
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={1} columns={15}>
              <Grid item xs={15} sm={15} md={7.5} lg={3} xl={3}>
                <StyledTextField
                  value={error400}
                  onChange={(e) => setError400(e.target.value)}
                  className="w-full"
                  size="small"
                  label="400 Error file path"
                />
              </Grid>{" "}
              <Grid item xs={15} sm={15} md={7.5} lg={3} xl={3}>
                <StyledTextField
                  value={error401}
                  onChange={(e) => setError401(e.target.value)}
                  className="w-full"
                  size="small"
                  label="401 Error file path"
                />
              </Grid>{" "}
              <Grid item xs={15} sm={15} md={7.5} lg={3} xl={3}>
                <StyledTextField
                  value={error403}
                  onChange={(e) => setError403(e.target.value)}
                  className="w-full"
                  size="small"
                  label="403 Error file path"
                />
              </Grid>{" "}
              <Grid item xs={15} sm={15} md={7.5} lg={3} xl={3}>
                <StyledTextField
                  value={error404}
                  onChange={(e) => setError404(e.target.value)}
                  className="w-full"
                  size="small"
                  label="404 Error file path"
                />
              </Grid>{" "}
              <Grid item xs={15} sm={15} md={7.5} lg={3} xl={3}>
                <StyledTextField
                  value={error500}
                  onChange={(e) => setError500(e.target.value)}
                  className="w-full"
                  size="small"
                  label="500 Error file path"
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </>
    );
  }
);
