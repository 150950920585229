import React, { memo, useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@mui/material";
import {
  StyledButton,
  StyledTextField,
} from "../../../../components/styled-components";
import { saveAs } from "file-saver";
import * as CryptoJS from "crypto-js";
import JSZip from "jszip";

export const Htpasswd = memo(
  ({ userInput, setUserInput, passInput, setPassInput }) => {
    function exportFile(content, name) {
      const blob = new Blob([content], { type: "application/octet-stream" });
      const zip = new JSZip(); // فرض بر این است که JSZip را به پروژه اضافه کرده‌اید
      zip.file(`.${"htpasswd"}`, blob);

      zip.generateAsync({ type: "blob" }).then(function (content) {
        saveAs(content, `${"htpasswd"}.zip`); // نام فایل ZIP
      });
    }
    // for test state
    const [test, setTest] = useState(false);
    useEffect(() => {
      setTest(true);
    }, []);
    return (
      <>
        <Accordion
          className="w-full text-slate-100 rounded-md"
          sx={{
            background: "rgb(29,103,158)",
            background:
              "linear-gradient(90deg, rgba(13, 166, 194, 0.23573179271708689) 0%, rgba(38, 36, 80, 0.804359243697479) 100%)",
          }}
        >
          <AccordionSummary
            className="h-14"
            expandIcon={<ExpandMoreIcon className="text-slate-100" />}
          >
            htpasswd Generator
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={1} columns={12}>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <StyledTextField
                  value={userInput}
                  onChange={(e) => setUserInput(e.target.value)}
                  size="small"
                  className="w-full"
                  label="Username"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <StyledTextField
                  value={passInput}
                  onChange={(e) => setPassInput(e.target.value)}
                  size="small"
                  className="w-full"
                  label="Password"
                />
              </Grid>
              <Grid
                className="flex justify-end items-center"
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
              >
                <StyledButton
                  onClick={() => {
                    console.log("test");
                    exportFile(
                      `${userInput}:${CryptoJS.MD5(passInput).toString()}`
                    );
                  }}
                  className="w-32 bg-gradient-to-l dark:from-bgGradientFromCustomButtonDark dark:to-bgGradientToCustomButtonDark dark:hover:from-bgGradientFromCustomButtonHoverDark dark:hover:to-bgGradientToCustomButtonHoverDark bg-bgCustomButtonLight hover:bg-bgCustomButtonHoverLight"
                >
                  Generate
                </StyledButton>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </>
    );
  }
);
