import LanguageIcon from "@mui/icons-material/Language";
import SubtitlesIcon from "@mui/icons-material/Subtitles";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DomainVerificationIcon from "@mui/icons-material/DomainVerification";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import { AppContext } from "../context/AppContext";
import "../Sidebar/custom-sidebar.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { LoginComponent } from "../Profile/components/LoginComponent";
import { lng } from "../lang/languagesObjects";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
export function CustomSidebar({ darkMode, language }) {
  // for multilanguage
  const { t } = useTranslation("prints");
  i18next.addResourceBundle("fa", "prints", lng.sidebar.fa);
  i18next.addResourceBundle("en", "prints", lng.sidebar.en);
  i18next.addResourceBundle("ar", "prints", lng.sidebar.ar);
  //initial states
  const { lang } = useContext(AppContext);
  // const [active, setActive] = useState("/");
  const { loginDialog } = useContext(AppContext);
  const navigate = useNavigate("/");
  const location = useLocation();
  const { sidebar } = useContext(AppContext);

  return (
    <>
      {loginDialog.showLoginComponent && <LoginComponent />}
      <ul
        className={`sidebar ${
          localStorage.getItem("dir") === "ltr"
            ? "border-r-[5px]"
            : "border-l-[5px]"
        }  border-[#a8a7a7] dark:border-[#7b78aa] bg-bgSidebarLight dark:bg-bgSidebarDark`}
        style={{
          direction: localStorage.getItem("dir"),
          position: "fixed",
          top: 0,
          left: localStorage.getItem("dir") === "ltr" ? 0 : "",
          zIndex: 100,
          right: localStorage.getItem("dir") === "ltr" ? "" : 0,

          padding:
            localStorage.getItem("dir") === "ltr"
              ? "20px 0 10px 10px"
              : "20px 10px 10px 0",
        }}
      >
        <div className=" flex flex-row justify-start items-center gap-4 absolute top-5 pl-2 logo">
          <img src="./assets/logo/logo-64.png" />
          <p className="text-[#ebe0f6] text-xl font-bold overflow-hidden text-ellipsis whitespace-nowrap pt-7">
            Version: 1.1.1
          </p>
        </div>
        <li
          onClick={() => {
            sidebar.setActiveItem("/view-source");
            navigate("/view-source");
          }}
          className={`sidebar-item  hover:bg-gradient-to-l dark:hover:from-bgGradientFromSidebarHoverItemDark dark:hover:to-bgGradientToSidebarHoverItemDark hover:to-bgGradientToSidebarHoverItemLight hover:from-bgGradientFromSidebarHoverItemLight  ${
            sidebar.activeItem === "/view-source"
              ? "active hover:bg-none bg-bgSidebarActiveItemLight dark:bg-bgSidebarActiveItemDark"
              : ""
          }`}
          style={{
            borderTopLeftRadius:
              localStorage.getItem("dir") === "ltr" ? "20px" : "",
            borderBottomLeftRadius:
              localStorage.getItem("dir") === "ltr" ? "20px" : "",
            borderTopRightRadius:
              localStorage.getItem("dir") === "ltr" ? "" : "20px",
            borderBottomRightRadius:
              localStorage.getItem("dir") === "ltr" ? "" : "20px",
          }}
        >
          <span
            className={`${
              localStorage.getItem("dir") === "ltr"
                ? "dark:shadow-[10px_10px_0_var(--bg-sidebar-groove-dark)] shadow-[10px_10px_0_var(--bg-sidebar-groove-light)] "
                : "dark:shadow-[-10px_10px_0_var(--bg-sidebar-groove-dark)] shadow-[-10px_10px_0_var(--bg-sidebar-groove-light)]"
            }`}
            style={{
              display: sidebar.activeItem === "/view-source" ? "flex" : "none",
              content: "",
              position: "absolute",
              top: "-20px",
              right: localStorage.getItem("dir") === "ltr" ? "0px" : "",
              left: localStorage.getItem("dir") === "ltr" ? "" : "0px",
              width: "20px",
              height: "20px",
              background: "transparent",
              borderRadius: "50%",
            }}
          ></span>
          <span
            className={`${
              localStorage.getItem("dir") === "ltr"
                ? "dark:shadow-[10px_-10px_0_var(--bg-sidebar-groove-dark)] shadow-[10px_-10px_0_var(--bg-sidebar-groove-light)]"
                : "dark:shadow-[-10px_-10px_0_var(--bg-sidebar-groove-dark)] shadow-[-10px_-10px_0_var(--bg-sidebar-groove-light)]"
            }`}
            style={{
              display: sidebar.activeItem === "/view-source" ? "flex" : "none",
              content: "",
              position: "absolute",
              bottom: "-20px",
              right: localStorage.getItem("dir") === "ltr" ? "0px" : "",
              left: localStorage.getItem("dir") === "ltr" ? "" : "0px",
              width: "20px",
              height: "20px",
              background: "transparent",
              borderRadius: "50%",
            }}
          ></span>
          <div className="flex flex-row items-center justify-start gap-2">
            <SubtitlesIcon fontSize="medium" />
            <p style={{ fontWeight: "bold", fontSize: "15px" }}>
              {t("sourceItem", { lng: localStorage.getItem("lang") })}
            </p>
          </div>
        </li>
        <li
          onClick={() => {
            sidebar.setActiveItem("/ping-world");
            navigate("/ping-world");
          }}
          className={`sidebar-item hover:bg-gradient-to-l dark:hover:from-bgGradientFromSidebarHoverItemDark dark:hover:to-bgGradientToSidebarHoverItemDark hover:to-bgGradientToSidebarHoverItemLight hover:from-bgGradientFromSidebarHoverItemLight ${
            sidebar.activeItem === "/ping-world"
              ? "active hover:bg-none bg-bgSidebarActiveItemLight dark:bg-bgSidebarActiveItemDark"
              : ""
          }`}
          style={{
            borderTopLeftRadius:
              localStorage.getItem("dir") === "ltr" ? "20px" : "",
            borderBottomLeftRadius:
              localStorage.getItem("dir") === "ltr" ? "20px" : "",
            borderTopRightRadius:
              localStorage.getItem("dir") === "ltr" ? "" : "20px",
            borderBottomRightRadius:
              localStorage.getItem("dir") === "ltr" ? "" : "20px",
          }}
        >
          <span
            className={`${
              localStorage.getItem("dir") === "ltr"
                ? "dark:shadow-[10px_10px_0_var(--bg-sidebar-groove-dark)] shadow-[10px_10px_0_var(--bg-sidebar-groove-light)] "
                : "dark:shadow-[-10px_10px_0_var(--bg-sidebar-groove-dark)] shadow-[-10px_10px_0_var(--bg-sidebar-groove-light)]"
            }`}
            style={{
              display: sidebar.activeItem === "/ping-world" ? "flex" : "none",
              content: "",
              position: "absolute",
              top: "-20px",
              right: localStorage.getItem("dir") === "ltr" ? "0px" : "",
              left: localStorage.getItem("dir") === "ltr" ? "" : "0px",
              width: "20px",
              height: "20px",
              background: "transparent",
              borderRadius: "50%",
            }}
          ></span>
          <span
            className={`${
              localStorage.getItem("dir") === "ltr"
                ? "dark:shadow-[10px_-10px_0_var(--bg-sidebar-groove-dark)] shadow-[10px_-10px_0_var(--bg-sidebar-groove-light)]"
                : "dark:shadow-[-10px_-10px_0_var(--bg-sidebar-groove-dark)] shadow-[-10px_-10px_0_var(--bg-sidebar-groove-light)]"
            }`}
            style={{
              display: sidebar.activeItem === "/ping-world" ? "flex" : "none",
              content: "",
              position: "absolute",
              bottom: "-20px",
              right: localStorage.getItem("dir") === "ltr" ? "0px" : "",
              left: localStorage.getItem("dir") === "ltr" ? "" : "0px",
              width: "20px",
              height: "20px",
              background: "transparent",
              borderRadius: "50%",
            }}
          ></span>
          <div className="flex flex-row items-center justify-start gap-2">
            <LanguageIcon fontSize="medium" />
            <p style={{ fontWeight: "bold", fontSize: "15px" }}>
              {t("pingWorldItem", { lng: localStorage.getItem("lang") })}
            </p>
          </div>
        </li>
        <li
          onClick={() => {
            sidebar.setActiveItem("/");
            navigate("/");
          }}
          className={`sidebar-item hover:bg-gradient-to-l dark:hover:from-bgGradientFromSidebarHoverItemDark dark:hover:to-bgGradientToSidebarHoverItemDark hover:to-bgGradientToSidebarHoverItemLight hover:from-bgGradientFromSidebarHoverItemLight ${
            sidebar.activeItem === "/"
              ? "active hover:bg-none bg-bgSidebarActiveItemLight dark:bg-bgGradientFromSidebarHoverItemDark"
              : ""
          }`}
          style={{
            borderTopLeftRadius:
              localStorage.getItem("dir") === "ltr" ? "20px" : "",
            borderBottomLeftRadius:
              localStorage.getItem("dir") === "ltr" ? "20px" : "",
            borderTopRightRadius:
              localStorage.getItem("dir") === "ltr" ? "" : "20px",
            borderBottomRightRadius:
              localStorage.getItem("dir") === "ltr" ? "" : "20px",
          }}
        >
          <span
            className={`${
              localStorage.getItem("dir") === "ltr"
                ? "dark:shadow-[10px_10px_0_var(--bg-sidebar-groove-dark)] shadow-[10px_10px_0_var(--bg-sidebar-groove-light)] "
                : "dark:shadow-[-10px_10px_0_var(--bg-sidebar-groove-dark)] shadow-[-10px_10px_0_var(--bg-sidebar-groove-light)]"
            }`}
            style={{
              display: sidebar.activeItem === "/" ? "flex" : "none",
              content: "",
              position: "absolute",
              top: "-20px",
              right: localStorage.getItem("dir") === "ltr" ? "0px" : "",
              left: localStorage.getItem("dir") === "ltr" ? "" : "0px",
              width: "20px",
              height: "20px",
              background: "transparent",
              borderRadius: "50%",
            }}
          ></span>
          <span
            className={`${
              localStorage.getItem("dir") === "ltr"
                ? "dark:shadow-[10px_-10px_0_var(--bg-sidebar-groove-dark)] shadow-[10px_-10px_0_var(--bg-sidebar-groove-light)]"
                : "dark:shadow-[-10px_-10px_0_var(--bg-sidebar-groove-dark)] shadow-[-10px_-10px_0_var(--bg-sidebar-groove-light)]"
            }`}
            style={{
              display: sidebar.activeItem === "/" ? "flex" : "none",
              content: "",
              position: "absolute",
              bottom: "-20px",
              right: localStorage.getItem("dir") === "ltr" ? "0px" : "",
              left: localStorage.getItem("dir") === "ltr" ? "" : "0px",
              width: "20px",
              height: "20px",
              background: "transparent",
              borderRadius: "50%",
            }}
          ></span>
          <div className="flex flex-row items-center justify-start gap-2">
            <HomeRoundedIcon fontSize="medium" />
            <p style={{ fontWeight: "bold", fontSize: "15px" }}>
              {t("homeItem", { lng: localStorage.getItem("lang") })}
            </p>
          </div>
        </li>
        <li
          onClick={() => {
            sidebar.setActiveItem("/call");
            navigate("/call");
          }}
          className={`sidebar-item hover:bg-gradient-to-l dark:hover:from-bgGradientFromSidebarHoverItemDark dark:hover:to-bgGradientToSidebarHoverItemDark hover:to-bgGradientToSidebarHoverItemLight hover:from-bgGradientFromSidebarHoverItemLight ${
            sidebar.activeItem === "/call"
              ? "active hover:bg-none bg-bgSidebarActiveItemLight dark:bg-bgGradientFromSidebarHoverItemDark"
              : ""
          }`}
          style={{
            borderTopLeftRadius:
              localStorage.getItem("dir") === "ltr" ? "20px" : "",
            borderBottomLeftRadius:
              localStorage.getItem("dir") === "ltr" ? "20px" : "",
            borderTopRightRadius:
              localStorage.getItem("dir") === "ltr" ? "" : "20px",
            borderBottomRightRadius:
              localStorage.getItem("dir") === "ltr" ? "" : "20px",
          }}
        >
          <span
            className={`${
              localStorage.getItem("dir") === "ltr"
                ? "dark:shadow-[10px_10px_0_var(--bg-sidebar-groove-dark)] shadow-[10px_10px_0_var(--bg-sidebar-groove-light)] "
                : "dark:shadow-[-10px_10px_0_var(--bg-sidebar-groove-dark)] shadow-[-10px_10px_0_var(--bg-sidebar-groove-light)]"
            }`}
            style={{
              display: sidebar.activeItem === "/call" ? "flex" : "none",
              content: "",
              position: "absolute",
              top: "-20px",
              right: localStorage.getItem("dir") === "ltr" ? "0px" : "",
              left: localStorage.getItem("dir") === "ltr" ? "" : "0px",
              width: "20px",
              height: "20px",
              background: "transparent",
              borderRadius: "50%",
            }}
          ></span>
          <span
            className={`${
              localStorage.getItem("dir") === "ltr"
                ? "dark:shadow-[10px_-10px_0_var(--bg-sidebar-groove-dark)] shadow-[10px_-10px_0_var(--bg-sidebar-groove-light)]"
                : "dark:shadow-[-10px_-10px_0_var(--bg-sidebar-groove-dark)] shadow-[-10px_-10px_0_var(--bg-sidebar-groove-light)]"
            }`}
            style={{
              display: sidebar.activeItem === "/call" ? "flex" : "none",
              content: "",
              position: "absolute",
              bottom: "-20px",
              right: localStorage.getItem("dir") === "ltr" ? "0px" : "",
              left: localStorage.getItem("dir") === "ltr" ? "" : "0px",
              width: "20px",
              height: "20px",
              background: "transparent",
              borderRadius: "50%",
            }}
          ></span>
          <div className="flex flex-row items-center justify-start gap-2">
            <DomainVerificationIcon fontSize="medium" />
            <p style={{ fontWeight: "bold", fontSize: "15px" }}>
              {t("domainCheckItem", { lng: localStorage.getItem("lang") })}
            </p>
          </div>
        </li>
        <li
          onClick={() => {
            if (localStorage.getItem("user")) {
              sidebar.setActiveItem("/profile");
              navigate("/profile");
            } else {
              loginDialog.setShowLoginComponent(true);
              loginDialog.setPathToMove("/profile");
            }
          }}
          className={`sidebar-item  hover:bg-gradient-to-l dark:hover:from-bgGradientFromSidebarHoverItemDark dark:hover:to-bgGradientToSidebarHoverItemDark hover:to-bgGradientToSidebarHoverItemLight hover:from-bgGradientFromSidebarHoverItemLight ${
            sidebar.activeItem === "/profile"
              ? "active hover:bg-none bg-bgSidebarActiveItemLight dark:bg-bgGradientFromSidebarHoverItemDark"
              : ""
          }`}
          style={{
            borderTopLeftRadius:
              localStorage.getItem("dir") === "ltr" ? "20px" : "",
            borderBottomLeftRadius:
              localStorage.getItem("dir") === "ltr" ? "20px" : "",
            borderTopRightRadius:
              localStorage.getItem("dir") === "ltr" ? "" : "20px",
            borderBottomRightRadius:
              localStorage.getItem("dir") === "ltr" ? "" : "20px",
          }}
        >
          <span
            className={`${
              localStorage.getItem("dir") === "ltr"
                ? "dark:shadow-[10px_10px_0_var(--bg-sidebar-groove-dark)] shadow-[10px_10px_0_var(--bg-sidebar-groove-light)] "
                : "dark:shadow-[-10px_10px_0_var(--bg-sidebar-groove-dark)] shadow-[-10px_10px_0_var(--bg-sidebar-groove-light)]"
            }`}
            style={{
              display: sidebar.activeItem === "/profile" ? "flex" : "none",
              content: "",
              position: "absolute",
              top: "-20px",
              right: localStorage.getItem("dir") === "ltr" ? "0px" : "",
              left: localStorage.getItem("dir") === "ltr" ? "" : "0px",
              width: "20px",
              height: "20px",
              background: "transparent",
              borderRadius: "50%",
            }}
          ></span>
          <span
            className={`${
              localStorage.getItem("dir") === "ltr"
                ? "dark:shadow-[10px_-10px_0_var(--bg-sidebar-groove-dark)] shadow-[10px_-10px_0_var(--bg-sidebar-groove-light)]"
                : "dark:shadow-[-10px_-10px_0_var(--bg-sidebar-groove-dark)] shadow-[-10px_-10px_0_var(--bg-sidebar-groove-light)]"
            }`}
            style={{
              display: sidebar.activeItem === "/profile" ? "flex" : "none",
              content: "",
              position: "absolute",
              bottom: "-20px",
              right: localStorage.getItem("dir") === "ltr" ? "0px" : "",
              left: localStorage.getItem("dir") === "ltr" ? "" : "0px",
              width: "20px",
              height: "20px",
              background: "transparent",
              borderRadius: "50%",
            }}
          ></span>
          <div className="flex flex-row items-center justify-start gap-2">
            <AccountCircleIcon fontSize="medium" />
            <p style={{ fontWeight: "bold", fontSize: "15px" }}>
              {t("profileItem", { lng: localStorage.getItem("lang") })}
            </p>
          </div>
        </li>
      </ul>
    </>
  );
}
