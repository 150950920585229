import { Grid } from "@mui/material";
import React from "react";
import { StyledTextField } from "../../../../components/styled-components";

export function VcardForm({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  phone,
  setPhone,
  email,
  setEmail,
  url,
  setUrl,
  setQrCodeValue,
  qrCodeValueStructures,
}) {
  return (
    <Grid container spacing={2} columns={12}>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        {" "}
        <StyledTextField
          className="w-full"
          label="First name"
          value={firstName}
          onChange={(e) => {
            setQrCodeValue(qrCodeValueStructures.vcard);
            setFirstName(e.target.value);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        {" "}
        <StyledTextField
          className="w-full"
          label="Last name"
          value={lastName}
          onChange={(e) => {
            setQrCodeValue(qrCodeValueStructures.vcard);
            setLastName(e.target.value);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        {" "}
        <StyledTextField
          type="tel"
          className="w-full"
          label="Phone"
          value={phone}
          onChange={(e) => {
            setQrCodeValue(qrCodeValueStructures.vcard);
            setPhone(e.target.value);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <StyledTextField
          type="email"
          className="w-full"
          label="Email"
          value={email}
          onChange={(e) => {
            setQrCodeValue(qrCodeValueStructures.vcard);
            setEmail(e.target.value);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <StyledTextField
          type="url"
          className="w-full"
          label="Website"
          value={url}
          onChange={(e) => {
            setQrCodeValue(qrCodeValueStructures.vcard);
            setUrl(e.target.value);
          }}
        />
      </Grid>
    </Grid>
  );
}
