import { Grid, Tooltip } from "@mui/material";
import Color from "color";
import React, { useEffect } from "react";
import { useState } from "react";

export function ThreeBaseColors({
  setPalletInput,
  rgbInput,
  rgbToHex,
  r_color,
  set_r_color,
  b_color,
  set_b_color,
  g_color,
  set_g_color,
}) {
  //initial states

  // handle set pallet input
  const handleSetPalletInput = (hexCode) => {
    setPalletInput(hexCode);
  };
  // handle copy text
  const handleCopyHashText = (content) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(content);
    } else {
      console.log("Clipboard API not supported");
    }
  };
  useEffect(() => {
    console.log("rgbInput: ");
    set_r_color(Color(rgbInput).array()[0]);
    set_g_color(Color(rgbInput).array()[1]);
    set_b_color(Color(rgbInput).array()[2]);
  }, [rgbInput]);
  return (
    <>
      <Grid container spacing={1} columns={12}>
        <Grid
          className="flex justify-start items-center"
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          <span className="text-lg text-slate-100">Tricolour</span>
        </Grid>
        <Grid
          className="flex justify-start items-center gap-5"
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          <Tooltip title={rgbToHex(r_color, 0, 0)} placement="top">
            <div
              onClick={() => {
                handleSetPalletInput(rgbToHex(r_color, 0, 0));
                handleCopyHashText(rgbToHex(r_color, 0, 0));
                document
                  .getElementsByClassName("container")[0]
                  .scrollTo({ top: 0, behavior: "smooth" });
              }}
              className="w-12 h-12 rounded-full cursor-pointer"
              style={{
                backgroundColor: `rgb(${r_color},0,0)`,
                border: "0.8px solid gray",
              }}
            ></div>
          </Tooltip>{" "}
          <Tooltip title={rgbToHex(0, g_color, 0)} placement="top">
            <div
              onClick={() => {
                handleSetPalletInput(rgbToHex(0, g_color, 0));

                handleCopyHashText(rgbToHex(0, g_color, 0));
                document
                  .getElementsByClassName("container")[0]
                  .scrollTo({ top: 0, behavior: "smooth" });
              }}
              className="w-12 h-12 rounded-full cursor-pointer"
              style={{
                backgroundColor: `rgb(0,${g_color},0)`,
                border: "0.8px solid gray",
              }}
            ></div>{" "}
          </Tooltip>{" "}
          <Tooltip title={rgbToHex(0, 0, b_color)} placement="top">
            <div
              onClick={() => {
                handleSetPalletInput(rgbToHex(0, 0, b_color));
                handleCopyHashText(rgbToHex(0, 0, b_color));
                document
                  .getElementsByClassName("container")[0]
                  .scrollTo({ top: 0, behavior: "smooth" });
              }}
              className="w-12 h-12 rounded-full cursor-pointer"
              style={{
                backgroundColor: `rgb(0,0,${b_color})`,
                border: "0.8px solid gray",
              }}
            ></div>
          </Tooltip>
        </Grid>
      </Grid>
    </>
  );
}
