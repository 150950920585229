import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  styled,
  TextField,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  StyledButton,
  StyledTextField,
} from "../../components/styled-components";
import * as yup from "yup";
import AccessAlarmRoundedIcon from "@mui/icons-material/AccessAlarmRounded";
import { useNavigate } from "react-router-dom";
import { PinInput, StatefulPinInput } from "react-input-pin-code";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { lng } from "../../lang/languagesObjects";
import { handleAutoLogOut } from "../../includes/functions";
import { useApi } from "../../customAxios";
export function LoginComponent() {
  // for multi language
  const { t } = useTranslation("prints");
  i18next.addResourceBundle("fa", "prints", lng.login.fa);
  i18next.addResourceBundle("en", "prints", lng.login.en);
  i18next.addResourceBundle("ar", "prints", lng.login.ar);
  // initial states
  const api = useApi();
  const [openLoginDialog, setOpenLoginDialog] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(true);
  const { loginDialog } = useContext(AppContext);
  const [emialOrPhoneInput, setEmialOrPhoneInput] = useState("");
  const [otpInput, setOtpInput] = useState(["", "", "", "", "", ""]);
  const [disabledLoginButton, setDisabledLoginButton] = useState(false);
  const [timer, setTimer] = useState(90);
  const [startTimer, setStartTimer] = useState(false);
  const [showTimer, setShowTimer] = useState(true);
  const [showResendButton, setShowResendButton] = useState(false);
  const navigate = useNavigate();
  // handle timer interval
  useEffect(() => {
    if (startTimer) {
      const interval = setInterval(() => {
        if (timer > 0) {
          setTimer((time) => time - 1);
        } else {
          setShowResendButton(true);
          setShowTimer(false);
          clearInterval(interval);
          setStartTimer(false);
          setTimer(90);
          console.log("clear interval");
        }
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [timer, startTimer]);
  useEffect(() => {
    handleOpenLoginDialog();
  }, []);
  // validation
  const validationSchema = yup.object().shape({
    inputLogin: yup
      .string()
      .test({
        test: function (value) {
          return (
            yup.string().email().isValidSync(value) ||
            yup
              .string()
              .matches(/^09\d{9}$/)
              .isValidSync(value)
          );
        },
        message: "The mobile number or email entered is not valid",
      })
      .required("This field is required to be filled"),
  });
  //for open dialog
  const handleOpenLoginDialog = () => {
    setOpenLoginDialog(true);
  };

  //for close dialog
  const handleCloseLoginDialog = () => {
    setOpenLoginDialog(false);
    loginDialog.setShowLoginComponent(false);
  };

  //for otp request
  const handleOTPRequest = () => {
    validationSchema
      .validate({ inputLogin: emialOrPhoneInput }, { abortEarly: false })
      .then((validData) => {
        setShowLoginForm(false);
        setDisabledLoginButton(true);
        setShowLoading(true);
        setShowOtpInput(false);
        api
          .post("/auth/otp", {
            mobile: emialOrPhoneInput,
          })
          .then((response) => {
            setShowLoading(false);
            if (response.data.status === false) {
              console.log("OTPstatus -> false: ", response.data.message);
            }
            setShowOtpInput(true);
            setDisabledLoginButton(true);
            setStartTimer(true);
            console.log("otp response", response.data);
          })
          .catch((err) => {
            setShowLoading(false);
            setShowLoginForm(true);
            setShowOtpInput(false);
            setDisabledLoginButton(false);
            console.log("profile err: ", err);
          });
      })
      .catch((validErr) => {
        console.log("valid not ok!!!", validErr);
      });
  };

  //for join
  const handleJoinRequest = () => {
    setShowLoading(true);
    setShowOtpInput(false);
    api
      .post("/auth/join", {
        otp: otpInput.join(""),
        mobile: emialOrPhoneInput,
      })

      .then((res) => {
        if (res.data.status === false) {
          console.log("JOINstatus -> false: ", res.data.message);
        }
        localStorage.setItem(
          "user",
          JSON.stringify({
            user: {
              token: res.data.data.token,
              data: res.data.data.user,
            },
          })
        );
        setShowTimer(false);
        handleCloseLoginDialog();
        setShowLoading(false);
        navigate(loginDialog.pathToMove);
        // setStartTimer(false);
        console.log("join res", res);
      })
      .catch((err) => {
        console.log("join error", err);
        setShowLoading(false);
        setShowOtpInput(true);
        setOtpInput(["", "", "", "", "", ""]);
      });
  };

  // for gradient progress
  function GradientCircularProgress() {
    return (
      <>
        <svg width={0} height={0}>
          <defs>
            <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#e01cd5" />
              <stop offset="100%" stopColor="#1CB5E0" />
            </linearGradient>
          </defs>
        </svg>
        <CircularProgress
          size={40}
          sx={{ "svg circle": { stroke: "url(#my_gradient)" } }}
        />
      </>
    );
  }

  // for resend otp
  const handleResendOTP = () => {
    setShowLoginForm(false);
    setDisabledLoginButton(true);
    setShowLoading(true);
    setShowOtpInput(false);

    api
      .post("/auth/otp", {
        mobile: emialOrPhoneInput,
      })
      .then((response) => {
        if (response.data.status === false) {
          console.log("OTPstatus -> false: ", response.data.message);
        }
        setOtpInput(["", "", "", "", "", ""]);
        setShowOtpInput(true);
        setShowLoading(false);
        setShowResendButton(false);
        setShowTimer(true);
        setStartTimer(true);
        console.log("otp response", response.data);
      })
      .catch((err) => {
        console.log("profile err: ", err);
        setShowOtpInput(true);
        setShowLoading(false);
        setShowTimer(false);
        setStartTimer(false);
      });
  };

  return (
    <>
      <Dialog
        open={openLoginDialog}
        fullWidth
        maxWidth={"xs"}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "22px",
            background: "transparent",
          },
        }}
      >
        <DialogTitle
          sx={{
            borderBottom: "1px solid #666666",
            direction: "ltr",
          }}
          className="flex flex-row justify-between items-center bg-gradient-to-l from-bgGradientFromTitleDialogLight to-bgGradientToTitleDialogLight dark:from-bgGradientFromTitleDialogDark dark:to-bgGradientToTitleDialogDark "
        >
          <p className="text-xl text-[#e4e4e4]">
            {t("loginTitle", { lng: localStorage.getItem("lang") })}
          </p>
          <CloseRoundedIcon
            onClick={handleCloseLoginDialog}
            fontSize="medium"
            className="cursor-pointer"
            htmlColor="#e4e4e4"
            sx={{
              opacity: 0.7,
              "&:hover": {
                opacity: 1,
              },
            }}
          />
        </DialogTitle>
        <DialogContent
          className="bg-gradient-to-l dark:from-bgGradientFromContentDialogDark dark:to-bgGradientToContentDialogDark from-bgGradientFromContentDialogLight to-bgGradientToContentDialogLight"
          sx={{
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          {showLoginForm && (
            <Grid
              container
              spacing={1}
              columns={12}
              className=""
              sx={{ pt: "15px", pb: "2px" }}
            >
              <Grid item xs={12} sm={9} md={9} lg={9} xl={9}>
                <StyledTextField
                  value={emialOrPhoneInput}
                  onChange={(e) => setEmialOrPhoneInput(e.target.value)}
                  autoFocus
                  label={t("mobileLabel", {
                    lng: localStorage.getItem("lang"),
                  })}
                  type="tel"
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <StyledButton
                  disabled={disabledLoginButton}
                  className="w-full bg-gradient-to-l dark:from-bgGradientFromCustomButtonDark dark:to-bgGradientToCustomButtonDark dark:hover:from-bgGradientFromCustomButtonHoverDark dark:hover:to-bgGradientToCustomButtonHoverDark bg-bgCustomButtonLight hover:bg-bgCustomButtonHoverLight  "
                  onClick={handleOTPRequest}
                  variant="contained"
                >
                  {t("loginButton", { lng: localStorage.getItem("lang") })}
                </StyledButton>
              </Grid>
            </Grid>
          )}
          {showLoading && (
            <div className="flex flex-row justify-center items-center w-full mt-3">
              <GradientCircularProgress />
            </div>
          )}
          {showOtpInput && (
            <Grid
              container
              spacing={2}
              columns={12}
              sx={{ pt: "15px", pb: "2px" }}
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="">
                <PinInput
                  inputStyle={{
                    color: "rgb(224 224 226)",
                    // borderRadius: "50%",
                    borderRadius: "12px",
                    paddingBottom: "3px",
                  }}
                  containerStyle={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  autoFocus={true}
                  autoTab={true}
                  showState={true}
                  type="number"
                  values={otpInput}
                  onChange={(value, index, values) => setOtpInput(values)}
                  onComplete={(value, index) => {
                    handleJoinRequest();
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className="">
                <StyledButton
                  className="w-full bg-gradient-to-l dark:from-bgGradientFromCustomButtonDark dark:to-bgGradientToCustomButtonDark dark:hover:from-bgGradientFromCustomButtonHoverDark dark:hover:to-bgGradientToCustomButtonHoverDark bg-bgCustomButtonLight hover:bg-bgCustomButtonHoverLight  "
                  onClick={handleJoinRequest}
                  size="medium"
                  variant="contained"
                >
                  {t("verifyButton", { lng: localStorage.getItem("lang") })}{" "}
                </StyledButton>
              </Grid>
              {showTimer && (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  className="flex flex-row justify-center items-center"
                >
                  <div className="flex flex-row  items-center justify-center gap-1">
                    <AccessAlarmRoundedIcon htmlColor="#e4e4e4" />
                    <p className="text-[#e4e4e4]">{timer}s</p>
                  </div>
                </Grid>
              )}
              {showResendButton && (
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className="">
                  <StyledButton
                    className="w-full bg-gradient-to-l dark:from-bgGradientFromCustomButtonDark dark:to-bgGradientToCustomButtonDark dark:hover:from-bgGradientFromCustomButtonHoverDark dark:hover:to-bgGradientToCustomButtonHoverDark bg-bgCustomButtonLight hover:bg-bgCustomButtonHoverLight  "
                    onClick={handleResendOTP}
                    size="medium"
                    variant="contained"
                  >
                    {t("resendButton", { lng: localStorage.getItem("lang") })}
                  </StyledButton>
                </Grid>
              )}
            </Grid>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
