import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  List,
  ListItem,
  TextField,
} from "@mui/material";
import { Netmask } from "netmask";
import React, { useEffect, useState } from "react";
import { DataItem } from "./components/DataItem";
import {
  StyledAutocomplete,
  StyledButton,
  StyledTextField,
} from "../../../components/styled-components";
import { Address4 } from "ip-address";
import SearchIcon from "@mui/icons-material/Search";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ConstructionIcon from "@mui/icons-material/Construction";
import { Link } from "react-router-dom";
import { ProgressSubnetMaskList } from "../../../loading-components/ProgressPingWorld";
export function SubnetMask() {
  // initial states
  const [ipBrowser, setIpBrowser] = useState(null);
  const [subnetKeys, setSubnetKeys] = useState([]);
  const [ipInput, setIpInput] = useState(null);
  const [bitMask, setBitMask] = useState(null);

  // bit mask options
  const bitMaskOptions = [
    { id: 1, label: "1" },

    { id: 2, label: "2" },

    { id: 3, label: "3" },

    { id: 4, label: "4" },

    { id: 5, label: "5" },

    { id: 6, label: "6" },

    { id: 7, label: "7" },

    { id: 8, label: "8" },

    { id: 9, label: "9" },

    { id: 10, label: "10" },

    { id: 11, label: "11" },

    { id: 12, label: "12" },

    { id: 13, label: "13" },

    { id: 14, label: "14" },

    { id: 15, label: "15" },

    { id: 16, label: "16" },

    { id: 17, label: "17" },

    { id: 18, label: "18" },

    { id: 19, label: "19" },

    { id: 20, label: "20" },

    { id: 21, label: "21" },

    { id: 22, label: "22" },

    { id: 23, label: "23" },

    { id: 24, label: "24" },

    { id: 25, label: "25" },

    { id: 26, label: "26" },

    { id: 27, label: "27" },

    { id: 28, label: "28" },

    { id: 29, label: "29" },

    { id: 30, label: "30" },

    { id: 31, label: "31" },

    { id: 32, label: "32" },
  ];
  const [netWorkData, setNetworkData] = useState(null);
  // handle calcute subnet mask
  const handleSubnetMask = (netWorkAddress) => {
    let v4 = null;
    try {
      const address = new Address4(netWorkAddress.trim());
      console.log(`Valid IP address: ${address}`);
      v4 = true;
    } catch (error) {
      console.log(error);
      v4 = false;
    }

    if (v4) {
      const block = new Netmask(`${netWorkAddress.trim()}/${bitMask}`);
      console.log("trueeeeeee");

      setNetworkData({
        base: block.base,
        mask: block.mask,
        bitmask: block.bitmask,
        hostmask: block.hostmask,
        broadcast: block.broadcast,
        size: block.size,
        first: block.first,
        last: block.last,
      });
    }
  };
  // handle default value
  useEffect(() => {
    setBitMask("12");
  }, []);

  // get ip
  useEffect(() => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch("https://api.ipify.org", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        setIpBrowser(result);
      })
      .catch((error) => console.log("error", error));
  }, []);
  //
  useEffect(() => {
    handleSubnetMask(ipBrowser);
  }, [ipBrowser]);
  //

  return (
    <div className="w-full pb-28">
      <Card
        className={`${
          localStorage.getItem("theme") === "dark-mode"
            ? "card-bg-gradient-dark"
            : "card-bg-gradient-light"
        } w-full`}
      >
        {
          <CardHeader
            title={
              <div
                className="w-full h-9"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent:
                    localStorage.getItem("dir") === "ltr" ? "start" : "end",
                }}
              >
                <p className="text-slate-200 text-2xl w-fit ">Subnet Mask</p>
              </div>
            }
          />
        }
        <CardContent>
          <Grid container spacing={5} columns={12}>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
              {" "}
              <Grid container spacing={3} columns={12}>
                {" "}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Grid container spacing={1} columns={12}>
                    <Grid
                      className="flex justify-start items-center"
                      item
                      xs={7}
                      sm={7}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <StyledTextField
                        size="small"
                        className="w-full"
                        label="Network Address"
                        value={ipInput}
                        onChange={(e) => {
                          setIpInput(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid
                      className="flex justify-start items-center"
                      item
                      xs={3}
                      sm={3}
                      md={4}
                      lg={4}
                      xl={4}
                    >
                      <StyledAutocomplete
                        disableClearable
                        sx={{
                          textAlign: "left",
                          width: "100%",
                          direction: localStorage.getItem("dir"),
                        }}
                        value={bitMask}
                        onChange={(e, value) => {
                          setBitMask(value?.label);
                        }}
                        size="small"
                        options={bitMaskOptions}
                        PaperComponent={({ children }) => (
                          <div
                            className={`dark:bg-slate-900 dark:text-slate-300`}
                          >
                            {children}
                          </div>
                        )}
                        renderInput={(params) => (
                          <TextField
                            label="Prefix"
                            size="medium"
                            {...params}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                color: "#d6d3d1",
                                "& fieldset": {
                                  borderColor: "#d6d3d1",
                                },
                                "&:hover fieldset": {
                                  borderColor: "white", // change border color on hover
                                },
                              },
                              "& .MuiInputLabel-root": {
                                color: "#d6d3d1",
                              },
                              "& .MuiSvgIcon-root": {
                                color: "white", // change icon color
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      className="flex justify-start items-center"
                      item
                      xs={2}
                      sm={2}
                      md={2}
                      lg={2}
                      xl={2}
                    >
                      <StyledButton
                        onClick={() => {
                          handleSubnetMask(ipInput);
                        }}
                        className=" w-full h-full bg-gradient-to-l dark:from-bgGradientFromCustomButtonDark dark:to-bgGradientToCustomButtonDark dark:hover:from-bgGradientFromCustomButtonHoverDark dark:hover:to-bgGradientToCustomButtonHoverDark bg-bgCustomButtonLight hover:bg-bgCustomButtonHoverLight"
                      >
                        <SearchIcon fontSize="medium" />
                      </StyledButton>
                    </Grid>
                  </Grid>
                </Grid>
                {netWorkData ? (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {" "}
                    <List
                      dense={false}
                      className="w-full text-slate-100 bg-gray-500 rounded-xl"
                      sx={{ maxHeight: "400px", overflowY: "auto" }}
                    >
                      {netWorkData &&
                        Object.keys(netWorkData).map((item, index) => {
                          return (
                            <DataItem
                              key={index}
                              name={item}
                              value={netWorkData[item]}
                            />
                          );
                        })}
                    </List>
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ProgressSubnetMaskList />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
              {" "}
              <Grid
                container
                spacing={5}
                columns={12}
                className=" text-slate-200"
              >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className="w-full h-full flex flex-col justify-start items-start gap-3">
                    <div className="w-full flex justify-start items-center gap-1">
                      <HelpOutlineIcon fontSize="small" />
                      <h4 className="font-bold">Whats Subnet Mask ?</h4>
                    </div>
                    <p style={{ textAlign: "justify", lineHeight: "30px" }}>
                      A subnet mask is a 32-bit number used in IPv4 (or 128-bit
                      for IPv6){" "}
                      <Link
                        className="hover:text-blue-500 hover:underline text-blue-400 cursor-pointer"
                        to={"/ip-convertor"}
                      >
                        "IP Convert"
                      </Link>{" "}
                      that divides your IP address into network and host
                      portions. The network portion ensures that data packets
                      reach the right network, while the host portion identifies
                      a specific device on that network.
                    </p>
                  </div>
                </Grid>{" "}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className="w-full h-full flex flex-col justify-start items-start gap-3">
                    <div className="w-full flex justify-start items-center gap-1">
                      <ConstructionIcon fontSize="small" />
                      <h4 className="font-bold">
                        What is the structure of Musk subnet ?
                      </h4>
                    </div>
                    <p style={{ textAlign: "justify", lineHeight: "30px" }}>
                      A subnet mask is a 32-bit number created by setting host
                      bits to all 0s and setting network bits to all 1s. In this
                      way, the subnet mask separates the IP address into the
                      network and host addresses. The “255” address is always
                      assigned to a broadcast address, and the “0” address is
                      always assigned to a network address.
                    </p>
                  </div>
                </Grid>{" "}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}
