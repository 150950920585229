import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import * as yup from "yup";
import ClearIcon from "@mui/icons-material/Clear";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import {
  StyledAutocomplete,
  StyledButton,
} from "../../components/styled-components";
import AltRouteOutlinedIcon from "@mui/icons-material/AltRouteOutlined";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../context/AppContext";
import i18next from "i18next";

import { lng } from "../../lang/languagesObjects";
import { useNavigate } from "react-router-dom";
import { handleAutoLogOut } from "../../includes/functions";
import { useApi } from "../../customAxios";
export function Robot({ language, darkMode, flagURL }) {
  // for multi language
  const { t } = useTranslation("prints");
  i18next.addResourceBundle("fa", "prints", lng.robotCheck.fa);
  i18next.addResourceBundle("en", "prints", lng.robotCheck.en);
  i18next.addResourceBundle("ar", "prints", lng.robotCheck.ar);
  //initial states
  const api = useApi();
  const navigate = useNavigate();
  const { lang } = useContext(AppContext);
  const [addressInput, setAddressInput] = useState("");
  const inputRef = useRef();
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [textFieldError, setTextFieldError] = useState(false);
  const [storedValues, setStoredValues] = useState(
    JSON.parse(localStorage.getItem("inputValues")) || []
  );
  const [robotData, setRobotData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showRobots, setShowRobots] = useState(false);
  const [showSectionRobot, setShowSectionRobot] = useState(false);
  const [selectedRobotIndex, setSelectedRobotIndex] = useState(null);
  const [shouldFetchData, setShouldFetchData] = useState(false);
  const [showHelperText, setShowHelperText] = useState("");

  useEffect(() => {
    if (shouldFetchData) {
      api
        .post("/tools/seo/robots", { url: addressInput })
        .then((res) => {
          console.log("robot res", res);
          setDisableSubmit(false);
          setRobotData(res.data.data);
          setIsLoading(false);
          setShowRobots(true);
        })
        .catch((err) => {
          console.log("robot err", err);
          setDisableSubmit(false);
          setIsLoading(true);
          setShowHelperText(
            t("helperText", { lng: localStorage.getItem("lang") })
          );
        });
    }
    return () => {
      setShouldFetchData(false);
    };
  }, [shouldFetchData]);

  // handle local storage
  useEffect(() => {
    const savedValues = localStorage.getItem("inputValues");
    if (savedValues) {
      setStoredValues(JSON.parse(savedValues));
    }
  }, []);

  const handleDeleteOption = (option) => {
    const updatedValues = storedValues.filter(
      (value) => value !== option.value
    );
    const updatedValuesString = JSON.stringify(updatedValues);
    localStorage.setItem("inputValues", updatedValuesString);
    setStoredValues(updatedValues);
  };

  const renderOption = (props, option, state) => (
    <li
      {...props}
      className="flex flex-row items-center justify-between mx-3 my-3"
    >
      {option.label}
      <button
        style={{ fontWeight: "bold", color: "#646666" }}
        onClick={(e) => {
          e.stopPropagation();
          handleDeleteOption(option);
        }}
      >
        <ClearIcon fontSize="small" />
      </button>
    </li>
  );

  const options = storedValues
    ? storedValues.map((value) => ({ label: value, value })).reverse()
    : [];

  //validation schema
  const validationSchema = yup.object().shape({
    url: yup
      .string()
      .url("The entered address is not valid")
      .required("Filling this field is mandatory"),
  });

  //validation's function
  function validation() {
    validationSchema
      .validate(
        {
          url: inputRef.current.value.startsWith("https://")
            ? inputRef.current.value
            : "https://" + inputRef.current.value,
        },
        { abortEarly: false }
      )
      .then((validData) => {
        setRobotData(null);
        const inputValue = inputRef.current.value;
        if (inputValue.trim() !== "") {
          const existingValues = new Set(storedValues);
          existingValues.add(inputValue);
          const updatedValues = Array.from(existingValues);
          setStoredValues(updatedValues);
          localStorage.setItem("inputValues", JSON.stringify(updatedValues));
        }
        setDisableSubmit(true);
        setTextFieldError(false);
        setIsLoading(true);
        setShowHelperText("wait...");
        setShowRobots(false);
        setShouldFetchData(true);
      })
      .catch((err) => {
        setTextFieldError(true);
        setShouldFetchData(false);
      });
  }

  function handleSubmit() {
    const inputValue = inputRef.current.value;
    if (inputValue.startsWith("https://")) {
      setAddressInput(inputValue);
    } else {
      setAddressInput("https://" + inputValue);
    }
    validation();
  }

  return (
    <>
      <div className="flex flex-col items-center justify-start gap-2 mb-36 ">
        <Card
          sx={{ width: "100%", backgroundColor: "#333C4D" }}
          className={`${
            localStorage.getItem("theme") === "dark-mode"
              ? "card-bg-gradient-dark"
              : "card-bg-gradient-light"
          }`}
        >
          <CardContent className="flex flex-col items-center justify-center gap-1 w-full">
            <Grid
              container
              spacing={1}
              columns={12}
              className="max-w-[550px] pt-2"
            >
              <Grid item xs={8} sm={8} md={8} lg={8}>
                <StyledAutocomplete
                  size="small"
                  freeSolo
                  options={options}
                  PaperComponent={({ children }) => (
                    <div
                      className={
                        darkMode
                          ? `dark:bg-slate-900 dark:text-slate-300`
                          : `bg-slate-50 text-slate-700`
                      }
                    >
                      {children}
                    </div>
                  )}
                  renderInput={(params) => (
                    <TextField
                      error={textFieldError}
                      inputRef={inputRef}
                      label={t("enterUrlRobotCheck", {
                        lng: localStorage.getItem("lang"),
                      })}
                      {...params}
                      sx={{
                        width: "100%",

                        "& .MuiOutlinedInput-root": {
                          color: darkMode ? "#d6d3d1" : "black",
                          "& fieldset": {
                            borderColor: darkMode ? "#d6d3d1" : "black",
                          },
                          "&:hover fieldset": {
                            borderColor: darkMode ? "white" : "black", // change border color on hover
                          },
                          "& .MuiSvgIcon-root": {
                            color: darkMode ? "white" : "black", // change icon color
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: darkMode ? "#d6d3d1" : "black",
                        },
                      }}
                    />
                  )}
                  renderOption={renderOption}
                />
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4}>
                {" "}
                <StyledButton
                  className="bg-gradient-to-l dark:from-bgGradientFromCustomButtonDark dark:to-bgGradientToCustomButtonDark dark:hover:from-bgGradientFromCustomButtonHoverDark dark:hover:to-bgGradientToCustomButtonHoverDark bg-bgCustomButtonLight hover:bg-bgCustomButtonHoverLight  "
                  style={{
                    width: "100%",
                    color: "#e7e5e4",
                    border: "none",
                  }}
                  size="large"
                  disabled={disableSubmit}
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  <SearchOutlinedIcon fontSize="medium" />
                </StyledButton>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card
          className={`w-full ${
            localStorage.getItem("theme") === "dark-mode"
              ? "card-bg-gradient-dark"
              : "card-bg-gradient-light"
          }`}
        >
          <CardContent>
            {/* <div className="flex flex-row items-center  justify-between  gap-1 w-full p-1 rounded-md"> */}
            <Grid container spacing={2} columns={12} className="pl-1 pr-1">
              <Grid
                item
                xs={9}
                sm={9}
                md={9}
                lg={9}
                className="flex flex-row items-center justify-start"
              >
                <div className="flex flex-row items-center justify-center gap-1 whitespace-nowrap overflow-hidden text-ellipsis">
                  <p className="text-[#e7e5e4] ">Check robot:</p>
                  <p className="text-[#e7e5e4] whitespace-nowrap overflow-hidden text-ellipsis">
                    {addressInput}
                  </p>
                </div>
              </Grid>
              <Grid
                item
                xs={3}
                sm={3}
                md={3}
                lg={3}
                className="flex flex-row items-center justify-end "
                sx={{
                  width: "100%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                <p className="text-[#e7e5e4]">
                  {t("number", { lng: localStorage.getItem("lang") })}:{" "}
                  {robotData ? robotData?.length : 0}
                </p>
              </Grid>
            </Grid>

            {/* </div> */}
          </CardContent>
        </Card>
        <Card
          className={`w-full ${
            localStorage.getItem("theme") === "dark-mode"
              ? "card-bg-gradient-dark"
              : "card-bg-gradient-light"
          }`}
        >
          <CardContent>
            <Grid spacing={1} columns={12} container className="">
              {robotData ? (
                robotData.map((item, index) => {
                  return showRobots ? (
                    <Grid item xs={6} sm={4} md={3} lg={3} xl={2}>
                      <Card
                        className="bg-bgOptionsCardsLight dark:bg-bgOptionsCardsDark"
                        sx={{
                          background: "var(--bg-gradient-options-cards)",
                        }}
                      >
                        <CardActionArea
                          onClick={() => {
                            setShowSectionRobot(true);
                            setShowRobots(false);
                            setSelectedRobotIndex(index);
                          }}
                        >
                          <CardContent className="flex flex-col items-center justify-center gap-1 font-bold flex-wrap">
                            <div className=" flex flex-row items-center justify-center content-center gap-1 w-full">
                              <img
                                alt=""
                                src={flagURL + item.userAgent.logo}
                                style={{ width: "45px", height: "45px" }}
                                className="block"
                              />
                              <p
                                style={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  width: "100%",
                                }}
                                className="text-slate-300"
                              >
                                {item.userAgent.name}
                              </p>
                            </div>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  ) : (
                    showSectionRobot && selectedRobotIndex === index && (
                      <>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={6}
                          lg={6}
                          className="h-full"
                        >
                          <Card sx={{ backgroundColor: "#22233a" }}>
                            <CardHeader
                              sx={{
                                "& .MuiCardHeader-avatar": {
                                  mr: 1, // کاهش فاصله بین عنوان و آواتار
                                  pb: 0.5,
                                },
                                // background: "rgb(2,0,36)",
                                background:
                                  localStorage.getItem("dir") === "ltr"
                                    ? "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(20,131,3,0.2553396358543417) 35%)"
                                    : " linear-gradient(90deg,rgba(2,0,36,1) 100%,  rgba(20,131,3,0.2553396358543417) 50%)",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                              title={
                                <span
                                  className="font-bold "
                                  style={{
                                    fontSize: "16px",
                                    color: "#e7e5e4",
                                    paddingLeft: "7px",
                                  }}
                                >
                                  {t("allow", {
                                    lng: localStorage.getItem("lang"),
                                  })}
                                </span>
                              }
                              action={
                                <span
                                  className="font-bold pr-2  "
                                  style={{
                                    fontSize: "16px",
                                    color: "#e7e5e4",
                                  }}
                                >
                                  {t("number", {
                                    lng: localStorage.getItem("lang"),
                                  })}
                                  : {item.allow.length}
                                </span>
                              }
                              avatar={
                                <VerifiedOutlinedIcon
                                  fontSize="small"
                                  htmlColor="green"
                                />
                              }
                            />
                            <CardContent
                              sx={{
                                "&::-webkit-scrollbar": {
                                  height: "4px",
                                },
                                color: "#e7e5e4",
                              }}
                              className="flex flex-col items-start justify-start gap-2 min-h-60 max-h-60 overflow-y-auto "
                            >
                              {item.allow.map((allowItem, index) => (
                                <div
                                  key={index}
                                  className="flex items-center justify-center gap-2"
                                >
                                  <AltRouteOutlinedIcon
                                    fontSize="small"
                                    htmlColor="#7efa70"
                                  />
                                  {allowItem}
                                </div>
                              ))}
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={6}
                          lg={6}
                          className="h-full"
                        >
                          <Card sx={{ backgroundColor: "#22233a" }}>
                            <CardHeader
                              avatar={
                                <RemoveCircleOutlineOutlinedIcon
                                  fontSize="small"
                                  htmlColor="red"
                                />
                              }
                              action={
                                <span
                                  className="font-bold pr-2"
                                  style={{
                                    fontSize: "16px",
                                    color: "#e7e5e4",
                                  }}
                                >
                                  {t("number", {
                                    lng: localStorage.getItem("lang"),
                                  })}
                                  : {item.disAllow.length}
                                </span>
                              }
                              title={
                                <span
                                  className="font-bold "
                                  style={{
                                    fontSize: "16px",
                                    color: "#e7e5e4",
                                    paddingLeft: "8px",
                                  }}
                                >
                                  {t("disAllow", {
                                    lng: localStorage.getItem("lang"),
                                  })}
                                </span>
                              }
                              sx={{
                                "& .MuiCardHeader-avatar": {
                                  mr: 1, // کاهش فاصله بین عنوان و آواتار
                                  pb: 0.5,
                                },
                                // background: " rgb(2,0,36)",
                                background:
                                  localStorage.getItem("dir") === "ltr"
                                    ? "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(131,6,6,0.2553396358543417) 35%)"
                                    : "linear-gradient(90deg, rgba(2,0,36,1) 100%, rgba(131,6,6,0.2553396358543417) 50%)",
                              }}
                            />
                            <CardContent
                              sx={{
                                "&::-webkit-scrollbar": {
                                  height: "4px", // ارتفاع نوار اسکرول بار را به 8 پیکسل کاهش دهید
                                },
                                color: "#e7e5e4",
                              }}
                              className=" flex flex-col items-start justify-start gap-2 min-h-60 max-h-60 overflow-y-auto "
                            >
                              {item.disAllow.map((disAllowItem, index) => (
                                <div
                                  key={index}
                                  className="flex items-center justify-center gap-2"
                                >
                                  <AltRouteOutlinedIcon
                                    fontSize="small"
                                    htmlColor="#fa7070"
                                  />
                                  {disAllowItem}
                                </div>
                              ))}
                            </CardContent>
                          </Card>
                        </Grid>
                      </>
                    )
                  );
                })
              ) : isLoading ? (
                <p className="w-full flex flex-row items-center justify-center text-white">
                  {showHelperText}
                </p>
              ) : (
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="subtitle1" className="text-[#e7e5e4]">
                    {t("notAvailableRobotCheck", {
                      lng: localStorage.getItem("lang"),
                    })}{" "}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </div>
    </>
  );
}
