import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useContext, useState } from "react";
import {
  StyledButton,
  StyledTextField,
} from "../../components/styled-components";
import { AppContext } from "../../context/AppContext";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { lng } from "../../lang/languagesObjects";
import { useNavigate } from "react-router-dom";
import { handleAutoLogOut } from "../../includes/functions";
import { useApi } from "../../customAxios";

export function UpdateProfileComponent({
  showLoading,
  setShowLoading,
  openUpdateDialog,
  handleOpenUpdateDialog,
  handleCloseUpdateDialog,
  updateFirstName,
  updateLastName,
  updateEmail,
  updatePhone,
  setUpdateFirstName,
  setUpdateLastName,
  setUpdateEmail,
  setUpdatePhone,
  setShowUpdateForm,
  showUpdateForm,
  setTextResultUpdate,
  textResultUpdate,
  showResult,
  setShowResult,
}) {
  // for multi language
  const { t } = useTranslation("prints");
  i18next.addResourceBundle("fa", "prints", lng.updateDialog.fa);
  i18next.addResourceBundle("en", "prints", lng.updateDialog.en);
  i18next.addResourceBundle("ar", "prints", lng.updateDialog.ar);
  // initial states
  const api = useApi();
  const navigate = useNavigate();
  //for update profile
  const handleUpdateProfile = () => {
    setShowResult(false);
    setShowLoading(true);
    setShowUpdateForm(false);

    api
      .post("/profile/update", {
        first_name: updateFirstName,
        last_name: updateLastName,
        mobile: updatePhone,
        email: updateEmail,
      })
      .then((res) => {
        console.log("update ", res);
        if (res.data.status === false) {
          // console.log("update statuse->fasle", res);
          throw Error(res.data.message);
        }
        setShowLoading(false);
        setShowResult(true);
        setTextResultUpdate(res.data.message);

        const newData = JSON.parse(localStorage.getItem("user"));
        newData.user.data.data.firstName = updateFirstName;
        newData.user.data.data.lastName = updateLastName;
        newData.user.data.data.mobile = updatePhone;
        newData.user.data.data.email = updateEmail;
        localStorage.setItem("user", JSON.stringify(newData));
      })
      .catch((err) => {
        console.log("err update-profile", err);
        setShowLoading(false);
        setShowUpdateForm(true);
        setShowResult(true);
        setTextResultUpdate(err.message);
      });
  };
  // for gradient progress
  function GradientCircularProgress() {
    return (
      <>
        <svg width={0} height={0}>
          <defs>
            <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#e01cd5" />
              <stop offset="100%" stopColor="#1CB5E0" />
            </linearGradient>
          </defs>
        </svg>
        <CircularProgress
          size={40}
          sx={{ "svg circle": { stroke: "url(#my_gradient)" } }}
        />
      </>
    );
  }
  return (
    <>
      {" "}
      <Dialog
        open={openUpdateDialog}
        fullWidth
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "22px",
            background: "transparent",
          },
        }}
      >
        <DialogTitle
          sx={{
            borderBottom: "1px solid #666666",
          }}
          className="flex flex-row justify-between items-center bg-gradient-to-l from-bgGradientFromTitleDialogLight to-bgGradientToTitleDialogLight dark:from-bgGradientFromTitleDialogDark dark:to-bgGradientToTitleDialogDark "
        >
          <p className="text-xl text-[#e4e4e4]">
            {t("updateTitle", { lng: localStorage.getItem("lang") })}
          </p>
          <CloseRoundedIcon
            onClick={handleCloseUpdateDialog}
            fontSize="medium"
            className="cursor-pointer"
            htmlColor="#e4e4e4"
            sx={{
              opacity: 0.7,
              "&:hover": {
                opacity: 1,
              },
            }}
          />
        </DialogTitle>
        <DialogContent className="bg-gradient-to-l dark:from-bgGradientFromContentDialogDark dark:to-bgGradientToContentDialogDark from-bgGradientFromContentDialogLight to-bgGradientToContentDialogLight">
          {showResult && (
            <div className="w-full h-14 text-slate-100 text-lg flex items-center justify-center">
              {textResultUpdate}
            </div>
          )}
          {showLoading && (
            <div className="flex flex-row justify-center items-center w-full mt-3">
              <GradientCircularProgress />
              {/* <CircularProgress color="" size={40} sx={{}} /> */}
            </div>
          )}
          {showUpdateForm && (
            <Grid container spacing={2} columns={12} className="pt-6 pb-3">
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <StyledTextField
                  className="w-full"
                  label={t("firstName", { lng: localStorage.getItem("lang") })}
                  size="small"
                  value={updateFirstName}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (
                      /^[A-Za-z0-9]+$/.test(inputValue) ||
                      e.target.value === ""
                    ) {
                      setUpdateFirstName(inputValue);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <StyledTextField
                  className="w-full"
                  label={t("lastName", { lng: localStorage.getItem("lang") })}
                  size="small"
                  value={updateLastName}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (
                      /^[A-Za-z0-9]+$/.test(inputValue) ||
                      e.target.value === ""
                    ) {
                      setUpdateLastName(inputValue);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <StyledTextField
                  className="w-full"
                  // disabled={
                  //   JSON.parse(localStorage.getItem("user")).user.data.data.email
                  //     ? true
                  //     : false
                  // }
                  label={t("updateEmail", {
                    lng: localStorage.getItem("lang"),
                  })}
                  size="small"
                  value={updateEmail}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (
                      /^[A-Za-z0-9]+$/.test(inputValue) ||
                      e.target.value === ""
                    ) {
                      setUpdateEmail(inputValue);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <StyledTextField
                  className="w-full"
                  // disabled={true}
                  label={t("updatePhone", {
                    lng: localStorage.getItem("lang"),
                  })}
                  size="small"
                  value={updatePhone}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (
                      /^[A-Za-z0-9]+$/.test(inputValue) ||
                      e.target.value === ""
                    ) {
                      setUpdatePhone(inputValue);
                    }
                  }}
                />
              </Grid>
            </Grid>
          )}
        </DialogContent>
        {showUpdateForm && (
          <DialogActions
            className="bg-gradient-to-l dark:from-bgGradientFromActionDialogDark dark:to-bgGradientToActionDialogDark from-bgGradientFromContentDialogLight to-bgGradientToContentDialogLight"
            sx={{
              borderTop: "1px solid #666666",
            }}
          >
            <StyledButton
              className="mr-1 w-28 bg-gradient-to-l dark:from-bgGradientFromCustomButtonDark dark:to-bgGradientToCustomButtonDark dark:hover:from-bgGradientFromCustomButtonHoverDark dark:hover:to-bgGradientToCustomButtonHoverDark bg-bgCustomButtonLight hover:bg-bgCustomButtonHoverLight  "
              onClick={handleUpdateProfile}
            >
              {t("updateButton", { lng: localStorage.getItem("lang") })}
            </StyledButton>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
}
