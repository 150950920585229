import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { RewriteTo } from "./components/RewriteTo";
import { RedirectFileOrDirectory } from "./components/RedirectFileOrDirectory";
import { CachingJavascript } from "./components/CachingJavascript";
import { PasswordProtectFile } from "./components/PasswordProtectFile";
import { Htpasswd } from "./components/Htpasswd";
import { PreventHotlinking } from "./components/PreventHotlinking";
import { CustomErrorPages } from "./components/CustomErrorPages ";
import { BlockOrAllowAnIP } from "./components/BlockOrAllowAnIP";
import { BlockBots } from "./components/block bots/BlockBots";
import { ChangeDefaultDirectoryPage } from "./components/ChangeDefaultDirectoryPage";
import { PreventViewing } from "./components/PreventViewing";
import { PreventDirectoryListing } from "./components/PreventDirectoryListing";
import { StyledButton } from "../../../components/styled-components";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { saveAs } from "file-saver";
import { Link } from "react-router-dom";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import EngineeringIcon from "@mui/icons-material/Engineering";
import JSZip from "jszip";

export function HtaccessGenerator({ flagURL }) {
  //initial states
  // rewrite to state
  const [rewriteTo, setRewriteTo] = useState(null);
  // redirect file or directory state
  const [oldFilePath, setOldFilePath] = useState(null);
  const [oldDirectoryPath, setOldDirectoryPath] = useState(null);
  const [newFilePath, setNewFilePath] = useState(null);
  const [newDirectoryPath, setNewDirectoryPath] = useState(null);
  // cachinh javascript inputs
  const [fileExtensions, setFileExtensions] = useState(null);
  const [time, setTime] = useState(null);
  const [unit, setUnit] = useState(60);
  const [reValid, setReValid] = useState(false);
  const [type, setType] = useState("public");
  // Hotlinking inputs states
  const [referringURL, setReferringURL] = useState(null);
  const [extensionInput, setExtensionInput] = useState(null);
  const [hotlinkingImage, setHotlinkingImage] = useState(null);
  // for custom error page
  const [error400, setError400] = useState(null);
  const [error401, setError401] = useState(null);
  const [error403, setError403] = useState(null);
  const [error404, setError404] = useState(null);
  const [error500, setError500] = useState(null);
  // for default directory page
  const [defaultDirectoryPage, setDefaultDirectoryPage] = useState(null);
  // for Prevent viewing
  const [preventViewingInput, setPreventViewingInput] = useState(false);
  //for Prevent Directory Listing
  const [preventDirectoryInput, setPreventDirectoryInput] = useState(false);
  // for htpasswd states
  const [userInput, setUserInput] = useState(null);
  const [passInput, setPassInput] = useState(null);
  // for password protect file
  const [fileName, setFileName] = useState(null);
  const [filePath, setFilePath] = useState(null);
  // block bot list for show
  const [blockBotList, setBlockBotList] = useState([]);
  // for blocked or allow to ipies
  const [allowIpInput, setAllowIpInput] = useState(null);
  const [blockIpInput, setBlockIpInput] = useState(null);
  // for htaccess content
  const htaccessContent = `//htaccess generated by [Helpix] https://helpix.app\n\n${
    rewriteTo
      ? `//Rewrite to www\nOptions +FollowSymLinks\nRewriteEngine on\nRewriteCond %{HTTP_HOST} ^${rewriteTo}[nc]\nRewriteRule ^(.*)$ ${rewriteTo}/$1 [r=301,nc]\n\n`
      : ""
  }${
    oldFilePath && newFilePath
      ? `//301 Redirect Old File\nRedirect 301 ${oldFilePath}  ${newFilePath}\n\n`
      : ""
  }${
    oldDirectoryPath && newDirectoryPath
      ? `//301 Redirect Entire Directory\nRedirectMatch 301 ${oldDirectoryPath}(.*)  ${newDirectoryPath}/$1\n\n`
      : ""
  }${
    fileExtensions
      ? `//Caching schema\n<FilesMatch ".(${fileExtensions})$">\nHeader set Cache-Control "${type}, ${
          reValid ? " proxyre-validate," : ""
        }  max-age=${time ? time * unit : "0"}"\n</FilesMatch>\n\n`
      : ""
  }${
    referringURL
      ? `//Stop hotlinking\nRewriteCond %{HTTP_REFERER} !^$\nRewriteCond %{HTTP_REFERER} !^http://(www.)?${referringURL}.*$ [NC]\nRewriteRule .(${extensionInput})$ ${hotlinkingImage}[R=302,L]\n\n`
      : ""
  }${error400 ? `//Custom 400 errors\nErrorDocument 400 ${error400}\n\n` : ""}${
    error401 ? `//Custom 401 errors\nErrorDocument 401 ${error401}\n\n` : ""
  }${error403 ? `//Custom 403 errors\nErrorDocument 403 ${error403}\n\n` : ""}${
    error404 ? `//Custom 404 errors\nErrorDocument 404 ${error404}\n\n` : ""
  }${error500 ? `//Custom 500 errors\nErrorDocument 500 ${error500}\n\n` : ""}${
    defaultDirectoryPage
      ? `//Change default directory page\nDirectoryIndex ${defaultDirectoryPage}\n\n`
      : ""
  }${
    preventViewingInput
      ? `//Prevent viewing of .htaccess file\n<Files .htaccess>\norder allow,deny\ndeny from all\n</Files>\n\n`
      : ""
  }${
    preventDirectoryInput
      ? `//Prevent directory listings\nOptions All -Indexes\n\n`
      : ""
  }${
    userInput && passInput
      ? `//Password Protect file\n<Files ${fileName}>\nAuthName "Prompt"\nAuthType Basic\nAuthUserFile ${filePath}\nRequire valid-user\n</Files>\n\n`
      : ""
  }${
    blockBotList.length > 0
      ? `//Block bad bots\n${blockBotList
          .map((item) => `RewriteCond %{HTTP_USER_AGENT} ^${item} [OR]\n`)
          .join("")}`
      : ""
  }${
    allowIpInput && !blockIpInput
      ? `\n//Allow users by IP\norder allow\nallow from ${allowIpInput}\n\n`
      : ""
  }${
    blockIpInput && !allowIpInput
      ? `\n//Block users by IP\norder deny\ndeny from ${blockIpInput}\n\n`
      : ""
  }${
    blockIpInput && allowIpInput
      ? `\n//Block & Allow users by IP\norder allow,deny\ndeny from ${blockIpInput}\nallow from ${allowIpInput}\n`
      : ""
  }`;

  function exportFile(content, name) {
    const blob = new Blob([content], { type: "application/octet-stream" });
    const zip = new JSZip(); // فرض بر این است که JSZip را به پروژه اضافه کرده‌اید
    zip.file(`.${"htaccess"}`, blob);

    zip.generateAsync({ type: "blob" }).then(function (content) {
      saveAs(content, `${"htaccess"}.zip`); // نام فایل ZIP
    });
  }
  useEffect(() => {
    console.log("blockBotList: ", blockBotList);
  }, [blockBotList]);

  // handle copy content
  const handleCopyHashText = (content) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(content);
    } else {
      console.log("Clipboard API not supported");
    }
  };
  return (
    <>
      <Card
        className={`${
          localStorage.getItem("theme") === "dark-mode"
            ? "card-bg-gradient-dark"
            : "card-bg-gradient-light"
        } w-full mb-32`}
      >
        <CardHeader
          title={
            <div
              className="w-full h-14"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent:
                  localStorage.getItem("dir") === "ltr" ? "start" : "end",
              }}
            >
              <p className="text-slate-200 text-2xl w-fit ">
                Htaccess Generator
              </p>
            </div>
          }
        />{" "}
        <CardContent>
          <Grid container spacing={3} columns={12}>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <Grid container spacing={2} columns={12}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <RewriteTo
                    rewriteTo={rewriteTo}
                    setRewriteTo={setRewriteTo}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <RedirectFileOrDirectory
                    oldFilePath={oldFilePath}
                    setOldFilePath={setOldFilePath}
                    oldDirectoryPath={oldDirectoryPath}
                    setOldDirectoryPath={setOldDirectoryPath}
                    newFilePath={newFilePath}
                    setNewFilePath={setNewFilePath}
                    newDirectoryPath={newDirectoryPath}
                    setNewDirectoryPath={setNewDirectoryPath}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <CachingJavascript
                    fileExtensions={fileExtensions}
                    setTime={setTime}
                    setUnit={setUnit}
                    setFileExtensions={setFileExtensions}
                    time={time}
                    unit={unit}
                    reValid={reValid}
                    setReValid={setReValid}
                    type={type}
                    setType={setType}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Htpasswd
                    userInput={userInput}
                    setUserInput={setUserInput}
                    passInput={passInput}
                    setPassInput={setPassInput}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <PasswordProtectFile
                    fileName={fileName}
                    setFileName={setFileName}
                    filePath={filePath}
                    setFilePath={setFilePath}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <PreventHotlinking
                    referringURL={referringURL}
                    setReferringURL={setReferringURL}
                    extensionInput={extensionInput}
                    setExtensionInput={setExtensionInput}
                    hotlinkingImage={hotlinkingImage}
                    setHotlinkingImage={setHotlinkingImage}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <CustomErrorPages
                    error400={error400}
                    setError400={setError400}
                    error401={error401}
                    setError401={setError401}
                    error403={error403}
                    setError403={setError403}
                    error404={error404}
                    setError404={setError404}
                    error500={error500}
                    setError500={setError500}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <BlockOrAllowAnIP
                    allowIpInput={allowIpInput}
                    setAllowIpInput={setAllowIpInput}
                    blockIpInput={blockIpInput}
                    setBlockIpInput={setBlockIpInput}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <ChangeDefaultDirectoryPage
                    defaultDirectoryPage={defaultDirectoryPage}
                    setDefaultDirectoryPage={setDefaultDirectoryPage}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <PreventViewing
                    preventViewingInput={preventViewingInput}
                    setPreventViewingInput={setPreventViewingInput}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <PreventDirectoryListing
                    preventDirectoryInput={preventDirectoryInput}
                    setPreventDirectoryInput={setPreventDirectoryInput}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <BlockBots
                    flagURL={flagURL}
                    blockBotList={blockBotList}
                    setBlockBotList={setBlockBotList}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              // className="flex justify-center items-center"
            >
              <Grid container spacing={3} columns={12}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className="w-full h-full relative bg-transparent">
                    {" "}
                    <textarea
                      value={htaccessContent}
                      readOnly
                      rows={14}
                      spellCheck={false}
                      style={{
                        resize: "none",
                        backgroundColor: "rgb(193 189 189)",
                        fontSize: "13px",
                      }}
                      className="p-3 w-full rounded-md "
                    />
                    <ContentCopyIcon
                      onClick={() => {
                        handleCopyHashText(htaccessContent);
                      }}
                      fontSize="medium"
                      className="cursor-pointer absolute top-2 right-2 pl-1"
                      htmlColor="#1e293b"
                    />
                  </div>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <StyledButton
                    onClick={() => {
                      exportFile(htaccessContent);
                    }}
                    className="w-full bg-gradient-to-l dark:from-bgGradientFromCustomButtonDark dark:to-bgGradientToCustomButtonDark dark:hover:from-bgGradientFromCustomButtonHoverDark dark:hover:to-bgGradientToCustomButtonHoverDark bg-bgCustomButtonLight hover:bg-bgCustomButtonHoverLight"
                  >
                    Export
                  </StyledButton>
                </Grid>{" "}
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <StyledButton className="w-full bg-gradient-to-l dark:from-bgGradientFromCustomButtonDark dark:to-bgGradientToCustomButtonDark dark:hover:from-bgGradientFromCustomButtonHoverDark dark:hover:to-bgGradientToCustomButtonHoverDark bg-bgCustomButtonLight hover:bg-bgCustomButtonHoverLight">
                    Generate
                  </StyledButton>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  {" "}
                  <div
                    style={{ borderTop: "1px solid gray" }}
                    className="text-slate-100 w-full h-full pt-8 flex flex-col justify-start items-start gap-3"
                  >
                    <div className="w-full flex justify-start items-center gap-1">
                      <HelpOutlineIcon fontSize="medium" />
                      <h4 className="font-bold">What is the htpasswd ?</h4>
                    </div>
                    <p style={{ textAlign: "justify", lineHeight: "30px" }}>
                      htpasswd is used to create and update the flat-files used
                      to store usernames and password for basic authentication
                      of HTTP users. If htpasswd cannot access a file, such as
                      not being able to write to the output file or not being
                      able to read the file in order to update it, it returns an
                      error status and makes no changes. Resources available
                      from the Apache HTTP server can be restricted to just the
                      users listed in the files created by htpasswd. This
                      program can only manage usernames and passwords stored in
                      a flat-file. It can{" "}
                      <Link
                        className="hover:text-blue-500 hover:underline text-blue-400"
                        to={"/hash-generator"}
                      >
                        "Hash"
                      </Link>{" "}
                      and display password information for use in other types of
                      data stores, though.
                    </p>
                  </div>
                </Grid>{" "}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  {" "}
                  <div className="text-slate-100 w-full h-full flex flex-col justify-start items-start gap-3">
                    <div className="w-full flex justify-start items-center gap-1">
                      <EngineeringIcon fontSize="medium" />
                      <h4 className="font-bold">How to use htpasswd ?</h4>
                    </div>
                    <p style={{ textAlign: "justify", lineHeight: "30px" }}>
                      Just enter username and password and an entry for a
                      htpasswd file is generated. You can use the htaccces
                      Authentication generator to create a htaccess file that
                      will password protect your site or a directory. This
                      htpasswd generator creates passwords that are hashed using
                      the MD5 algorithm, which means that you can use it for
                      sites hosted on any platform, including Windows and Linux.
                      You can also create htpasswd passwords with PHP on your
                      own server – this technique only works on Linux though.
                    </p>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
