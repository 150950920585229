import { useEffect, useState } from "react";

import * as CryptoJS from "crypto-js";
import forge from "node-forge";
import { saveAs } from "file-saver";

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  StyledAutocomplete,
  StyledButton,
  StyledTextField,
} from "../../components/styled-components";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import TagIcon from "@mui/icons-material/Tag";
import ModeStandbyIcon from "@mui/icons-material/ModeStandby";
import LockIcon from "@mui/icons-material/Lock";
import PublicIcon from "@mui/icons-material/Public";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";

export function HashGenerator() {
  //initial states
  const [textAreaInput, setTextAreaInput] = useState(null);
  const [autoCompleteInput, setAutoCompleteInput] = useState("MD5");
  const [result, setResult] = useState(null);
  const [hashType, setHashType] = useState(
    CryptoJS.MD5(textAreaInput).toString()
  );
  const [privateKeyXML, setPrivateKeyXML] = useState(null);
  const [publicKeyXML, setPublicKeyXML] = useState(null);

  const [byte, setByte] = useState(null);
  const [privateKeyString, setPrivateKeyString] = useState(null);
  const [publicKeyString, setPublicKeyString] = useState(null);

  //methods hash option from auto complete & bytes options
  const hashOptions = [
    {
      id: 1,
      label: "MD5",
      value: CryptoJS.MD5(textAreaInput).toString(),
    },

    {
      id: 2,
      label: "RipeMD-160",
      value: CryptoJS.RIPEMD160(textAreaInput).toString(),
    },

    {
      id: 3,
      label: "SHA-224",
      value: CryptoJS.SHA224(textAreaInput).toString(),
    },
    {
      id: 4,
      label: "SHA-256",
      value: CryptoJS.SHA256(textAreaInput).toString(),
    },
  ];

  const bytesOptions = [
    {
      id: 1,
      label: "512 bit",
      value: 512,
    },
    {
      id: 2,
      label: "1024 bit",
      value: 1024,
    },
    {
      id: 3,
      label: "2048 bit",
      value: 2048,
    },
    {
      id: 4,
      label: "4096 bit",
      value: 4096,
    },
  ];
  const hashFunctions = {
    MD5: (input) => CryptoJS.MD5(input).toString(),
    "RipeMD-160": (input) => CryptoJS.RIPEMD160(input).toString(),
    "SHA-224": (input) => CryptoJS.SHA224(input).toString(),
    "SHA-256": (input) => CryptoJS.SHA256(input).toString(),
  };
  const handleHash = () => {
    setResult(hashFunctions[autoCompleteInput](textAreaInput));
  };
  useEffect(() => {
    handleHash();
  }, [autoCompleteInput]);

  // handle copy text
  const handleCopyHashText = (content) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(content);
    } else {
      console.log("Clipboard API not supported");
    }
  };

  //generate keys
  function generateKeys() {
    const keyPair = forge.pki.rsa.generateKeyPair({ bits: byte });
    const privateKeyPem = forge.pki.privateKeyToPem(keyPair.privateKey);
    const publicKeyPem = forge.pki.publicKeyToPem(keyPair.publicKey);
    setPrivateKeyString(privateKeyPem);
    setPublicKeyString(publicKeyPem);
    const privateKeyXml = `
  <RSAKeyValue>
    <Modulus>${forge.util.encode64(
      String.fromCharCode.apply(null, keyPair.privateKey.n.toByteArray())
    )}</Modulus>
    <Exponent>${forge.util.encode64(
      String.fromCharCode.apply(null, keyPair.privateKey.e.toByteArray())
    )}</Exponent>
    <P>${forge.util.encode64(
      String.fromCharCode.apply(null, keyPair.privateKey.p.toByteArray())
    )}</P>
    <Q>${forge.util.encode64(
      String.fromCharCode.apply(null, keyPair.privateKey.q.toByteArray())
    )}</Q>
    <DP>${forge.util.encode64(
      String.fromCharCode.apply(null, keyPair.privateKey.dP.toByteArray())
    )}</DP>
    <DQ>${forge.util.encode64(
      String.fromCharCode.apply(null, keyPair.privateKey.dQ.toByteArray())
    )}</DQ>
    <InverseQ>${forge.util.encode64(
      String.fromCharCode.apply(null, keyPair.privateKey.qInv.toByteArray())
    )}</InverseQ>
    <D>${forge.util.encode64(
      String.fromCharCode.apply(null, keyPair.privateKey.d.toByteArray())
    )}</D>
  </RSAKeyValue>
  `;

    const publicKeyXml = `
  <RSAKeyValue>
    <Modulus>${forge.util.encode64(
      String.fromCharCode.apply(null, keyPair.publicKey.n.toByteArray())
    )}</Modulus>
    <Exponent>${forge.util.encode64(
      String.fromCharCode.apply(null, keyPair.publicKey.e.toByteArray())
    )}</Exponent>
  </RSAKeyValue>
  `;
    setPrivateKeyXML(privateKeyXml);
    setPublicKeyXML(publicKeyXml);
  }

  //handle export keys xml file
  function exportFile(content, name) {
    const blob = new Blob([content], {
      type: "text/plain;charset=utf-8",
    });
    saveAs(blob, `${name}.xml`);
  }

  return (
    <>
      <div className="flex flex-col justify-start items-stretch gap-4 pb-28">
        <Card
          className={`${
            localStorage.getItem("theme") === "dark-mode"
              ? "card-bg-gradient-dark"
              : "card-bg-gradient-light"
          } w-full`}
        >
          <CardHeader
            title={
              <div
                className="w-full h-14"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent:
                    localStorage.getItem("dir") === "ltr" ? "start" : "end",
                }}
              >
                <p className="text-slate-200 text-2xl w-fit ">Hash Generator</p>
              </div>
            }
          />{" "}
          <CardContent>
            <Grid container columns={12} spacing={2}>
              <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
                <StyledTextField
                  label="Normal text"
                  className="pl-2 pr-2 pb-4 w-full"
                  multiline
                  maxRows={3}
                  minRows={3}
                  value={textAreaInput}
                  onChange={(e) => {
                    setTextAreaInput(e.target.value);
                    handleHash();
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={2}
                md={2}
                lg={2}
                xl={2}
                className="flex flex-col justify-center items-center gap-2"
              >
                <StyledAutocomplete
                  disableClearable
                  sx={{
                    textAlign: "left",
                    width: "100%",
                    direction: localStorage.getItem("dir"),
                  }}
                  onChange={(e, value) => {
                    setAutoCompleteInput(value?.label);
                    setHashType(value?.value);
                  }}
                  value={autoCompleteInput}
                  size="small"
                  options={hashOptions}
                  PaperComponent={({ children }) => (
                    <div className={`dark:bg-slate-900 dark:text-slate-300`}>
                      {children}
                    </div>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={"algorithm"}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          color: "#d6d3d1",
                          "& fieldset": {
                            borderColor: "#d6d3d1",
                          },
                          "&:hover fieldset": {
                            borderColor: "white", // change border color on hover
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: "#d6d3d1",
                        },
                        "& .MuiSvgIcon-root": {
                          color: "white", // change icon color
                        },
                      }}
                    />
                  )}
                />{" "}
                <StyledButton className="w-full bg-gradient-to-l dark:from-bgGradientFromCustomButtonDark dark:to-bgGradientToCustomButtonDark dark:hover:from-bgGradientFromCustomButtonHoverDark dark:hover:to-bgGradientToCustomButtonHoverDark bg-bgCustomButtonLight hover:bg-bgCustomButtonHoverLight">
                  Generate
                </StyledButton>
              </Grid>
              <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
                <div className="w-full h-full bg-[#19173d] flex flex-col justify-around items-start gap-3 p-3 rounded-xl text-slate-200">
                  <div className=" flex flex-row justify-between items-center gap-1 w-full">
                    <Tooltip title={result} placement="bottom">
                      <div className="truncate flex flex-row justify-start items-center gap-1 ">
                        {" "}
                        <p className="font-bold">
                          Your {autoCompleteInput} Hash:
                        </p>
                        <p>{result}</p>
                      </div>
                    </Tooltip>
                    <Tooltip title="Copy" placement="bottom">
                      {" "}
                      <ContentCopyIcon
                        onClick={() => {
                          handleCopyHashText(result);
                        }}
                        fontSize="medium"
                        className="cursor-pointer"
                      />
                    </Tooltip>
                  </div>
                  <div className="truncate flex flex-row justify-start items-center gap-1  w-full">
                    <p className="font-bold">Your string:</p>
                    <p className="">{textAreaInput}</p>
                  </div>
                </div>{" "}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card
          className={`${
            localStorage.getItem("theme") === "dark-mode"
              ? "card-bg-gradient-dark"
              : "card-bg-gradient-light"
          } w-full`}
        >
          <CardContent>
            <Grid container spacing={2} columns={12}>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <label>
                  <div className="w-full flex justify-between items-center">
                    <p className="text-slate-100 text-lg pb-2">Private Key</p>
                    <Tooltip title="Copy">
                      {" "}
                      <ContentCopyIcon
                        onClick={() => {
                          handleCopyHashText(privateKeyString);
                        }}
                        fontSize="medium"
                        className="mb-2 cursor-pointer"
                        htmlColor="#e2e8f0"
                      />
                    </Tooltip>
                  </div>
                  <textarea
                    value={privateKeyString}
                    readOnly
                    rows={10}
                    spellCheck={false}
                    style={{
                      resize: "none",
                      backgroundColor: "rgb(193 189 189)",
                    }}
                    className="p-3 w-full rounded-md"
                  />
                </label>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <label>
                  <div className="w-full flex justify-between items-center">
                    <p className="text-slate-100 text-lg pb-2">Public Key</p>
                    <Tooltip title="Copy">
                      <ContentCopyIcon
                        onClick={() => {
                          handleCopyHashText(publicKeyString);
                        }}
                        fontSize="medium"
                        className="mb-2 cursor-pointer"
                        htmlColor="#e2e8f0"
                      />
                    </Tooltip>
                  </div>

                  <textarea
                    value={publicKeyString}
                    readOnly
                    rows={10}
                    spellCheck={false}
                    style={{
                      resize: "none",
                      backgroundColor: "rgb(193 189 189)",
                    }}
                    className="p-3 w-full rounded-md "
                  />
                </label>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                {" "}
                <Grid container spacing={2} columns={12}>
                  <Grid item xs={7} sm={6} md={3} lg={3} xl={3}>
                    {" "}
                    <StyledAutocomplete
                      disableClearable
                      sx={{
                        textAlign: "left",
                        width: "100%",
                        direction: localStorage.getItem("dir"),
                      }}
                      onChange={(e, value) => {
                        setByte(value?.label);
                      }}
                      value={byte}
                      size="small"
                      options={bytesOptions}
                      PaperComponent={({ children }) => (
                        <div
                          className={`dark:bg-slate-900 dark:text-slate-300`}
                        >
                          {children}
                        </div>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"Key Size"}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              color: "#d6d3d1",
                              "& fieldset": {
                                borderColor: "#d6d3d1",
                              },
                              "&:hover fieldset": {
                                borderColor: "white", // change border color on hover
                              },
                            },
                            "& .MuiInputLabel-root": {
                              color: "#d6d3d1",
                            },
                            "& .MuiSvgIcon-root": {
                              color: "white", // change icon color
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={5} sm={6} md={3} lg={3} xl={3}>
                    <StyledButton
                      onClick={generateKeys}
                      className="w-full bg-gradient-to-l dark:from-bgGradientFromCustomButtonDark dark:to-bgGradientToCustomButtonDark dark:hover:from-bgGradientFromCustomButtonHoverDark dark:hover:to-bgGradientToCustomButtonHoverDark bg-bgCustomButtonLight hover:bg-bgCustomButtonHoverLight"
                    >
                      Generate
                    </StyledButton>
                  </Grid>
                  <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                    <StyledButton
                      onClick={() => {
                        exportFile(privateKeyXML, "privateKey");
                      }}
                      className="w-full bg-gradient-to-l dark:from-bgGradientFromCustomButtonDark dark:to-bgGradientToCustomButtonDark dark:hover:from-bgGradientFromCustomButtonHoverDark dark:hover:to-bgGradientToCustomButtonHoverDark bg-bgCustomButtonLight hover:bg-bgCustomButtonHoverLight"
                    >
                      Private Key
                    </StyledButton>
                  </Grid>
                  <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                    <StyledButton
                      onClick={() => {
                        exportFile(publicKeyXML, "publicKey");
                      }}
                      className="w-full bg-gradient-to-l dark:from-bgGradientFromCustomButtonDark dark:to-bgGradientToCustomButtonDark dark:hover:from-bgGradientFromCustomButtonHoverDark dark:hover:to-bgGradientToCustomButtonHoverDark bg-bgCustomButtonLight hover:bg-bgCustomButtonHoverLight"
                    >
                      Public Key{" "}
                    </StyledButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} className="pt-px">
                <Grid
                  container
                  spacing={7}
                  columns={12}
                  className=" text-slate-200 mt-px pt-px"
                >
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <div className="w-full h-full flex flex-col justify-start items-start gap-3">
                      <div className="w-full flex justify-start items-center gap-1">
                        <TagIcon fontSize="medium" />
                        <h4 className="font-bold">What is hash generator ?</h4>
                      </div>

                      <p style={{ textAlign: "justify", lineHeight: "30px" }}>
                        Hashing is the process of transforming any given key or
                        a string of characters into another value. This is
                        usually represented by a shorter, fixed-length value or
                        key that represents and makes it easier to find or
                        employ the original string. The most popular use of
                        hashing is for setting up hash tables. A hash table
                        stores key and value pairs in a list that's accessible
                        through its index. Because the number of keys and value
                        pairs is unlimited, the hash function maps the keys to
                        the table size. A hash value then becomes the index for
                        a specific element.
                      </p>
                    </div>
                  </Grid>{" "}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <div className="w-full h-full flex flex-col justify-start items-start gap-3">
                      <div className="w-full flex justify-start items-center gap-1">
                        <ModeStandbyIcon fontSize="medium" />
                        <h4 className="font-bold">
                          What is hash power used for ?
                        </h4>
                      </div>

                      <p style={{ textAlign: "justify", lineHeight: "30px" }}>
                        Hash power or hashing power is the power that your
                        computer or hardware uses to run and solve different
                        hashing algorithms. These algorithms are used for
                        generating new cryptocurrencies and allowing
                        transactions between them. This process is also called
                        mining.
                      </p>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <div className="w-full h-full flex flex-col justify-start items-start gap-3">
                      <div className="w-full flex justify-start items-center gap-1">
                        <LockIcon fontSize="small" />
                        <h4 className="font-bold">What is an MD5 hash ?</h4>
                      </div>

                      <p style={{ textAlign: "justify", lineHeight: "30px" }}>
                        An MD5 hash is created by taking a string of an any
                        length and encoding it into a 128-bit fingerprint.
                        Encoding the same string using the MD5 algorithm will
                        always result in the same 128-bit hash output. MD5
                        hashes are commonly used with smaller strings when
                        storing passwords, credit card numbers or other
                        sensitive data in databases such as the popular MySQL.
                        This tool provides a quick and easy way to encode an MD5
                        hash from a simple string of up to 256 characters in
                        length. MD5 hashes are also used to ensure the data
                        integrity of files. Because the MD5 hash algorithm
                        always produces the same output for the same given
                        input, users can compare a hash of the source file with
                        a newly created hash of the destination file to check
                        that it is intact and unmodified.
                      </p>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <div className="w-full h-full flex flex-col justify-start items-start gap-3">
                      <div className="w-full flex justify-start items-center gap-1">
                        <LockIcon fontSize="small" />

                        <h4 className="font-bold">What is an RipeMD-160 ?</h4>
                      </div>
                      <p style={{ textAlign: "justify", lineHeight: "30px" }}>
                        RIPEMD-160 is a cryptographic hash function based upon
                        the Merkle–Damgård construction. It is used in the
                        Bitcoin standard. It is a a strengthened version of the
                        RIPEMD algorithm which produces a 128 bit hash digest
                        while the RIPEMD-160 algorithm produces a 160-bit
                        output. The compression function is made up of 80 stages
                        made up of 5 blocks that run 16 times each. This pattern
                        runs twice with the results being combined at the bottom
                        using modulo 32 addition.
                      </p>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <div className="w-full h-full flex flex-col justify-start items-start gap-3">
                      <div className="w-full flex justify-start items-center gap-1">
                        <LockIcon fontSize="small" />
                        <h4 className="font-bold">What is an SHA-224 ?</h4>
                      </div>
                      <p style={{ textAlign: "justify", lineHeight: "30px" }}>
                        Since SHA-224 is based on SHA-256, roughly the same
                        amount of effort is consumed to compute a SHA-224 or a
                        SHA-256 digest message digest value. Even though SHA-224
                        and SHA-256 have roughly equivalent computational
                        complexity, SHA-224 is an appropriate choice for a
                        one-way hash function that provides 112 bits of
                        security. The use of a different initial value ensures
                        that a truncated SHA-256 message digest value cannot be
                        mistaken for a SHA-224 message digest value computed on
                        the same data. Some usage environments are sensitive to
                        every octet that is transmitted. In these cases, the
                        smaller (by 4 octets) message digest value provided by
                        SHA-224 is important.
                      </p>
                    </div>
                  </Grid>{" "}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <div className="w-full h-full flex flex-col justify-start items-start gap-3">
                      <div className="w-full flex justify-start items-center gap-1">
                        <LockIcon fontSize="small" />
                        <h4 className="font-bold">What is an SHA-256 ?</h4>
                      </div>
                      <p style={{ textAlign: "justify", lineHeight: "30px" }}>
                        What is SHA-256? The SHA-256 algorithm is one flavor of
                        SHA-2 (Secure Hash Algorithm 2), which was created by
                        the National Security Agency in 2001 as a successor to
                        SHA-1. SHA-256 is a patented cryptographic hash function
                        that outputs a value that is 256 bits long.
                      </p>
                    </div>
                  </Grid>{" "}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <div className="w-full h-full flex flex-col justify-start items-start gap-3">
                      <div className="w-full flex justify-start items-center gap-1">
                        <PrivacyTipIcon fontSize="small" />
                        <h4 className="font-bold">What is Private Key ?</h4>
                      </div>
                      <p style={{ textAlign: "justify", lineHeight: "30px" }}>
                        RSA key is a private key based on RSA algorithm. Private
                        Key is used for authentication and a symmetric key
                        exchange during establishment of an SSL/TLS session. It
                        is a part of the public key infrastructure that is
                        generally used in case of SSL certificates.
                      </p>
                    </div>
                  </Grid>{" "}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <div className="w-full h-full flex flex-col justify-start items-start gap-3">
                      <div className="w-full flex justify-start items-center gap-1">
                        <PublicIcon fontSize="small" />
                        <h4 className="font-bold">What is Public Key ?</h4>
                      </div>
                      <p style={{ textAlign: "justify", lineHeight: "30px" }}>
                        RSA (Rivest–Shamir–Adleman) is a public-key
                        cryptosystem, one of the oldest widely used for secure
                        data transmission. The initialism "RSA" comes from the
                        surnames of Ron Rivest, Adi Shamir and Leonard Adleman,
                        who publicly described the algorithm in 1977.
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
          {/* <CardActions className="pb-6"></CardActions> */}
        </Card>
      </div>
    </>
  );
}
