import { Card, Grid, TextField, Typography } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { PingWorldItem } from "./components/PingWorldItem";
import { DnsWorldItem } from "./components/DnsWorldItem";
import { HttpWorldItem } from "./components/HttpWorldItem";
import { TcpWorldItem } from "./components/TcpWorldItem";
import { UdpWorldItem } from "./components/UdpWorldItem";
import { InfoWorldItem } from "./components/InfoWorldItem";
import * as yup from "yup";
import ClearIcon from "@mui/icons-material/Clear";
import {
  StyledAutocomplete,
  StyledButton,
} from "../components/styled-components";
import { useTranslation } from "react-i18next";
import { lng } from "../lang/languagesObjects";
import i18next from "i18next";
import { AppContext } from "../context/AppContext";
export function PingWorldPage({
  darkMode,
  protocols,
  setProtocols,
  language,
  flagURL,
}) {
  // for multilanguage
  const { t } = useTranslation("prints");
  i18next.addResourceBundle("fa", "prints", lng.pingworld.pingWorld.fa);
  i18next.addResourceBundle("en", "prints", lng.pingworld.pingWorld.en);
  i18next.addResourceBundle("ar", "prints", lng.pingworld.pingWorld.ar);
  //initial states
  const { sidebar } = useContext(AppContext);

  const { lang } = useContext(AppContext);
  const [ipBrowser, setIpBrowser] = useState(null);
  const [activeButton, setActiveButton] = useState("info");
  const [showItems, setShowItems] = useState(protocols ? protocols : "info");
  const [dnsInput, setDnsInput] = useState(null);
  const [pingInput, setPingInput] = useState(null);
  const [infoInput, setInfoInput] = useState(null);
  const [httpInput, setHttpInput] = useState(null);
  const [udpInput, setUdpInput] = useState(null);
  const [tcpInput, setTcpInput] = useState(null);
  const [disableTab, setDisableTab] = useState(false);
  const [storedValues, setStoredValues] = useState(
    JSON.parse(localStorage.getItem("inputValues")) || []
  );
  const [doRequest, setDoRequest] = useState(false);
  const inputRef = useRef("");
  const [infoPartDisplay, setInfoPartDisplay] = useState(false);

  // custom yup validation for ipv4
  function ipv4() {
    return this.matches(/(^(\d{1,3}\.){3}(\d{1,3})$)/, {
      excludeEmptyString: true,
    }).test("ip", (value) => {
      return value === undefined || value.trim() === ""
        ? true
        : value.split(".").find((i) => parseInt(i, 10) > 255) === undefined;
    });
  }
  yup.addMethod(yup.string, "ipv4", ipv4);

  //validation schema
  const validationSchema = yup.object().shape({
    ipAddressOrUrl: yup
      .string()
      .test({
        test: function (value) {
          return (
            yup.string().url().isValidSync(value) ||
            yup.string().ipv4().isValidSync(value)
          );
        },
        message: "The IP address or URL entered is not valid",
      })
      .required("Filling this field is required"),
  });
  //validation's function
  function validation() {
    const inputValue = inputRef.current.value.trim();
    console.log("inputValue: ", inputValue);

    validationSchema
      .validate(
        {
          ipAddressOrUrl: inputValue.match(/^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/)
            ? inputValue
            : inputValue.startsWith("https://")
            ? inputValue
            : "https://" + inputValue,
        },
        { abortEarly: false }
      )
      .then((validData) => {
        setDisableTab(false);
        setInfoInput(inputValue);
      })
      .catch((error) => {
        setDisableTab(true);
      });
  }

  useEffect(() => {
    const savedValues = localStorage.getItem("inputValues");
    if (savedValues) {
      setStoredValues(JSON.parse(savedValues));
    }
    return () => {
      setProtocols(false);
    };
  }, []);
  //  for active sidebar & navigation item
  useEffect(() => {
    sidebar.setActiveItem("/ping-world");
  }, []);
  const handleTabClick = () => {
    const inputValue = inputRef.current.value;

    if (inputValue.trim() !== "") {
      const existingValues = new Set(storedValues);
      existingValues.add(inputValue);
      const updatedValues = Array.from(existingValues);
      setStoredValues(updatedValues);
      localStorage.setItem("inputValues", JSON.stringify(updatedValues));
    }
  };

  const handleDeleteOption = (option) => {
    const updatedValues = storedValues.filter(
      (value) => value !== option.value
    );
    const updatedValuesString = JSON.stringify(updatedValues);
    localStorage.setItem("inputValues", updatedValuesString);
    setStoredValues(updatedValues);
  };

  const renderOption = (props, option, state) => (
    <li
      {...props}
      className="flex flex-row items-center justify-between mx-3 my-3"
    >
      {option.label}
      <button
        style={{ fontWeight: "bold", color: "#646666" }}
        onClick={(e) => {
          e.stopPropagation();
          handleDeleteOption(option);
        }}
      >
        <ClearIcon fontSize="small" />
      </button>
    </li>
  );
  const options = storedValues
    .map((value) => ({
      label: value,
      value,
    }))
    .reverse();

  return (
    <>
      <div className={"w-full "}>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "center",
            gap: "10px",
            direction: localStorage.getItem("dir"),
          }}
        >
          <Card
            className={`${
              localStorage.getItem("theme") === "dark-mode"
                ? "card-bg-gradient-dark"
                : "card-bg-gradient-light"
            }`}
            sx={{
              width: "100%",
              padding: "16px 14px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "14px",
            }}
          >
            <Grid
              container
              spacing={1}
              columns={12}
              sx={{
                display: "flex",
                alignItems: "center",
                maxWidth: "100%",
              }}
            >
              <Grid
                item
                xs={protocols ? 9 : 12}
                sm={protocols ? 9 : 12}
                md={protocols ? 9 : 5}
                lg={protocols ? 9 : 5}
                xl={protocols ? 9 : 5}
              >
                <StyledAutocomplete
                  sx={{
                    textAlign: "left",
                    width: "100%",
                    direction: localStorage.getItem("dir"),
                  }}
                  onChange={(e, value) => {
                    if (value) {
                      console.log("onchange value: ", value);
                      validation();
                    } else {
                      setDisableTab(true);
                    }
                  }}
                  onSelect={(event, value) => {
                    console.log("onselect value: ", value);
                    validation();
                  }}
                  size="small"
                  freeSolo
                  options={options}
                  PaperComponent={({ children }) => (
                    <div className={`dark:bg-slate-900 dark:text-slate-300`}>
                      {children}
                    </div>
                  )}
                  renderInput={(params) => (
                    <TextField
                      inputRef={inputRef}
                      {...params}
                      label={t("enterUrl", {
                        lng: localStorage.getItem("lang"),
                      })}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          color: darkMode ? "#d6d3d1" : "black",
                          "& fieldset": {
                            borderColor: "#d6d3d1",
                          },
                          "&:hover fieldset": {
                            borderColor: darkMode ? "white" : "black", // change border color on hover
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: darkMode ? "#d6d3d1" : "black",
                        },
                        "& .MuiSvgIcon-root": {
                          color: darkMode ? "white" : "black", // change icon color
                        },
                      }}
                    />
                  )}
                  renderOption={renderOption}
                />
              </Grid>
              <Grid
                item
                xs={!protocols ? 12 : 3}
                sm={!protocols ? 12 : 3}
                md={!protocols ? 7 : 3}
                lg={!protocols ? 7 : 3}
                xl={!protocols ? 7 : 3}
                width={"100%"}
              >
                <div
                  className="flex flex-row justify-center items-center "
                  style={{ width: "100%" }}
                >
                  <Grid
                    flexWrap="wrap"
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                    }}
                    container
                    columns={12}
                    spacing={1}
                  >
                    {protocols === false ? (
                      <>
                        <Grid
                          sx={{
                            width: "100%",
                            height: "100%",
                          }}
                          item
                          xs={4}
                          sm={4}
                          md={2}
                          lg={2}
                          xl={2}
                        >
                          <StyledButton
                            className="w-full bg-gradient-to-l dark:from-bgGradientFromCustomButtonDark dark:to-bgGradientToCustomButtonDark dark:hover:from-bgGradientFromCustomButtonHoverDark dark:hover:to-bgGradientToCustomButtonHoverDark bg-bgCustomButtonLight hover:bg-bgCustomButtonHoverLight  "
                            disabled={disableTab}
                            size="large"
                            onClick={() => {
                              //
                              setActiveButton("info");
                              setShowItems("info");
                              setInfoPartDisplay(true);
                              setDoRequest(true);
                              handleTabClick();
                              const inputValue = inputRef.current.value;
                              if (
                                inputValue.match(
                                  /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/
                                )
                              ) {
                                setInfoInput(inputValue);
                              } else if (inputValue.startsWith("https://")) {
                                setInfoInput(inputValue);
                              } else {
                                if (inputValue)
                                  setInfoInput("https://" + inputValue);
                                else {
                                  setInfoInput(ipBrowser);
                                }
                              }
                            }}
                            sx={{
                              padding: "0px",
                              margin: "0px",
                              fontSize: "11px",
                              height: "38px",
                              display: "flex",
                              alignItems: "center",
                              color: "#e7e5e4",
                              border: "none",
                              "&:hover": {
                                backgroundColor: "#6b7280",
                                border: "none",
                              },
                            }}
                            variant={
                              activeButton === "info" ? "contained" : "outlined"
                            }
                          >
                            <Typography
                              variant="p"
                              sx={{
                                display: "-webkit-box",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                WebkitLineClamp: 1,
                                WebkitBoxOrient: "vertical",
                              }}
                            >
                              Info
                            </Typography>
                          </StyledButton>
                        </Grid>
                        <Grid
                          // minWidth={90}
                          sx={{ width: "100%" }}
                          item
                          xs={4}
                          sm={4}
                          md={2}
                          lg={2}
                          xl={2}
                        >
                          <StyledButton
                            className="w-full bg-gradient-to-l dark:from-bgGradientFromCustomButtonDark dark:to-bgGradientToCustomButtonDark dark:hover:from-bgGradientFromCustomButtonHoverDark dark:hover:to-bgGradientToCustomButtonHoverDark bg-bgCustomButtonLight hover:bg-bgCustomButtonHoverLight  "
                            size="large"
                            disabled={disableTab}
                            onClick={() => {
                              setActiveButton("ping");
                              setShowItems("ping");
                              handleTabClick();

                              const inputValue = inputRef.current.value;
                              if (!inputValue) {
                                setDoRequest(false);
                              } else if (inputValue) {
                                // setFirstData(null);
                                // setLastData(null);
                                setDoRequest(true);
                              }

                              if (
                                inputValue.match(
                                  /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/
                                )
                              ) {
                                setPingInput(inputValue);
                              } else if (inputValue.startsWith("https://")) {
                                setPingInput(inputValue);
                              } else {
                                setPingInput("https://" + inputValue);
                              }
                            }}
                            sx={{
                              backgroundColor: "#4b5563",
                              padding: "0px",
                              fontSize: "11px",
                              height: "38px",
                              display: "flex",
                              alignItems: "center",
                              color: "#e7e5e4",
                              border: "none",
                              "&:hover": {
                                backgroundColor: "#6b7280",
                                border: "none",
                              },
                            }}
                          >
                            <Typography
                              variant="p"
                              sx={{
                                display: "-webkit-box",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                WebkitLineClamp: 1,
                                WebkitBoxOrient: "vertical",
                              }}
                            >
                              {" "}
                              Ping
                            </Typography>
                          </StyledButton>
                        </Grid>
                        <Grid
                          // minWidth={90}
                          sx={{ width: "100%" }}
                          item
                          xs={4}
                          sm={4}
                          md={2}
                          lg={2}
                          xl={2}
                        >
                          <StyledButton
                            className="w-full bg-gradient-to-l dark:from-bgGradientFromCustomButtonDark dark:to-bgGradientToCustomButtonDark dark:hover:from-bgGradientFromCustomButtonHoverDark dark:hover:to-bgGradientToCustomButtonHoverDark bg-bgCustomButtonLight hover:bg-bgCustomButtonHoverLight  "
                            size="large"
                            disabled={disableTab}
                            onClick={() => {
                              setActiveButton("dns");
                              setShowItems("dns");
                              setDoRequest(true);
                              handleTabClick();
                              const inputValue = inputRef.current.value;
                              if (!inputValue) {
                                setDoRequest(false);
                              }
                              if (
                                inputValue.match(
                                  /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/
                                )
                              ) {
                                setDnsInput(inputValue);
                              } else if (inputValue.startsWith("https://")) {
                                setDnsInput(inputValue);
                              } else {
                                setDnsInput("https://" + inputValue);
                              }
                            }}
                            sx={{
                              backgroundColor: "#4b5563",
                              width: "100%",
                              padding: "0px",
                              fontSize: "11px",
                              height: "38px",
                              display: "flex",
                              alignItems: "center",
                              color: "#e7e5e4",
                              border: "none",
                              "&:hover": {
                                backgroundColor: "#6b7280",
                                border: "none",
                              },
                            }}
                          >
                            <Typography
                              variant="p"
                              sx={{
                                display: "-webkit-box",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                WebkitLineClamp: 1,
                                WebkitBoxOrient: "vertical",
                              }}
                            >
                              DNS
                            </Typography>
                          </StyledButton>
                        </Grid>
                        <Grid
                          // minWidth={90}
                          sx={{ width: "100%" }}
                          item
                          xs={4}
                          sm={4}
                          md={2}
                          lg={2}
                          xl={2}
                        >
                          <StyledButton
                            className="w-full bg-gradient-to-l dark:from-bgGradientFromCustomButtonDark dark:to-bgGradientToCustomButtonDark dark:hover:from-bgGradientFromCustomButtonHoverDark dark:hover:to-bgGradientToCustomButtonHoverDark bg-bgCustomButtonLight hover:bg-bgCustomButtonHoverLight  "
                            size="large"
                            disabled={disableTab}
                            onClick={() => {
                              setActiveButton("tcp");
                              setShowItems("tcp");
                              setDoRequest(true);
                              handleTabClick();
                              const inputValue = inputRef.current.value;
                              if (!inputValue) {
                                setDoRequest(false);
                              }
                              if (
                                inputValue.match(
                                  /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/
                                )
                              ) {
                                setTcpInput(inputValue);
                              } else if (inputValue.startsWith("https://")) {
                                setTcpInput(inputValue);
                              } else {
                                setTcpInput("https://" + inputValue);
                              }
                            }}
                            sx={{
                              backgroundColor: "#4b5563",
                              width: "100%",
                              padding: "0px",
                              fontSize: "11px",
                              height: "38px",
                              display: "flex",
                              alignItems: "center",
                              color: "#e7e5e4",
                              border: "none",
                              "&:hover": {
                                backgroundColor: "#6b7280",
                                border: "none",
                              },
                            }}
                            variant={
                              activeButton === "tcp" ? "contained" : "outlined"
                            }
                          >
                            <Typography
                              variant="p"
                              sx={{
                                display: "-webkit-box",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                WebkitLineClamp: 1,
                                WebkitBoxOrient: "vertical",
                              }}
                            >
                              TCP Port
                            </Typography>
                          </StyledButton>
                        </Grid>
                        <Grid
                          // minWidth={90}
                          sx={{ width: "100%" }}
                          item
                          xs={4}
                          sm={4}
                          md={2}
                          lg={2}
                          xl={2}
                        >
                          <StyledButton
                            className="w-full bg-gradient-to-l dark:from-bgGradientFromCustomButtonDark dark:to-bgGradientToCustomButtonDark dark:hover:from-bgGradientFromCustomButtonHoverDark dark:hover:to-bgGradientToCustomButtonHoverDark bg-bgCustomButtonLight hover:bg-bgCustomButtonHoverLight  "
                            size="large"
                            disabled={disableTab}
                            onClick={() => {
                              setActiveButton("udp");
                              setShowItems("udp");
                              setDoRequest(true);
                              handleTabClick();
                              const inputValue = inputRef.current.value;
                              if (!inputValue) {
                                setDoRequest(false);
                              }
                              if (
                                inputValue.match(
                                  /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/
                                )
                              ) {
                                setUdpInput(inputValue);
                              } else if (inputValue.startsWith("https://")) {
                                setUdpInput(inputValue);
                              } else {
                                setUdpInput("https://" + inputValue);
                              }
                            }}
                            sx={{
                              backgroundColor: "#4b5563",
                              width: "100%",
                              padding: "0px",
                              fontSize: "11px",
                              height: "38px",
                              display: "flex",
                              alignItems: "center",
                              color: "#e7e5e4",
                              border: "none",
                              "&:hover": {
                                backgroundColor: "#6b7280",
                                border: "none",
                              },
                            }}
                          >
                            <Typography
                              variant="p"
                              sx={{
                                display: "-webkit-box",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                WebkitLineClamp: 1,
                                WebkitBoxOrient: "vertical",
                              }}
                            >
                              UDP Port
                            </Typography>
                          </StyledButton>
                        </Grid>
                        <Grid
                          // minWidth={90}
                          sx={{ width: "100%" }}
                          item
                          xs={4}
                          sm={4}
                          md={2}
                          lg={2}
                          xl={2}
                        >
                          <StyledButton
                            className="w-full bg-gradient-to-l dark:from-bgGradientFromCustomButtonDark dark:to-bgGradientToCustomButtonDark dark:hover:from-bgGradientFromCustomButtonHoverDark dark:hover:to-bgGradientToCustomButtonHoverDark bg-bgCustomButtonLight hover:bg-bgCustomButtonHoverLight  "
                            disabled={disableTab}
                            size="large"
                            onClick={() => {
                              setActiveButton("http");
                              setShowItems("http");
                              setDoRequest(true);
                              handleTabClick();
                              const inputValue = inputRef.current.value;
                              if (!inputValue) {
                                setDoRequest(false);
                              }

                              if (
                                inputValue.match(
                                  /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/
                                )
                              ) {
                                setHttpInput(inputValue);
                              } else if (inputValue.startsWith("https://")) {
                                setHttpInput(inputValue);
                              } else {
                                setHttpInput("https://" + inputValue);
                              }
                            }}
                            sx={{
                              backgroundColor: "#4b5563",
                              width: "100%",
                              padding: "0px",
                              fontSize: "11px",
                              height: "38px",
                              display: "flex",
                              alignItems: "center",
                              color: "#e7e5e4",
                              border: "none",
                              "&:hover": {
                                backgroundColor: "#6b7280",
                                border: "none",
                              },
                            }}
                          >
                            <Typography
                              variant="p"
                              sx={{
                                display: "-webkit-box",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                WebkitLineClamp: 1,
                                WebkitBoxOrient: "vertical",
                              }}
                            >
                              HTTP
                            </Typography>
                          </StyledButton>
                        </Grid>
                      </>
                    ) : (
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <StyledButton
                          onClick={() => {
                            if (protocols === "info") {
                              setShowItems("info");
                              handleTabClick();
                              setInfoPartDisplay(true);

                              const inputValue = inputRef.current.value;
                              if (!inputValue) {
                                setDoRequest(false);
                              } else {
                                setDoRequest(true);
                              }

                              if (
                                inputValue.match(
                                  /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/
                                )
                              ) {
                                setInfoInput(inputValue);
                              } else if (inputValue.startsWith("https://")) {
                                setInfoInput(inputValue);
                              } else {
                                setInfoInput("https://" + inputValue);
                              }
                            } else if (protocols === "ping") {
                              setShowItems("ping");
                              handleTabClick();
                              setDoRequest(true);

                              const inputValue = inputRef.current.value;
                              if (!inputValue) {
                                setDoRequest(false);
                              }
                              if (
                                inputValue.match(
                                  /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/
                                )
                              ) {
                                setPingInput(inputValue);
                              } else if (inputValue.startsWith("https://")) {
                                setPingInput(inputValue);
                              } else {
                                setPingInput("https://" + inputValue);
                              }
                            } else if (protocols === "dns") {
                              setShowItems("dns");
                              handleTabClick();

                              const inputValue = inputRef.current.value;
                              if (!inputValue) {
                                setDoRequest(false);
                              } else {
                                setDoRequest(true);
                              }
                              if (
                                inputValue.match(
                                  /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/
                                )
                              ) {
                                setDnsInput(inputValue);
                              } else if (inputValue.startsWith("https://")) {
                                setDnsInput(inputValue);
                              } else {
                                setDnsInput("https://" + inputValue);
                              }
                            } else if (protocols === "tcp") {
                              setShowItems("tcp");
                              handleTabClick();

                              const inputValue = inputRef.current.value;
                              if (!inputValue) {
                                setDoRequest(false);
                              } else {
                                setDoRequest(true);
                              }
                              if (
                                inputValue.match(
                                  /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/
                                )
                              ) {
                                setTcpInput(inputValue);
                              } else if (inputValue.startsWith("https://")) {
                                setTcpInput(inputValue);
                              } else {
                                setTcpInput("https://" + inputValue);
                              }
                            } else if (protocols === "udp") {
                              setShowItems("udp");
                              handleTabClick();

                              const inputValue = inputRef.current.value;
                              if (!inputValue) {
                                setDoRequest(false);
                              } else {
                                setDoRequest(true);
                              }
                              if (
                                inputValue.match(
                                  /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/
                                )
                              ) {
                                setUdpInput(inputValue);
                              } else if (inputValue.startsWith("https://")) {
                                setUdpInput(inputValue);
                              } else {
                                setUdpInput("https://" + inputValue);
                              }
                            } else if (protocols === "http") {
                              setShowItems("http");
                              handleTabClick();
                              const inputValue = inputRef.current.value;
                              if (!inputValue) {
                                setDoRequest(false);
                              } else {
                                setDoRequest(true);
                              }
                              if (
                                inputValue.match(
                                  /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/
                                )
                              ) {
                                setHttpInput(inputValue);
                              } else if (inputValue.startsWith("https://")) {
                                setHttpInput(inputValue);
                              } else {
                                setHttpInput("https://" + inputValue);
                              }
                            }
                          }}
                          className="w-full bg-gradient-to-l dark:from-bgGradientFromCustomButtonDark dark:to-bgGradientToCustomButtonDark dark:hover:from-bgGradientFromCustomButtonHoverDark dark:hover:to-bgGradientToCustomButtonHoverDark bg-bgCustomButtonLight hover:bg-bgCustomButtonHoverLight  "
                          variant="contained"
                          disabled={disableTab}
                          size="large"
                          sx={{
                            color: "#e7e5e4",
                            border: "none",
                          }}
                        >
                          {protocols === "info"
                            ? "info"
                            : protocols === "ping"
                            ? "ping"
                            : protocols === "dns"
                            ? "dns"
                            : protocols === "tcp"
                            ? "tcp"
                            : protocols === "udp"
                            ? "udp"
                            : protocols === "http" && "http"}
                        </StyledButton>
                      </Grid>
                    )}
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Card>
          {showItems === "ping" ? (
            <PingWorldItem
              pingInput={pingInput}
              darkMode={darkMode}
              doRequest={doRequest}
              setDoRequest={setDoRequest}
              flagURL={flagURL}
              language={language}
            />
          ) : null}
          {showItems === "dns" ? (
            <DnsWorldItem
              dnsInput={dnsInput}
              darkMode={darkMode}
              doRequest={doRequest}
              setDoRequest={setDoRequest}
              flagURL={flagURL}
              language={language}
            />
          ) : null}

          {showItems === "http" ? (
            <HttpWorldItem
              httpInput={httpInput}
              darkMode={darkMode}
              doRequest={doRequest}
              setDoRequest={setDoRequest}
              flagURL={flagURL}
              language={language}
            />
          ) : null}

          {showItems === "tcp" ? (
            <TcpWorldItem
              tcpInput={tcpInput}
              darkMode={darkMode}
              doRequest={doRequest}
              setDoRequest={setDoRequest}
              flagURL={flagURL}
              language={language}
            />
          ) : null}

          {showItems === "udp" ? (
            <UdpWorldItem
              udpInput={udpInput}
              darkMode={darkMode}
              doRequest={doRequest}
              setDoRequest={setDoRequest}
              flagURL={flagURL}
              language={language}
            />
          ) : null}

          {showItems === "info" ? (
            <InfoWorldItem
              infoInput={infoInput}
              ipBrowser={ipBrowser}
              setIpBrowser={setIpBrowser}
              darkMode={darkMode}
              doRequest={doRequest}
              setDoRequest={setDoRequest}
              setInfoPartDisplay={setInfoPartDisplay}
              infoPartDisplay={infoPartDisplay}
              flagURL={flagURL}
              language={language}
            />
          ) : null}
        </div>
      </div>
    </>
  );
}
