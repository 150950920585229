import { Grid } from "@mui/material";
import React from "react";
import { StyledTextField } from "../../../../components/styled-components";

export function SMSForm({
  phoneNumber,
  setPhoneNumber,
  messageSMS,
  setMessageSMS,
  setQrCodeValue,
  qrCodeValueStructures,
}) {
  return (
    <Grid container spacing={2} columns={12}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        {" "}
        <StyledTextField
          className="w-full"
          label="Number"
          value={phoneNumber}
          onChange={(e) => {
            setQrCodeValue(qrCodeValueStructures.sms);
            setPhoneNumber(e.target.value);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        {" "}
        <StyledTextField
          multiline
          minRows={4}
          maxRows={7}
          className="w-full"
          label="Message"
          value={messageSMS}
          onChange={(e) => {
            setQrCodeValue(qrCodeValueStructures.sms);
            setMessageSMS(e.target.value);
          }}
        />
      </Grid>
    </Grid>
  );
}
